import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const Status = ({ status }) => (
  <span className={`text-capitalize badge badge-${status}`}>
    <T value={`badges.${status}`} />
  </span>
);

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;
