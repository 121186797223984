import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import IconPanel from '../../../IconPanel';
import suspendedIcon from '../../../../images/icons/Suspended.svg';

const CandidateStoryFailure = () => (
  <IconPanel
    translationKey='components.Report.CandidateStories.failure'
    icon={
      <img
        src={suspendedIcon}
        alt={i18n.getStr(
          'components.Report.CandidateStories.failure.informationNotSent',
        )}
      />
    }
    detailsCentered
  />
);

export default CandidateStoryFailure;
