import React from 'react';
import { QuestionData, FaqQuestion } from './FaqQuestion';

export interface FaqQuestionListProps {
  questions: Array<QuestionData>;
}
const FaqQuestionList: React.FC<FaqQuestionListProps> = ({ questions }) => {
  return (
    <ul>
      {questions.map(({ question, url }: QuestionData, i: number) => (
        <FaqQuestion
          key={`${question}:${url}`}
          question={question}
          url={url}
          id={`faq-question-${i + 1}`}
          className={`faq-question-${i + 1}`}
        />
      ))}
    </ul>
  );
};

export default FaqQuestionList;
