import * as ActionTypes from '../actions';

export const initialState = {
  international: false,
};

export const international = (state = initialState, action) => {
  const { type, value } = action;

  switch (type) {
    case ActionTypes.SET_INTERNATIONAL:
      return {
        ...state,
        international: value,
      };
    default:
      return state;
  }
};
