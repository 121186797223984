import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import ContentContainer from 'components/Workflow/ContentContainer';
import { M } from '@dashboard-experience/mastodon';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const PaidFinesInformation = ({
  back,
  closed,
}: {
  back: () => void;
  closed: () => void;
}) => {
  const closeButton = () => {
    return (
      <M.Button size='sm' kind='secondary' onClick={closed}>
        {i18n.getStr('buttons.close')}
      </M.Button>
    );
  };
  return (
    <div>
      <ContentContainer>
        <div data-testid={DISPUTES_FLOW.disputePaidFinesInformation}>
          {i18n.renderHTML(
            'workflows.dispute.finesHaveBeenPaidInformation.explanation',
          )}
        </div>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_paid_fines_back_button'
        rightButtonContent={closeButton()}
      />
    </div>
  );
};

export default PaidFinesInformation;
