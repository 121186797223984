import PropTypes from 'prop-types';
import React from 'react';

const DisputeSelect = ({
  displayValue,
  selectValue,
  className,
  date,
  trackAction,
  input: { onChange },
}) => {
  const handleClick = () => {
    if (
      selectValue.constructor === Object &&
      selectValue.charges !== undefined
    ) {
      trackAction(`selected_record: ${selectValue.id}`);
    } else {
      trackAction(`selected '${selectValue}'`);
    }
    onChange(selectValue);
  };

  return (
    <div
      data-testid={displayValue}
      className={`d-flex justify-content-between ${className}`}
      onClick={handleClick}
      role='button'
      tabIndex={0}
    >
      <div>{displayValue}</div>
      <div className='text-very-muted'>{date}</div>
    </div>
  );
};

DisputeSelect.propTypes = {
  date: PropTypes.string,
  displayValue: PropTypes.string.isRequired,
  selectValue: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  trackAction: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
};

DisputeSelect.defaultProps = {
  date: '',
};

export default DisputeSelect;
