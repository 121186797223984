import React from 'react';
import styled from 'styled-components';
import { i18n } from '@international/mastodon-i18n';
import { colors } from '@dashboard-experience/mastodon';
import {
  CHECKR_APPLICANTS_BASE_URL,
  CHECKR_APPLICANTS_EU_BASE_URL,
} from '../../constants';

const StyledHeaderLogoContainer = styled.div`
  padding: 17px 23px;

  a {
    color: ${colors.checkrBlue};
    text-decoration: none;
    background-color: transparent;
  }

  .brand-link {
    line-height: 17px;
    display: inline-block;
    .header-logo {
      vertical-align: baseline;
      height: 24px;
      width: 102px;
    }
    .header-portal {
      font-size: 1.15rem;
      font-weight: 200;
      margin-left: 0.5em;
      display: none;
      @media (min-width: 768px) {
        display: inline;
      }
    }
  }
`;

const HeaderLogo: React.FC<{ isInternational: boolean }> = ({
  isInternational,
}) => (
  <StyledHeaderLogoContainer className='header-brand'>
    <a
      href={
        isInternational
          ? CHECKR_APPLICANTS_EU_BASE_URL
          : CHECKR_APPLICANTS_BASE_URL
      }
      className='brand-link'
    >
      <img
        className='header-logo'
        src='https://assets.checkr.com/logo-white-2022.svg'
        alt={i18n.getStr('components.Header.checkrLogo')}
      />
      <span className='header-portal' data-testid='portal-title'>
        {isInternational
          ? i18n.getStr('components.Header.internationalTitle')
          : i18n.getStr('components.Header.title')}
      </span>
    </a>
  </StyledHeaderLogoContainer>
);

export default HeaderLogo;
