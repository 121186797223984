import React from 'react';
import App from 'components/App';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = () => (
  <App>
    <Outlet />
  </App>
);
export default Layout;
