import { useCallback, useState } from 'react';
import {
  DisputeReasonSummary,
  DisputeReasonSummaryMap,
  File,
  ReasonCode,
  Subreasons,
} from 'components/Workflow/Disputes/Types';
import { i18n } from '@international/mastodon-i18n';
import resources from 'lib/translations/resources';

i18n.init({ resources: resources() });
export type transitionCallback = (transition: string) => void;
export type transitionHelper = () => void;
export type WorkflowScreenStateHookResult = {
  addBreadCrumbKeyPath: transitionHelper;
  breadCrumbKeys: (string | undefined)[];
  breadCrumbKeyPaths: (string | undefined)[][];
  currentScreen: string;
  nextScreen: transitionHelper;
  previousScreen: transitionHelper;
  removeBreadCrumbKeyPath: (index: number) => void;
  transitionToScreen: transitionCallback;
  transitionToScreenWithMostRecentBreadCrumbs: transitionCallback;
  transitionBackToScreen: transitionCallback;
};
export type WorkflowScreenStates = {
  [currentScreen: string]: {
    crumbKey?: string;
    transitions: { [transition: string]: string };
  };
};
export type WorkflowScreenStateHook = (
  initialScreen: string,
  workflowScreenStates: WorkflowScreenStates,
) => WorkflowScreenStateHookResult;

export const useWorkflowScreenStates: WorkflowScreenStateHook = (
  initialScreen: string,
  workflowScreenStates: WorkflowScreenStates,
) => {
  const crumbKey = (screen: string): string | undefined => {
    return workflowScreenStates[screen].crumbKey;
  };

  const [currentScreen, setCurrentScreen] = useState(initialScreen);
  const [breadCrumbKeys, setBreadCrumbKeys] = useState([
    crumbKey(initialScreen),
  ]);
  const [breadCrumbKeyPaths, setBreadCrumbKeyPaths] = useState<
    (string | undefined)[][]
  >([]);

  const popBreadCrumbKey = () => setBreadCrumbKeys(keys => keys.slice(0, -1));
  const pushBreadCrumbKey = (screen: string) =>
    setBreadCrumbKeys(keys => [...keys, crumbKey(screen)]);
  const resetBreadCrumbKey = () => {
    setBreadCrumbKeys([crumbKey(initialScreen)]);
  };
  const addBreadCrumbKeyPath = () => {
    setBreadCrumbKeyPaths(paths => {
      return [...paths, breadCrumbKeys];
    });
  };

  const removeBreadCrumbKeyPath = (indexToRemove: number) => {
    const modifiedBreadCrumbKeyPaths = [
      ...breadCrumbKeyPaths.slice(0, indexToRemove),
      ...breadCrumbKeyPaths.slice(indexToRemove + 1),
    ];
    setBreadCrumbKeyPaths(modifiedBreadCrumbKeyPaths);
  };

  const transitionScreen = useCallback(
    (transition: string) => {
      const newScreenName =
        workflowScreenStates[currentScreen].transitions[transition];
      if (!workflowScreenStates[newScreenName])
        throw new Error(
          i18n.getStr('components.youMustProvideConfiguration', newScreenName),
        );
      return newScreenName;
    },
    [currentScreen, workflowScreenStates],
  );

  // Callbacks
  const transitionToScreen: transitionCallback = (transition: string) => {
    const newScreen = transitionScreen(transition);
    setCurrentScreen(newScreen);
    pushBreadCrumbKey(newScreen);
  };

  const transitionToScreenWithMostRecentBreadCrumbs: transitionCallback = (
    transition: string,
  ) => {
    transitionToScreen(transition);
    setBreadCrumbKeys(breadCrumbKeyPaths[breadCrumbKeyPaths.length - 1]);
  };

  const previousScreen: transitionHelper = () => {
    const screen = transitionScreen('previous');
    setCurrentScreen(screen);
    popBreadCrumbKey();
  };

  const nextScreen: transitionHelper = () => {
    transitionToScreen('next');
  };

  const transitionBackToScreen: transitionCallback = (transition: string) => {
    const newScreen = transitionScreen(transition);
    setCurrentScreen(newScreen);
    resetBreadCrumbKey();
  };

  return {
    addBreadCrumbKeyPath,
    breadCrumbKeys,
    breadCrumbKeyPaths,
    currentScreen,
    nextScreen,
    previousScreen,
    removeBreadCrumbKeyPath,
    transitionToScreen,
    transitionToScreenWithMostRecentBreadCrumbs,
    transitionBackToScreen,
  };
};

export type InitDraft = (
  id: string,
  situation: string,
  reasonCode: ReasonCode,
  subreasons: Subreasons,
  disputedItemId: string | null,
) => void;

export type DestroyDraft = (id: string) => void;

export type FinalizeDrafts = () => void;

export type RemoveFinalizeDraft = (i: number) => void;

export type ClearDrafts = () => void;

interface UpdateDraftParameters {
  explanation?: string | null;
  reasonCode?: ReasonCode | null;
  situation?: string | null;
  subreasons?: Subreasons | null;
  files?: File[] | null;
}
export type UpdateDraft = (
  id: string,
  parameters: UpdateDraftParameters,
) => void;

export type DraftSummaryStateHookResult = {
  destroyDraft: DestroyDraft;
  draftDisputeReasonSummaries: DisputeReasonSummaryMap;
  initDraft: InitDraft;
  finalizeDrafts: FinalizeDrafts;
  finalizedDisputeReasonSummaries: DisputeReasonSummary[];
  updateDraft: UpdateDraft;
  clearDrafts: ClearDrafts;
  removeFinalizedDisputeReasonSummary: RemoveFinalizeDraft;
};
export type DisputeReasonSummaryBuilderHook = () => DraftSummaryStateHookResult;

export const useDisputeReasonSummaryBuilder: DisputeReasonSummaryBuilderHook =
  () => {
    const [draftDisputeReasonSummaries, setDraftDisputeReasonSummaries] =
      useState<DisputeReasonSummaryMap>({});

    const [
      finalizedDisputeReasonSummaries,
      setFinalizedDisputeReasonSummaries,
    ] = useState<DisputeReasonSummary[]>([]);

    const initDraft = (
      id: string,
      situation: string,
      reasonCode: ReasonCode,
      subreasons: Subreasons,
      disputedItemId: string | null,
    ) => {
      setDraftDisputeReasonSummaries({
        ...draftDisputeReasonSummaries,
        [id]: {
          disputedItemId,
          situation,
          reasonCode,
          subreasons,
          explanation: '',
          files: [],
        },
      });
    };

    const destroyDraft = (id: string) => {
      const { [id]: omitted, ...remainingDraftDisputeReasonSummaries } =
        draftDisputeReasonSummaries;
      setDraftDisputeReasonSummaries(remainingDraftDisputeReasonSummaries);
    };

    const updateNullableString = (
      newStr: string | null | undefined,
      currentStr: string,
    ) => {
      return newStr === '' ? newStr : newStr || currentStr;
    };

    const updateDraft = (
      id: string,
      {
        explanation,
        reasonCode,
        situation,
        subreasons,
        files,
      }: UpdateDraftParameters,
    ) => {
      const existingDisputeReasonSummary = draftDisputeReasonSummaries[id];
      if (!existingDisputeReasonSummary) {
        throw new Error(i18n.getStr('components.draftDoesNotExist', id));
      }

      setDraftDisputeReasonSummaries({
        ...draftDisputeReasonSummaries,
        [id]: {
          disputedItemId: existingDisputeReasonSummary.disputedItemId || '',
          explanation: updateNullableString(
            explanation,
            existingDisputeReasonSummary.explanation,
          ),
          reasonCode: reasonCode || existingDisputeReasonSummary.reasonCode,
          situation: situation || existingDisputeReasonSummary.situation,
          subreasons: subreasons || existingDisputeReasonSummary.subreasons,
          files: files || existingDisputeReasonSummary.files,
        },
      });
    };

    const finalizeDrafts: FinalizeDrafts = () => {
      const disputeReasonSummaries: DisputeReasonSummary[] = Object.values(
        draftDisputeReasonSummaries,
      );
      setFinalizedDisputeReasonSummaries([
        ...finalizedDisputeReasonSummaries,
        ...disputeReasonSummaries,
      ]);
      setDraftDisputeReasonSummaries({});
    };

    const clearDrafts: ClearDrafts = () => {
      setDraftDisputeReasonSummaries({});
    };

    const removeFinalizedDisputeReasonSummary: RemoveFinalizeDraft =
      indexToBeRemoved => {
        const modifiedSummaryList = [
          ...finalizedDisputeReasonSummaries.slice(0, indexToBeRemoved),
          ...finalizedDisputeReasonSummaries.slice(indexToBeRemoved + 1),
        ];
        setFinalizedDisputeReasonSummaries(modifiedSummaryList);
      };

    return {
      destroyDraft,
      draftDisputeReasonSummaries,
      initDraft,
      finalizeDrafts,
      finalizedDisputeReasonSummaries,
      updateDraft,
      clearDrafts,
      removeFinalizedDisputeReasonSummary,
    };
  };
