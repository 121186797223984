import { Report } from 'types/report';
import moment from 'moment';
import { ReportStatuses } from '@dashboard-experience/utils';
import { UPDATE_PERSONAL_INFO_DISABLED_ACCOUNT_URIS } from './constants';

const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60;

export const isReportEligibleForUpdateDL = (report: Report) => {
  const isMotorVehicleReportConsidered =
    report.motorVehicleReport?.status === ReportStatuses.CONSIDER;
  const isAccountUriEnabled =
    !UPDATE_PERSONAL_INFO_DISABLED_ACCOUNT_URIS.includes(
      report.account.uriName,
    );
  const timeNow = moment();
  const completedAt = report.completedAt && moment(report.completedAt);
  const wasCompletedAt30DaysAgo =
    timeNow.diff(completedAt, 'seconds') <= THIRTY_DAYS_IN_SECONDS;

  return (
    isMotorVehicleReportConsidered &&
    isAccountUriEnabled &&
    wasCompletedAt30DaysAgo
  );
};
