import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { labelErrorClass } from './helper';

const FeedbackLabel = ({ touched, error, warning }) => {
  let properties = {};

  if (error && typeof error !== 'string') {
    // Create a new object without the 'message' property
    properties = Object.fromEntries(
      Object.entries(error).filter(([key]) => key !== 'message'),
    );
    error = error.message;
  }

  return (
    <small
      className={`form-control-feedback ${labelErrorClass(touched, error)}`}
    >
      {touched &&
        ((error && <T value={error} {...properties} />) ||
          (warning && <span>{warning}</span>))}
    </small>
  );
};

FeedbackLabel.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  warning: PropTypes.string,
};

FeedbackLabel.defaultProps = {
  touched: false,
  error: '',
  warning: '',
};

export default FeedbackLabel;
