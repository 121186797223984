/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// REFACTOR FOR LINT LATER
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';

import DisputeSelect from '../../../fields/DisputeSelect';

const SectionsForm = ({ sections, trackAction }) => (
  <div data-testid='sections-form'>
    <div className='form-group'>
      <label className='form-question'>
        <T value='components.Report.Dispute.reportSections.question' />
      </label>
      <hr />
    </div>
    <div className='form-group'>
      {sections.map(section => (
        <Field
          name='section'
          key={section.name}
          id={section.name}
          component={DisputeSelect}
          displayValue={section.name}
          selectValue={section.name}
          trackAction={trackAction}
          className='tile-option-select p-4 mb-2 rounded'
        />
      ))}
    </div>
  </div>
);

SectionsForm.propTypes = {
  sections: PropTypes.array,
  trackAction: PropTypes.func,
};

SectionsForm.defaultProps = {
  sections: [],
  trackAction: () => {},
};

export default SectionsForm;
