import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { getUploadCustomText } from 'components/fields/helper';
import { MAX_FILE_SIZE } from '../../../constants';
import deleteFileStackLink from '../lib/deleteFileStackLink';

type RequiredItem<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

const FileStackDefaultOptions: RequiredItem<any, 'maxFiles'> = {
  accept: ['image/*', '.pdf'],
  storeTo: { location: 'S3' },
  fromSources: ['local_file_system'],
  maxFiles: 1,
  disableTransformer: true,
  uploadInBackground: false,
  onFileUploadFinished: deleteFileStackLink,
  customText: getUploadCustomText(),
  lang: i18n.getLocale().substring(0, 2),
  maxSize: MAX_FILE_SIZE,
};

const useFilestackOptions = (options: any): RequiredItem<any, 'maxFiles'> => {
  const [resultOptions, setResultOptions] = React.useState<
    RequiredItem<any, 'maxFiles'>
  >({
    ...FileStackDefaultOptions,
    ...options,
  });
  React.useEffect(() => {
    setResultOptions({
      ...FileStackDefaultOptions,
      ...options,
    });
  }, [options]);
  return resultOptions;
};
export default useFilestackOptions;
