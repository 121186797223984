import PropTypes from 'prop-types';
import React from 'react';
import AbbreviatedEducation from './AbbreviatedEducation';
import Education from '../../Education/Education';

const displayParsedEducationRecord = record => {
  return record.result.degrees.length > 0;
};

const IntlEducationRecord = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <>
      {displayParsedEducationRecord(record) && <Education record={record} />}
      {!displayParsedEducationRecord(record) && (
        <AbbreviatedEducation record={record} />
      )}
    </>
  );
};

IntlEducationRecord.propTypes = {
  record: PropTypes.object,
};

IntlEducationRecord.defaultProps = {
  record: null,
};
export default IntlEducationRecord;
