import React from 'react';
import { Link } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import { Report } from '../../components/Reports/Report/Report';
import { MOCK_REPORTS } from '../../constants';

const invalidMock = () => (
  <>
    <h3>Invalid mock value</h3>
    <h4>Valid mocks:</h4>
    <ul>
      {Object.keys(MOCK_REPORTS).map(key => (
        <li key={key}>
          <Link to={`/mock?type=${key}`}>{key}</Link>
        </li>
      ))}
    </ul>
  </>
);

const MockReport = () => {
  const myURL = new URL(window.location.toString());
  const mock = myURL.searchParams.get('type');
  const international = myURL.searchParams.get('international');

  if (!mock) {
    return invalidMock();
  }

  // @ts-ignore
  const mockExists = MOCK_REPORTS[mock];
  if (!mockExists) {
    return invalidMock();
  }

  const { report, expungements } = mockExists;

  if (report) {
    return (
      <MainContainer>
        <Report
          report={report}
          expungement={expungements}
          international={!!international}
        />
      </MainContainer>
    );
  }

  return invalidMock();
};

export default MockReport;
