import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import Panel from '../../../Panels';
import ProLicenseVerificationCertificationResults from './ProLicenseVerificationCertificationResults';
import { isEmpty } from '../../../../lib/helpers';

const VERIFIED_BY_PARTNER = 'partner';
const NOT_VERIFIED = 'none';

export class ProLicenseVerification extends React.Component {
  candidateName() {
    const { candidate } = this.props;
    return ['firstName', 'middleName', 'lastName']
      .map(name => candidate[name])
      .filter(name => name)
      .join(' ');
  }

  render() {
    const { screening } = this.props;
    if (screening && screening.status !== 'canceled' && !isEmpty(screening)) {
      const verifiedCertifications = screening.certifications.filter(
        certification =>
          certification.result.verifiedBy === VERIFIED_BY_PARTNER,
      );

      if (verifiedCertifications.length > 0) {
        return (
          <Panel
            data-testid='pro-license-verification'
            title={<T value='components.Report.ProLicenseVerification.title' />}
            status={screening.status}
          >
            {verifiedCertifications && (
              <ProLicenseVerificationCertificationResults
                certifications={verifiedCertifications}
                candidateName={this.candidateName()}
              />
            )}
          </Panel>
        );
      }
    }
    return <div />;
  }
}

ProLicenseVerification.propTypes = {
  screening: PropTypes.object,
  candidate: PropTypes.object,
};

ProLicenseVerification.defaultProps = {
  screening: {},
  candidate: {},
};

export const hasProLicenseVerificationManualEntries = report => {
  return (
    report.professionalLicenseVerifications &&
    report.professionalLicenseVerifications.length > 0 &&
    report.professionalLicenseVerifications.some(
      professionalLicenseVerification =>
        professionalLicenseVerification.certifications.some(
          certification => certification.result.verifiedBy === NOT_VERIFIED,
        ),
    )
  );
};
