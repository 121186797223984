import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import {
  CANS_STEP_PROVIDE,
  CANS_STEP_REVIEW,
  CANS_STEP_SELECT,
} from '../../../../constants';

const CandidateStoryHeader = ({ step, provide_step, provide_total }) => (
  <div>
    <div className='form-group'>
      <div className='breadcrumb-modal'>
        <span className={step === CANS_STEP_SELECT ? 'form-question' : ''}>
          <T value='components.Report.CandidateStories.Modal.selectRecord' />
        </span>
        <span className='text-muted'>&#8226;</span>
        <span className={step === CANS_STEP_PROVIDE ? 'form-question' : ''}>
          <T value='components.Report.CandidateStories.Modal.provideInformation' />
          &nbsp;
          {step === CANS_STEP_PROVIDE &&
            provide_total > 1 &&
            `(${provide_step} of ${provide_total})`}
        </span>
        <span className='text-muted'>&#8226;</span>
        <span className={step === CANS_STEP_REVIEW ? 'form-question' : ''}>
          <T value='components.Report.CandidateStories.Modal.reviewSend' />
        </span>
      </div>
      <hr />
    </div>
  </div>
);

CandidateStoryHeader.propTypes = {
  step: PropTypes.string.isRequired,
  provide_step: PropTypes.number,
  provide_total: PropTypes.number,
};

CandidateStoryHeader.defaultProps = {
  provide_step: 0,
  provide_total: 0,
};

export default CandidateStoryHeader;
