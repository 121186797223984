import React from 'react';
import { Translate as T } from 'react-redux-i18n';

interface DescribeInaccurateTextFormProps {
  section: string;
}

const DescribeInaccurateTextForm = ({
  section,
}: DescribeInaccurateTextFormProps) => {
  return (
    <div data-testid='describe-inaccurate-form'>
      <div className='form-group'>
        <span className='text-very-muted'>
          {`${section} > `}
          <T value='components.Report.Dispute.DescribeSituation.MoreAboutRecordRootPath' />
          {' > '}
        </span>
        <span className='form-question'>
          <T value='components.Report.Dispute.DescribeSituation.MoreAboutRecordCurrentPath' />
        </span>
        <hr />
      </div>
      <div className='form-group'>
        <T
          value='components.Report.Dispute.DescribeSituation.MoreAboutRecordText'
          dangerousHTML
        />
      </div>
    </div>
  );
};

export default DescribeInaccurateTextForm;
