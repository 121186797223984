import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Icon } from './Icon';
import { Paragraph, Alignment as ParagraphAlignment } from './Paragraph';

interface UploadDeadendProps {
  greeting: React.ReactNode;
  uberStyle?: boolean;
}

export const UploadDeadend: React.FC<UploadDeadendProps> = ({
  greeting,
  uberStyle = false,
}) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
        padding: '24px 16px 56px 16px',
        border: 'none',
      }}
    >
      {greeting}
      <StyleableContainer type={Type.Fail} styledTopBorder>
        <Icon type={Type.Fail} />
        <Heading
          textKey='components.InstantExceptions.UploadDeadend.heading'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%', margin: '8px 0px' }} />
        <Paragraph
          textKey='components.InstantExceptions.UploadDeadend.details1'
          align={ParagraphAlignment.Left}
        />
        {uberStyle ? (
          <Paragraph
            textKey='components.InstantExceptions.UploadDeadend.details2'
            align={ParagraphAlignment.Left}
            textVar='Acme'
          />
        ) : null}
      </StyleableContainer>
    </M.Container>
  );
};
