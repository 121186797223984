import React from 'react';
import { TFunction } from 'types/i18n';
import isValidFaqQuestion from '../lib/isValidFaqQuestion';
import { QuestionData } from '../FaqQuestion';

interface FaqTranslation {
  title: string;
  subtitle: string;
  questions: QuestionData[];
}

interface UseFaqTranslation {
  (translationKey: string, t: TFunction): FaqTranslation;
}

const useFaqTranslation: UseFaqTranslation = (translationKey, t) => {
  const [questions, setQuestions] = React.useState<QuestionData[]>([]);
  const [title, setTitle] = React.useState<string>('');
  const [subtitle, setSubtitle] = React.useState<string>('');
  React.useEffect(() => {
    if (translationKey) {
      const faqTranslation = t(translationKey, {
        returnObjects: true,
      });
      const baseTranslation: FaqTranslation = {
        questions: [],
        title: '',
        subtitle: '',
      };
      const {
        questions: tQuestions = [],
        title: tTitle = '',
        subtitle: tSubtitle = '',
      } = Array.isArray(faqTranslation)
        ? baseTranslation
        : ({ ...baseTranslation, ...faqTranslation } as FaqTranslation);
      setQuestions(
        Array.isArray(tQuestions) ? tQuestions.filter(isValidFaqQuestion) : [],
      );
      setTitle(tTitle);
      setSubtitle(tSubtitle);
    }
  }, [t, translationKey]);
  return {
    questions,
    title,
    subtitle,
  };
};

export default useFaqTranslation;
