import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import { useParams } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import Loading from '../../components/Loading';
import Unsubscribe from '../../components/Verification/Unsubscribe';
import { unsubscribeVerification, fetchVerification } from '../../actions';

const Subscription: React.FC = () => {
  const { verification, fetching } = useSelector((state: any) => {
    return {
      verification: state.verifications.verification,
      fetching: state.verifications.fetching,
    };
  });
  const dispatch = useDispatch();
  const { verificationId } = useParams();
  React.useEffect(() => {
    document.title = i18n.getStr('containers.page.manageSubscription');
  }, []);

  React.useEffect(() => {
    if (!fetching && !verification) {
      dispatch(fetchVerification(verificationId));
    }
  }, [dispatch, verificationId, fetching, verification]);

  const handleUnsubscribe = () => {
    dispatch(unsubscribeVerification(verification));
  };

  if (fetching) {
    return (
      <MainContainer>
        <Loading />
      </MainContainer>
    );
  }

  if (!verification) {
    return null;
  }

  return (
    <MainContainer>
      <Unsubscribe verification={verification} onSubmit={handleUnsubscribe} />
    </MainContainer>
  );
};

export default i18n.renderTranslation()(Subscription);
