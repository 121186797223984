import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { Exception } from 'api/candidates/types/Report';
import { M } from '@dashboard-experience/mastodon';
import { useFlagr } from '../../../../api/flagr';
import Panel from '../../../Panels';
import { CUSTOM_OBJECT_NAMES, FLAGR_KEYS } from '../../../../constants';
import classify from '../../../../lib/classify';
import Notification from './Notification';

const getVerificationName = (
  object: string,
  OBJECT_NAMES: string[] = CUSTOM_OBJECT_NAMES,
) => (OBJECT_NAMES.includes(object) ? classify(object) : 'Default');

const getNewVerificationName = (object: string, exceptionType: string) => {
  if (
    object === 'matrix_verification' &&
    MatrixExceptionInfoMap[exceptionType]
  ) {
    return MatrixExceptionInfoMap[exceptionType];
  }

  return classify(object);
};

const MatrixExceptionInfoMap: Record<string, string> = {
  id_documentation_requested: 'IdVerification',
  ssn_confirmation_requested: 'SsnCheck',
  ssn_documentation_requested: 'SsnVerification',
};

const VerificationNotification: React.FC<{
  verification: Record<string, any>;
  isNewSsnResolutionFlow?: boolean;
}> = ({ verification, isNewSsnResolutionFlow = false }) => {
  const { object, exceptionType } = verification;

  const verificationName = isNewSsnResolutionFlow
    ? getNewVerificationName(object, exceptionType)
    : getVerificationName(object);

  if (isNewSsnResolutionFlow) {
    return (
      <Notification
        verificationName={verificationName}
        verification={verification}
      />
    );
  }
  return (
    <Panel
      i18n
      title={`components.Report.Verification.${verificationName}.title`}
      className='warning'
      icon='WarningAltFilled'
    >
      <T
        value={`components.Report.Verification.${verificationName}.text`}
        dangerousHTML
      />
      <div className='p-3 text-center'>
        <M.Link
          href={verification.href}
          target='_blank'
          rel='noopener noreferrer'
        >
          <M.Button kind='primary'>
            <T
              value={`components.Report.Verification.${verificationName}.button`}
            />
          </M.Button>
        </M.Link>
      </div>
    </Panel>
  );
};

const Verifications: React.FC<{
  verifications: Exception[];
  candidate?: any;
}> = ({ verifications, candidate }) => {
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useFlagr({
    context: {
      locationHost: window.location.host,
      candidateEmail: candidate?.email,
    },
    entity: { id: candidate?.email, type: 'candidateEmail' },
    id: FLAGR_KEYS.newSsnResolutionFlow,
    fallbackVariant: 'off',
  });

  if (!verifications || verifications.length === 0) return null;

  return (
    <div className='report__verifications'>
      {verifications.map((verification, i) => {
        const isNewSsnResolutionFlow = newSsnResolutionFlow === 'on';

        if (verification.completedAt && !isNewSsnResolutionFlow) return null;
        return (
          <VerificationNotification
            key={`div-${i + 1}`}
            verification={verification}
            isNewSsnResolutionFlow={isNewSsnResolutionFlow}
          />
        );
      })}
    </div>
  );
};

export default Verifications;
