import React from 'react';
import {
  useNavigate,
  useLocation,
  NavigateFunction,
  Location,
  useParams,
  Params,
} from 'react-router-dom';

type WithRouterProps<T> = T & {
  navigate: NavigateFunction;
  location: Location;
  params: Readonly<Params<string>>;
};

export const withRouter = <T extends object>(
  Component: React.ComponentClass<T>,
) => {
  const WithRouter: React.FC<WithRouterProps<T>> = (props: T) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    return (
      <Component
        navigate={navigate}
        location={location}
        params={params}
        {...props}
      />
    );
  };
  return WithRouter;
};
