import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import IconPanel from '../../../IconPanel';
import pendingIcon from '../../../../images/icons/Pending.svg';

const PendingNotice = ({ copyRequested, applyMessaging }) => (
  <IconPanel
    translationKey={
      applyMessaging
        ? 'components.Report.applyPendingNotice'
        : 'components.Report.PendingNotice'
    }
    icon={
      <img
        src={pendingIcon}
        alt={i18n.getStr('components.Report.reportPending')}
      />
    }
    detailsCentered
  >
    <div className='text-center'>
      {copyRequested ? (
        <T value='components.Report.ETA.confirmation' />
      ) : (
        <T value='components.Report.ETA.confirmation' />
      )}
    </div>
  </IconPanel>
);

PendingNotice.propTypes = {
  copyRequested: PropTypes.bool.isRequired,
  applyMessaging: PropTypes.bool,
};

PendingNotice.defaultProps = {
  applyMessaging: false,
};

export default i18n.renderTranslation()(PendingNotice);
