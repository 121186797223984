import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { getRandomKey } from 'lib/helpers';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import { getChargeTitle } from '../lib/screenings';

const FieldsList = ({ fields }) => {
  const newFields = fields
    .map(field => i18n.getConstStr('disputes.fieldsForm', field))
    .join(', ');
  return (
    <div>
      <T value='components.Report.Dispute.summary.fields' fields={newFields} />
    </div>
  );
};

FieldsList.propTypes = {
  fields: PropTypes.array,
};

FieldsList.defaultProps = {
  fields: [],
};

const DisputeItem = ({ index, item, removeItem }) => {
  return (
    <div
      data-testid={DISPUTES_FLOW.disputeItem}
      className='dispute-item p-4 mb-2 rounded'
    >
      <M.Button
        kind='primary'
        className='text-muted py-2 px-3 float-right'
        onClick={() => {
          removeItem(index);
        }}
      >
        <M.Icon icon='Close' style={{ marginRight: 0 }} />
      </M.Button>
      <div>
        <strong>
          {item.section}: {item.article}
        </strong>
      </div>
      {item.charge && (
        <div>
          <T
            value='components.Report.Dispute.summary.charge'
            title={getChargeTitle(item.charge)}
          />
        </div>
      )}
      {item.fields.length > 0 && <FieldsList fields={item.fields} />}
      <div>
        <T
          value='components.Report.Dispute.summary.explanation'
          explanation={item.explanation}
        />
      </div>
      <div>
        <T
          value='components.Report.Dispute.summary.documentsUploaded'
          documents={item.documents.length}
        />
      </div>
    </div>
  );
};

DisputeItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  removeItem: PropTypes.func,
};

DisputeItem.defaultProps = {
  index: PropTypes.number,
  item: {},
  removeItem: () => {},
};

const DisputeSummary = ({ disputedItems, removeItem }) => {
  return (
    <div data-testid='dispute-summary'>
      {disputedItems.map((item, i) => (
        <DisputeItem
          key={getRandomKey()}
          index={i}
          item={item}
          removeItem={removeItem}
        />
      ))}
      <div className='alert alert-info p-4'>
        <T value='components.Report.Dispute.summary.confirmation' />
      </div>
    </div>
  );
};

DisputeSummary.propTypes = {
  disputedItems: PropTypes.array.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default DisputeSummary;
