import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import withNonCanceledSearch from '../WithNonCanceledSearch';

import Panel from '../../../../Panels';
import Record from '../../Record';

const IntlEducationVerification = ({ search }) => {
  return (
    <Panel
      data-testid='intl-education-verification'
      title={i18n.getStr('components.internationalEducationVerification')}
      status={search.status}
    >
      {Array.isArray(search.records) &&
        (!search.records.length ? (
          <span>
            <T value='components.noEducationHistory' />
          </span>
        ) : (
          search.records.map((record, i) => (
            <React.Fragment key={`intl-edu-record-${i + 1}`}>
              <Record
                record={record}
                type='internationalEducationVerification'
              />
              {i < search.records.length - 1 && <hr />}
            </React.Fragment>
          ))
        ))}
    </Panel>
  );
};

IntlEducationVerification.propTypes = {
  search: PropTypes.object,
};

IntlEducationVerification.defaultProps = {
  search: null,
};

export default withNonCanceledSearch(IntlEducationVerification);
