import httpHelper from './reducerHelpers';

import {
  API_STATUS_REQUEST,
  API_STATUS_SUCCESS,
  API_STATUS_FAILURE,
  ADD_CUSTOM_INCIDENT,
} from '../actions/apiStatusBanner';

export const initialState = {
  apiStatusRequest: { ...httpHelper.initialState },
  incidents: undefined,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case API_STATUS_REQUEST:
      return {
        ...state,
        incidents: action.incidents,
      };
    case API_STATUS_FAILURE:
      return {
        ...state,
        incidents: undefined,
      };
    case API_STATUS_SUCCESS:
      return {
        ...state,
        incidents: action.incidents,
      };
    case ADD_CUSTOM_INCIDENT:
      return {
        ...state,
        customIncidentContentKeys: action.customIncidentContentKeys,
      };
    default:
      return state;
  }
};
