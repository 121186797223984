import * as React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import portalQueryClient from './queryClient';
import portalStore from './portalStore';

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <QueryClientProvider client={portalQueryClient}>
    <Provider store={portalStore}>{children}</Provider>
  </QueryClientProvider>
);

export default Providers;
