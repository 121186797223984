import { moment, i18n } from '@international/mastodon-i18n';
import { shortDatePattern } from 'lib/dateFormat';

export const formatDate = (eta: string | null) => {
  moment.locale(i18n.getLocale());
  if (eta === null)
    return i18n.getStr(
      'components.CandidateLandingPage.card.textItem.description.paused',
    );
  return moment(eta.replace(/z$/i, '')).format(shortDatePattern());
};
