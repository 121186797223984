import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { useNavigate } from 'react-router-dom';
import MastodonPanel from 'components/Panels/MastodonPanel';
import './Notification.scss';
import useVerificationData from './hooks/useVerificationData';

const Notification: React.FC<any> = ({ verification, verificationName }) => {
  const { link, className, iconName, date, isComplete, translationsKeys } =
    useVerificationData(verification, verificationName);
  const navigate = useNavigate();
  return (
    <MastodonPanel
      title={i18n.getStr(translationsKeys.title)}
      className={`${className} ${className}--ssn`}
      icon={iconName}
      tag={i18n.getStr(translationsKeys.tag)}
    >
      <div>
        <p className='panel__text'>{i18n.getStr(translationsKeys.text)}</p>
        {!isComplete ? (
          <M.Button
            kind='primary'
            size='md'
            className='panel__button'
            onClick={() => {
              navigate(link);
            }}
          >
            {i18n.getStr('components.Verifications.Verification.button')}
          </M.Button>
        ) : null}
        {date ? (
          <p className='panel__eta'>
            {i18n.getStr(
              translationsKeys.date,
              i18n.getLocalizedShortDateFormat(date),
            )}
          </p>
        ) : null}
      </div>
    </MastodonPanel>
  );
};

export default Notification;
