import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import ContentContainer from 'components/Workflow/ContentContainer';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const MotorVehicleRecordInformationSelector = ({
  back,
  continueNext,
}: {
  back: () => void;
  continueNext: () => void;
}) => {
  const continueButton = () => {
    return (
      <M.Button size='sm' kind='primary' onClick={continueNext}>
        {i18n.getStr('buttons.continue')}
      </M.Button>
    );
  };

  return (
    <div data-testid={DISPUTES_FLOW.disputeMvrStatusEducation}>
      <ContentContainer>
        {i18n.renderHTML(
          'workflows.dispute.motorVehicleRecordInformationSelector.instructionsText',
        )}
      </ContentContainer>
      <NavigationFooter back={back} rightButtonContent={continueButton()} />
    </div>
  );
};

export default MotorVehicleRecordInformationSelector;
