import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { EducationDegreeArray } from '../../../../../types/report';

interface EducationDegreeProps {
  degree: EducationDegreeArray;
  index: number;
}

const EducationDegree = ({ degree, index }: EducationDegreeProps) => {
  return (
    <div className='table-responsive'>
      <table className='table table-sm table-bottom-bordered'>
        <thead>
          <tr>
            <td className='column-6'>
              <b>
                <T
                  value='components.education.verifiedDegree'
                  index={index + 1}
                />
              </b>
            </td>
            <td className='column-6' />
          </tr>
        </thead>

        <tbody>
          {[
            {
              name: 'title',
              title: i18n.getStr('components.education.degreeLevel'),
            },
            {
              name: 'majors',
              title: i18n.getStr('components.education.majors'),
            },
            {
              name: 'majorConcentrations',
              title: i18n.getStr('components.education.concentrations'),
            },
            {
              name: 'minors',
              title: i18n.getStr('components.education.minors'),
            },
            {
              name: 'yearAwarded',
              title: i18n.getStr('components.education.yearAwarded'),
            },
          ].map((field: { name: string; title: string }, i) => (
            <tr key={`tr-${i + 1}`}>
              <td className='text-very-muted column-6'>{field.title}</td>
              <td className='column-6'>{degree[field.name] || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

EducationDegree.propTypes = {
  degree: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default EducationDegree;
