import { moment } from '@international/mastodon-i18n';
import { I18n } from 'react-redux-i18n';
import { DATE_AFTER, DATE_FORMAT, DATE_IN_THE_PAST } from './messages';
import { slashDatePattern } from '../dateFormat';

const defaultFormat = slashDatePattern();

const dateInThePast =
  (format = defaultFormat) =>
  date => {
    if (!moment(date, format).isBefore(moment())) {
      return DATE_IN_THE_PAST;
    }
    return null;
  };

const validDate =
  (format = defaultFormat, strict = false) =>
  date => {
    if (!moment(date, format, strict).isValid()) {
      return { message: DATE_FORMAT, format };
    }
    return null;
  };

const dateAfter =
  (format = defaultFormat, dateCompared) =>
  date => {
    const before = moment(dateCompared, format);
    const after = moment(date, format);

    if (before.isValid() && after.isBefore(before)) {
      return { message: DATE_AFTER, dateCompared };
    }
    return null;
  };

const datePickerValidation = value => {
  const format = 'MM/DD/YYYY';
  const valid =
    /\d{2}\/\d{2}\/\d{4}/.test(value) && moment(value, format).isValid();

  if (!valid) {
    return I18n.t(DATE_FORMAT, { format });
  }
  return null;
};

export { dateAfter, dateInThePast, validDate, datePickerValidation };
