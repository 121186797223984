import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import Attribute from '../Attribute';
import { FacisVerificationObject } from '../../../../types/report';

interface FacisVerificationProps {
  search: FacisVerificationObject;
}

const FacisVerification = ({ search }: FacisVerificationProps) =>
  !!search &&
  search.status !== 'canceled' && (
    <Panel
      title={i18n.getStr(
        'components.facisVerification.title',
        search.subtype === '1' ? '1M' : search.subtype,
      )}
      status={search.status}
    >
      {search.records.map((record, i) => (
        <div key={`panel-${i + 1}`}>
          <div>
            <hr className='mb-2 mt-2' />
            {record.authority}
            <hr className='mb-2 mt-2' />
          </div>
          <Attribute
            attr={i18n.getStr('components.facisVerification.authority')}
            value={record.authority}
          />
          <Attribute
            attr={i18n.getStr('components.facisVerification.dob')}
            value={record.dob}
          />
          <Attribute
            attr={i18n.getStr('components.facisVerification.fullName')}
            value={record.fullName}
          />
          <Attribute
            attr={i18n.getStr('components.facisVerification.registryDatabase')}
            value={record.databaseRegistry}
          />
          {!!record.sanctions &&
            record.sanctions.map((sanction, idx) => (
              <Attribute
                attr={i18n.getStr('components.facisVerification.sanction', {
                  id: idx + 1,
                })}
                key={`attribute-${idx + 1}`}
                value={
                  sanction.sanction ||
                  i18n.getStr('components.facisVerification.unknownSanction')
                }
              />
            ))}
          {i < search.records.length - 1 && <hr />}
        </div>
      ))}
    </Panel>
  );

FacisVerification.propTypes = {
  search: PropTypes.object,
};

FacisVerification.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(FacisVerification);
