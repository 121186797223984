import * as Sentry from '@sentry/react';
import { setLocale } from 'react-redux-i18n';
import { decamelizeKeys, decamelize } from 'humps';
import { i18n, moment } from '@international/mastodon-i18n';
import { getAPIBase } from '../api/verifications/getAPIBase';
import resources from '../lib/translations/resources';
import { request, initializeDDRUM } from './helper';
import { normalizeHealthScreenings, getResourceLocale } from '../lib/helpers';
import {
  IS_PRODUCTION,
  CHECKR_EXPUNGEMENTS_API_BASE,
  BACKEND_FLAG_PATHS,
} from '../constants';
import { slashDatePattern } from '../lib/dateFormat';

/*
 * Action Types
 */

export const FETCH_REPORTS_UNKNOWN_FAILURE = 'FETCH_REPORTS_UNKNOWN_FAILURE';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';
export const FETCH_REPORTS_FAILURE_INTL = 'FETCH_REPORTS_FAILURE_INTL';
export const FETCH_REPORTS_BUSY = 'FETCH_REPORTS_BUSY';
export const CLEAR_FETCH_REPORTS_FAILURE = 'CLEAR_FETCH_REPORTS_FAILURE';
export const FETCH_REPORTS_REDIRECT = 'FETCH_REPORTS_REDIRECT';
export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_EXPUNGEMENTS_SUCCESS = 'FETCH_EXPUNGEMENTS_SUCCESS';
export const LOGOUT_CANDIDATE = 'LOGOUT_CANDIDATE';
export const CLEAR_LOGGED_OUT = 'CLEAR_LOGGED_OUT';
export const RELOAD_REPORT = 'RELOAD_REPORT';

export const UNACTIONABLE_EXPUNGEMENT_STATUSES = [
  'ineligible',
  'unfit_closed',
  'not_approved',
];

/*
 * Action Type Helpers
 */

export const fetchReportsRequest = search => ({
  type: FETCH_REPORTS_REQUEST,
  search,
});

export const fetchReportsSuccess = ({
  data: reports,

  meta: { token, applicantId, notices, termsOfService },
  privacyCandidateIds,
  privacyCandidateName,
  count,
}) => ({
  applicantId,
  privacyCandidateIds,
  privacyCandidateName,
  count,
  notices,
  reports,
  termsOfService,
  token,
  type: FETCH_REPORTS_SUCCESS,
});

export const fetchCandidateSuccess = ({
  data: reports,
  meta: { token, firstName, lastName, applicantId, termsOfService, country },
  count,
}) => ({
  applicantId,
  firstName,
  count,
  reports,
  termsOfService,
  token,
  country,
  privacyCandidateIds: [
    ...new Set(reports.map(({ candidateId }) => candidateId)),
  ],
  privacyCandidateName: `${firstName} ${lastName}`,
  type: FETCH_REPORTS_SUCCESS,
});

export const fetchReportsFailure = (isInternational = false) => ({
  type: isInternational ? FETCH_REPORTS_FAILURE_INTL : FETCH_REPORTS_FAILURE,
});

export const fetchReportsBusy = () => ({
  type: FETCH_REPORTS_BUSY,
});

export const clearFetchReportsFailure = () => ({
  type: CLEAR_FETCH_REPORTS_FAILURE,
});

export const fetchReportsUnknownFailure = () => ({
  type: FETCH_REPORTS_UNKNOWN_FAILURE,
});

export const fetchReportsRedirect = () => ({
  type: FETCH_REPORTS_REDIRECT,
});

export const fetchExpungementsSuccess = expungements => ({
  type: FETCH_EXPUNGEMENTS_SUCCESS,
  expungements,
});

/*
 * Action Helpers
 */

const formatDob = ({ month, day, year }) =>
  moment(`${month}/${day}/${year}`, 'MM/DD/YYYY').format();

export const formatSearch = (values, international) => {
  const search = { ...values };

  if (search.dob) {
    if (international) {
      search.dob = moment(search.dob, slashDatePattern()).format();
    } else {
      search.dob = formatDob(search.dob);
    }
  }

  if (search.idType && international) {
    search.idType = decamelize(search.idType);
  }

  return search;
};

const initializeIntegrations = data => {
  if (data === null || !data.accountId) return;

  const { email, applicantId } = data;
  initializeDDRUM(email, applicantId);
};

/*
 * Actions
 */

const fetchExpungements = (token, dispatch) => {
  const path = `${CHECKR_EXPUNGEMENTS_API_BASE}/expungements?sort_by=updated_at&sort_direction=desc`;
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request(path, options)
    .then(response => {
      const expungements = response.data;
      const actionableExpungements = expungements.filter(
        exp => !UNACTIONABLE_EXPUNGEMENT_STATUSES.includes(exp.status),
      );

      const statusOrder = ['potential', 'eligible'];
      actionableExpungements.sort((a, b) => {
        return statusOrder.indexOf(b.status) - statusOrder.indexOf(a.status);
      });

      dispatch(fetchExpungementsSuccess(actionableExpungements));
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

const setCandidateLocale = (dispatch, { country, locale }) => {
  if (locale && country) {
    i18n.setLocale({
      value: locale,
      resources: resources(),
      country,
      useCookie: true,
    });
    dispatch(setLocale(getResourceLocale(resources(), locale, country)));
  }
};

const candidateFetch = (dispatch, isInternational) => {
  const success = json => {
    json.data.forEach(normalizeHealthScreenings);
    if (IS_PRODUCTION) {
      const accountId = json.data?.[0]?.candidateId;
      const { firstName, lastName, applicantId, email } = json.meta;
      initializeIntegrations({
        firstName,
        lastName,
        applicantId,
        email,
        accountId,
      });
    }
    if (isInternational) {
      setCandidateLocale(dispatch, json.meta);
    }
    dispatch(fetchCandidateSuccess({ ...json }));
    fetchExpungements(json.meta.token, dispatch);
  };
  return {
    onSuccess: async json => {
      success(json);
    },
    onError: error => {
      const isUnknownFailure = typeof error.status === 'undefined';
      if (isUnknownFailure) {
        dispatch(fetchReportsUnknownFailure());
      } else if ([500, 502, 504].indexOf(error.status) >= 0) {
        dispatch(fetchReportsBusy());
      } else {
        dispatch(fetchReportsFailure(isInternational));
      }

      const approvedErrors = [401, 404, 500, 502, 504];
      // 404 is returned if candidate is not found, 401 if candidate is not authorized
      // 500, 502 and 504 are likely due to timeout issues in calling the monolith to get data
      // We do not want to throw in that case, it is handled by the above dispatch
      if (approvedErrors.indexOf(error.status) === -1) {
        Sentry.captureException(error);
      }
    },
  };
};

const fetchCandidate = (values, isInternational, dispatch) => {
  const search = formatSearch(values, isInternational);

  dispatch(fetchReportsRequest(search));

  const options = {
    method: 'POST',
    body: JSON.stringify(decamelizeKeys(search)),
    // We do not want to retry on fetchReports, since it uses a RECAPTCHA key that may have been consumed
    retries: 0,
  };

  const { onSuccess, onError } = candidateFetch(dispatch, isInternational);
  return getAPIBase({ path: BACKEND_FLAG_PATHS.REPORTS_SEARCH_CANDIDATE }).then(
    baseURL =>
      request(`${baseURL}/reports/search/candidate`, options)
        .then(onSuccess)
        .catch(onError),
  );
};

const fetchCandidateWithIds = (values, dispatch) => {
  const { candidateIds, candidateJwt } = values;
  dispatch(fetchReportsRequest({ candidateIds }));

  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${candidateJwt}`,
    },
    body: JSON.stringify(decamelizeKeys({ candidateIds })),
    retries: 3,
  };

  const { onSuccess, onError } = candidateFetch(dispatch);
  return getAPIBase({ path: BACKEND_FLAG_PATHS.REPORTS_CANDIDATES_AUTH }).then(
    baseURL =>
      request(`${baseURL}/reports/candidates/auth`, options)
        .then()
        .then(onSuccess)
        .catch(onError),
  );
};

export const fetchCandidateReports = values => dispatch => {
  return fetchCandidate(values, false, dispatch);
};

export const fetchCandidateReportsIntl = values => dispatch => {
  return fetchCandidate(values, true, dispatch);
};

export const fetchCandidateUsingIds = values => dispatch => {
  return fetchCandidateWithIds(values, dispatch);
};

export const logoutCandidate = () => ({
  type: LOGOUT_CANDIDATE,
});

export const clearLoggedOut = () => ({
  type: CLEAR_LOGGED_OUT,
});

export const reloadReport = () => ({
  type: RELOAD_REPORT,
});
