import React from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import useToken from 'utils/useToken';
import { useSelector } from 'react-redux';
import { PATHS } from '../../../constants';

const International: React.FC = () => {
  const { search } = useLocation();
  const isLoggedIn = useSelector((state: any) => state.portal.loggedIn);
  const token = useToken();
  if (!token && !isLoggedIn) {
    return <Navigate to={`${PATHS.INTERNATIONAL}/login${search}`} />;
  }
  return <Outlet />;
};

export default International;
