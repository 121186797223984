/* eslint-disable jsx-a11y/label-has-for */
// REFACTOR for Lint LATER
import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

const Select = ({
  input: { onBlur, onChange, value, name },
  options,
  label,
  meta: { touched, error, warning },
  placeholder,
  dataTestId,
}) => {
  return (
    <M.Select
      id={name ? name.replace('.', '-') : 'select-field'}
      placeholder={i18n.getStr(placeholder)}
      labelText={i18n.getStr(label)}
      invalid={touched && error}
      warn={touched && warning}
      defaultValue={value}
      onBlur={onBlur}
      onChange={onChange}
      data-testid={dataTestId || name || 'select-field'}
    >
      {options.map((option, i) => (
        <M.Select.Item
          key={`option-${i + 1}`}
          value={option.value}
          text={option.i18n ? i18n.getStr(option.name) : option.name}
        />
      ))}
    </M.Select>
  );
};

Select.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  dataTestId: PropTypes.string,
};

Select.defaultProps = {
  input: {},
  options: [],
  label: '',
  meta: {
    touched: false,
    error: '',
    warning: false,
  },
  placeholder: '',
  dataTestId: '',
};

export default i18n.renderTranslation()(Select);
