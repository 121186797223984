import PropTypes from 'prop-types';
import React from 'react';

import Charge from '../Charge/Arrest';
import Attribute from '../Attribute';

import { getChargeTitle } from '../lib/screenings';

const ARREST_ATTRS = Object.freeze([
  'bookingDate',
  'bookingLocation',
  'bookingNumber',
  'county',
  'state',
  'fullName',
  'yob',
]);

const ArrestRecord = ({ record }) => (
  <div className='arrest-record mb-4'>
    <div className='mb-3'>
      <hr className='mb-2 mt-2' />
      <div className='d-flex'>
        <span className='text-uppercase'>{getChargeTitle(record)}</span>
      </div>
      <hr className='mb-2 mt-2' />
    </div>

    <div className='mb-4 mt-4'>
      {ARREST_ATTRS.map((key, i) => (
        <Attribute key={`attribute-${i + 1}`} attr={key} value={record[key]} />
      ))}
    </div>

    {record.charges.length > 0 && <hr />}

    {record.charges.map((charge, i) => (
      <div key={`div-${i + 1}`}>
        <Charge charge={charge} index={i} />
        <hr />
      </div>
    ))}
  </div>
);

ArrestRecord.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ArrestRecord;
