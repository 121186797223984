export const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes === 0) {
    return '0.00 B';
  }
  const e = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
  const unity = ['K', 'M', 'G', 'T', 'P'];
  if (unity.length > e) {
    return `${(sizeInBytes / 1024 ** e).toFixed(2)} ${unity[e - 1]}B`;
  }
  const maxE = unity.length;
  return `${(sizeInBytes / 1024 ** maxE).toFixed(2)} ${unity[maxE - 1]}B`;
};

const titleCase = (text: string) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const formatFileType = (fileType: string, i18n: any) => {
  const [type, extension] = fileType.split('/');
  const translated = i18n.getStr(`filetype.${type}`);
  return `${
    translated === type ? titleCase(type) : translated
  } ${extension.toUpperCase()}`;
};
