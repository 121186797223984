/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// REFACTOR FOR LINT LATER
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { getRandomKey } from 'lib/helpers';

import { DISPUTES_FLOW } from 'identifiers/identifiers';
import DisputeSelect from '../../../fields/DisputeSelect';

const ArticlesForm = ({
  candidateStoriesEnabled,
  section,
  articles,
  trackAction,
  switchToCansModal,
}) => (
  <div data-testid='articles-form'>
    <div
      data-testid={DISPUTES_FLOW.disputeArticlesBreadCrumbs}
      className='form-group'
    >
      <label>
        <span className='text-muted'>{section} &#62; </span>
        <span className='form-question'>
          <T value='components.articlesForm.selectOptionDescribesYourSituation' />
        </span>
      </label>
      <hr />
    </div>
    <div
      data-testid={DISPUTES_FLOW.disputeArticleFormOptions}
      className='form-group'
    >
      {articles.map(article => {
        if (article.provideAdditionalInfo && candidateStoriesEnabled) {
          return (
            <div
              key={getRandomKey()}
              role='button'
              tabIndex={0}
              className='tile-option-select p-4 mb-2 rounded'
              onClick={switchToCansModal}
            >
              <div>{article.title}</div>
            </div>
          );
        }
        return (
          <Field
            name='article'
            key={article.name}
            id={article.name}
            component={DisputeSelect}
            displayValue={article.name}
            selectValue={article.name}
            trackAction={trackAction}
            className='tile-option-select p-4 mb-2 rounded'
          />
        );
      })}
    </div>
  </div>
);

ArticlesForm.propTypes = {
  section: PropTypes.string,
  articles: PropTypes.array,
  trackAction: PropTypes.func,
  switchToCansModal: PropTypes.func.isRequired,
  candidateStoriesEnabled: PropTypes.bool,
};

ArticlesForm.defaultProps = {
  section: '',
  articles: [],
  trackAction: () => {},
  candidateStoriesEnabled: false,
};

const mapStateToProps = state => ({
  candidateStoriesEnabled: state.candidateStories.candidateStoriesEnabled,
});

export default connect(mapStateToProps)(ArticlesForm);
