import React, { FC } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import {
  OccupationalHealthScreeningTest,
  OccupationalHealthScreening as OccupationalHealthScreeningType,
} from '../../../../types/report';
import {
  getOccupationalHealthCategorizedTestResults,
  showOccupationalHealthDispositionIndicator,
} from '../lib/utils';

interface Props {
  screening: OccupationalHealthScreeningType;
}

interface BodyProps {
  tests: OccupationalHealthScreeningTest[];
}

const OccupationalHealthScreening: FC<Props> = ({
  screening: { tests, status },
}) => {
  const categorizedTestResults =
    getOccupationalHealthCategorizedTestResults(tests);

  return (
    <Panel
      data-testid='health-screening-panel'
      title={i18n.getStr('components.healthScreening.healthScreening')}
      status={status}
      className='card-screening-results'
    >
      {categorizedTestResults.map(({ name, tests: filteredTests }) => (
        <div key={`category-${name}`} className='screening-results-category'>
          <div className='mb-4'>
            <strong>{name}</strong>
          </div>
          <div className='table-responsive'>
            <table className='table table-sm table-bottom-bordered'>
              <thead>
                <tr>
                  <td className='text-very-muted' width='40%'>
                    <T value='components.healthScreening.test' />
                  </td>
                  <td className='text-very-muted' width='60%'>
                    <T value='components.healthScreening.result' />
                  </td>
                </tr>
              </thead>
              <tbody>
                <Body tests={filteredTests} />
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </Panel>
  );
};

const Body: FC<BodyProps> = ({ tests }) => (
  <>
    {tests.map(({ disposition, comment, name, status, slug }) => (
      <tr key={`tr-${slug}`}>
        <td>{name}</td>
        <td>
          {showOccupationalHealthDispositionIndicator(disposition, status) && (
            <span className='disposition-indicator text-warning'>
              <M.Icon icon='InformationFilled' />{' '}
            </span>
          )}
          <span className='disposition'>{disposition}</span>
          {comment && <div className='text-very-muted'>{comment}</div>}
        </td>
      </tr>
    ))}
  </>
);

export default OccupationalHealthScreening;
