import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import IconPanel from '../../../IconPanel';
import completeIcon from '../../../../images/icons/Complete.svg';
import pendingIcon from '../../../../images/icons/Pending.svg';
import { CHECKR_EXPUNGEMENTS_PORTAL_BASE } from '../../../../constants';

const getIcon = expungementStatus => {
  switch (expungementStatus) {
    case 'paid':
    case 'submitted':
    case 'information_required':
    case 'processing':
      return (
        <img
          src={pendingIcon}
          alt={i18n.getStr(
            'components.Report.CompleteNotice.expungementProcessing',
          )}
        />
      );
    default:
      return (
        <img
          src={completeIcon}
          alt={i18n.getStr('components.Report.CompleteNotice.reportComplete')}
        />
      );
  }
};

const completeNoticeKey = expungementStatus => {
  switch (expungementStatus) {
    case 'potential':
    case 'eligible':
      return 'components.Report.PotentialExpungableNotice';
    case 'paid':
    case 'submitted':
    case 'information_required':
    case 'processing':
      return 'components.Report.SubmittedExpungableNotice';
    case 'approved':
    case 'previously_approved':
      return 'components.Report.ApprovedExpungableNotice';
    default:
      return 'components.Report.CompleteNotice';
  }
};

const CompletedNotice = props => {
  const footer = <div />;
  const { email, expungement } = props;
  const expungementUrl = `${CHECKR_EXPUNGEMENTS_PORTAL_BASE}/expungements/${expungement?.id}`;
  return (
    <div data-testid='complete-notice'>
      <IconPanel
        translationKey={completeNoticeKey(expungement?.status)}
        icon={getIcon(expungement?.status)}
        footer={footer}
        detailsCentered
        params={{ expungementUrl, email }}
      />
      <M.Card className='mb-3'>
        <T
          className='font-style'
          value='components.Report.CompleteNotice.additionalDetails'
          dangerousHTML
        />
      </M.Card>
      <M.Card className='mb-3'>
        <T value='components.Report.CompleteNotice.engagement' dangerousHTML />
      </M.Card>
    </div>
  );
};

CompletedNotice.propTypes = {
  expungement: PropTypes.object,
  email: PropTypes.string.isRequired,
};

CompletedNotice.defaultProps = {
  expungement: undefined,
};

export default i18n.renderTranslation()(CompletedNotice);
