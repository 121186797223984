/* eslint-disable @typescript-eslint/no-shadow */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';

import Panel from '../Panels';
import Checkbox from '../fields/Checkbox';

import * as V from '../../lib/validations';

import HTMLText from '../HTMLText';

import { acceptTos } from '../../actions/index';

const ButtonWrapperStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ScrollableDivStyled = styled.div`
  max-height: 450px;
  padding: 20px 10px;
  overflow-y: scroll;

  border: 1px solid #ddd;
  border-radius: 3px;
`;

const HrStyled = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const FieldStyled = styled(Field)`
  margin-bottom: 0;
`;

const TermsOfService = ({ content, handleSubmit, valid, acceptTos }) => (
  <form onSubmit={handleSubmit(acceptTos)}>
    <Panel>
      <T value='components.TermsOfService.headerText' dangerousHTML />
    </Panel>

    <Panel
      title='components.TermsOfService.title'
      icon='Document'
      className='card-border'
      i18n
    >
      <ScrollableDivStyled>
        <HTMLText content={content} />
      </ScrollableDivStyled>
      <HrStyled />
      <FieldStyled
        type='checkbox'
        name='accepted'
        label='components.TermsOfService.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        boldLabel
        i18n
      />
    </Panel>

    <ButtonWrapperStyled>
      <M.Button type='submit' disabled={!valid}>
        <T value='components.TermsOfService.continue' />
      </M.Button>
    </ButtonWrapperStyled>
  </form>
);

TermsOfService.propTypes = {
  content: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  acceptTos: PropTypes.func.isRequired,
};

const ReduxTosForm = reduxForm({
  form: 'tos',
  destroyOnUnmount: false,
})(TermsOfService);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    applicantId: state.portal.applicantId,
    contentHash: ownProps.contentHash,
  },
});

export default connect(mapStateToProps, { acceptTos })(ReduxTosForm);
