import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import EducationHeader from './EducationHeader';
import EducationDegree from './EducationDegree';
import {
  EducationDegreeArray,
  EducationRecordObject,
} from '../../../../../types/report';

interface EducationRecordProps {
  record: EducationRecordObject;
}

const EducationRecord = ({ record }: EducationRecordProps) =>
  record.status !== 'canceled' && (
    <div className='education-record'>
      <EducationHeader record={record} />
      {record.result &&
        record.result.degrees &&
        record.result.degrees.map((degree: EducationDegreeArray, i: number) => (
          <EducationDegree
            degree={degree}
            index={i}
            key={`education-degree-${i + 1}`}
          />
        ))}
    </div>
  );

EducationRecord.propTypes = {
  record: PropTypes.object,
};

EducationRecord.defaultProps = {
  record: {},
};

export default i18n.renderTranslation()(EducationRecord);
