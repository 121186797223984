import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportSummary } from 'api/candidates/types/Report';
import { REPORTS_PAGE } from 'identifiers/identifiers';
import ReportsHeader from '../ReportListHeader';
import ReportCard from '../ReportCard';
import I18nLink from '../../../I18nLink/I18nLink';
import './ReportList.scss';
import sortByOptions, { ReportsOrderBy } from '../lib/sortReportCardsBy';
import isActionRequired from '../lib/isActionRequired';

export const ReportList: React.FC<{ reports: ReportSummary[] }> = ({
  reports,
}) => {
  const [actionRequiredCount, setActionRequiredCount] =
    React.useState<number>(0);
  const [sortBy, setSortBy] = React.useState<ReportsOrderBy>('status');

  React.useEffect(() => {
    if (reports.length) {
      setActionRequiredCount(
        reports.filter(report => isActionRequired(report)).length,
      );
    }
  }, [reports]);

  return (
    <M.Container.Col>
      <ReportsHeader
        reportCount={reports.length}
        actionRequiredCount={actionRequiredCount}
        sortedBy={sortBy}
        onSortChange={(e: React.MouseEvent<HTMLInputElement>) => {
          const { value } = e.target as HTMLInputElement;
          setSortBy(value as ReportsOrderBy);
        }}
      />
      {reports.length ? (
        <M.TileOptionList
          className='cards-list'
          id={REPORTS_PAGE.reportCardList}
          data-testid={REPORTS_PAGE.reportCardList}
        >
          {reports.sort(sortByOptions[sortBy].compare).map((report, i) => (
            <ReportCard
              key={report.id}
              report={report}
              id={`report-card-${report.reportOrder}`}
            />
          ))}
        </M.TileOptionList>
      ) : (
        <I18nLink
          translationKey='components.CandidateLandingPage.noReportsMessage'
          className='empty-reports'
          id='no-reports-message'
        />
      )}
    </M.Container.Col>
  );
};
