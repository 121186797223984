import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import Header from '../../components/Header';

const PrivacyPage: React.FC<{ international?: boolean; children: any }> = ({
  children,
  international = false,
}) => {
  return (
    <>
      <Header isInternational={international} />
      {children}
      <footer className='text-center' data-testid='privacy-footer'>
        <T value='containers.privacy.privacyChoices' dangerousHTML />
      </footer>
    </>
  );
};

export default PrivacyPage;
