/* eslint-disable jsx-a11y/label-has-for */
// REFACTOR for Lint LATER
import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import { inputErrorClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const Select = ({
  input,
  options,
  label,
  meta: { touched, error, warning },
  placeholder,
  testId,
}) => {
  const [value, setValue] = React.useState('');
  const selectRef = React.useRef(null);
  const onChangeHandler = e => {
    setValue(e.target.value);
    input.onChange(e);
  };

  React.useEffect(() => {
    const initialValue = selectRef.current?.value;
    if (initialValue) setValue(initialValue);
  }, []);

  return (
    <div className='form-group'>
      <Label
        label={label}
        htmlFor={input.name}
        touched={touched}
        error={Boolean(error)}
      />
      <select
        {...input}
        ref={selectRef}
        data-testid={testId}
        id={input.name ? input.name.replace('.', '-') : 'select-field'}
        className={`form-control ${inputErrorClass(touched, error)}`}
        placeholder={placeholder}
        value={value}
        onChange={onChangeHandler}
      >
        {options.map((option, i) => (
          <option key={`option-${i + 1}`} value={option.value}>
            {option.i18n ? i18n.getStr(option.name) : option.name}
          </option>
        ))}
      </select>
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Select.propTypes = {
  input: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.bool,
  }),
  placeholder: PropTypes.string,
  testId: PropTypes.string,
};

Select.defaultProps = {
  input: {},
  options: [],
  label: '',
  meta: {
    touched: false,
    error: '',
    warning: false,
  },
  placeholder: '',
  testId: '',
};

export default i18n.renderTranslation()(Select);
