import { decamelizeKeys } from 'humps';
import { stripEmojis } from '../lib/helpers';
import { getAPIBase } from '../api/verifications/getAPIBase';
import { request } from './helper';
import { trackAnalyticsEvent } from './analytics';
import { ANALYTICS_EVENTS } from '../lib/analytics';
import { BACKEND_FLAG_PATHS } from '../constants';

/*
 * Action Types
 */

export const SUBMIT_CANDIDATE_STORY_REQUEST = 'SUBMIT_CANDIDATE_STORY_REQUEST';
export const submitCandidateStoryRequestAction = () => ({
  type: SUBMIT_CANDIDATE_STORY_REQUEST,
});

export const SUBMIT_CANDIDATE_STORY_SUCCESS = 'SUBMIT_CANDIDATE_STORY_SUCCESS';
export const submitCandidateStorySuccessAction = () => ({
  type: SUBMIT_CANDIDATE_STORY_SUCCESS,
});

export const SET_CANDIDATE_STORIES_FROM_SUBMISSION =
  'SET_CANDIDATE_STORIES_FROM_SUBMISSION';
export const setCandidateStoriesFromSubmission = stories => ({
  type: SET_CANDIDATE_STORIES_FROM_SUBMISSION,
  stories,
});

export const SUBMIT_CANDIDATE_STORY_FAILURE = 'SUBMIT_CANDIDATE_STORY_FAILURE';
export const submitCandidateStoryFailureAction = () => ({
  type: SUBMIT_CANDIDATE_STORY_FAILURE,
});

export const SET_CANDIDATE_STORIES_SUBMISSION_STATUS =
  'SET_CANDIDATE_STORIES_SUBMISSION_STATUS';
export const setCandidateStoriesSubmitted = candidateStorySubmitted => ({
  type: SET_CANDIDATE_STORIES_SUBMISSION_STATUS,
  candidateStorySubmitted,
});

export const SET_CANDIDATE_STORIES_ENABLED = 'SET_CANDIDATE_STORIES_ENABLED';
export const setCandidateStoriesEnabled = candidateStoriesEnabled => ({
  type: SET_CANDIDATE_STORIES_ENABLED,
  candidateStoriesEnabled,
});

export const SET_CANDIDATE_STORIES_GENERAL_INFO_ONLY =
  'SET_CANDIDATE_STORIES_GENERAL_INFO_ONLY';
export const setCandidateStoriesGeneralInfoOnly =
  candidateStoriesGeneralInfoOnly => ({
    type: SET_CANDIDATE_STORIES_GENERAL_INFO_ONLY,
    candidateStoriesGeneralInfoOnly,
  });

/*
 * Actions
 */
export const setCandidateStoriesSubmissionStatus = status => dispatch => {
  dispatch(setCandidateStoriesSubmitted(status));
};

export const setCandidateStoriesEnabledFromSetting = enabled => dispatch => {
  dispatch(setCandidateStoriesEnabled(enabled));
};

export const setCandidateStoriesGeneralInfoOnlyFromSetting =
  enabled => dispatch => {
    dispatch(setCandidateStoriesGeneralInfoOnly(enabled));
  };

export const submitCandidateStories = values => dispatch => {
  dispatch(submitCandidateStoryRequestAction());

  const { candidateStories } = values;
  const keys = Object.keys(candidateStories).filter(item => item !== 'content');
  const candidate_stories = [];

  const formatDocuments = docs =>
    docs.map(doc => ({
      filename: doc.filename,
      tempfile: doc.url,
      type: doc.mimetype,
    }));

  keys.forEach(key => {
    const story = {
      content: candidateStories.content?.[key],
      documents: candidateStories[key].documents,
      record_id: key === 'general' ? undefined : key,
    };

    story.content = story.content && stripEmojis(story.content);
    story.documents = story.documents && formatDocuments(story.documents);

    if (Object.keys(story.documents).length === 0) {
      story.documents = undefined;
    }

    candidate_stories.push(story);
  });

  const submissionValues = {
    report: values.report,
    candidate_stories,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(decamelizeKeys(submissionValues)),
  };

  return getAPIBase({ path: BACKEND_FLAG_PATHS.CANDIDATE_STORIES }).then(
    baseURL =>
      request(`${baseURL}/candidate_stories`, options)
        .then(response => {
          dispatch(submitCandidateStorySuccessAction());
          dispatch(
            trackAnalyticsEvent(ANALYTICS_EVENTS.CANDIDATE_STORY_SHARED),
          );
          dispatch(setCandidateStoriesFromSubmission(response));
        })
        .catch(() => {
          dispatch(submitCandidateStoryFailureAction());
        }),
  );
};
