/* eslint-disable consistent-return */
import { REQUIRED_DOCUMENTS } from './messages';

const requiredDocuments =
  (minDocuments = 1) =>
  documents => {
    const { length } = documents || [];
    if (!documents || length < minDocuments) {
      return {
        message: `${REQUIRED_DOCUMENTS}.${minDocuments > 1 ? 'many' : 'one'}`,
        minDocuments,
      };
    }
    return null;
  };

const required2Documents = requiredDocuments(2);
const required1Document = requiredDocuments(1);

export { required1Document, required2Documents };
