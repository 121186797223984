import { moment } from '@international/mastodon-i18n';

const useVerificationData = (
  verification: Record<string, any>,
  verificationName: string,
) => {
  const isComplete = !!verification.completedAt;
  const date = moment(
    isComplete ? verification.completedAt : verification.expiresAt,
  );
  return {
    link: `/verifications/${verification.id}`,
    className: isComplete ? 'caution' : 'warning',
    iconName: isComplete ? 'Renew' : 'WarningFilled',
    isComplete,
    date: date.isValid() ? date : null,
    translationsKeys: {
      title: [
        `components.Verifications.Verification.${verificationName}.title`,
        'components.Verifications.Verification.Default.title',
      ],
      text: [
        `components.Verifications.Verification.${verificationName}.${
          isComplete ? 'completedText' : 'text'
        }`,
        `components.Verifications.Verification.Default.${
          isComplete ? 'completedText' : 'text'
        }`,
      ],
      tag: `components.Verifications.Verification.tags.${
        isComplete ? 'reviewing' : 'required'
      }`,
      date: `components.Verifications.Verification.date.${
        isComplete ? 'completedAt' : 'expireAt'
      }`,
    },
  };
};

export default useVerificationData;
