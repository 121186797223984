import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import Status from '../Status';

const ReferenceQuestions = ({ questions }) => (
  <div>
    {questions.length > 0 && (
      <div>
        <b>
          <T value='components.referenceVerification.questionsAndAnswers' />
        </b>
        <hr className='mt-2 mb-4' />
        {questions.map((question, i) => (
          <div key={`div-${i + 1}`}>
            <b>{question.question}</b>
            <br />
            <span>{question.answer}</span>
            <hr />
          </div>
        ))}
      </div>
    )}
  </div>
);

ReferenceQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
};

const ReferenceVerification = ({ searches }) => (
  <div>
    {searches.map((verification, i) => (
      <div key={`div-${i + 1}`} className='mb-5'>
        <div className='d-flex mb-2'>
          <b className='text-capitalize'>{verification.relationship}</b>
          <div className='ml-auto'>
            <Status status={verification.status} />
          </div>
        </div>

        <hr className='mt-2' />

        <div className='mt-4 mb-4'>
          <b>
            <T value='components.referenceVerification.reference' />
          </b>
          <br />
          {verification.name}
          <br />
          <span className='text-very-muted'>
            {verification.email} &middot; {verification.phone}
          </span>
        </div>

        <ReferenceQuestions questions={verification.questions} />
      </div>
    ))}
  </div>
);

ReferenceVerification.propTypes = {
  searches: PropTypes.array.isRequired,
};

export default ReferenceVerification;
