import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import DocumentList from '../../../fields/DocumentList';
import UploadButton from '../../../fields/UploadButton';

const CandidateStoryDocsForm = ({ documents, disabled, field, remove }) => {
  return (
    <div hidden={disabled}>
      <div className='form-group'>
        <p>
          <T
            value='components.Report.CandidateStories.Modal.documents.subtext'
            dangerousHTML
          />
        </p>

        <ul className='text-muted'>
          <T value='components.Report.examples' />
          <li>
            <T value='components.Report.CandidateStories.Modal.documents.example1' />
          </li>
          <li>
            <T value='components.Report.CandidateStories.Modal.documents.example2' />
          </li>
          <li>
            <T value='components.Report.CandidateStories.Modal.documents.example3' />
          </li>
        </ul>

        <UploadButton
          name={field}
          id='upload-btn'
          kind='secondary'
          context='candidate-stories'
        />
      </div>

      <div className='form-group'>
        <DocumentList documents={documents} onRemove={remove} />
      </div>
    </div>
  );
};

CandidateStoryDocsForm.propTypes = {
  documents: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
};

CandidateStoryDocsForm.defaultProps = {
  disabled: false,
};

export default CandidateStoryDocsForm;
