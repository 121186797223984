import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import IconPanel from '../../../IconPanel';
import suspendedIcon from '../../../../images/icons/Suspended.svg';

const SuspendedNotice = ({ onHold, companyName }) => (
  <IconPanel
    translationKey={`components.Report.${
      onHold ? 'OnHold' : ''
    }SuspendedNotice`}
    icon={
      <img
        src={suspendedIcon}
        alt={i18n.getStr('components.Report.SuspendedNotice.reportSuspended')}
      />
    }
    detailsCustomValue={{ companyName }}
    detailsCentered
  />
);

SuspendedNotice.propTypes = {
  onHold: PropTypes.bool,
  companyName: PropTypes.string,
};

SuspendedNotice.defaultProps = {
  onHold: false,
  companyName: '',
};

export default i18n.renderTranslation()(SuspendedNotice);
