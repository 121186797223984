import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { DisputeArticle } from './ArticleTicket';
import DescribeField from './DescribeField';

interface DescribeFormProps {
  article: DisputeArticle;
  selectedField: (selected: string) => void;
}

const DescribeForm = ({ article, selectedField }: DescribeFormProps) => {
  return (
    <div data-testid='describe-form'>
      <div className='form-group'>
        <span className='form-question'>
          <T value='components.Report.Dispute.DescribeSituation.question' />
        </span>
        <hr />
      </div>
      {article.wrongPersonConfirmation && (
        <M.CardList data-testid='describe-form-ul-wrong-person'>
          <DescribeField
            caption='components.Report.Dispute.DescribeSituation.isNotMine'
            value='components.Report.Dispute.DescribeSituation.isNotMineReason'
            selectedField={selectedField}
          />
          <DescribeField
            caption='components.Report.Dispute.DescribeSituation.stolenIdentity'
            value='components.Report.Dispute.DescribeSituation.stolenIdentityReason'
            selectedField={selectedField}
          />
        </M.CardList>
      )}
      {!article.wrongPersonConfirmation && (
        <M.CardList>
          <DescribeField
            caption='components.Report.Dispute.DescribeSituation.hasChanged'
            value='components.Report.Dispute.DescribeSituation.hasChangedReason'
            selectedField={selectedField}
          />
          <DescribeField
            caption='components.Report.Dispute.DescribeSituation.isInaccurate'
            value='components.Report.Dispute.DescribeSituation.isInaccurateReason'
            selectedField={selectedField}
          />
        </M.CardList>
      )}
    </div>
  );
};

export default DescribeForm;
