import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import IconPanel from '../IconPanel';
import FaqButton from '../fields/FaqButton';

import completeIcon from '../../images/icons/Complete.svg';

const Canceled = () => (
  <IconPanel
    translationKey='components.Verification.Canceled'
    icon={<img src={completeIcon} alt={i18n.getStr('components.canceled')} />}
  >
    <div className='text-center'>
      <FaqButton />
    </div>
  </IconPanel>
);

export default Canceled;
