import { colors } from '@dashboard-experience/mastodon';

const headerButtonStyle = `
  height: 32px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  padding: 9px 16px !important;
  background-color: transparent !important;
  color: ${colors.white} !important;
  font-weight: 400 !important;
  border: 1px solid transparent !important;
  border-radius: 2px !important;
  &:hover,
  &:focus {
    outline: none;
    border-color: ${colors.white} !important;
  }
  &:hover {
    background-color: ${colors.navy01} !important;
  }
  svg {
    width: 16px;
    height: 16px;
    margin: 0 0 0 8px;
  }
`;

export default headerButtonStyle;
