/* eslint-disable react/no-unknown-property */
import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { DISPUTES_FLOW } from 'identifiers/identifiers';
import FeedbackLabel from './FeedbackLabel';

const Checkbox = ({
  boldLabel,
  className,
  input,
  i18n,
  label,
  labelOptions,
  meta: { touched, error, warning },
}) => {
  const labelComponent = i18n ? (
    <T value={label} {...labelOptions} dangerousHTML />
  ) : (
    label
  );
  return (
    <div className={`form-group ${className}`}>
      <label
        data-testid={DISPUTES_FLOW.disputeAcceptCheckbox}
        className='mr-2'
        htmlFor={input.name}
        touched={touched ? 'true' : undefined}
        error={error}
      >
        <input {...input} id={input.name} type='checkbox' />
        {boldLabel ? <b>{labelComponent}</b> : labelComponent}
      </label>
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Checkbox.propTypes = {
  boldLabel: PropTypes.bool,
  className: PropTypes.string,
  input: PropTypes.object,
  i18n: PropTypes.bool,
  label: PropTypes.string,
  labelOptions: PropTypes.object,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
};

Checkbox.defaultProps = {
  boldLabel: false,
  className: '',
  input: {},
  i18n: false,
  label: '',
  labelOptions: {},
  meta: {},
  placeholder: '',
};

export default Checkbox;
