import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const ConfirmForm = ({ actions, companyName }) => (
  <div>
    <div className='form-group'>
      <p>
        <T
          value='components.Report.CandidateStories.Modal.Confirm.beforeYouProvide'
          company={companyName}
        />
      </p>
      <span className='form-question'>
        <T value='components.Report.CandidateStories.Modal.Confirm.isEverythingAccurate' />
      </span>
      <hr />
    </div>
    <div className='form-group'>
      <div
        className='d-flex justify-content-between tile-option-select p-4 mb-2 rounded'
        onClick={actions.confirmAccuracy}
        role='button'
        tabIndex={0}
        data-testid='candidate-stories-confirm-btn'
      >
        <div>
          <T
            value='components.Report.CandidateStories.Modal.Confirm.yesAccurate'
            company={companyName}
            dangerousHTML
          />
        </div>
      </div>
      <div
        className='d-flex justify-content-between tile-option-select p-4 mb-2 rounded'
        onClick={actions.switchToDisputeModal}
        role='button'
        tabIndex={0}
      >
        <div data-testid='candidate-stories-no-wrong-button'>
          <T
            value='components.Report.CandidateStories.Modal.Confirm.noWrong'
            dangerousHTML
          />
        </div>
      </div>
    </div>
  </div>
);

ConfirmForm.propTypes = {
  actions: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default ConfirmForm;
