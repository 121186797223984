import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { i18nReducer as i18n } from 'react-redux-i18n';
import { createResponsiveStateReducer } from 'redux-responsive';

import candidateStories from './candidateStories.reducer';
import candidateLogin from './candidateLogin.reducer';
import login from './loginform';
import intlLogin from './intlLoginform';
import candidateLoginForm from './candidateLoginForm.reducer';
import portal from './portal';
import navigation from './navigation';
import verification from './verificationform';
import verifications from './verifications';
import privacy from './privacy.reducer';
import { international } from './international.reducer';
import apiStatusBanner from './apiStatusBanner.reducer';

export const appBreakpoints = {
  extraSmall: 576,
  small: 768,
  medium: 992,
  large: 1200,
};

const rootReducer = combineReducers({
  browser: createResponsiveStateReducer(appBreakpoints),
  privacy,
  form: form.plugin({ login, intlLogin, verification, candidateLoginForm }),
  i18n,
  portal,
  navigation,
  verifications,
  international,
  candidateStories,
  candidateLogin,
  apiStatusBanner,
});

export default rootReducer;
