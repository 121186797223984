import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import styles from './Breadcrumb.module.css';

const Breadcrumb = ({ crumbKeys }: { crumbKeys: (string | undefined)[] }) => {
  const renderCrumbs = () => {
    return crumbKeys.map(key => {
      return (
        <li key={key} className='breadcrumb-divider'>
          {i18n.getStr(key)}
        </li>
      );
    });
  };

  return (
    <nav
      className={styles.styled}
      data-testid={DISPUTES_FLOW.disputeBreadCrumb}
      aria-label={i18n.getStr('htmlAttributes.breadcrumb')}
      title={i18n.getStr('htmlAttributes.breadcrumb')}
    >
      <ol>{renderCrumbs()}</ol>
    </nav>
  );
};

export default Breadcrumb;
