import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import securityLogo from '../../images/icons/security.svg';

const SecurityLogo = styled.img`
  height: ${props => props.logoHeight};
  align-self: center;
`;

const SecurityDiv = styled.div`
  display: flex;
  padding-right: 0.25rem;
`;

const MarginBottomH3 = styled.h3`
  margin-bottom: 0;
`;

const SecurityNotice = ({ logoHeight, smallerTitle }) => (
  <M.Grid>
    <M.Container type='official'>
      <M.Container.Row>
        <SecurityDiv>
          <SecurityLogo
            src={securityLogo}
            alt={i18n.getStr('components.Privacy.SecurityNotice.securityLogo')}
            logoHeight={logoHeight}
          />
        </SecurityDiv>
        <M.Container.Col>
          <M.Container.Row>
            {smallerTitle ? (
              <MarginBottomH3>
                <T value='components.Privacy.SecurityNotice.title' />
              </MarginBottomH3>
            ) : (
              <h5>
                <T value='components.Privacy.SecurityNotice.title' />
              </h5>
            )}
            <T
              value='components.Privacy.SecurityNotice.description'
              dangerousHTML
            />
          </M.Container.Row>
        </M.Container.Col>
      </M.Container.Row>
    </M.Container>
  </M.Grid>
);

SecurityNotice.propTypes = {
  logoHeight: PropTypes.string,
  smallerTitle: PropTypes.bool,
};

SecurityNotice.defaultProps = {
  logoHeight: '86px',
  smallerTitle: false,
};

export default i18n.renderTranslation()(SecurityNotice);
