import React, { HTMLAttributeAnchorTarget, HTMLProps } from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { I18nRenderedTranslation } from 'types/i18n';
import DOMPurify from 'isomorphic-dompurify';

interface I18nLinkProps extends HTMLProps<HTMLParagraphElement> {
  translationKey: string | string[];
  className?: string;
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>;
  to?: string;
  linkTarget?: HTMLAttributeAnchorTarget;
  renderHTML?: boolean;
}

const I18nLink: React.FC<I18nRenderedTranslation<I18nLinkProps>> = ({
  t,
  translationKey,
  className,
  i18n: i18next,
  onLinkClick,
  to = '',
  renderHTML = false,
  linkTarget = '_blank',
  ...restProps
}) => {
  const renderText = React.useCallback(
    (text: string) => {
      if (renderHTML) {
        return (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
          />
        );
      }
      return text;
    },
    [renderHTML],
  );
  const translatedItems = t(translationKey, {
    returnObjects: true,
  });
  if (Array.isArray(translatedItems)) return null;
  if (typeof translatedItems === 'string') return translatedItems;
  const { text, url, urlText } = translatedItems;
  const [preLinkText, postLinkText] = text.split(/{{var}}/);
  return (
    <p className={className || ''} {...restProps}>
      {renderText(preLinkText)}
      <M.Link
        href={to || url}
        onClick={onLinkClick}
        size='lg'
        inline
        target={linkTarget}
      >
        {urlText}
      </M.Link>
      {renderText(postLinkText)}
    </p>
  );
};

export default i18n.renderTranslation()(I18nLink);
