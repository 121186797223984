import PropTypes from 'prop-types';
import React, { ReactElement, useState } from 'react';
import { Translate as T } from 'react-redux-i18n';
import {
  arrayRemove as reduxFormArrayRemove,
  Field,
  FormAction,
  formValueSelector,
} from 'redux-form';
import { connect, MapStateToPropsParam } from 'react-redux';
import styled from 'styled-components';
import { i18n, moment } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

import UploadButton from '../fields/UploadButton';
import SimpleDocumentList from '../fields/SimpleDocumentList';
import * as V from '../../lib/validations';
import employmentIcon from '../../images/icons/documents/Employment.svg';
import { MultilineText, Select } from '../fields';
import { VERIFICATION_FORM_NAME } from '../../constants';
import SimplePanel from '../Panels/SimplePanel';
import DisclosureInfoPanel from './DisclosureInfoPanel';

const MAX_CANDIDATE_EXPLANATION_LENGTH = 250;
const NO_DOCUMENT_AVAILABLE = 'no_document_available';
const noDocumentAvailableSelected = (selectedDocType: string) =>
  selectedDocType === NO_DOCUMENT_AVAILABLE;

interface EmployerProps {
  name: string;
  position: string;
  startDate: string;
  endDate: string;
}

interface EmploymentDocumentsPanelProps {
  arrayRemove: (form: string, field: string, index: number) => FormAction;
  children: ReactElement;
  documents: File[];
  model: string;
  employer: EmployerProps;
  selectedDocType: string;
}

const EmploymentDocumentsPanel = ({
  arrayRemove,
  children,
  documents,
  model,
  employer,
  selectedDocType,
}: EmploymentDocumentsPanelProps) => {
  const onRemove = (i: number) => arrayRemove(VERIFICATION_FORM_NAME, model, i);
  const { name, position, startDate, endDate } = employer;

  const SELECT_DOC_TYPE_I18N_KEY = 'options.selectADocType';

  // Doc type values correspond to EmploymentProof subclass snake-case names in monolith.
  const DOC_TYPE_DROPDOWN_OPTIONS = [
    { value: '', name: SELECT_DOC_TYPE_I18N_KEY, i18n: true },
    { value: 'tax_form_w2', name: 'options.employmentDocTypes.w2', i18n: true },
    {
      value: 'tax_form1099',
      name: 'options.employmentDocTypes.form1099',
      i18n: true,
    },
    {
      value: 'paystub',
      name: 'options.employmentDocTypes.paystub',
      i18n: true,
    },
    {
      value: 'tax_form_schedule_c',
      name: 'options.employmentDocTypes.scheduleC',
      i18n: true,
    },
    {
      value: NO_DOCUMENT_AVAILABLE,
      name: 'options.noDocumentAvailable',
      i18n: true,
    },
  ];

  const StyledBulletPoint = styled.li`
    &:not(:last-child) {
      padding-bottom: 8px !important;
    }
  `;

  const [remainingCharacterCount, setRemainingCharacterCount] = useState(
    MAX_CANDIDATE_EXPLANATION_LENGTH,
  );
  const handleCandidateExplanationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const candidateExplanation = e.target.value;
    setRemainingCharacterCount(
      MAX_CANDIDATE_EXPLANATION_LENGTH - candidateExplanation.length,
    );
  };

  return (
    <div>
      <SimplePanel>
        <div className='text-center document-upload-header'>
          <img
            src={employmentIcon}
            alt={i18n.getStr(
              'components.Verification.EmploymentDocumentsPanel.employmentProof',
            )}
          />
          <br />
          <br />
          <h3>{name}</h3>
          <h4>{position}</h4>
          <h5 className='text-muted'>
            {moment(startDate).format(i18n.DateFormats.DATE_SHORT)} -{' '}
            {endDate ? (
              moment(endDate).format(i18n.DateFormats.DATE_SHORT)
            ) : (
              <T value='now' />
            )}
          </h5>
        </div>
        <M.Grid>
          <M.GridRow>
            <M.GridCol md={12}>
              <T
                value='components.Verification.EmploymentDocumentsPanel.text'
                employerName={name}
                dangerousHTML
              />
            </M.GridCol>
          </M.GridRow>
        </M.Grid>
      </SimplePanel>
      <SimplePanel>
        <M.Grid>
          <M.GridRow>
            <M.GridCol md={12}>
              <h2>
                <T value='buttons.uploadDocument' />
              </h2>
              <hr />
              <ul>
                <StyledBulletPoint data-testid='emp-bullet-point'>
                  <T
                    value='components.Verification.EmploymentDocumentsPanel.details.w2Or1099'
                    employerName={name}
                    dangerousHTML
                  />
                </StyledBulletPoint>
                <StyledBulletPoint data-testid='emp-bullet-point'>
                  <T
                    value='components.Verification.EmploymentDocumentsPanel.details.paystub'
                    employerName={name}
                    dangerousHTML
                  />
                </StyledBulletPoint>
                <StyledBulletPoint data-testid='emp-bullet-point'>
                  <T
                    value='components.Verification.EmploymentDocumentsPanel.details.scheduleC'
                    dangerousHTML
                  />
                </StyledBulletPoint>
              </ul>

              {children}

              <M.GridRow>
                <M.GridCol md={12}>
                  <T
                    value='components.Verification.EmploymentDocumentsPanel.noDocumentHelpText'
                    dangerousHTML
                  />
                </M.GridCol>
              </M.GridRow>

              <hr />
              <DisclosureInfoPanel textI18nKey='components.Verification.EmploymentDocumentsPanel.candidateDisclosurePrompt' />
            </M.GridCol>
          </M.GridRow>

          <M.GridRow className='justify-content-center'>
            <M.GridCol md={12} className='text-md-left'>
              <Field
                type='select'
                name={`${model}__employmentDocType`}
                component={Select}
                defaultValue={i18n.getStr(SELECT_DOC_TYPE_I18N_KEY)}
                validate={[V.required]}
                options={DOC_TYPE_DROPDOWN_OPTIONS}
              />
            </M.GridCol>
            <M.GridCol md={8} />
          </M.GridRow>

          {documents.length === 0 && (
            <M.GridRow className='justify-content-center mb-3'>
              <M.GridCol md={4} className='text-md-left'>
                <UploadButton
                  kind='primary'
                  name={model}
                  context={
                    noDocumentAvailableSelected(selectedDocType)
                      ? 'candidate-explanation'
                      : 'verifications'
                  }
                  buttonText='buttons.uploadDocument'
                  fileOptions={{ maxFiles: 1 }}
                  disabled={noDocumentAvailableSelected(selectedDocType)}
                />
              </M.GridCol>
              <M.GridCol md={8} />
            </M.GridRow>
          )}

          {noDocumentAvailableSelected(selectedDocType) && (
            <M.GridRow>
              <M.GridCol md={8}>
                <p className='mb-3'>
                  <T value='components.Verification.EmploymentDocumentsPanel.noDocumentDisclaimer' />
                </p>
                <Field
                  name='candidate_explanation'
                  component={MultilineText}
                  maxLength={MAX_CANDIDATE_EXPLANATION_LENGTH}
                  validate={[V.required]}
                  onChange={handleCandidateExplanationChange}
                  remainingCharacterCount={remainingCharacterCount}
                />
              </M.GridCol>
            </M.GridRow>
          )}

          {!noDocumentAvailableSelected(selectedDocType) &&
            documents.length > 0 && (
              <M.GridRow className='justify-content-center mb-3'>
                <M.GridCol md={12} className='text-md-left'>
                  <SimpleDocumentList
                    onRemove={onRemove}
                    documents={documents}
                  />
                </M.GridCol>
              </M.GridRow>
            )}
        </M.Grid>
      </SimplePanel>
    </div>
  );
};

EmploymentDocumentsPanel.propTypes = {
  arrayRemove: PropTypes.func,
  children: PropTypes.node,
  documents: PropTypes.array,
  employer: PropTypes.object,
  model: PropTypes.string,
  selectedDocType: PropTypes.string,
};

EmploymentDocumentsPanel.defaultProps = {
  arrayRemove: () => {},
  children: [],
  documents: [],
  employer: {},
  model: '',
  selectedDocType: '',
};

interface EmploymentDocumentsPanelStateProps {
  documents: File[];
  selectedDocType: string;
}

interface EmploymentDocumentsPanelDispatchProps {
  arrayRemove: (form: string, field: string, index: number) => FormAction;
}

const selector = formValueSelector(VERIFICATION_FORM_NAME);

const mapStateToProps: MapStateToPropsParam<
  EmploymentDocumentsPanelStateProps,
  { model: string }
> = (state, ownProps) => ({
  documents: selector(state, ownProps.model) || ([] as File[]),
  selectedDocType: selector(
    state,
    `${ownProps.model}__employmentDocType`,
  ) as string,
});

const reduxStateConnector = connect<
  EmploymentDocumentsPanelStateProps,
  EmploymentDocumentsPanelDispatchProps,
  EmploymentDocumentsPanelProps
>(mapStateToProps, { arrayRemove: reduxFormArrayRemove })(
  EmploymentDocumentsPanel,
);

export default i18n.renderTranslation()(reduxStateConnector);
