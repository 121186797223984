import React from 'react';
import MissingInfoPanel from '../MissingInfoPanel';
import VerificationHeaderPanel from '../VerificationHeaderPanel';
import { VerificationWelcomePageProps } from './types';

const VerificationWelcomePage: React.FC<VerificationWelcomePageProps> = ({
  matrixFields,
  verification,
  steps,
}) => {
  return (
    <>
      <VerificationHeaderPanel {...verification} />
      <MissingInfoPanel
        object={verification.object}
        matrixFields={matrixFields}
        documents={steps}
        company={verification.company}
      />
    </>
  );
};

export default VerificationWelcomePage;
