import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { trackAnalyticsEvent } from '../../../../../actions';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  AMPLITUDE_NAME_MAP,
} from '../../../../../lib/analytics';
import HTMLText from '../../../../HTMLText';
import {
  CLEAR_STATUS,
  PENDING_STATUS,
  SUSPENDED_STATUS,
} from '../../lib/constants';

const ShowShareCodeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FAILURE_REASON_MESSAGE_MAP: { [key: string]: string } = {
  INVALID_DOCUMENTS: 'components.Report.RightToWork.defaultInstructions',
  SHARE_CODE_NOT_PROVIDED: 'components.Report.RightToWork.noShareCode',
  VENDOR_FAILURE: 'components.Report.RightToWork.vendorFailure',
  OPT_OUT: 'components.Report.RightToWork.defaultInstructions',
  INVALID_SHARE_CODE: 'components.Report.RightToWork.invalidShareCode',
  DEFAULT_MESSAGE: 'components.Report.RightToWork.defaultInstructions',
};

const STATUS_MESSAGE_MAP: { [key: string]: string } = {
  [PENDING_STATUS]: '',
  [SUSPENDED_STATUS]: 'components.Report.RightToWork.defaultInstructions',
};

const stringMessage = (data_test_id: string, message: string) => {
  return (
    <>
      <div data-testid={data_test_id}>
        <strong>
          <HTMLText content={message} />
        </strong>
      </div>
    </>
  );
};

const providedShareCodeMessage = (shareCode: string) => {
  return (
    <>
      <ShowShareCodeWrapper data-testid='provided-share-code-message'>
        <strong>
          <T
            value='components.Report.RightToWork.providedShareCode.message'
            shareCode={shareCode}
          />
        </strong>
        <M.Button
          onClick={() => navigator.clipboard.writeText(shareCode)}
          kind='tertiary'
          data-testid='share-code-copy-btn'
        >
          <M.Icon icon='Copy' />
        </M.Button>
      </ShowShareCodeWrapper>
      <div data-testid='rtw-verify-link-message'>
        <HTMLText
          content={i18n.getStr(
            'components.Report.RightToWork.providedShareCode.verifyLink',
          )}
        />
      </div>
    </>
  );
};

const buildMessage = (search: any) => {
  const { status, result, resultSubstatus, shareCode } = search || {};

  const message = STATUS_MESSAGE_MAP[status];

  if (message !== undefined) {
    return stringMessage('rtw-not-completed-message', i18n.getStr(message));
  }

  if (result === CLEAR_STATUS) {
    const clear_message = `${i18n.getStr(
      'components.Report.RightToWork.completedOn',
    )}${' '}
        ${i18n.getLocalizedShortDateTimeFormat(search.completedAt)}`;

    return stringMessage('rtw-clear-message', clear_message);
  }

  if (resultSubstatus === 'SHARE_CODE_PROVIDED') {
    return providedShareCodeMessage(shareCode);
  }

  return stringMessage(
    'rtw-message',
    i18n.getStr(
      FAILURE_REASON_MESSAGE_MAP[resultSubstatus || 'DEFAULT_MESSAGE'],
    ),
  );
};

const IntlRightToWork = ({ record }: any) => {
  const dispatch = useDispatch();
  // double check
  if (!record) {
    return <></>;
  }

  const rtw_content = buildMessage(record);

  const sendAmplitudeEvent = () => {
    dispatch(
      trackAnalyticsEvent(ANALYTICS_EVENTS.SCREENING_DOWNLOADED, {
        [ANALYTICS_PROPERTIES.SCREENING_NAME]: AMPLITUDE_NAME_MAP.right_to_work,
      }),
    );
  };

  return (
    <>
      <div className='mb-2'>
        {rtw_content}
        <div className='ml-auto' />
      </div>
      {record.pdfUrl && (
        <div>
          <a
            href={record.pdfUrl}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => sendAmplitudeEvent()}
          >
            <T value='components.viewReport' />
          </a>
        </div>
      )}
    </>
  );
};

IntlRightToWork.propTypes = {
  record: PropTypes.object,
};

IntlRightToWork.defaultProps = {
  record: null,
};

export default IntlRightToWork;
