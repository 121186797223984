export const REPORT_PAGE = {
  downloadPdfButton: 'download-pdf-toggle',
  downloadPdfReport: 'download-report',
  checkrLogo: 'img[alt="Checkr logo"]',
  cookieBanner: '#onetrust-accept-btn-handler',
  candidateStoryCtaContainer: 'candidate-story-cta-container',
  candidateStoriesShareMoreInfoBtn: 'candidate-stories-share-more-info-btn',
  candidateStoryModal: 'candidate-story-modal',
  candidateStoriesNoWrongButton: 'candidate-stories-no-wrong-button',
  candidateStoriesConfirmButton: 'candidate-stories-confirm-btn',
  candidateStoriesMultiSelectCheckbox:
    'candidate-stories-multi-select-checkbox',
  candidateStoryFooterContinueButton: 'candidate-story-footer-continue-button',
  candidateStoryTextField: 'candidate-stories-text-field',
  candidateStoryFooterSubmitButton: 'candidate-story-footer-submit-button',
  candidateStoryUpload: '[data-e2e="upload"]',
  candidateStoryAddADocument: 'upload-button',
  candidateStorySuccess: 'candidate-story-success',
  disputeButton: 'dispute-button',
  backToAllReportsButton: 'back-to-all-reports',
  informationPanel: 'information-panel',
};

export const DISPUTES_FLOW = {
  continue: 'continue',
  disputeModal: 'dispute-modal',
  disputeNewDlInformation: 'new-dl-information',
  disputeInaccurateMotorVehicleRecord: 'inaccurate-motor-vehicle-record',
  disputeProvideAdditionalContext: 'provide-additional-context',
  disputePaidFinesInformation: 'paid-fines-information',
  disputeCauseConsiderStatusInformation: 'cause-consider-status-information',
  disputeInaccurateLicenseInfo: 'inaccurate-license-info',
  disputeFooterContinueButton: 'dispute-footer-continue-button',
  disputeFooterBackButton: 'dispute-footer-back-button',
  provideInformationAccordionSection: 'provide-information-accordion-section',
  provideInformationSubreasons: '#provide-information-subreasons',
  tileCheckboxGroup: 'fieldset.tile-checkbox-group',
  checkBox: 'input[type="checkbox"]',
  provideInformationSubreasonHeading:
    'h3#provide-information-subreason-heading',
  provideInformationExplanation: 'provide-information-explanation',
  deleteSummaryCard: 'delete-summary-card',
  disputeSubmitButton: 'dispute-submit-button',
  disputeConfirmationHeader: 'confirmation-header',
  disputeRightButton: 'right-button',
  disputeMotorVehicleRecordNote: 'note',
  disputeBreadCrumb: 'dispute-breadcrumb',
  disputeMvrStatusEducation: 'mvr-status-education',
  disputeCity: 'city',
  disputeConsiderExplanation: 'consider-explanation',
  disputeCauseConsiderStatusCloseButton: 'cause-consider-status-close-button',
  disputeBifurcationNoOption: 'bifurcation-selector-no-option',
  disputeChargesForm: 'dispute-charges-form',
  disputeChargeSearchTitle: 'dispute-search-title',
  disputeArticlesBreadCrumbs: 'dispute-articles-breadcrumbs',
  disputeArticleFormOptions: 'dispute-articles-form-options',
  disputeArticleText: 'dispute-article-text',
  disputeArticleTicket: 'article-ticket',
  disputeArticleBody: 'dispute-article-body',
  disputeArticle: 'dispute-article',
  disputeForumIcon: 'dispute-forum-icon',
  disputeCandidateSelectReasonButton: 'candidate-select-reason-btn',
  disputeExplanationTextField: 'explanation-text-field',
  disputeUpload: '[data-e2e="upload"]',
  disputeFooterAddButton: 'dispute-footer-add-button',
  disputeFooterAddErrorButton: 'dispute-footer-add-error-button',
  disputeFieldsForm: 'fields-form',
  disputeItem: 'dispute-item',
  disputeFooterSubmitButton: 'dispute-footer-submit-button',
  disputeAcceptCheckbox: 'checkbox-label',
  disputeAddDocument: 'upload-button',
  disputeMotorVehicleRecordSubreasonDetailSelector:
    'motor-vehicle-subreason-detail-selector',
  disputeMotorVehicleRecordSubreasonSelector:
    'motor-vehicle-subreason-selector',
  disputeMotorVehicleRecordSubreasonSelectorForm: 'subreason-selector-form',
  disputeFederalDistrictSearches: 'Federal District Searches',
  disputeFederalDistrictCivilSearches: 'Federal District Civil Searches',
  disputeViolationSelector: 'violation-selector',
  disputeLicenseInformationSelector: 'license-information-selector',
  disputeRestrictionsSelector: 'restrictions-selector',
  disputeEndorsementsSelector: 'endorsements-selector',
  disputeAccidentsSelector: 'accidents-selector',
  disputeSuspensionsSelector: 'suspensions-selector',
  disputeEmojiNote: 'emoji-note',
  radioButtonNo: 'radio-no',
  radioButtonYes: 'radio-yes',
};

export const REPORTS_PAGE = {
  reportCardList: 'card-list',
};

export const CANDIDATE_STORIES = {
  candidateStoryCtaContainer: 'candidate-story-cta-container',
  candidateStoriesShareMoreInfoBtn: 'candidate-stories-share-more-info-btn',
  candidateStoryModal: 'candidate-story-modal',
  candidateStoriesNoWrongButton: 'candidate-stories-no-wrong-button',
  candidateStoriesConfirmButton: 'candidate-stories-confirm-btn',
  candidateStoriesMultiSelectCheckbox:
    'candidate-stories-multi-select-checkbox',
  candidateStoryFooterContinueButton: 'candidate-story-footer-continue-button',
  candidateStoryTextField: 'candidate-stories-text-field',
  candidateStoryFooterSubmitButton: 'candidate-story-footer-submit-button',
  candidateStoryUpload: '[data-e2e="upload"]',
  candidateStoryAddADocument: 'upload-button',
  candidateStorySuccess: 'candidate-story-success',
  candidateStoryProvideAdditionalContext: 'provide-additional-context',
  candidateStoryConfirmButton: 'candidate-stories-confirm-btn',
  candidateStoryMultiSelectCheckbox: 'candidate-stories-multi-select-checkbox',
  candidateStoryAcknowledgeNonDisputeCheckbox:
    'acknowledge-non-dispute-checkbox',
};
