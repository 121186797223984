import React from 'react';

import Panel, { PanelProps } from './Panel';
import MastodonPanel from './MastodonPanel';

type PanelSelectProps = PanelProps & { isMastodon?: boolean; tag?: string };

const PanelSelect: React.FC<PanelSelectProps> = ({
  isMastodon = false,
  tag = '',
  ...props
}) => {
  if (isMastodon) return <MastodonPanel {...props} tag={tag} />;
  return <Panel {...props} />;
};

export default PanelSelect;
