import PropTypes from 'prop-types';
import React from 'react';

import Panel from '../../../Panels';
import Record from '../Record';
import Status from '../Status';

import { computeSearchesStatus } from '../lib/status';
import { orderByKey } from '../lib/sort';
import { formatSearchTitle } from '../lib/screenings';

const Search = ({ search, type, hideSuspended }) => {
  const {
    county,
    state,
    country,
    records,
    filteredByPositiveAdjudicationRecords,
  } = search;

  if (!county && !state && !country) {
    return null;
  }

  if (search.status === 'canceled') {
    return <div />;
  }

  const searchTitle = formatSearchTitle(search);

  // don't display the search if the search is suspended
  if (
    (hideSuspended && search.status === 'suspended') ||
    search.status === 'canceled'
  ) {
    return <div />;
  }

  const recordsToDisplay = (records || []).concat(
    filteredByPositiveAdjudicationRecords || [],
  );

  return (
    <div className='mb-3'>
      <div className='d-flex mb-2'>
        <div>
          <strong>{searchTitle}</strong>
        </div>
        <div className='ml-auto'>
          <Status status={search.status} />
        </div>
      </div>
      <div>
        {recordsToDisplay.map((record, i) => (
          <Record
            key={`record-${i + 1}`}
            record={record}
            index={i}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

Search.propTypes = {
  type: PropTypes.string,
  search: PropTypes.object,
  hideSuspended: PropTypes.bool,
};

Search.defaultProps = {
  type: '',
  search: {},
  hideSuspended: false,
};

const SearchCollection = ({
  dataTestId,
  searches,
  title,
  type,
  hideSuspended,
}) => {
  if (!searches || !searches.length) {
    return null;
  }

  const status = computeSearchesStatus(searches);
  const orderedSearches = orderByKey(searches, 'status', [
    'pending',
    'consider',
    'suspended',
    'complete',
    'clear',
  ]);

  if ((hideSuspended && status === 'suspended') || status === 'canceled') {
    return <div />;
  }

  return (
    <Panel data-testid={dataTestId} title={title} status={status}>
      {orderedSearches.map((search, i) => (
        <Search
          key={`search-${i + 1}`}
          search={search}
          type={type}
          hideSuspended={hideSuspended}
        />
      ))}
    </Panel>
  );
};

SearchCollection.propTypes = {
  searches: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  hideSuspended: PropTypes.bool,
  dataTestId: PropTypes.string,
};

SearchCollection.defaultProps = {
  searches: [],
  title: '',
  type: '',
  hideSuspended: false,
  dataTestId: '',
};

export default SearchCollection;
