import * as Sentry from '@sentry/react';
import { poll, request } from './helper';
import { CHECKR_VERIFICATIONS_API_BASE } from '../constants';

/*
 * Action Types
 */

export const DRUG_SCHEDULE_LOCATION_REQUEST = 'DRUG_SCHEDULE_LOCATION_REQUEST';
export const DRUG_SCHEDULE_LOCATION_UPDATED = 'DRUG_SCHEDULE_LOCATION_UPDATED';
export const DRUG_SCHEDULE_SCHEDULE_REQUEST = 'DRUG_SCHEDULE_SCHEDULE_REQUEST';
export const DRUG_SCHEDULE_SCHEDULE_SUCCESS = 'DRUG_SCHEDULE_SCHEDULE_SUCCESS';
export const DRUG_SCHEDULE_VERIFICATION_READY =
  'DRUG_SCHEDULE_VERIFICATION_READY';

/*
 * Actions
 */

export const drugScheduleLocationRequest = applicantLocation => ({
  type: DRUG_SCHEDULE_LOCATION_REQUEST,
  applicantLocation,
});
export const drugScheduleLocationUpdated = verification => ({
  type: DRUG_SCHEDULE_LOCATION_UPDATED,
  verification,
});
export const drugScheduleScheduleRequest = () => ({
  type: DRUG_SCHEDULE_SCHEDULE_REQUEST,
});
export const drugScheduleScheduleSuccess = verification => ({
  type: DRUG_SCHEDULE_SCHEDULE_SUCCESS,
  verification,
});
export const drugScheduleVerificationReady = verification => ({
  type: DRUG_SCHEDULE_VERIFICATION_READY,
  verification,
});

export const pollForDrugScheduleVerificationReady = uri => dispatch => {
  poll(
    () => request(`${CHECKR_VERIFICATIONS_API_BASE}${uri}`), // func to poll
    result => result.ready, // check on func
  )
    .then(verification => dispatch(drugScheduleVerificationReady(verification)))
    // Report this specific error to sentry to isolate it from common failed fetch events
    .catch(error =>
      Sentry.captureException(
        new Error(`Schedule verification not ready: ${error}`),
      ),
    );
};

export const submitDrugScheduleRequest = (uri, scheduleRequest) => dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify(scheduleRequest),
  };

  dispatch(drugScheduleScheduleRequest());

  return request(`${CHECKR_VERIFICATIONS_API_BASE}${uri}/schedule`, options)
    .then(json => {
      dispatch(drugScheduleScheduleSuccess(json));
    })
    .catch(error => {
      throw error;
    });
};

export const submitScheduleRequest =
  (verification, scheduleRequest) => dispatch => {
    const options = {
      method: 'PUT',
      body: JSON.stringify(scheduleRequest),
    };

    dispatch(drugScheduleScheduleRequest());

    return request(
      `${CHECKR_VERIFICATIONS_API_BASE}/schedule_verifications/${verification.id}/location`,
      options,
    )
      .then(location => {
        return request(
          `${CHECKR_VERIFICATIONS_API_BASE}/verifications/${verification.id}`,
        ).then(verificationResponse => {
          verificationResponse.ready = {
            ready: true,
          };

          dispatch(drugScheduleScheduleSuccess(verification));
        });
      })
      .catch(error => {
        throw error;
      });
  };

export const updateDrugScheduleLocation =
  (uri, applicantLocation) => dispatch => {
    const options = {
      method: 'POST',
      body: JSON.stringify(applicantLocation),
    };

    dispatch(drugScheduleLocationRequest(applicantLocation));

    return request(`${CHECKR_VERIFICATIONS_API_BASE}${uri}/location`, options)
      .then(json => {
        dispatch(drugScheduleLocationUpdated(json));
      })
      .catch(error => {
        throw error;
      });
  };
