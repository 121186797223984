import PropTypes from 'prop-types';
import React from 'react';

import BaseRecord from './Base';
import CivilRecord from './Civil';
import CriminalRecord from './Criminal';
import EvictionRecord from './Eviction';
import SexualRecord from './Sexual';
import ArrestRecord from './Arrest';
import ChildAbuseRecord from './ChildAbuse';
import IntlEducationRecord from './International/Education/IntlEducation';
import IntlEmploymentRecord from './International/IntlEmployment';
import IntlCriminalV2 from './International/IntlCriminalV2';
import IntlAdverseMedia from './International/IntlAdverseMedia';
import IntlRightToWork from './International/IntlRightToWork';
import IntlCanadaBcEnhancedCriminal from './International/IntlCanadaBcEnhancedCriminal';
import IntlMotorVehicleReport from './International/IntlMotorVehicleReport';

const Record = ({ record, type, dataTestId }) => {
  const getRecordComponent = () => {
    switch (type) {
      case 'base':
        return <BaseRecord record={record} />;
      case 'civil':
        return <CivilRecord record={record} />;
      case 'criminal':
        return <CriminalRecord record={record} />;
      case 'eviction':
        return <EvictionRecord record={record} />;
      case 'sexual':
        return <SexualRecord record={record} />;
      case 'arrest':
        return <ArrestRecord record={record} />;
      case 'childAbuse':
        return <ChildAbuseRecord record={record} />;
      case 'internationalAdverseMediaSearch':
        return <IntlAdverseMedia record={record} />;
      case 'internationalRightToWork':
        return <IntlRightToWork record={record} />;
      case 'internationalCriminalSearchV2':
        return <IntlCriminalV2 record={record} />;
      case 'internationalEducationVerification':
        return <IntlEducationRecord record={record} />;
      case 'internationalEmploymentVerification':
        return <IntlEmploymentRecord record={record} />;
      case 'internationalCanadaBcEnhancedCriminal':
        return <IntlCanadaBcEnhancedCriminal record={record} />;
      case 'internationalMotorVehicleReport':
        return <IntlMotorVehicleReport record={record} />;
      default:
        return null;
    }
  };

  return (
    <div className='mb-3' data-testid={dataTestId}>
      {getRecordComponent()}
    </div>
  );
};

Record.propTypes = {
  record: PropTypes.object,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
};

Record.defaultProps = {
  record: {},
  type: '',
  dataTestId: '',
};

export default Record;
