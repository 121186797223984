import React, { SVGProps } from 'react';
import { M } from '@dashboard-experience/mastodon';

interface IconProps extends SVGProps<SVGElement> {
  iconName: string;
}

const IconErrorBoundary: React.FC<IconProps> = ({ iconName, ...props }) => {
  return (
    <M.ErrorBoundary fallback={null}>
      <M.Icon icon={iconName} {...props} />
    </M.ErrorBoundary>
  );
};

export default IconErrorBoundary;
