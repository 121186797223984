import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import {
  CANDIDATE_STORY_FORM_NAME,
  CANDIDATE_STORY_SECTIONS,
} from '../../../../constants';
import { settingIsEnabled } from '../../../../lib/helpers';
import { getSearches } from '../lib/screenings';
import { submitCandidateStories as submitCandidateStoriesAction } from '../../../../actions';
import ChargesForm from './ChargesForm';
import ConfirmForm from './ConfirmForm';
import GeneralInformationForm from './GeneralInformationForm';
import CaseInformationForm from './CaseInformationForm';
import CandidateStorySummary from './CandidateStorySummary';
import Footer from './Footer';

class CandidateStoryForm extends Component {
  constructor(props) {
    super(props);

    this.switchToDisputeModal = this.switchToDisputeModal.bind(this);
    this.confirmAccuracy = this.confirmAccuracy.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.completeInformationSelection =
      this.completeInformationSelection.bind(this);
    this.toggleGeneralInformation = this.toggleGeneralInformation.bind(this);
    this.toggleRecord = this.toggleRecord.bind(this);
    this.completeRecordSelection = this.completeRecordSelection.bind(this);
    this.remove = this.remove.bind(this);
    this.findIndexOfRecord = this.findIndexOfRecord.bind(this);
    this.switchToChargeSelection = this.switchToChargeSelection.bind(this);
    this.passAcknowledged = this.passAcknowledged.bind(this);
  }

  componentDidMount() {
    let { generalInformationOnly } = this.props;

    generalInformationOnly =
      generalInformationOnly ||
      (this.isSettingEnabled('GeneralInfo') &&
        !this.isSettingEnabled('RecordsInfo'));

    if (generalInformationOnly) {
      this.toggleGeneralInformation();
    }
  }

  switchToDisputeModal() {
    const { toggleCandidateStoryModal, toggleDisputeModal } = this.props;
    toggleDisputeModal();
    toggleCandidateStoryModal();
  }

  /**
   * Returns `true` if the setting is enabled.
   *
   * Note: The name will be prefixed with `candidateStories`.
   *
   * Settings:
   * - candidate_stories_general_info
   * - candidate_stories_records_info
   *
   * @param {String} name the setting name suffix, camel-cased
   */
  isSettingEnabled(name) {
    const {
      report: { account },
    } = this.props;

    const setting = account[`candidateStories${name}`];
    return settingIsEnabled(setting, { nullish: true });
  }

  confirmAccuracy() {
    const { change } = this.props;

    change('confirmed', true);
  }

  handleBack() {
    const {
      change,
      caseInformationComplete,
      confirmed,
      currentCaseId,
      generalInformation,
      generalInformationComplete,
      recordSelectionComplete,
      selectedRecords,
    } = this.props;

    change('acknowledged', false);

    if (caseInformationComplete) {
      change('caseInformationComplete', false);
    } else if (generalInformationComplete) {
      change('generalInformationComplete', false);
    } else if (
      selectedRecords.length > 0 &&
      generalInformationComplete &&
      !caseInformationComplete
    ) {
      const index = this.findIndexOfRecord(currentCaseId);
      const prev = index - 1 >= 0 ? index - 1 : -1;
      if (prev >= 0) {
        change('currentCaseId', selectedRecords[prev].id);
      } else if (generalInformation) {
        change('generalInformationComplete', false); // back to general information
      } else {
        change('recordSelectionComplete', false); // back to record selection
      }
    } else if (
      recordSelectionComplete &&
      generalInformation &&
      !generalInformationComplete
    ) {
      change('recordSelectionComplete', false); // back to charges selection
    } else if (recordSelectionComplete) {
      change('recordSelectionComplete', false);
    } else if (confirmed) {
      change('confirmed', false);
    }
  }

  completeInformationSelection() {
    const {
      change,
      currentCaseId,
      generalInformation,
      generalInformationComplete,
      selectedRecords,
    } = this.props;

    if (generalInformation && !generalInformationComplete) {
      change('generalInformationComplete', true);
    } else if (selectedRecords.length) {
      const index = this.findIndexOfRecord(currentCaseId);
      if (selectedRecords[index + 1]) {
        change('currentCaseId', selectedRecords[index + 1].id);
      } else {
        change('caseInformationComplete', true);
      }
    } else {
      // move directly to summary screen
      change('generalInformationComplete', true);
    }
  }

  toggleGeneralInformation() {
    const { change, candidateStories, generalInformation, selectedItems } =
      this.props;

    if (generalInformation) {
      const newCandidateStory = { ...candidateStories };
      delete newCandidateStory.content.general;
      delete newCandidateStory.general;

      change('candidateStories', newCandidateStory);
      change('selectedItems', selectedItems > 0 ? selectedItems - 1 : 1);
    } else {
      change('selectedItems', selectedItems + 1);
    }
    change('generalInformation', !generalInformation);
  }

  toggleRecord(record) {
    const { array, change, candidateStories, selectedItems } = this.props;
    const i = this.findIndexOfRecord(record.id);

    if (i === -1) {
      array.push('selectedRecords', record);
      change('selectedItems', selectedItems + 1);
    } else {
      array.remove('selectedRecords', i);
      change('selectedItems', selectedItems - 1);

      const newCandidateStory = { ...candidateStories };
      delete newCandidateStory.content[record.id];
      delete newCandidateStory[record.id];
      change('candidateStories', newCandidateStory);
    }
  }

  completeRecordSelection() {
    const { change, selectedRecords } = this.props;
    change('recordSelectionComplete', true);
    if (selectedRecords.length) {
      change('currentCaseId', selectedRecords[0].id); // set the first case
    }
  }

  remove(i, model) {
    const { array } = this.props;
    array.remove(model, i);
  }

  findIndexOfRecord(recordId) {
    const { selectedRecords } = this.props;
    return selectedRecords.findIndex(item => item.id === recordId);
  }

  passAcknowledged() {
    const { change } = this.props;
    change('acknowledged', true);
  }

  switchToChargeSelection() {
    const {
      change,
      generalInformationComplete,
      caseInformationComplete,
      recordSelectionComplete,
    } = this.props;

    if (caseInformationComplete) {
      change('caseInformationComplete', false);
    }
    if (generalInformationComplete) {
      change('generalInformationComplete', false);
    }
    if (recordSelectionComplete) {
      change('recordSelectionComplete', false);
    }
  }

  render() {
    const {
      acknowledged,
      companyName,
      confirmed,
      currentCaseId,
      selectedRecords,
      selectedItems,
      generalInformation,
      generalInformationComplete,
      generalInformationOnly,
      handleSubmit,
      toggleDisputeModal,
      processing,
      recordSelectionComplete,
      report,
      submitCandidateStories,
      valid,
      candidateStories,
      caseInformationComplete,
    } = this.props;

    const generalInfoEnabled = this.isSettingEnabled('GeneralInfo');
    const recordsInfoEnabled = this.isSettingEnabled('RecordsInfo');

    const formPages = [
      { name: 'confirmSelect', showIf: !confirmed },
      {
        name: 'chargeSelect',
        showIf:
          recordsInfoEnabled &&
          !recordSelectionComplete &&
          !generalInformationOnly,
      },
      {
        name: 'generalInformation',
        showIf:
          generalInfoEnabled &&
          generalInformation &&
          !generalInformationComplete,
      },
      {
        name: 'caseInformation',
        showIf: selectedRecords.length > 0 && !caseInformationComplete,
      },
      {
        name: 'summary',
        showIf: caseInformationComplete || generalInformationComplete,
      },
    ];
    const currentPage = formPages.find(page => page.showIf).name;

    const formActions = {
      completeRecordSelection: this.completeRecordSelection.bind(this),
      confirmAccuracy: this.confirmAccuracy.bind(this),
      handleBack: this.handleBack.bind(this),
      switchToDisputeModal: this.switchToDisputeModal.bind(this),
      completeInformationSelection:
        this.completeInformationSelection.bind(this),
    };

    return (
      <form onSubmit={handleSubmit(submitCandidateStories)}>
        <div className='p-4'>
          {currentPage === 'confirmSelect' && (
            <ConfirmForm
              companyName={companyName}
              toggleDisputeModal={toggleDisputeModal}
              actions={formActions}
            />
          )}

          {currentPage === 'chargeSelect' && (
            <ChargesForm
              searches={getSearches(report, CANDIDATE_STORY_SECTIONS)}
              toggleGeneralInformation={this.toggleGeneralInformation}
              toggleRecord={this.toggleRecord}
              generalInfoEnabled={generalInfoEnabled}
              generalInformation={generalInformation}
              selectedRecords={selectedRecords}
            />
          )}

          {currentPage === 'generalInformation' && (
            <GeneralInformationForm
              account={report.account}
              companyName={companyName}
              candidateStories={candidateStories}
              remove={this.remove}
              selectedItems={selectedItems}
            />
          )}

          {currentPage === 'caseInformation' &&
            selectedRecords.map((record, i) => (
              <CaseInformationForm
                account={report.account}
                record={record}
                companyName={companyName}
                candidateStories={candidateStories}
                remove={this.remove}
                selectedRecords={selectedRecords}
                selectedItems={selectedItems}
                order={i + 1}
                currentCaseId={currentCaseId}
                generalInformation={generalInformation}
                key={record.id}
              />
            ))}

          {currentPage === 'summary' && (
            <CandidateStorySummary
              candidateStories={candidateStories}
              companyName={companyName}
              generalInformationOnly={generalInformationOnly}
              selectedRecords={selectedRecords}
              switchToChargeSelection={this.switchToChargeSelection}
              passAcknowledged={this.passAcknowledged}
            />
          )}
        </div>
        <div className='tile-option-footer'>
          {' '}
          <Footer
            currentPage={currentPage}
            actions={formActions}
            processing={processing}
            valid={valid}
            selectedItems={selectedItems}
            acknowledged={acknowledged}
          />
        </div>
      </form>
    );
  }
}

CandidateStoryForm.propTypes = {
  acknowledged: PropTypes.bool.isRequired,
  array: PropTypes.object.isRequired,
  caseInformationComplete: PropTypes.bool,
  change: PropTypes.func.isRequired,
  companyName: PropTypes.string.isRequired,
  confirmed: PropTypes.bool,
  currentCaseId: PropTypes.string,
  generalInformation: PropTypes.bool,
  generalInformationComplete: PropTypes.bool,
  generalInformationOnly: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  toggleCandidateStoryModal: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  recordSelectionComplete: PropTypes.bool,
  report: PropTypes.object.isRequired,
  selectedItems: PropTypes.number,
  selectedRecords: PropTypes.array,
  submitCandidateStories: PropTypes.func.isRequired,
  toggleDisputeModal: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  candidateStories: PropTypes.object,
};

CandidateStoryForm.defaultProps = {
  caseInformationComplete: false,
  confirmed: false,
  currentCaseId: '',
  generalInformation: true,
  generalInformationComplete: false,
  generalInformationOnly: false,
  recordSelectionComplete: false,
  selectedItems: 0,
  selectedRecords: [],
  candidateStories: {},
};

const ReduxCandidateStoryForm = reduxForm({
  form: CANDIDATE_STORY_FORM_NAME,
  shouldValidate: () => true,
})(CandidateStoryForm);

const selector = formValueSelector(CANDIDATE_STORY_FORM_NAME);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    candidateStories: {},
    report: ownProps.report,
    userActions: [],
  },
  caseInformationComplete: selector(state, 'caseInformationComplete') || false,
  confirmed: selector(state, 'confirmed') || false,
  currentCaseId: selector(state, 'currentCaseId') || '',
  fields: selector(state, 'fields') || [],
  generalInformation: selector(state, 'generalInformation') || false,
  generalInformationComplete:
    selector(state, 'generalInformationComplete') || false,
  processing: state.candidateStories.processing,
  recordSelectionComplete: selector(state, 'recordSelectionComplete') || false,
  selectedItems: selector(state, 'selectedItems') || 0,
  selectedRecords: selector(state, 'selectedRecords') || [],
  candidateStories: selector(state, 'candidateStories') || {},
  acknowledged: selector(state, 'acknowledged') || false,
});

export default connect(mapStateToProps, {
  submitCandidateStories: submitCandidateStoriesAction,
})(ReduxCandidateStoryForm);
