import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { getAPIBase } from 'api/verifications/getAPIBase';
import { CANDIDATE_STORIES } from 'identifiers/identifiers';
import SecureFilestack from '../SecureFilestack/SecureFilestack';
import {
  BACKEND_FLAG_PATHS,
  FILE_STACK_API_KEY,
  MAX_FILE_SIZE,
} from '../../constants';
import { request } from '../../actions/helper';
import '../../scss/FileStackOverrides.scss';
import { getUploadCustomText } from './helper';

const deleteFile = file => {
  return getAPIBase({ path: BACKEND_FLAG_PATHS.DELETE_FILE }).then(baseURL => {
    const path = `${baseURL}/delete_file`;
    const options = {
      method: 'POST',
      body: JSON.stringify({ url: file.url }),
      retryDelay: 200,
    };

    return request(path, options);
  });
};

const Upload = ({
  buttonClass,
  buttonText,
  icon,
  input,
  options = {},
  v1,
  disabled,
  kind,
}) => {
  const FileStackDefaultOptions = {
    accept: ['image/*', '.pdf'],
    storeTo: { location: 'S3' },
    fromSources: ['local_file_system'],
    maxFiles: 10,
    disableTransformer: true,
    uploadInBackground: false,
    onFileUploadFinished: deleteFile,
    customText: getUploadCustomText(),
    lang: i18n.getLocale().substring(0, 2),
    maxSize: MAX_FILE_SIZE,
  };

  const fileStackOptions = { ...FileStackDefaultOptions, ...options };

  const handleSuccess = e => {
    const { maxFiles } = options;
    const allFiles = (input.value || []).concat(e.filesUploaded);

    while (maxFiles && allFiles.length > maxFiles) {
      allFiles.shift();
    }
    input.onChange(allFiles);
  };

  const uploadButton = onPick => {
    if (v1) {
      return (
        <M.Button
          data-testid={CANDIDATE_STORIES.candidateStoryAddADocument}
          v1='true'
          kind='secondary'
          onClick={onPick}
          disabled={disabled}
        >
          <M.Icon style={{ marginRight: 0 }} icon='Upload' size={16} />
          &nbsp;
          <T value={buttonText} />
        </M.Button>
      );
    }
    return (
      <M.Button
        data-testid={CANDIDATE_STORIES.candidateStoryAddADocument}
        kind={kind}
        onClick={onPick}
        className={buttonClass}
        disabled={disabled}
      >
        {icon && <M.Icon icon={icon} size={16} style={{ marginRight: 0 }} />}
        &nbsp;
        <T value={buttonText} />
      </M.Button>
    );
  };

  return (
    <SecureFilestack
      key={i18n.getLocale()}
      apikey={FILE_STACK_API_KEY}
      actionOptions={fileStackOptions}
      onSuccess={handleSuccess}
      customRender={({ onPick }) => uploadButton(onPick)}
    />
  );
};

Upload.propTypes = {
  buttonClass: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  input: PropTypes.object.isRequired,
  options: PropTypes.object,
  v1: PropTypes.bool,
  disabled: PropTypes.bool,
  kind: PropTypes.string,
};

Upload.defaultProps = {
  buttonClass: '',
  icon: '',
  options: {},
  v1: false,
  disabled: false,
  kind: 'primary',
};

export default Upload;
