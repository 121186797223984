const formattedInputValue = (key, input, candidateName) => {
  switch (key) {
    case 'certificationIssuedTo':
      return input.businessName || candidateName;
    default:
      return input[key] || '-';
  }
};

export default formattedInputValue;
