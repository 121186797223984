import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Panel from '../../../Panels';
import ReferenceVerification from './ReferenceVerification';

import { computeSearchesStatus } from '../lib/status';

const PersonalReferenceVerification = ({ searches }) =>
  !!searches.length && (
    <Panel
      title={i18n.getStr('components.personalReferenceVerifications')}
      status={computeSearchesStatus(searches)}
    >
      <ReferenceVerification searches={searches} />
    </Panel>
  );

PersonalReferenceVerification.propTypes = {
  searches: PropTypes.array,
};

PersonalReferenceVerification.defaultProps = {
  searches: [],
};

export default i18n.renderTranslation()(PersonalReferenceVerification);
