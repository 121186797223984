import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';

const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
`;

const FooterButton = ({
  kind,
  type,
  onClickFunction,
  value,
  testId,
  disabled,
  processing,
}) => (
  <M.Button
    kind={kind}
    data-testid={testId}
    type={type}
    onClick={onClickFunction}
    disabled={disabled}
  >
    {value === 'buttons.back' && <>&lt; </>}
    {processing && type === 'submit' && (
      <M.LoadingInline className='button-loading-icon' />
    )}
    {!processing && <T value={value} />}
  </M.Button>
);

FooterButton.propTypes = {
  kind: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onClickFunction: PropTypes.func,
  value: PropTypes.string.isRequired,
  testId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  processing: PropTypes.bool.isRequired,
};

FooterButton.defaultProps = {
  onClickFunction: () => {},
  disabled: false,
};

const Footer = ({
  acknowledged,
  actions,
  currentPage,
  processing,
  valid,
  selectedItems,
}) => {
  const footerMapping = {
    confirmSelect: 'select_without_back',
    chargeSelect: 'multi_select',
    generalInformation: 'information',
    caseInformation: 'information',
    summary: 'summary',
  };
  const footerType = footerMapping[currentPage];

  return (
    <FooterWrapperStyled>
      <div>
        {footerType !== 'select_without_back' && (
          <FooterButton
            kind='tertiary'
            type='button'
            onClickFunction={actions.handleBack}
            value='buttons.back'
            testId='candidate-story-footer-back-button'
            processing={processing}
          />
        )}
      </div>
      <div data-testid='candidate-story-footer-button-wrapper'>
        {footerType === 'summary' && (
          <FooterButton
            kind='primary'
            type='submit'
            onClickFunction={null}
            value='buttons.send'
            testId='candidate-story-footer-submit-button'
            processing={processing}
            disabled={processing || !valid || !acknowledged}
          />
        )}
        {footerType === 'multi_select' && (
          <FooterButton
            kind='primary'
            type='button'
            onClickFunction={actions.completeRecordSelection}
            value='buttons.continue'
            testId='candidate-story-footer-continue-button'
            processing={processing}
            disabled={!valid || !selectedItems}
            acknowledged
          />
        )}
        {footerType === 'information' && (
          <FooterButton
            kind='primary'
            type='button'
            onClickFunction={actions.completeInformationSelection}
            value='buttons.continue'
            testId='candidate-story-footer-continue-button'
            processing={processing}
            disabled={!valid}
            acknowledged
          />
        )}
      </div>
    </FooterWrapperStyled>
  );
};

Footer.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  currentPage: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  selectedItems: PropTypes.number.isRequired,
  acknowledged: PropTypes.bool.isRequired,
};

export default Footer;
