export type ReportStatus =
  | 'pending'
  | 'clear'
  | 'complete'
  | 'consider'
  | 'suspended'
  | 'canceled'
  | 'closed'
  | 'dispute';

export type ReportDisplayStatus =
  | 'actionRequired'
  | 'inProgress'
  | 'complete'
  | 'consider'
  | 'suspended'
  | 'canceled'
  | 'disputed'
  | 'closed';

export enum ReportType {
  actionRequired = 'actionRequired',
}

export type Exception = Record<string, any>;
export interface ReportSummary {
  id: string;
  candidateId?: string;
  accountName: string;
  accountId: string;
  type: string;
  status: ReportStatus;
  exceptions: Exception[];
  eta: string | null;
  lastTouchedAt: string;
  completeByDate: string | null;
  completedDate: string | null;
  reportOrder?: number;
  displayStatus: ReportDisplayStatus | null;
  expectedActionNeededByDate?: string;
}
