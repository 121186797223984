import { SESSION_STORAGE_JWT_KEY } from '../constants';
import * as ActionTypes from '../actions';

export const initialState = {
  error: null,
  processing: false,
  loggedIn: false,
  loggedOut: false,
  reports: [],
  count: 0,
  token: null,
  commentSubmitted: false,
  disputeSubmitted: false,
  applicantId: null,
  firstName: null,
  country: null,
  notices: [],
  termsOfService: {
    content: '',
    accepted: false,
  },
  expungements: [],
};

export const DISPUTE_STATUS = 'dispute';

const mapObjectResultToStatus = val => {
  if (
    Object.keys(val).indexOf('status') > -1 &&
    Object.keys(val).indexOf('result') > -1 &&
    val.status !== 'dispute'
  ) {
    const newStatus = val.result || val.status;
    val.status = newStatus;
  }
};

const mapReportResultToStatus = readOnlyReports => {
  const reports = JSON.parse(JSON.stringify(readOnlyReports));
  try {
    reports.forEach(report => {
      mapObjectResultToStatus(report);
      Object.keys(report).forEach(key => {
        if (report[key] && typeof report[key] === 'object') {
          if (Array.isArray(report[key])) {
            report[key].forEach(val => mapObjectResultToStatus(val));
          } else {
            mapObjectResultToStatus(report[key]);
          }
        }
      });
    });
    return reports;
  } catch (error) {
    return reports;
  }
};

const portal = (state = initialState, action) => {
  const {
    reports,
    count,
    token,
    type,
    termsOfService,
    firstName,
    country,
    applicantId,
    notices,
    reportId,
    expungements,
  } = action;
  switch (type) {
    case ActionTypes.FETCH_REPORTS_REQUEST:
      return {
        ...initialState,
        processing: true,
      };
    case ActionTypes.FETCH_REPORTS_SUCCESS:
      return {
        ...state,
        error: reports.length === 0 && 'errors.reportsArchived',
        loggedIn: true,
        processing: false,
        reports: mapReportResultToStatus(reports),
        count,
        token,
        applicantId,
        notices,
        termsOfService,
        country,
        firstName,
      };
    case ActionTypes.FETCH_REPORTS_FAILURE:
      return {
        ...initialState,
        error: 'errors.reportNotFound',
      };
    case ActionTypes.FETCH_REPORTS_UNKNOWN_FAILURE:
      return {
        ...initialState,
        error: 'errors.unknownFailure',
      };
    case ActionTypes.FETCH_REPORTS_FAILURE_INTL:
      return {
        ...initialState,
        error: 'errors.candidateNotFound',
      };
    case ActionTypes.FETCH_REPORTS_BUSY:
      return {
        ...initialState,
        error: 'errors.busy',
      };
    case ActionTypes.CLEAR_FETCH_REPORTS_FAILURE:
    case ActionTypes.FETCH_REPORTS_REDIRECT:
      return {
        ...initialState,
      };
    case ActionTypes.ACCEPT_TOS_SUCCESS:
      return {
        ...state,
        termsOfService: {
          content: '',
          accepted: true,
        },
      };
    case ActionTypes.SUBMIT_COMMENT_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case ActionTypes.SUBMIT_COMMENT_SUCCESS:
      return {
        ...state,
        commentSubmitted: true,
        processing: false,
      };
    case ActionTypes.SUBMIT_DISPUTE_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case ActionTypes.SUBMIT_DISPUTE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedReports =
        state.reports && state.reports.length > 0
          ? state.reports.map(report =>
              report.id === reportId
                ? { ...report, status: DISPUTE_STATUS }
                : report,
            )
          : [];
      return {
        ...state,
        disputeSubmitted: true,
        processing: false,
        reports: updatedReports,
      };
    case ActionTypes.SUBMIT_INTAKE_SUCCESS:
      return {
        ...state,
        disputeSubmitted: true,
        processing: false,
      };
    case ActionTypes.FETCH_EXPUNGEMENTS_SUCCESS:
      return {
        ...state,
        expungements,
      };
    case ActionTypes.LOGOUT_CANDIDATE:
      window.sessionStorage.setItem(SESSION_STORAGE_JWT_KEY, '');
      return {
        ...state,
        ...initialState,
        loggedOut: true,
      };
    case ActionTypes.RELOAD_REPORT:
      window.location.reload();
      return {
        ...state,
        processing: true,
      };
    default:
      return state;
  }
};

export default portal;
