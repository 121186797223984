import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import { DRIVING_EXPERIENCE } from './constants';
import Verification, { connectToVerifications } from './VerificationBase';
import VerificationHeaderPanel from './VerificationHeaderPanel';
import MatrixVerificationForm from './MatrixVerificationForm';
import MissingInformationHeader from './MissingInformationHeader';
import MatrixVerificationAdditionalInfo from './MatrixVerificationAdditionalInfo';
import DrivingExperience from './DrivingExperience';

class MatrixVerification extends Verification {
  renderCustomPanel() {
    const { isFinalStep, verification } = this.getRenderAttributes();
    if (!isFinalStep) return null;

    const matrixFields = verification?.fieldConfigurations?.map(fc => fc.name);
    const isMvrConsent = matrixFields?.includes('mvr_consent');

    if (
      verification.metadata?.configuration?.exceptionType === DRIVING_EXPERIENCE
    ) {
      return <DrivingExperience verification={verification} />;
    }

    return (
      <div className='matrix-verification-wrapper'>
        <VerificationHeaderPanel {...verification} hideLogo={isMvrConsent} />
        <MissingInformationHeader matrixFields={matrixFields} />
        <M.Grid>
          <MatrixVerificationForm
            fields={verification.fieldConfigurations}
            company={verification.company}
          />
          {verification.fieldConfigurations[0]?.additionalInfoKey && (
            <MatrixVerificationAdditionalInfo
              additionalInfoKey={
                verification.fieldConfigurations[0].additionalInfoKey
              }
            />
          )}
        </M.Grid>
      </div>
    );
  }
}

export default connectToVerifications(MatrixVerification);
