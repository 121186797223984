import { decamelizeKeys } from 'humps';
import { BACKEND_FLAG_PATHS } from 'constants';
import { getAPIBase } from '../api/verifications/getAPIBase';
import { request } from './helper';

/*
 * Action Types
 */

export const ACCEPT_TOS_SUCCESS = 'ACCEPT_TOS_SUCCESS';
export const acceptTosAction = () => ({ type: ACCEPT_TOS_SUCCESS });

/*
 * Actions
 */

export const acceptTos = values => dispatch => {
  const options = {
    method: 'POST',
    body: JSON.stringify(decamelizeKeys(values)),
  };

  return getAPIBase({ path: BACKEND_FLAG_PATHS.TOS }).then(baseURL =>
    request(
      `${baseURL}/applicants/${values.applicantId}/terms_of_service`,
      options,
    ).then(() => {
      dispatch(acceptTosAction());
    }),
  );
};
