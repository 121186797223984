import React from 'react';
import EmailLogin from 'components/Reports/CandidateLogin/EmailLogin';
import EmailAuth from 'containers/EmailAuth';

const AuthenticateCandidate: React.FC = () => {
  return (
    <EmailAuth>
      <EmailLogin />
    </EmailAuth>
  );
};

export default AuthenticateCandidate;
