import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import {
  Field,
  formValueSelector,
  reduxForm,
  InjectedFormProps,
} from 'redux-form';
import { connect, MapStateToPropsParam } from 'react-redux';
import * as V from '../../../lib/validations';
import * as N from '../../../lib/normalizations';
import { Checkbox } from '../../fields';
import { VERIFICATION_FORM_NAME } from '../../../constants';
import { SensitiveTextInput } from '../../fields/mastodon';
import './SsnCheckVerificationForm.scss';
import {
  StyledHelpfulToKnow,
  StyledDivider,
  StyledFaq,
} from '../VerificationFooter';

interface SsnCheckVerificationFormProps {
  canDeclareNoSsn?: boolean;
  noSsn?: boolean;
  formSubmitted?: boolean;
}

const SsnCheckVerificationForm: React.FC<
  SsnCheckVerificationFormProps & InjectedFormProps
> = ({ canDeclareNoSsn, noSsn, change, formSubmitted }) => {
  const onNoSsnUpdate: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { checked },
  }) => {
    if (checked) {
      change('ssn', '');
      change('ssnConfirmation', '');
    }
  };

  return (
    <>
      <M.Container className='verification-exception'>
        <M.Container.Row as='header'>
          <h1>
            {i18n.getStr(
              'components.Report.Verification.UpdatedSsnCheck.title',
            )}
          </h1>
          <p data-testid='verification-name'>
            {i18n.getStr('components.Report.Verification.UpdatedSsnCheck.name')}
          </p>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col lg={8} md={4}>
            <Field
              id='ssnInput'
              type='tel'
              name='ssn'
              placeholder='placeholders.ssn'
              className='ssn-reentry__input'
              labelControl={
                <>
                  <span style={{ fontSize: '14px' }}>
                    {i18n.getStr(
                      'components.Report.Verification.UpdatedSsnCheck.ssnInputLabel',
                    )}
                  </span>
                  <M.Icon icon='Locked' />
                </>
              }
              component={SensitiveTextInput}
              normalize={N.ssn}
              validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
              disabled={noSsn}
              mask={/\w/g}
              onPaste={(e: any) => e.preventDefault()}
              tooltip={{
                show: i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.tooltip.show',
                ),
                hide: i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.tooltip.hide',
                ),
              }}
            />
          </M.Container.Col>
          <M.Container.Col lg={8} md={4}>
            <Field
              type='tel'
              name='ssnConfirmation'
              placeholder='placeholders.ssn'
              className='ssn-reentry__input'
              labelControl={
                <>
                  <span style={{ fontSize: '14px' }}>
                    {i18n.getStr(
                      'components.Report.Verification.UpdatedSsnCheck.confirmSsnInputLabel',
                    )}
                  </span>
                  <M.Icon icon='Locked' />
                </>
              }
              component={SensitiveTextInput}
              normalize={N.ssn}
              validate={[V.ssnRequired, V.ssnValid, V.ssnMatch]}
              disabled={noSsn}
              mask={/\w/g}
              onPaste={(e: any) => e.preventDefault()}
              tooltip={{
                show: i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.tooltip.show',
                ),
                hide: i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.tooltip.hide',
                ),
              }}
            />
          </M.Container.Col>
        </M.Container.Row>
        {canDeclareNoSsn && (
          <M.Container.Row>
            <M.Container.Col>
              <Field
                type='checkbox'
                name='noSsn'
                label='labels.noSsn'
                className='inline-checkbox top-inline bottom-inline'
                onChange={onNoSsnUpdate}
                component={Checkbox}
                i18n
              />
            </M.Container.Col>
          </M.Container.Row>
        )}
        <M.Container.Row className='verification-exception__submit-container'>
          <M.GridRow>
            <M.Button kind='primary' type='submit' disabled={formSubmitted}>
              {formSubmitted ? (
                <M.LoadingInline className='button-loading-icon button-loading-icon--primary' />
              ) : (
                i18n.getStr(
                  'components.Report.Verification.UpdatedSsnCheck.button',
                )
              )}
            </M.Button>
          </M.GridRow>
        </M.Container.Row>
      </M.Container>
      <M.Container className='verification-footer'>
        <M.Container.Row>
          <StyledHelpfulToKnow translationKey='components.Report.Verification.UpdatedSsnCheck.helpfulToKnow' />
        </M.Container.Row>
        <StyledDivider />
        <M.Container.Row>
          <StyledFaq translationKey='components.Report.Verification.UpdatedSsnCheck.faq' />
        </M.Container.Row>
      </M.Container>
    </>
  );
};

const UpdatedSsnCheckReduxForm = reduxForm({
  form: VERIFICATION_FORM_NAME,
})(SsnCheckVerificationForm);

const selector = formValueSelector(VERIFICATION_FORM_NAME);
const mapStateToProps: MapStateToPropsParam<
  any,
  SsnCheckVerificationFormProps,
  any
> = (state: any) => {
  return {
    ssn: selector(state, 'ssn') || '',
    ssnConfirmation: selector(state, 'ssnConfirmation') || '',
    noSsn: selector(state, 'noSsn') || false,
  };
};

export default connect<any, null, SsnCheckVerificationFormProps, any>(
  mapStateToProps,
)(i18n.renderTranslation()(UpdatedSsnCheckReduxForm));
