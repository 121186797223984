import React from 'react';
import { useDispatch } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';
import { colors } from '@dashboard-experience/mastodon';
import { HELP_CENTER_LINKS } from '../../constants';
import { trackAnalyticsEvent } from '../../actions';
import { ANALYTICS_EVENTS } from '../../lib/analytics';

const HelpCenterLinkWrapper = styled.a`
  color: ${colors.checkrBlue};
  text-decoration: none !important;
  background-color: transparent;
`;

const HeaderHelpcenterLink: React.FC<{
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
  tabIndex?: -1 | 0 | '-1' | '0';
}> = ({ onClick, tabIndex = 0 }) => {
  const dispatch = useDispatch();
  return (
    <HelpCenterLinkWrapper
      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
        dispatch(trackAnalyticsEvent(ANALYTICS_EVENTS.HELP_CENTER_VIEWED));
        onClick(e);
      }}
      target='_blank'
      data-testid='help-center-link'
      href={HELP_CENTER_LINKS.candidatesHelpCenter}
      className='header__menu-item'
      tabIndex={+tabIndex}
      rel='noopener noreferrer'
    >
      {i18n.getStr(`components.Header.helpCenter`)}
    </HelpCenterLinkWrapper>
  );
};

export default HeaderHelpcenterLink;
