import { DISPUTES_FLOW } from 'identifiers/identifiers';

export const REASON_TYPE_TO_SELECTORS_DATA_TESTID: {
  [key: string]: string;
} = {
  licenseInformation: DISPUTES_FLOW.disputeLicenseInformationSelector,
  violations: DISPUTES_FLOW.disputeViolationSelector,
  restrictions: DISPUTES_FLOW.disputeRestrictionsSelector,
  endorsements: DISPUTES_FLOW.disputeEndorsementsSelector,
  accidents: DISPUTES_FLOW.disputeAccidentsSelector,
  suspensions: DISPUTES_FLOW.disputeSuspensionsSelector,
};
