import PropTypes from 'prop-types';
import React from 'react';

import Charge from '../Charge/Criminal';
import Attribute from '../Attribute';

import { getChargeTitle, getChargeDate } from '../lib/screenings';

const CRIMINAL_ATTRS = Object.freeze([
  'caseNumber',
  'fileDate',
  'courtOfRecord',
  'courtJurisdiction',
  'arrestingAgency',
  'county',
  'state',
  'fullName',
  'dob',
  'yob',
]);

const CriminalRecord = ({ record }) => {
  const chargesToDisplay = (record.charges || []).concat(
    record.filteredByPositiveAdjudicationCharges || [],
    record.chargesHiddenByAssess || [],
  );
  const hasCharges = chargesToDisplay.length > 1;

  return (
    <div className='criminal-record mb-4'>
      <div className='mb-3'>
        <hr className='mb-2 mt-2' />
        <div className='d-flex'>
          <span className='text-uppercase'>{getChargeTitle(record)}</span>
          <span className='ml-auto'>{getChargeDate(record)}</span>
        </div>
        <hr className='mb-2 mt-2' />
      </div>

      <div className='mb-4 mt-4'>
        {CRIMINAL_ATTRS.map((key, i) => (
          <Attribute
            key={`attribute-${i + 1}`}
            attr={key}
            value={record[key]}
          />
        ))}
      </div>

      {hasCharges && <hr />}

      {chargesToDisplay.map((charge, i) => (
        <div key={`div-${i + 1}`}>
          <Charge charge={charge} index={i} />
          <hr />
        </div>
      ))}
    </div>
  );
};

CriminalRecord.propTypes = {
  record: PropTypes.object.isRequired,
};

export default CriminalRecord;
