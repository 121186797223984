import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import { MotorVehicleRecord } from 'types/motorVehicleReport';
import TileCheckbox from 'components/TileCheckbox';
import {
  DisputeReasonSummaryMap,
  ReasonCodeToAttributeMap,
} from 'components/Workflow/Disputes/Types';
import { UpdateDraft } from 'components/Workflow/hooks';
import TileCheckboxGroup from 'components/TileCheckboxGroup';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { M } from '@dashboard-experience/mastodon';
import ContentContainer from 'components/Workflow/ContentContainer';
import { formatDateIfValid, isEmpty } from 'lib/helpers';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const DATE_FIELDS = ['convictionDate', 'expirationDate', 'issuedDate'];

const formatValue = (key: string, value: number | string) => {
  if (DATE_FIELDS.includes(key)) {
    return formatDateIfValid(value);
  }

  return String(value);
};

const MotorVehicleRecordSubreasonDetailSelector = ({
  back,
  continueNext,
  draftDisputeReasonSummaries,
  updateDraft,
  motorVehicleRecord,
}: {
  back: () => void;
  continueNext: () => void;
  draftDisputeReasonSummaries: DisputeReasonSummaryMap;
  updateDraft: UpdateDraft;
  motorVehicleRecord: MotorVehicleRecord;
}) => {
  const subreason =
    draftDisputeReasonSummaries.motorVehicleRecord.subreasons[0];

  const { reasonCode } = draftDisputeReasonSummaries.motorVehicleRecord;

  const handleChange = (detail: string) => {
    const existingDetails = subreason.details || [];
    if (existingDetails.includes(detail)) {
      updateDraft('motorVehicleRecord', {
        subreasons: [
          {
            ...subreason,
            details: existingDetails.filter(
              existingDetail => existingDetail !== detail,
            ),
          },
        ],
      });
    } else {
      updateDraft('motorVehicleRecord', {
        subreasons: [{ ...subreason, details: [...existingDetails, detail] }],
      });
    }
  };

  const subreasonDetailCheckboxes = () => {
    const reasonCodeToAttributesListMapping: ReasonCodeToAttributeMap = {
      violations: [
        'description',
        'issuedDate',
        'city',
        'disposition',
        'convictionDate',
        'points',
        'state',
        'stateCode',
      ],
      suspensions: [
        'description',
        'startDate',
        'city',
        'endDate',
        'points',
        'state',
        'stateCode',
      ],
      accidents: [
        'description',
        'accidentDate',
        'severity',
        'points',
        'state',
        'stateCode',
      ],
    };
    return Object.entries(motorVehicleRecord).map(([key, value]) => {
      if (
        reasonCodeToAttributesListMapping[reasonCode].includes(key) &&
        String(value)
      ) {
        const detailText = i18n.getStr(
          `workflows.dispute.subreasonDetails.${key}`,
          formatValue(key, value),
        );

        return (
          <TileCheckbox
            id={key}
            key={key}
            checked={subreason.details?.includes(detailText)}
            onChange={() => handleChange(detailText)}
            labelText={detailText}
          />
        );
      }
      return null;
    });
  };

  const continueButton = () => {
    return (
      <M.Button
        className='analytics_select_records_subreasons_details_continue_button'
        disabled={isEmpty(subreason.details)}
        aria-disabled={isEmpty(subreason.details)}
        size='sm'
        kind='primary'
        onClick={continueNext}
      >
        {i18n.getStr('buttons.continue')}
      </M.Button>
    );
  };

  return (
    <div>
      <ContentContainer>
        <div
          data-testid={
            DISPUTES_FLOW.disputeMotorVehicleRecordSubreasonDetailSelector
          }
          className='mb-4'
          role='note'
        >
          {i18n.renderHTML(
            'workflows.dispute.motorVehicleRecordSubreasonDetailSelector.instructionsText',
          )}
        </div>
        <form
          aria-label={i18n.getStr(
            'workflows.dispute.motorVehicleRecordSubreasonDetailSelector.formName',
          )}
        >
          <TileCheckboxGroup
            name={i18n.getStr(
              'workflows.dispute.motorVehicleRecordSubreasonSelector.reasons.violations.name',
            )}
          >
            {subreasonDetailCheckboxes()}
          </TileCheckboxGroup>
        </form>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_select_records_subreasons_details_back_button'
        rightButtonContent={continueButton()}
      />
    </div>
  );
};

export default MotorVehicleRecordSubreasonDetailSelector;
