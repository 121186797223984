import { QueryKey, useQuery } from '@tanstack/react-query';
import getFilePolicy from '../actions/actions';

function useFilestackPolicy(filestackKey: string) {
  const key: QueryKey = ['file_policy/get', filestackKey];

  return useQuery({
    queryKey: key,
    queryFn: () => getFilePolicy(filestackKey),
    ...{
      staleTime: 25 * 60 * 1000, // 25 mins
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: true,
    },
  });
}

export default useFilestackPolicy;
