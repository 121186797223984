import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { colors, M } from '@dashboard-experience/mastodon';
import { SSNTraceObject } from 'types/report';
import Panel from '../../../Panels';

interface SSNTraceProps {
  search: SSNTraceObject;
}

const ReportableExceptionNotification: React.FC<{ flag: string }> = ({
  flag,
}) => {
  return (
    <M.ActionableNotification
      inline='classic'
      hideActionButton
      hideCloseButton
      kind='warning'
      title={i18n.getStr(
        `components.Report.SSNTrace.reportable.exceptions.${flag}.title`,
      )}
      subtitle={i18n.getStr(
        `components.Report.SSNTrace.reportable.exceptions.${flag}.text`,
      )}
      style={{
        maxWidth: '100%',
        minWidth: '0px',
        borderColor: colors.archivePink,
        backgroundColor: colors.archivePinkBg,
      }}
    />
  );
};

const SSNTrace = ({ search }: SSNTraceProps) => {
  return (
    !!search &&
    search.status !== 'canceled' && (
      <Panel title={i18n.getStr('components.ssnTrace')} status={search.status}>
        {search.reportable && (
          <>
            {search.dobMismatch && (
              <ReportableExceptionNotification flag='dobMismatch' />
            )}
            {search.nameMismatch && (
              <ReportableExceptionNotification flag='nameMismatch' />
            )}
            {search.thinFile && (
              <ReportableExceptionNotification flag='thinFile' />
            )}
            {search.dataMismatch && (
              <ReportableExceptionNotification flag='dataMismatch' />
            )}
            {search.deathIndex && (
              <ReportableExceptionNotification flag='deathIndex' />
            )}
            {search.invalidIssuanceYear && (
              <ReportableExceptionNotification flag='invalidIssuanceYear' />
            )}
          </>
        )}
      </Panel>
    )
  );
};

SSNTrace.propTypes = {
  search: PropTypes.object,
};

SSNTrace.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(SSNTrace);
