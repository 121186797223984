import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';
import React from 'react';

const SubmitButton = ({
  disabled,
  isFinalStep,
  submitting,
  hideIcon,
  buttonClass,
}) => {
  const submitContent = isSubmitting =>
    isSubmitting ? (
      <span>
        <M.LoadingInline className='button-loading-icon' />
      </span>
    ) : (
      <T value='buttons.submit' />
    );

  return (
    <M.Button
      data-testid='submitButton'
      type='submit'
      size='lg'
      kind={`${buttonClass}${isFinalStep ? 'primary' : 'tertiary'}`}
      disabled={disabled || submitting}
    >
      {isFinalStep ? (
        submitContent(submitting)
      ) : (
        <span>
          <T value='buttons.continue' />
          {!hideIcon && <M.Icon icon='ChevronRight' />}
        </span>
      )}
    </M.Button>
  );
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  isFinalStep: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  hideIcon: PropTypes.bool,
  buttonClass: PropTypes.string,
};

SubmitButton.defaultProps = {
  hideIcon: false,
  buttonClass: '',
};

export default SubmitButton;
