import PropTypes from 'prop-types';
import React from 'react';

import CandidateStoryDocsForm from './CandidateStoryDocsForm';
import CandidateStoryHeader from './CandidateStoryHeader';
import CandidateStoryItemTitle from './CandidateStoryItemTitle';
import CaseInformationTextForm from './CaseInformationTextForm';
import { getChargeTitle } from '../lib/screenings';
import { CANS_STEP_PROVIDE } from '../../../../constants';
import { settingIsEnabled } from '../../../../lib/helpers';

const CaseInformationForm = ({
  account,
  candidateStories,
  currentCaseId,
  generalInformation,
  order,
  record,
  remove,
  selectedItems,
}) => {
  const setting = account.candidateStoriesRecordsInfo;
  const docsEnabled = settingIsEnabled(setting, {
    nullish: true,
    keys: ['docs'],
  });
  const textEnabled = settingIsEnabled(setting, {
    nullish: true,
    keys: ['text'],
  });

  if (!candidateStories[record.id]) {
    candidateStories[record.id] = { documents: [] };
  }
  const chargeName = getChargeTitle(record);

  const removeDocuments = i => {
    remove(i, `candidateStories.${record.id}.documents`);
  };

  const step = generalInformation ? order + 1 : order;

  return (
    currentCaseId === record.id && (
      <div>
        <CandidateStoryHeader
          step={CANS_STEP_PROVIDE}
          provide_step={step}
          provide_total={selectedItems}
        />
        <div className='form-question-wrapper'>
          <span className='form-question'>
            <CandidateStoryItemTitle record={record} />
          </span>
        </div>
        <div>
          <CaseInformationTextForm
            charge={chargeName}
            company={account.name}
            disabled={!textEnabled}
            field={`candidateStories.content.${record.id}`}
          />
          <CandidateStoryDocsForm
            documents={candidateStories[record.id].documents}
            disabled={!docsEnabled}
            field={`candidateStories.${record.id}.documents`}
            remove={removeDocuments}
          />
        </div>
      </div>
    )
  );
};

CaseInformationForm.propTypes = {
  account: PropTypes.shape({
    candidateStoriesRecordsInfo: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({ docs: PropTypes.bool, text: PropTypes.bool }),
    ]),
    name: PropTypes.string,
  }),
  candidateStories: PropTypes.object.isRequired,
  currentCaseId: PropTypes.string.isRequired,
  generalInformation: PropTypes.bool.isRequired,
  order: PropTypes.number.isRequired,
  record: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  selectedItems: PropTypes.number.isRequired,
};

CaseInformationForm.defaultProps = {
  account: {},
};

export default CaseInformationForm;
