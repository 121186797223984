import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import IconPanel from '../../../IconPanel';
import clearIcon from '../../../../images/icons/Clear.svg';

const ClearNotice = () => (
  <IconPanel
    translationKey='components.Report.ClearNotice'
    icon={
      <img
        src={clearIcon}
        alt={i18n.getStr('components.Report.ClearNotice.reportClear')}
      />
    }
    detailsCentered
  />
);

export default ClearNotice;
