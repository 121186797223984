import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import { StyleableContainer, defaultStyle } from './StyleableContainer';
import { Type } from './Type';
import {
  Heading,
  Alignment as HeadingAlignment,
  defaultStyle as HeadingStyle,
} from './Heading';
import { Icon } from './Icon';
import { Paragraph } from './Paragraph';
import { SecureDisclosure } from './SecureDisclosure';
import { ReduxFileInput } from './ReduxFileInput';
import FooterButtons from './FooterButtons';
import Typography2024Hack from './Typography2024Hack';
import { Page } from './Page';

interface SsnUploadProps {
  greeting: React.ReactNode;
  setPage: (pageToSet: Page) => void;
}

export const SsnUpload: React.FC<SsnUploadProps> = ({ greeting, setPage }) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
        padding: '24px 16px 56px 16px',
        border: 'none',
      }}
    >
      {greeting}
      <StyleableContainer
        type={Type.Info}
        styledTopBorder
        style={{
          ...defaultStyle,
          gap: '16px',
        }}
      >
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.SsnUpload.heading'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%', margin: '8px 0px' }} />
        <Paragraph
          textKey='components.InstantExceptions.SsnUpload.detail'
          align={HeadingAlignment.Left}
        />
        <Field name='ssnDocument' component={ReduxFileInput} type='hidden' />
        <Heading
          textKey='components.InstantExceptions.SsnUpload.requirementsHeader'
          align={HeadingAlignment.Left}
          style={{ ...HeadingStyle, ...Typography2024Hack.label }}
        />
        <M.UnorderedList>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.SsnUpload.requirements1'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.SsnUpload.requirements2'
            />
          </M.ListItem>
        </M.UnorderedList>
        <M.Divider style={{ width: '100%', margin: '8px 0px 0px 0px' }} />
        <SecureDisclosure />
      </StyleableContainer>
      <FooterButtons>
        <M.Button
          onClick={() => setPage(Page.UploadDocumentLater)}
          kind='secondary'
        >
          {i18n.getStr('buttons.addLater')}
        </M.Button>
        <M.Button type='submit'>{i18n.getStr('buttons.submit')}</M.Button>
      </FooterButtons>
    </M.Container>
  );
};
