import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import React from 'react';
import styled from 'styled-components';
import { appBreakpoints } from '../../reducers';

type DisclosureInfoPanelProps = {
  textI18nKey: string;
};

const StyledMastodonContainer = styled(M.Container)`
  padding: 1rem !important;
`;

const StyledIcon = styled(M.Icon)`
  @media (min-width: ${appBreakpoints.small}px) {
    height: 32px !important;
    width: 32px !important;
  }
`;

const StyledContainerDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIconSpan = styled.span`
  padding-right: 12px;
`;

const StyledTextSpan = styled.span`
  padding-left: 12px;
`;

const DisclosureInfoPanel: React.FC<DisclosureInfoPanelProps> = ({
  textI18nKey,
}) => {
  return (
    <StyledMastodonContainer type='info'>
      <StyledContainerDiv>
        <StyledIconSpan>
          <StyledIcon icon='Idea' />
        </StyledIconSpan>

        <StyledTextSpan>
          <T value={textI18nKey} dangerousHTML />
        </StyledTextSpan>
      </StyledContainerDiv>
    </StyledMastodonContainer>
  );
};

export default DisclosureInfoPanel;
