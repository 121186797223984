import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { I18nRenderedTranslation } from 'types/i18n';
import FaqQuestions from './FaqQuestionList';
import useFaqTranslation from './hooks/useFaqTranslation';

export interface FaqProps {
  translationKey: string;
  className?: string;
}

const Faq: React.FC<I18nRenderedTranslation<FaqProps>> = ({
  t,
  translationKey,
  className = '',
}) => {
  const {
    subtitle = '',
    title = '',
    questions = [],
  } = useFaqTranslation(translationKey, t);
  if (questions.length === 0) return null;
  return (
    <div className={`${className} faq`}>
      <h3 id='faqTitle'>{title || t('components.Faq.title')}</h3>
      {subtitle ? (
        <p className='faq-subtitle' id='faqMessage'>
          {subtitle}
        </p>
      ) : null}
      <FaqQuestions questions={questions} />
    </div>
  );
};

export default i18n.renderTranslation()(Faq);
