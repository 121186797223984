import PropTypes from 'prop-types';
import React from 'react';
import Subscribed from './Subscribed';
import Unsubscribed from './Unsubscribed';

const Unsubscribe = ({ onSubmit, verification: { unsubscribedAt } }) => {
  if (!unsubscribedAt) {
    return <Subscribed onSubmit={onSubmit} />;
  }

  return <Unsubscribed unsubscribedAt={unsubscribedAt} />;
};

Unsubscribe.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  verification: PropTypes.object.isRequired,
};

export default Unsubscribe;
