import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Status from '../../Status';
import PicVSRegulatoryRequirements from './Components/PicVSRegulatoryRequirements';
import { trackAnalyticsEvent } from '../../../../../actions';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  AMPLITUDE_NAME_MAP,
} from '../../../../../lib/analytics';

const SUBSTATUSES = {
  NO_RECORD_VERIFIED: {
    icon: {
      name: 'CheckmarkFilled',
      className: 'status-clear',
    },
    title: 'components.intlCriminal.noRecordVerifiedTitle',
    subtitle:
      'components.intlCanadaBcEnhancedCriminal.noRecordVerifiedSubtitle',
  },
  RECORD_VERIFIED: {
    icon: {
      name: 'CheckmarkFilled',
      className: 'status-clear',
    },
    title: 'components.intlCriminal.recordVerifiedTitle',
    subtitle: 'components.intlCanadaBcEnhancedCriminal.recordVerifiedSubtitle',
  },
  NOT_VERIFIED: {
    icon: {
      name: 'CheckmarkFilled',
      className: 'status-consider',
    },
    title: 'components.intlCriminal.notVerifiedTitle',
    subtitle: 'components.intlCanadaBcEnhancedCriminal.notVerifiedSubtitle',
  },
};

const IntlCanadaBcEnhancedCriminal = ({ record }) => {
  const dispatch = useDispatch();
  if (!record) return null;

  const subStatusInfo = SUBSTATUSES[record.resultSubstatus];

  return (
    <>
      <div id='canada-bc-criminal-report'>
        <div className='d-flex mb-3'>
          <div>
            <strong>
              <T value='components.intlCanadaBcEnhancedCriminal.criminalReportTitle' />{' '}
            </strong>
            {record.turnaroundTime && (
              <span>
                {i18n.getLocalizedShortDateTimeFormat(record.completedAt)} (
                {i18n.getLocalizedDuration(record.turnaroundTime)})
              </span>
            )}
            {record.resultSubstatus && (
              <div id='sub-status-section'>
                <div className='mt-2 mb-2'>
                  <M.Icon
                    icon={subStatusInfo.icon.name}
                    className={subStatusInfo.icon.className}
                  />
                  <strong className='title icon-text'>
                    <T id='title' value={subStatusInfo.title} />
                  </strong>
                </div>
                <div>
                  <T id='subtitle' value={subStatusInfo.subtitle} />
                </div>
              </div>
            )}
          </div>
          <div className='ml-auto'>
            <Status
              status={
                record.internationalCanadaBcEnhancedCriminalStandaloneStatus
              }
            />
          </div>
        </div>
        {record.pdfUrl && (
          <div className='mb-4'>
            <a
              href={record.pdfUrl}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => {
                dispatch(
                  trackAnalyticsEvent(ANALYTICS_EVENTS.SCREENING_DOWNLOADED, {
                    [ANALYTICS_PROPERTIES.SCREENING_NAME]:
                      AMPLITUDE_NAME_MAP.canada_bc_enhanced_criminal,
                  }),
                );
              }}
            >
              <T value='components.intlCanadaBcEnhancedCriminal.viewBcCriminalReport' />
            </a>
          </div>
        )}
      </div>
      {record.canadaPoliceInformationCheckExceptionStatus && (
        <div id='canada-bc-pic-vs-report'>
          <div className='d-flex mb-3'>
            <div>
              <strong>
                <T value='components.intlCanadaBcEnhancedCriminal.picVsReportTitle' />{' '}
              </strong>
              {record.turnaroundTime && (
                <span>
                  {i18n.getLocalizedShortDateTimeFormat(record.completedAt)} (
                  {i18n.getLocalizedDuration(record.turnaroundTime)})
                </span>
              )}
            </div>
            <div className='ml-auto'>
              <Status
                status={record.canadaPoliceInformationCheckExceptionStatus}
              />
            </div>
          </div>
          {record.canadaPoliceInformationCheckExceptionStatus === 'pending' && (
            <div id='canada-bc-pic-vs-pending-status-message'>
              <T value='components.intlCanadaBcEnhancedCriminal.viewPicVsPendingReport' />
            </div>
          )}
          {record.canadaPoliceInformationCheckDocumentUri && (
            <div>
              <a
                href={record.canadaPoliceInformationCheckDocumentUri}
                target='_blank'
                rel='noopener noreferrer'
              >
                <T value='components.intlCanadaBcEnhancedCriminal.viewPicVsCandidateReport' />
              </a>
            </div>
          )}
        </div>
      )}
      <PicVSRegulatoryRequirements />
    </>
  );
};

IntlCanadaBcEnhancedCriminal.propTypes = {
  record: PropTypes.object.isRequired,
};

export default IntlCanadaBcEnhancedCriminal;
export {
  IntlCanadaBcEnhancedCriminal as IntlCanadaBcEnhancedCrim,
  SUBSTATUSES,
};
