/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// REFACTOR FOR LINT LATER
import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { i18n } from '@international/mastodon-i18n';
import DisputeMultiSelect from '../../../fields/DisputeMultiSelect';

const FieldsForm = ({ section, options, fields, toggleField, trackAction }) => {
  const fieldIsSelected = field => {
    return fields.indexOf(field) !== -1;
  };

  return (
    <div data-testid='fields-form'>
      <div className='form-group'>
        <label>
          <span className='text-muted'>{section} &#62; </span>
          <span className='form-question'>
            <T value='components.fieldsForm.selectFields' />
          </span>
        </label>
        <hr />
      </div>
      <div className='form-group'>
        {options.map(field => (
          <DisputeMultiSelect
            id={field}
            name='field'
            key={field}
            displayValue={i18n.getConstStr('disputes.fieldsForm', field)}
            selectValue={field}
            toggleValue={toggleField}
            trackAction={trackAction}
            selected={fieldIsSelected(field)}
          />
        ))}
      </div>
    </div>
  );
};

FieldsForm.propTypes = {
  section: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  toggleField: PropTypes.func.isRequired,
  trackAction: PropTypes.func.isRequired,
};

export default FieldsForm;
