import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { logoutCandidate } from 'actions';
import portalStore from 'providers/portalStore';
import { CHECKR_API_BASE } from '../constants';

export class CheckrMonolithApiClient {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({ baseURL: CHECKR_API_BASE });
    this.instance.interceptors.response.use(
      res => res,
      this.responseErrorHandler,
    );
  }

  getClient() {
    return this.instance;
  }

  responseErrorHandler(error: AxiosError) {
    if (error.response?.status === 401) {
      portalStore.dispatch(logoutCandidate());
    }
    return Promise.reject(error);
  }

  get(url: string, params: AxiosRequestConfig = {}) {
    return this.instance.get(url, params);
  }
}

export const Client = new CheckrMonolithApiClient();
