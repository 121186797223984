import React from 'react';
import { i18n, moment } from '@international/mastodon-i18n';
import TileCheckboxGroup from 'components/TileCheckboxGroup';
import { M } from '@dashboard-experience/mastodon';
import { titleCase, isEmpty } from 'lib/helpers';
import { LicenseReport } from 'types/motorVehicleReport';
import {
  DisputeReasonSummaryMap,
  ReasonCode,
  Subreason,
} from 'components/Workflow/Disputes/Types';
import ContentContainer from 'components/Workflow/ContentContainer';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import {
  DestroyDraft,
  InitDraft,
  UpdateDraft,
} from 'components/Workflow/hooks';
import TileCheckbox from 'components/TileCheckbox';
import { shortDatePattern } from 'lib/dateFormat';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const licenseInformationSubreasonsPath =
  'workflows.dispute.licenseInformationSubreasonSelector.reasons.licenseInformation.subreasons';

const LicenseInformationSubreasonSelector = ({
  back,
  continueNext,
  licenseReport,
  destroyDraft,
  draftDisputeReasonSummaries,
  initDraft,
  updateDraft,
  transitionToScreen,
  isReportEligibleForUpdateDL,
  toggle,
  toggleUpdateDLModal,
}: {
  back: () => void;
  continueNext: () => void;
  licenseReport: LicenseReport;
  destroyDraft: DestroyDraft;
  draftDisputeReasonSummaries: DisputeReasonSummaryMap;
  initDraft: InitDraft;
  updateDraft: UpdateDraft;
  transitionToScreen: (screen: string) => void;
  isReportEligibleForUpdateDL: boolean;
  toggle: () => void;
  toggleUpdateDLModal: () => void;
}) => {
  const LICENSE_INFORMATION_TILE_CHECKBOX_LABELS: {
    translationKey: string;
    reportKey: keyof LicenseReport;
  }[] = [
    {
      translationKey: 'licenseState',
      reportKey: 'state',
    },
    {
      translationKey: 'licenseStatus',
      reportKey: 'status',
    },
    { translationKey: 'licenseType', reportKey: 'type' },
    { translationKey: 'licenseClass', reportKey: 'class' },
    {
      translationKey: 'licenseExpirationDate',
      reportKey: 'expirationDate',
    },
    {
      translationKey: 'licenseIssuedDate',
      reportKey: 'issuedDate',
    },
  ];

  const removeSubreason = (reason: ReasonCode, subreason: string) => {
    const updatedSubreasons = draftDisputeReasonSummaries[
      reason
    ].subreasons.filter(({ name }) => name !== subreason);
    if (updatedSubreasons.length === 0) {
      destroyDraft(reason);
    } else {
      updateDraft(reason, { subreasons: updatedSubreasons });
    }
  };

  const addSubreason = (reason: ReasonCode, subreason: string) => {
    const updatedSubreasons = [
      ...draftDisputeReasonSummaries[reason].subreasons,
      { name: subreason },
    ];
    updateDraft(reason, { subreasons: updatedSubreasons });
  };

  const handleOnChange = (reason: ReasonCode, subreason: string) => {
    if (subreasonSelected(subreason)) {
      removeSubreason(reason, subreason);
    } else if (draftDisputeReasonSummaries[reason]) {
      addSubreason(reason, subreason);
    } else {
      initDraft(
        reason,
        i18n.getStr(
          'workflows.dispute.licenseInformationSubreasonSelector.situation',
        ),
        reason,
        [{ name: subreason }],
        null,
      );
    }
  };

  const subreasonSelected = (subreason: string) => {
    const { licenseInformation, restrictions, endorsements } =
      draftDisputeReasonSummaries;
    if (!licenseInformation && !restrictions && !endorsements) {
      return false;
    }

    const currentSubreasons = Object.values(draftDisputeReasonSummaries).reduce(
      (result: Subreason[], value: any) => {
        result = [...result, ...value.subreasons];
        return result;
      },
      [],
    );

    return currentSubreasons.some(s => s.name === subreason);
  };

  const formatSubreasonValue = (reportKey: keyof LicenseReport) => {
    if (!licenseReport[reportKey]) {
      return '';
    }
    if (['expirationDate', 'issuedDate'].includes(reportKey)) {
      return moment(licenseReport[reportKey]).format(shortDatePattern());
    }
    if (reportKey === 'state') {
      return licenseReport[reportKey]?.toUpperCase();
    }
    return titleCase(licenseReport[reportKey]);
  };

  const mapLicenseInformationLabels = () => {
    const labels = LICENSE_INFORMATION_TILE_CHECKBOX_LABELS.map(
      ({ translationKey, reportKey }) => {
        const value = formatSubreasonValue(reportKey);
        if (!value) {
          return null;
        }
        const translation = `${licenseInformationSubreasonsPath}.${translationKey}`;
        const subreason = i18n.getStr(translation, value);
        return subreason;
      },
    );
    return labels.filter(label => label);
  };

  const mapEndorsementLabels = () => {
    return licenseReport.endorsements.map(endorsement => {
      return endorsement.dmvDescription;
    });
  };

  const renderTileCheckboxGroup = (reason: ReasonCode, labels: string[]) => {
    if (!isEmpty(labels)) {
      return (
        <TileCheckboxGroup
          showLegend
          name={i18n.getStr(
            `workflows.dispute.licenseInformationSubreasonSelector.reasons.${reason}.name`,
          )}
        >
          {labels.map(label => {
            return (
              <TileCheckbox
                id={label}
                key={label}
                checked={subreasonSelected(label)}
                onChange={() => handleOnChange(reason, label)}
                labelText={label}
              />
            );
          })}
        </TileCheckboxGroup>
      );
    }

    return <></>;
  };

  const transitionToUpdateDL = () => {
    if (isReportEligibleForUpdateDL) {
      toggle();
      toggleUpdateDLModal();
    } else {
      transitionToScreen('newDLInformation');
    }
  };

  const continueNextVariative = () => {
    const licenseStateSubreasonLabel = i18n.getStr(
      `${licenseInformationSubreasonsPath}.licenseState`,
      formatSubreasonValue('state'),
    );

    if (
      draftDisputeReasonSummaries?.licenseInformation?.subreasons.some(
        subreason => subreason.name === licenseStateSubreasonLabel,
      )
    ) {
      transitionToUpdateDL();
      return;
    }
    continueNext();
  };

  const continueButton = () => {
    return (
      <M.Button
        data-testid={DISPUTES_FLOW.disputeFooterContinueButton}
        className='analytics_select_errors_continue_button'
        disabled={isEmpty(draftDisputeReasonSummaries)}
        aria-disabled={isEmpty(draftDisputeReasonSummaries)}
        size='sm'
        kind='primary'
        onClick={continueNextVariative}
      >
        {i18n.getStr('buttons.continue')}
      </M.Button>
    );
  };

  return (
    <div>
      <ContentContainer>
        <M.Form
          aria-label={i18n.getStr(
            'workflows.dispute.licenseInformationSubreasonSelector.instructionsText',
          )}
        >
          <p className='mb-4'>
            {i18n.getStr(
              'workflows.dispute.licenseInformationSubreasonSelector.instructionsText',
            )}
          </p>
          {renderTileCheckboxGroup(
            'licenseInformation',
            mapLicenseInformationLabels(),
          )}
          {renderTileCheckboxGroup('restrictions', licenseReport.restrictions)}
          {renderTileCheckboxGroup('endorsements', mapEndorsementLabels())}
        </M.Form>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_select_errors_back_button'
        rightButtonContent={continueButton()}
      />
    </div>
  );
};

export default LicenseInformationSubreasonSelector;
