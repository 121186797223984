import { DisputeReasonSummary } from 'components/Workflow/Disputes/Types';
import { UpdateDraft } from 'components/Workflow/hooks';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import exceptionNoticeIcon from 'images/icons/ExceptionNotice.svg';
import greenCheckmarkIcon from 'images/icons/GreenCheckmark.svg';
import React from 'react';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const ProvideInformationAccordionSection = ({
  updateDraft,
  disputeReasonSummary,
}: {
  disputeReasonSummary: DisputeReasonSummary;
  updateDraft: UpdateDraft;
}) => {
  const { reasonCode, subreasons } = disputeReasonSummary;

  const handleChange = (event: { target: HTMLInputElement }) => {
    const explanation = event.target.value;
    updateDraft(reasonCode, {
      explanation,
    });
  };

  const taskStatusIcon = () => {
    if (!disputeReasonSummary.explanation) {
      return (
        <div className='task-pending'>
          <img src={exceptionNoticeIcon} alt='exception' />
          {i18n.getStr('workflows.dispute.provideInformationForm.infoNeeded')}
        </div>
      );
    }
    return (
      <div className='task-complete'>
        <img src={greenCheckmarkIcon} alt='complete' />
        {i18n.getStr('workflows.dispute.provideInformationForm.taskComplete')}
      </div>
    );
  };

  return (
    <M.Accordion data-testid={DISPUTES_FLOW.provideInformationAccordionSection}>
      <M.AccordionItem
        title={
          <div className='accordion-title'>
            <div>{i18n.getStr(`workflows.dispute.reasons.${reasonCode}`)}</div>
            {taskStatusIcon()}
          </div>
        }
      >
        <div id='provide-information-subreasons'>
          {subreasons.map(({ name }) => {
            return (
              <h3
                key={name}
                className='provide-information-subreason-heading'
                id='provide-information-subreason-heading'
              >
                {name}
              </h3>
            );
          })}
        </div>
        <M.TextArea
          data-testid={DISPUTES_FLOW.provideInformationExplanation}
          name='licenseInformationInaccurate.licenseInformation.explanation'
          placeholder={i18n.getStr(
            'workflows.dispute.provideInformationForm.inputPlaceholder',
          )}
          value={disputeReasonSummary.explanation}
          onChange={handleChange}
          light
        />
      </M.AccordionItem>
    </M.Accordion>
  );
};

export default ProvideInformationAccordionSection;
