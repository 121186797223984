import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import IconPanel from '../IconPanel';
import FaqButton from '../fields/FaqButton';

import completeIcon from '../../images/icons/Suspended.svg';

const Expired = (): JSX.Element => (
  <IconPanel
    translationKey='components.Verification.Expired'
    icon={<img src={completeIcon} alt={i18n.getStr('components.expired')} />}
  >
    <div className='text-center'>
      <FaqButton />
    </div>
  </IconPanel>
);

export default Expired;
