import { colors } from '@dashboard-experience/mastodon';

export enum Type {
  Default = 1,
  Info,
  Success,
  Fail,
  Upload,
}

export const TypeParams = {
  [Type.Default]: {
    color: colors.uiGrey300,
    icon: null,
    height: 36,
    width: 36,
  },
  [Type.Info]: {
    color: colors.uiOrange400,
    icon: 'WarningFilled',
    height: 36,
    width: 36,
  },
  [Type.Success]: {
    color: colors.uiAqua500,
    icon: 'CheckmarkFilled',
    height: 36,
    width: 36,
  },
  [Type.Fail]: {
    color: colors.uiOrange500,
    icon: 'ErrorFilled',
    height: 36,
    width: 36,
  },
  [Type.Upload]: {
    color: colors.uiTextPrimaryLight,
    icon: 'Upload',
    height: 16,
    width: 16,
  },
};
