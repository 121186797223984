import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Panel from '../../../../Panels';
import Record from '../../Record';

const IntlCanadaBcEnhancedCriminalSearch = ({ search }) => {
  const isVisible = () =>
    !!search &&
    search.internationalCanadaBcEnhancedCriminalStandaloneStatus !== 'canceled';

  if (isVisible())
    return (
      <Panel
        data-testid='intl-canada-bc-enhanced-criminal-search'
        title={i18n.getStr(
          'components.internationalCanadaBcEnhancedCriminalSearch',
        )}
        status={search.status}
      >
        <Record record={search} type='internationalCanadaBcEnhancedCriminal' />
      </Panel>
    );

  // Return an empty fragment when there is not a visible element.
  return <></>;
};

IntlCanadaBcEnhancedCriminalSearch.propTypes = {
  search: PropTypes.object,
};

IntlCanadaBcEnhancedCriminalSearch.defaultProps = {
  search: null,
};

export default IntlCanadaBcEnhancedCriminalSearch;
