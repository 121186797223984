import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../../Panels';
import Record from '../../Record';
import { formatSearchTitle } from '../../lib/screenings';
import { CANCELED_STATUS } from '../../lib/constants';

const IntlRightToWork = ({ search }: any) => {
  const panelTitle = search
    ? `${i18n.getStr(
        'components.internationalRightToWork',
      )} - ${formatSearchTitle(search)}`
    : i18n.getStr('components.internationalRightToWork');

  const isVisible =
    search?.status && ![CANCELED_STATUS].includes(search?.status);

  return (
    <div>
      {isVisible && (
        <Panel
          data-testid='intl-right-to-work'
          title={panelTitle}
          status={search?.status}
        >
          <Record record={search} type='internationalRightToWork' />
        </Panel>
      )}
    </div>
  );
};

IntlRightToWork.propTypes = {
  search: PropTypes.object,
};

IntlRightToWork.defaultProps = {
  search: null,
};

export default IntlRightToWork;
