import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { KeyValuePair } from 'types/mastodon';

import Icon from './IconErrorBoundary';
import iconTextStyle from './IconTextList.module.scss';

export interface IconTextListProps {
  items: KeyValuePair<React.ReactNode | string>[];
  title?: string;
  className?: string;
}

const IconTextList: React.FC<IconTextListProps> = ({
  items,
  className = '',
  title = '',
}) => {
  const setIcon = (key: string | React.ReactNode) => {
    return typeof key === 'string' ? (
      <Icon iconName={key || ''} role='img' />
    ) : (
      key
    );
  };

  return (
    <div
      className={`icon-text-list ${className} ${iconTextStyle.iconTextList}`}
      data-testid='icon-text-list'
    >
      {title && <h3>{title}</h3>}
      {items.length ? (
        <M.KeyValueList
          items={items.map(({ itemKey, itemValue }) => ({
            itemKey: setIcon(itemKey),
            itemValue,
          }))}
        />
      ) : null}
    </div>
  );
};

export default IconTextList;
