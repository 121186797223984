import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { LicenseReport, MotorVehicleRecord } from 'types/motorVehicleReport';
import ContentContainer from 'components/Workflow/ContentContainer';
import { InitDraft } from 'components/Workflow/hooks';
import { ReasonCode } from 'components/Workflow/Disputes/Types';
import { formatDateIfValid, isEmpty } from 'lib/helpers';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import { REASON_TYPE_TO_SELECTORS_DATA_TESTID } from '../constans';

const MotorVehicleRecordSubreasonSelector = ({
  back,
  continueNext,
  licenseReport,
  initDraft,
}: {
  back: () => void;
  continueNext: (motorVehicleRecord: MotorVehicleRecord) => void;
  licenseReport: LicenseReport;
  initDraft: InitDraft;
}) => {
  const handleClick = (
    motorVehicleRecord: MotorVehicleRecord,
    recordType: ReasonCode,
  ) => {
    const situation = i18n.getStr(
      'workflows.dispute.motorVehicleRecordSubreasonSelector.situation',
    );
    initDraft(
      'motorVehicleRecord',
      situation,
      recordType,
      [{ name: motorVehicleRecord.description }],
      motorVehicleRecord.id,
    );
    continueNext(motorVehicleRecord);
  };

  const renderRecordSection = (
    records: MotorVehicleRecord[],
    recordType: ReasonCode,
    dateType: string,
  ) => {
    if (!isEmpty(records)) {
      return (
        <>
          <h3 className='mt-3'>
            {i18n.getStr(`workflows.dispute.reasons.${recordType}`)}
          </h3>
          <M.TileOptionList
            ariaLabel={i18n.getStr(`workflows.dispute.reasons.${recordType}`)}
            data-testid={
              REASON_TYPE_TO_SELECTORS_DATA_TESTID[recordType] ||
              'record-selector'
            }
          >
            {records.map(record => {
              // @ts-ignore
              const formattedDate = formatDateIfValid(record[dateType]);
              return (
                <M.TileOption
                  title={record.description}
                  rightInfo={formattedDate}
                  optionSelected={() => handleClick(record, recordType)}
                  key={`${record.description} ${formattedDate}`}
                />
              );
            })}
          </M.TileOptionList>
        </>
      );
    }
    return <></>;
  };

  return (
    <div>
      <ContentContainer>
        <div
          data-testid={DISPUTES_FLOW.disputeMotorVehicleRecordSubreasonSelector}
          className='mb-4'
          role='note'
        >
          {i18n.renderHTML(
            'workflows.dispute.motorVehicleRecordSubreasonSelector.instructionsText',
          )}
        </div>
        <form
          data-testid={
            DISPUTES_FLOW.disputeMotorVehicleRecordSubreasonSelectorForm
          }
          aria-label={i18n.getStr(
            'workflows.dispute.motorVehicleRecordSubreasonSelector.formName',
          )}
        >
          {renderRecordSection(
            licenseReport.violations,
            'violations',
            'issuedDate',
          )}
          {renderRecordSection(
            licenseReport.suspensions,
            'suspensions',
            'startDate',
          )}
          {renderRecordSection(
            licenseReport.accidents,
            'accidents',
            'accidentDate',
          )}
        </form>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_select_records_back_button'
      />
    </div>
  );
};

export default MotorVehicleRecordSubreasonSelector;
