import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import '../../scss/SimpleDocumentList.scss';

type SimpleDocumentListProps = {
  documents: Array<any>;
  onRemove?: (index: number) => void;
};

const SimpleDocumentList: React.FC<SimpleDocumentListProps> = ({
  documents,
  onRemove = () => {},
}) => (
  <div>
    {documents.map((doc, i) => (
      <div key={`document-${doc.handle}`} className='d-flex align-items-center'>
        <div data-testid='success-icon' className='success-icon'>
          <M.Icon icon='CheckmarkFilled' />
        </div>
        <p className='no-link-file-name'>{doc.filename}</p>
        <div
          className='remove-doc'
          onClick={() => onRemove(i)}
          role='button'
          tabIndex={0}
        >
          <M.Icon icon='TrashCan' className='remove-icon' />
        </div>
      </div>
    ))}
  </div>
);

export default SimpleDocumentList;
