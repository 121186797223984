import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Field } from 'redux-form';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';
import { generateUSStatesOptions } from 'components/fields/helper';
import Select from '../../fields/mastodon/Select';
import { TextInput } from '../../fields/mastodon';
import * as V from '../../../lib/validations';

const SelectStyledWrapper = styled.div`
  width: 196px;
`;

const DriverLicenseInfoWrapper = styled(M.Grid)`
  flex: 1;
`;

export interface DriverLicenseInfoProps {
  fieldKey: string;
}

const SELECT_STATE_OPTION = { value: '', name: 'Select state' };

const DriverLicenseInfo = ({ fieldKey }: DriverLicenseInfoProps) => {
  const dlNumberKey = `${fieldKey}.dl_number`;
  const dlStateKey = `${fieldKey}.dl_state`;
  const stateOptions = [SELECT_STATE_OPTION, ...generateUSStatesOptions()];

  return (
    <DriverLicenseInfoWrapper>
      <M.Container.Row>
        <SelectStyledWrapper>
          <Field
            name={dlStateKey}
            label={i18n.getStr(
              'components.Report.Verification.MatrixVerification.DrivingExperience.form.dlState.label',
            )}
            component={Select}
            context={dlStateKey}
            options={stateOptions}
            dataTestId='verification-dl-state'
            validate={[V.required]}
          />
        </SelectStyledWrapper>
      </M.Container.Row>
      <M.Container.Row>
        <Field
          type='text'
          id='verification-dl-number'
          testId='verification-dl-number'
          name={dlNumberKey}
          component={TextInput}
          context={dlNumberKey}
          placeholder='Number'
          labelControl={i18n.getStr(
            'components.Report.Verification.MatrixVerification.DrivingExperience.form.dlNumber.label',
          )}
          validate={[V.required]}
        />
      </M.Container.Row>
    </DriverLicenseInfoWrapper>
  );
};

export default DriverLicenseInfo;
