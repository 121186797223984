import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import completeIcon from 'images/icons/Complete.svg';
import { M } from '@dashboard-experience/mastodon';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import styles from './SubmissionConfirmation.module.css';

const SubmissionConfirmation = ({
  closed,
  setHasSubmitted,
}: {
  closed: () => void;
  setHasSubmitted: (param: boolean) => void;
}) => {
  const doneButton = () => {
    return (
      <M.Button size='sm' kind='primary' onClick={handleDone}>
        {i18n.getStr('buttons.done')}
      </M.Button>
    );
  };

  const handleDone = () => {
    closed();
    setHasSubmitted(true);
  };

  return (
    <>
      <ContentContainer>
        <div className={styles.styled}>
          <div
            className={styles.centerHeader}
            data-testid={DISPUTES_FLOW.disputeConfirmationHeader}
          >
            <h2>
              {i18n.getStr('workflows.dispute.submissionConfirmation.heading')}
            </h2>
            <img src={completeIcon} alt='checkmark' />
          </div>
          <div
            role='note'
            aria-label={i18n.getStr(
              'workflows.dispute.submissionConfirmation.message',
            )}
          >
            {i18n.renderHTML(
              'workflows.dispute.submissionConfirmation.message',
            )}
          </div>
        </div>
      </ContentContainer>
      <NavigationFooter rightButtonContent={doneButton()} />
    </>
  );
};

export default SubmissionConfirmation;
