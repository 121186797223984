import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../../Panels';
import { trackAnalyticsEvent } from '../../../../../actions';
import {
  AMPLITUDE_NAME_MAP,
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../../../lib/analytics';
import withNonCanceledSearch from '../WithNonCanceledSearch';

const IntlGlobalWatchlistSearch = ({ search }) => {
  const dispatch = useDispatch();
  return (
    <Panel
      data-testid='international-global-watchlist-search'
      title={i18n.getStr('components.globalWatchlistSearch.title')}
      status={search.status}
    >
      {search.pdfUrl && (
        <a
          href={search.pdfUrl}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            dispatch(
              trackAnalyticsEvent(ANALYTICS_EVENTS.SCREENING_DOWNLOADED, {
                [ANALYTICS_PROPERTIES.SCREENING_NAME]:
                  AMPLITUDE_NAME_MAP.global,
              }),
            );
          }}
        >
          {i18n.getStr('components.globalWatchlistSearch.viewReport')}
        </a>
      )}
    </Panel>
  );
};

IntlGlobalWatchlistSearch.propTypes = {
  search: PropTypes.shape({
    status: PropTypes.string,
    pdfUrl: PropTypes.string,
  }),
};

IntlGlobalWatchlistSearch.defaultProps = {
  search: null,
};

export default withNonCanceledSearch(IntlGlobalWatchlistSearch);
