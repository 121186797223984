import React from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { isInternational as getIsInternational } from 'lib/helpers';
import useToken from 'utils/useToken';
import { useSelector } from 'react-redux';
import { PATHS } from '../../constants';

const Home: React.FC = () => {
  const { hash } = useLocation();
  const { isLoggedIn, isInternational } = useSelector((state: any) => ({
    isLoggedIn: state.portal.loggedIn || state.candidateLogin.loggedIn,
    isInternational: getIsInternational() || state.international?.international,
  }));
  const token = useToken();
  if ((!token && !isLoggedIn) || ['#login', '#login_failed'].includes(hash)) {
    return (
      <Navigate
        to={isInternational ? `${PATHS.INTERNATIONAL}/login` : '/login'}
      />
    );
  }
  return <Outlet />;
};

export default Home;
