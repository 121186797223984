import React from 'react';
import {
  connect,
  MapStateToPropsParam,
  useDispatch,
  useSelector,
} from 'react-redux';
import { colors, fonts } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Report } from 'types/report';
import { requestApiStatus } from '../../actions/apiStatusBanner';
import ApiStatusBanner, { Incident } from '../ApiStatusBanner';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import HeaderLogo from './HeaderLogo';

import '../../scss/CandidateStories.scss';

interface HeaderProps {
  isInternational: boolean;
  incidents?: any[];
  customIncidentContentKeys?: string[];
  candidateFirstName?: string;
}

const StyledAppHeader = styled.header`
  background-color: ${colors.navy03};
  position: relative;
  .header-container {
    width: 100%;
    max-width: 1272px;
    margin: 0 auto;
    padding: 0 15px;
    font-family: ${fonts.legacy};
    @media (min-width: 768px) {
      height: 90px;
    }
    .header-brand {
      a {
        color: ${colors.white} !important;
      }
    }
    a {
      &:hover {
        text-decoration: none;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Header: React.FC<HeaderProps> = ({
  customIncidentContentKeys = [],
  incidents = [],
  isInternational,
  candidateFirstName = '',
}: HeaderProps) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(requestApiStatus());
  }, [dispatch]);

  const { reportId } = useParams<{ reportId: string }>();
  const { firstName, createdAt } = useSelector((state: any) => {
    const laCountyReport = state.portal.reports.find(
      (report: Report) => report.id === reportId,
    );
    return {
      firstName: state.portal?.firstName,
      createdAt: laCountyReport?.createdAt,
    };
  });

  return (
    <StyledAppHeader>
      <div className='header-container'>
        <HeaderLogo isInternational={isInternational} />
        <HeaderMenu isInternational={isInternational} />
      </div>
      <ApiStatusBanner
        customIncidentContentKeys={customIncidentContentKeys}
        incidents={incidents}
        createdAt={createdAt}
        candidateFirstName={firstName}
      />
    </StyledAppHeader>
  );
};

interface HeaderStateProps {
  apiStatusRequest: any;
  incidents: any[];
}

interface HeaderInitialState {
  apiStatusBanner: any;
  portal: any;
}

const mapStateToProps: MapStateToPropsParam<
  HeaderStateProps,
  HeaderProps,
  HeaderInitialState
> = (state: { apiStatusBanner: any; portal: any }) => {
  const { apiStatusBanner } = state;
  const incidents = apiStatusBanner.incidents?.incidents || [];
  const candidatePortalIncidents = incidents.filter((incident: Incident) =>
    incident.components.some(
      component => component.name === 'Candidate Portal',
    ),
  );
  return {
    apiStatusRequest: apiStatusBanner.apiStatusRequest,
    incidents: candidatePortalIncidents,
    customIncidentContentKeys: apiStatusBanner.customIncidentContentKeys || [],
  };
};

export default connect<HeaderStateProps, null, HeaderProps, HeaderInitialState>(
  mapStateToProps,
)(i18n.renderTranslation()(Header));
