import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { KeyValuePair } from 'types/mastodon';
import { I18nRenderedTranslation } from 'types/i18n';
import DOMPurify from 'isomorphic-dompurify';
import IconTextList from '../IconTextList';

interface HelpfulToKnowProps {
  translationKey: string;
  noTitle?: boolean;
  className?: string;
}

const HelpfulToKnow: React.FC<I18nRenderedTranslation<HelpfulToKnowProps>> = ({
  translationKey,
  t,
  noTitle = false,
  className = '',
}) => {
  const [title, setTitle] = React.useState<string>('');
  const [items, setItems] = React.useState<KeyValuePair[]>([]);
  React.useEffect(() => {
    const baseTranslation = { title: '', items: [] };
    const translation = t(translationKey, { returnObjects: true });
    const { title: translatedTitle = '', items: translatedItems = [] } =
      Array.isArray(translation) ? baseTranslation : translation;
    if (!noTitle) {
      setTitle(translatedTitle || t('components.HelpfulToKnow.title'));
    }
    setItems(
      translatedItems.map(({ icon, text }: { icon: string; text: string }) => ({
        itemKey: icon?.replace(/{|}/g, ''),
        itemValue: (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
          />
        ),
      })),
    );
  }, [translationKey, noTitle, t]);

  if (items.length === 0) return null;

  return <IconTextList title={title} items={items} className={className} />;
};

export default i18n.renderTranslation()(
  HelpfulToKnow,
) as React.FC<HelpfulToKnowProps>;
