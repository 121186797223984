import React from 'react';
import ReactFilestack from 'filestack-react';
import { M } from '@dashboard-experience/mastodon';
import { File } from 'components/Workflow/Disputes/Types';
import useFilestackPolicy from './hooks/hooks';

type GenericObject = { [key: string]: any };

type SecureFilestackProps = {
  actionOptions: GenericObject;
  apikey: string;
  componentDisplayMode?: GenericObject;
  customRender: (arg0: { onPick: () => void }) => JSX.Element;
  onSuccess: (res: { filesUploaded: File[] }) => void;
};

type FilestackSecurity = {
  security: { policy: string; signature: string };
};

const SecureFilestack: React.FC<SecureFilestackProps> = ({
  actionOptions,
  apikey,
  componentDisplayMode,
  customRender,
  onSuccess,
}) => {
  const { data, isSuccess } = useFilestackPolicy(apikey);

  if (!isSuccess || !data?.data) {
    return <M.LoadingInline />;
  }

  const filestackSecurity: FilestackSecurity = { security: { ...data?.data } };

  return (
    <ReactFilestack
      actionOptions={actionOptions}
      apikey={apikey}
      componentDisplayMode={componentDisplayMode}
      customRender={customRender}
      onSuccess={onSuccess}
      clientOptions={filestackSecurity}
    />
  );
};

export default SecureFilestack;
