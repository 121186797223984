import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';

import ConfirmationImage from '../../../images/candidateAuth/EmailSent.png';

const CandidateEmailConfirmation = ({ resetPath }) => (
  <M.Grid fullWidth id='email-confirmation' data-testid='email-confirmation'>
    <M.Container>
      <M.GridRow>
        <h2 className='title'>
          <T value='components.candidateAuthEmail.confirmationTitle' />
        </h2>
      </M.GridRow>
      <M.GridRow>
        <M.GridCol lg={9} className='confirmation-content'>
          <p>
            <T value='components.candidateAuthEmail.confirmationText' />
          </p>
          <p className='font-weight-bold'>
            <T value='components.candidateAuthEmail.emailNotReceived.title' />
          </p>
          <ul>
            <li>
              <T value='components.candidateAuthEmail.emailNotReceived.bullets.1' />
            </li>
            <li>
              <T value='components.candidateAuthEmail.emailNotReceived.bullets.2' />
            </li>
            <li>
              <T value='components.candidateAuthEmail.emailNotReceived.bullets.3' />
            </li>
          </ul>
          <p>
            <T
              value='components.candidateAuthEmail.emailNotReceived.resendText'
              path={resetPath}
              dangerousHTML
            />
          </p>
        </M.GridCol>
        <M.GridCol lg={7} className='confirmation-content'>
          <img src={ConfirmationImage} alt='' />
        </M.GridCol>
      </M.GridRow>
    </M.Container>
  </M.Grid>
);

CandidateEmailConfirmation.propTypes = {
  resetPath: PropTypes.string.isRequired,
};

export default CandidateEmailConfirmation;
