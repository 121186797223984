import React from 'react';
import { useDispatch } from 'react-redux';
import { colors, M } from '@dashboard-experience/mastodon';
import { useNavigate } from 'react-router-dom';

import { ReportSummary, ReportType } from 'api/candidates/types/Report';
import styled from 'styled-components';
import { formatDate } from 'components/Reports/Report/lib/formatting';
import { getSoonestActionRequiredByDate } from 'lib/helpers';
import { CardLabel } from './CardLabel';

import './ReportCard.scss';
import { DetailText } from './DetailText';
import { changeReportIndex } from '../../../../actions';
import isActionRequired from '../lib/isActionRequired';

const ActionDateWrapper = styled.div`
  color: ${colors.coolBlack100};
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ActionDate = styled.span`
  margin-left: 20px;
`;

const ActionDateClock = styled(M.Icon)`
  height: 16px !important;
  width: 16px !important;
  margin-bottom: 3px !important;
`;

const getStatusClass = (cardData: ReportSummary): string => {
  const classPrefix = 'status-';
  const classes: Record<string, string> = {
    complete: `${classPrefix}complete`,
    clear: `${classPrefix}complete`,
    canceled: `${classPrefix}complete`,
    consider: `${classPrefix}consider`,
    dispute: `${classPrefix}in-progress`,
    pending: `${classPrefix}in-progress`,
  };

  if (isActionRequired(cardData)) return `${classPrefix}action-required`;

  return classes[cardData.status.toLowerCase()] || '';
};

const ActionDateInfo = (report: ReportSummary) => {
  return (
    <ActionDateWrapper data-testid='actionDateWrapper'>
      <ActionDateClock icon='Time' />
      <ActionDate data-testid='actionDate'>
        <strong>Due By:</strong>{' '}
        {formatDate(getSoonestActionRequiredByDate(report), 'MMMM DD, YYYY')}
      </ActionDate>
    </ActionDateWrapper>
  );
};
interface ReportCardProps extends React.HTMLProps<HTMLLIElement> {
  report: ReportSummary;
}

const ReportCard: React.FC<ReportCardProps> = ({ report, ...restProps }) => {
  const statusClass = getStatusClass(report);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shoudDisplayActionDate =
    report.displayStatus === ReportType.actionRequired &&
    report.exceptions.length > 0;
  return (
    <M.TileOption
      data-testid={`report-${report.id}`}
      title={report.accountName}
      rightInfo={
        <CardLabel
          status={report.displayStatus ?? ''}
          statusClass={statusClass}
        />
      }
      ariaLabel={`${report.accountName} - ${report.status}`}
      className={`report-card ${statusClass}`}
      detailText={
        <>
          <DetailText report={report} />
          {shoudDisplayActionDate && <ActionDateInfo {...report} />}
        </>
      }
      optionSelected={() => {
        navigate(`/reports/${report.id}`);
        dispatch(changeReportIndex((report.reportOrder ?? 1) - 1));
      }}
      {...restProps}
    />
  );
};

export default ReportCard;
