import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';

import IconPanel from '../../../IconPanel';
import pendingIcon from '../../../../images/icons/Pending.svg';

const DisputeNotice = ({ email }) => (
  <IconPanel
    data-testid='dispute-notice'
    translationKey='components.Report.DisputeNotice'
    icon={
      <img
        src={pendingIcon}
        alt={i18n.getStr('components.Report.DisputeNotice.disputePending')}
      />
    }
    detailsCentered
  >
    <div className='text-center'>
      <T
        value='components.Report.DisputeNotice.emailDetails'
        email={email}
        dangerousHTML
      />
    </div>
  </IconPanel>
);

DisputeNotice.propTypes = {
  email: PropTypes.string,
};

DisputeNotice.defaultProps = {
  email: '',
};

export default i18n.renderTranslation()(DisputeNotice);
