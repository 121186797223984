import PropTypes from 'prop-types';
import React from 'react';
import {
  arrayRemove as reduxFormArrayRemove,
  formValueSelector,
} from 'redux-form';
import { connect } from 'react-redux';

import IconPanel from '../IconPanel';
import DocumentList from '../fields/DocumentList';
import { VERIFICATION_FORM_NAME } from '../../constants';

import driverLicense from '../../images/icons/documents/DriverLicense.svg';
import passport from '../../images/icons/documents/Passport.svg';
import ssnCard from '../../images/icons/documents/SSNCard.svg';
import ssn from '../../images/icons/documents/SSNDocument.svg';
import photo from '../../images/icons/documents/Photo.svg';

const DOCUMENT_ICONS = {
  passport,
  previousDriverLicense: driverLicense,
  ssnCard,
  stateIdCard: driverLicense,
  photo,
  driverLicense,
  'driverLicenseHistory.drivingExperienceRule': driverLicense,
  'driverLicenseHistory.inquiryScope': driverLicense,
  ssnDocumentVerification: ssn,
};

const DocumentsPanel = ({
  arrayRemove,
  children,
  documents,
  model,
  name,
  floatIcon,
  noHeader,
  noDivider,
  detailsWide,
  noDetails,
  infoTranslationProps,
}) => {
  const onRemove = i => {
    arrayRemove(VERIFICATION_FORM_NAME, model, i);
  };

  const footer = (
    <>
      {documents.length > 0 && (
        <DocumentList onRemove={onRemove} documents={documents} />
      )}
      {children}
    </>
  );

  return (
    <IconPanel
      translationKey={`components.Verification.DocumentsPanel.${name}`}
      name={name}
      icon={<img src={DOCUMENT_ICONS[name]} alt={name} />}
      footer={footer}
      floatIcon={floatIcon}
      noHeader={noHeader}
      noDivider={noDivider}
      detailsWide={detailsWide}
      noDetails={noDetails}
      infoTranslationProps={infoTranslationProps}
    />
  );
};

DocumentsPanel.propTypes = {
  arrayRemove: PropTypes.func,
  children: PropTypes.node,
  documents: PropTypes.array,
  model: PropTypes.string,
  name: PropTypes.string,
  floatIcon: PropTypes.bool,
  noHeader: PropTypes.bool,
  noDivider: PropTypes.bool,
  detailsWide: PropTypes.bool,
  noDetails: PropTypes.bool,
  infoTranslationProps: PropTypes.object,
};

DocumentsPanel.defaultProps = {
  arrayRemove: () => {},
  children: [],
  documents: [],
  model: '',
  name: '',
  floatIcon: false,
  noHeader: false,
  noDivider: false,
  detailsWide: false,
  noDetails: false,
  infoTranslationProps: {},
};

const selector = formValueSelector(VERIFICATION_FORM_NAME);

const mapStateToProps = (state, ownProps) => ({
  documents: selector(state, ownProps.model) || [],
});

export default connect(mapStateToProps, { arrayRemove: reduxFormArrayRemove })(
  DocumentsPanel,
);
