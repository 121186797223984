import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import Attribute from '../Attribute';

const EVICTION_ATTRS = Object.freeze([
  'caseNumber',
  'fileDate',
  'judgementAmount',
  'plaintiff',
  'offense',
  'courtJurisdiction',
  'fullName',
  'address',
]);

const EVICTION_EVENT_ATTRS = Object.freeze([
  'finding',
  'event',
  'judgementDate',
  'fileDate',
]);

const EvictionAttrTitle = attr => {
  if (attr === 'offense') {
    return 'case';
  }
  return attr;
};

const EvictionEvent = ({ evictionEvent }) => (
  <div className='eviction-record-event'>
    <div className='mb-2'>
      <span>
        <T value='components.eviction.event' />
      </span>
    </div>

    <div className='mb-4'>
      {EVICTION_EVENT_ATTRS.map((key, i) => (
        <Attribute
          key={`attribute-${i + 1}`}
          attr={key}
          value={evictionEvent[key]}
        />
      ))}
    </div>
  </div>
);

EvictionEvent.propTypes = {
  evictionEvent: PropTypes.object.isRequired,
};

const EvictionRecord = ({ record }) => (
  <div className='eviction-record'>
    <div className='mb-3'>
      <hr className='mb-2 mt-2' />
      <span className='text-uppercase'>
        <T value='components.eviction.evictionRecord' />
      </span>
      <hr className='mb-2 mt-2' />
    </div>

    <div className='mb-4 mt-4'>
      {EVICTION_ATTRS.map((key, i) => (
        <Attribute
          key={`attribute-${i + 1}`}
          attr={EvictionAttrTitle(key)}
          value={record[key]}
        />
      ))}
    </div>
    {record.events && (
      <div className='mb-4'>
        {record.events.map((event, i) => (
          <EvictionEvent key={`event-${i + 1}`} evictionEvent={event} />
        ))}
      </div>
    )}
  </div>
);

EvictionRecord.propTypes = {
  record: PropTypes.object.isRequired,
};

export default EvictionRecord;
