import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Attribute from '../Attribute';
import ExpungementBanner from '../Record/ExpungementBanner';
import { CHARGE_ATTRS } from '../../../../constants';

const CriminalCharge = ({ charge, expungement }) => (
  <div className='criminal-charge'>
    {expungement && <ExpungementBanner expungement={expungement} />}

    {Object.keys(CHARGE_ATTRS).map((key, i) => (
      <Attribute
        key={`attribute-${i + 1}`}
        attr={CHARGE_ATTRS[key]}
        value={charge[key]}
      />
    ))}
  </div>
);

CriminalCharge.propTypes = {
  charge: PropTypes.object.isRequired,
  expungement: PropTypes.object,
};

CriminalCharge.defaultProps = {
  expungement: undefined,
};

const mapStateToProps = (state, ownProps) => ({
  expungement: state.portal.expungements.find(exp => {
    return exp.chargeId === ownProps.charge.id;
  }),
});

export default connect(mapStateToProps)(CriminalCharge);
