import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { PanelProps } from './Panel';
import './MastodonPanel.scss';

type MastodonPanelProps = PanelProps & { tag: string };

const MastodonPanel: React.FC<MastodonPanelProps> = ({
  children,
  className = '',
  i18n = false,
  icon = '',
  noBlock = false,
  title = '',
  tag,
}) => {
  const [panelTitle, setPanelTitle] = React.useState<React.ReactNode>(null);
  const [hasPanelBody, setHasPanelBody] = React.useState(false);
  React.useEffect(() => {
    const headerIcon = icon ? <M.Icon icon={icon} /> : null;
    if (i18n && typeof title === 'string') {
      setPanelTitle(
        <h3>
          {headerIcon}
          <T value={title} dangerousHTML />
        </h3>,
      );
    } else {
      setPanelTitle(
        <h3>
          {headerIcon}
          {title}
        </h3>,
      );
    }
  }, [icon, i18n, title]);

  React.useEffect(() => {
    setHasPanelBody(
      !noBlock && !!children && React.Children.count(children) > 0,
    );
  }, [children, noBlock]);

  return (
    <M.Container className={`panel panel--${className}`}>
      {panelTitle && (
        <M.Container.Row className='panel__header'>
          <M.Container.Col className='panel__title'>
            {panelTitle}
          </M.Container.Col>
          <M.Container.Col className='panel__tag'>{tag}</M.Container.Col>
        </M.Container.Row>
      )}
      {hasPanelBody && (
        <M.Container.Row className='panel__body'>{children}</M.Container.Row>
      )}
    </M.Container>
  );
};

export default MastodonPanel;
