import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

const DropDown = ({
  input: { onChange, value, name },
  options,
  meta,
  titleText,
  label,
  showDobError,
  disabled,
}) => {
  const { error, touched } = meta;
  const showErrorMessage = touched && error;

  // based on the options passed from 'lib/options'
  const fields = options.map(option =>
    option.i18n ? i18n.getStr(option.name) : option.name,
  );

  return (
    <M.Dropdown
      name={name}
      label={label || fields[0]}
      items={fields.splice(1)}
      invalid={showErrorMessage || showDobError}
      invalidText={showErrorMessage ? i18n.getStr(error) : null}
      value={value}
      onChange={onChange}
      titleText={titleText}
      disabled={disabled}
    />
  );
};

DropDown.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  titleText: PropTypes.string,
  label: PropTypes.string,
  showDobError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

DropDown.defaultProps = {
  titleText: '',
  label: '',
  disabled: false,
};

export default DropDown;
