/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { i18n } from '@international/mastodon-i18n';

import SubmissionConfirmation from 'components/Workflow/Disputes/MotorVehicleReport/SubmissionConfirmation';
import ContentContainer from 'components/Workflow/ContentContainer';
import {
  DisputeReasonSummary,
  File,
  Subreasons,
} from 'components/Workflow/Disputes/Types';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import 'scss/Summary.scss';
import { postDisputeIntake } from 'api/verifications';
import { Report } from 'types/report';
import SubmitButton from 'components/Workflow/Disputes/MotorVehicleReport/SubmitButton';
import { M } from '@dashboard-experience/mastodon';
import { trackAnalyticsEvent } from 'actions/analytics';
import { ANALYTICS_EVENTS } from 'lib/analytics';
import { useDispatch } from 'react-redux';
import { PostDisputeIntakeParams } from 'api/verifications/types';
import ToastNotification from 'components/ToastNotification';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const Summary = ({
  closed,
  finalizedDisputeReasonSummaries,
  report,
  setHasSubmitted,
  addAnError,
  deleteCard,
  visitedDmvDate,
  confirmVisitedDmv,
}: {
  closed: () => void;
  finalizedDisputeReasonSummaries: DisputeReasonSummary[];
  report: Report;
  setHasSubmitted: (param: boolean) => void;
  addAnError: () => void;
  deleteCard: (param: number) => void;
  visitedDmvDate: string;
  confirmVisitedDmv: boolean;
}) => {
  // some flag that controls rendering of summary or submission confirmation
  const [displaySubmissionConfirmation, setDisplay] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [hadFailedSubmission, setHadFailedSubmission] = useState(false);
  const dispatch = useDispatch();

  function toSnakeCase(str: string): string {
    return str
      .replace(/\.?([A-Z]+)/g, (x: string, y: string) => `_${y.toLowerCase()}`)
      .replace(/^_/, '');
  }

  const submitDispute = async () => {
    const disputedItems = finalizedDisputeReasonSummaries.map(
      disputeReasonSummary => {
        return {
          disputed_item_id: disputeReasonSummary.disputedItemId,
          article: `${disputeReasonSummary.situation}`,
          explanation: disputeReasonSummary.explanation,
          fields: disputeReasonSummary.subreasons.map(s => {
            if (s.details) {
              return `${s.name} - ${s.details.join(', ')}`;
            }
            return s.name;
          }),
          reason: toSnakeCase(disputeReasonSummary.reasonCode),
          section: 'Motor Vehicle Report',
          documents: disputeReasonSummary.files,
        };
      },
    );

    const params = {
      report,
      disputedItems,
      meta: {
        visited_dmv_date: visitedDmvDate,
        confirm_visited_dmv: confirmVisitedDmv,
      },
    } as PostDisputeIntakeParams;

    return postDisputeIntake(params);
  };

  const handleSubmit = () => {
    setSubmissionInProgress(true);
    submitDispute()
      .then(() => {
        dispatch(
          trackAnalyticsEvent(ANALYTICS_EVENTS.REPORT_ERROR_BUTTON_CLICKED),
        );
        setDisplay(true);
      })
      .catch(() => {
        setSubmissionInProgress(false);
        setHadFailedSubmission(true);
      });
  };

  const submitButton = () => {
    return (
      <SubmitButton
        submissionInProgress={submissionInProgress}
        handleSubmit={handleSubmit}
        submitDisabled={finalizedDisputeReasonSummaries.length < 1}
      />
    );
  };

  const renderSubreasonDetails = (subreasons: Subreasons) => {
    const subreasonDetails = subreasons
      .map(s => s.details)
      .flat()
      .filter(detail => detail)
      .join(', ');

    if (subreasonDetails) {
      return `(${subreasonDetails})`;
    }
    return '';
  };

  const renderDocumentsCount = (files: File[] | null | undefined) => {
    if (files && files.length > 0) {
      return i18n.getStr(
        'workflows.dispute.summary.documentsCount',
        files.length,
      );
    }
    return '';
  };

  const cards = finalizedDisputeReasonSummaries.map(
    (disputeReasonSummary, index) => {
      return (
        <M.Card
          data-testid={DISPUTES_FLOW.deleteSummaryCard}
          ariaLabel={i18n.getStr('buttons.delete')}
          key={index}
          closeHandler={() => deleteCard(index)}
        >
          <h2 className='summary-situation'>
            {i18n.getStr(
              'workflows.dispute.summary.screening',
              disputeReasonSummary.situation,
            )}
          </h2>
          <h3 className='summary-reason-code'>
            {i18n.getStr(
              `workflows.dispute.reasons.${disputeReasonSummary.reasonCode}`,
            )}
          </h3>
          <h4 className='summary-subreason-text'>
            {disputeReasonSummary.subreasons.map(s => s.name).join(', ')}
          </h4>
          <span className='summary-subreason-detail-text'>
            {renderSubreasonDetails(disputeReasonSummary.subreasons)}
          </span>
          <p className='mt-3'>
            {i18n.getStr(
              'workflows.dispute.summary.explanation',
              disputeReasonSummary.explanation,
            )}
          </p>
          <p>{renderDocumentsCount(disputeReasonSummary.files)}</p>
        </M.Card>
      );
    },
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  const SummaryContent = () => {
    return (
      <div id='summary-container'>
        <ContentContainer>
          <div
            className='pb-4'
            role='note'
            aria-label={i18n.getStr(
              'workflows.dispute.summary.instructionsText',
            )}
          >
            {i18n.getStr('workflows.dispute.summary.instructionsText')}
          </div>
          <M.CardList
            ariaLabel={i18n.getStr('workflows.dispute.summary.groupName')}
          >
            {cards}
          </M.CardList>
          <div className='mb-3 add-an-error-container'>
            <M.Button
              className='analytics_summary_add_error_button'
              size='sm'
              kind='secondary'
              aria-disabled='false'
              onClick={addAnError}
            >
              {i18n.getStr('buttons.addAdditionalError')}
            </M.Button>
          </div>
        </ContentContainer>
        <NavigationFooter rightButtonContent={submitButton()} />
      </div>
    );
  };

  return (
    <div>
      {hadFailedSubmission && (
        <ToastNotification
          message={i18n.getStr('messages.genericPostError')}
          kind='error'
        />
      )}
      {displaySubmissionConfirmation ? (
        <SubmissionConfirmation
          closed={closed}
          setHasSubmitted={setHasSubmitted}
        />
      ) : (
        <SummaryContent />
      )}
    </div>
  );
};

export default Summary;
