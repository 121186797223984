import React from 'react';

interface UseItemKeyNavigtion {
  <T extends HTMLElement>(
    containerRef: React.RefObject<T>,
    isActive?: boolean,
    onEscape?: Function,
  ): void;
}

const getActiveElementSibling = (next: boolean = true) => {
  let result = document.activeElement?.nextElementSibling;
  if (!next) {
    result = document.activeElement?.previousElementSibling;
  }
  return result as HTMLElement | null | undefined;
};

const useItemKeysNavigation: UseItemKeyNavigtion = (
  containerRef,
  isActive,
  onEscapeKeyDown,
) => {
  const focusFirst = React.useCallback(() => {
    (
      containerRef.current?.firstChild as HTMLElement | null | undefined
    )?.focus();
  }, [containerRef]);
  const focusLast = React.useCallback(() => {
    (
      containerRef.current?.lastChild as HTMLElement | null | undefined
    )?.focus();
  }, [containerRef]);
  const focusPrev = React.useCallback(() => {
    const prevSibling = getActiveElementSibling(false);
    if (prevSibling) {
      prevSibling.focus();
    } else {
      focusLast();
    }
  }, [focusLast]);

  const focusNext = React.useCallback(() => {
    const nextSibling = getActiveElementSibling();
    if (nextSibling) {
      nextSibling.focus();
    } else {
      focusFirst();
    }
  }, [focusFirst]);

  React.useEffect(() => {
    (
      containerRef.current?.querySelector(
        '.selected',
      ) as HTMLButtonElement | null
    )?.focus();
  }, [isActive, containerRef]);

  /**
   * aria-role='menu' keyboard interactions
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/menu_role#keyboard_interactions
   */
  const keyDownEventHandler = React.useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case 'Escape':
          if (onEscapeKeyDown) {
            e.preventDefault();
            onEscapeKeyDown();
          }
          break;
        case 'ArrowUp':
        case 'ArrowRight':
          e.preventDefault();
          focusPrev();
          break;
        case 'ArrowDown':
        case 'ArrowLeft':
          e.preventDefault();
          focusNext();
          break;
        case 'Home':
          e.preventDefault();
          focusFirst();
          break;
        case 'End':
          e.preventDefault();
          focusLast();
          break;
        default:
          break;
      }
    },
    [onEscapeKeyDown, focusPrev, focusNext, focusFirst, focusLast],
  );

  React.useEffect(() => {
    const container = containerRef.current;
    container?.addEventListener('keydown', keyDownEventHandler);
    return () => {
      container?.removeEventListener('keydown', keyDownEventHandler);
    };
  }, [containerRef, keyDownEventHandler]);
};

export default useItemKeysNavigation;
