import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import ContentContainer from 'components/Workflow/ContentContainer';
import { LicenseReport } from 'types/motorVehicleReport';
import { getMotorVehicleRecordLengths } from 'components/Reports/Report/lib/screenings';
import { DisputeReasonSummary } from 'components/Workflow/Disputes/Types';
import { Report } from 'types/report';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import { isReportEligibleForUpdateDL } from 'components/Reports/Report/lib/mvr';

interface SituationSelectorProps {
  back?: () => void;
  candidateStoriesEnabled: boolean;
  finalizedDisputeReasonSummaries: DisputeReasonSummary[];
  toggle: () => void;
  toggleCandidateStoryModal: () => void;
  toggleUpdateDLModal: () => void;
  transitionToSummary: () => void;
  reasonSelected: (reason: string) => void;
  licenseReport: LicenseReport;
  report: Report;
}

const SituationSelector = ({
  back,
  candidateStoriesEnabled,
  finalizedDisputeReasonSummaries,
  toggle,
  toggleCandidateStoryModal,
  toggleUpdateDLModal,
  transitionToSummary,
  reasonSelected,
  licenseReport,
  report,
}: SituationSelectorProps) => {
  const cancelButton = (
    <M.Button size='sm' kind='secondary' onClick={toggle}>
      {i18n.getStr('buttons.cancel')}
    </M.Button>
  );

  const summaryButton = (
    <M.Button size='sm' kind='secondary' onClick={transitionToSummary}>
      {i18n.getStr('buttons.summary')}
    </M.Button>
  );

  const transitionToCandidateStory = () => {
    toggleCandidateStoryModal();
    toggle();
  };

  const transitionToUpdateDL = () => {
    if (isReportEligibleForUpdateDL(report)) {
      toggle();
      toggleUpdateDLModal();
    } else {
      reasonSelected('newDLInformation');
    }
  };

  const shouldRenderMvrInaccurateTileOption = () => {
    const mvrLengths: {
      violationsLength?: number;
      accidentsLength?: number;
      suspensionsLength?: number;
    } = getMotorVehicleRecordLengths(licenseReport);

    const { violationsLength, accidentsLength, suspensionsLength } = mvrLengths;

    return !!(violationsLength || accidentsLength || suspensionsLength);
  };

  const rightButton = () => {
    return finalizedDisputeReasonSummaries.length > 0
      ? summaryButton
      : cancelButton;
  };

  return (
    <div>
      <ContentContainer>
        <div className='pb-4'>
          {i18n.getStr('workflows.dispute.situationSelector.title')}
        </div>
        <form
          aria-label={i18n.getStr(
            'workflows.dispute.situationSelector.formName',
          )}
        >
          <M.TileOptionList
            ariaLabel={i18n.getStr('workflows.dispute.situationSelector.title')}
          >
            <M.TileOption
              data-testid={DISPUTES_FLOW.disputeNewDlInformation}
              title={i18n.getStr(
                'workflows.dispute.situationSelector.situations.newDLInformation',
              )}
              optionSelected={transitionToUpdateDL}
              detailText={i18n.getStr(
                'workflows.dispute.situationSelector.detailText.newDLInformation',
              )}
            />
            <M.TileOption
              data-testid={DISPUTES_FLOW.disputeInaccurateLicenseInfo}
              title={i18n.getStr(
                'workflows.dispute.situationSelector.situations.licenseInformationInaccurate',
              )}
              optionSelected={() => reasonSelected('inaccurateLicenseInfo')}
              detailText={i18n.getStr(
                'workflows.dispute.situationSelector.detailText.licenseInformationInaccurate',
              )}
            />
            {shouldRenderMvrInaccurateTileOption() && (
              <M.TileOption
                data-testid={DISPUTES_FLOW.disputeInaccurateMotorVehicleRecord}
                title={i18n.getStr(
                  'workflows.dispute.situationSelector.situations.motorVehicleRecordInaccurate',
                )}
                optionSelected={() =>
                  reasonSelected('inaccurateMotorVehicleRecord')
                }
                detailText={i18n.getStr(
                  'workflows.dispute.situationSelector.detailText.motorVehicleRecordInaccurate',
                )}
              />
            )}
            {candidateStoriesEnabled && (
              <M.TileOption
                data-testid={DISPUTES_FLOW.disputeProvideAdditionalContext}
                title={i18n.getStr(
                  'workflows.dispute.situationSelector.situations.provideAdditionalContext',
                )}
                optionSelected={transitionToCandidateStory}
              />
            )}
            <M.TileOption
              data-testid={DISPUTES_FLOW.disputePaidFinesInformation}
              title={i18n.getStr(
                'workflows.dispute.situationSelector.situations.finesHaveBeenPaid',
              )}
              optionSelected={() => reasonSelected('paidFinesInformation')}
            />
            <M.TileOption
              data-testid={DISPUTES_FLOW.disputeCauseConsiderStatusInformation}
              title={i18n.getStr(
                'workflows.dispute.situationSelector.situations.causeConsiderStatus',
              )}
              optionSelected={() =>
                reasonSelected('causeConsiderStatusInformation')
              }
            />
          </M.TileOptionList>
        </form>
      </ContentContainer>
      <NavigationFooter back={back} rightButtonContent={rightButton()} />
    </div>
  );
};

export default SituationSelector;
