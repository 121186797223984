import PropTypes from 'prop-types';
import React from 'react';

const DisputeMultiSelect = ({
  displayValue,
  selectValue,
  selected,
  toggleValue,
  trackAction,
  date,
}) => {
  const handleClick = () => {
    if (selected) {
      trackAction(`un-selected: ${selectValue}`);
    } else {
      trackAction(`selected: ${selectValue}`);
    }

    toggleValue(selectValue);
  };

  let classes =
    'd-flex justify-content-between tile-option-select tile-option-multi-select p-4 mb-2 rounded';
  if (selected) {
    classes += ' tile-option-select-selected';
  }

  return (
    <div
      className={`${classes}`}
      onClick={handleClick}
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      role='option'
      tabIndex={0}
    >
      <div>{displayValue}</div>
      <div className='text-very-muted'>{date}</div>
    </div>
  );
};

DisputeMultiSelect.propTypes = {
  date: PropTypes.string,
  displayValue: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  selectValue: PropTypes.any.isRequired,
  toggleValue: PropTypes.func.isRequired,
  trackAction: PropTypes.func.isRequired,
};

DisputeMultiSelect.defaultProps = {
  date: '',
};

export default DisputeMultiSelect;
