import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Icon } from './Icon';
import { Paragraph, Alignment as ParagraphAlignment } from './Paragraph';

interface ContactCheckrDeadendProps {
  greeting: React.ReactNode;
}

const supportUrl =
  'https://help.checkr.com/hc/en-us/articles/360036929273-Contact-Checkr-Candidate-Support';

export const ContactCheckrDeadend: React.FC<ContactCheckrDeadendProps> = ({
  greeting,
}) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
        padding: '24px 16px 56px 16px',
        border: 'none',
      }}
    >
      {greeting}
      <StyleableContainer type={Type.Fail} styledTopBorder>
        <Icon type={Type.Fail} />
        <Heading
          textKey='components.InstantExceptions.ContactCheckrDeadend.heading'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%', margin: '8px 0px' }} />
        <Paragraph
          textKey='components.InstantExceptions.ContactCheckrDeadend.details1'
          align={ParagraphAlignment.Left}
        >
          <a href={supportUrl}>
            {i18n.getStr(
              'components.InstantExceptions.ContactCheckrDeadend.checkrSupportLink',
            )}
          </a>
          {i18n.getStr(
            'components.InstantExceptions.ContactCheckrDeadend.details2',
          )}
        </Paragraph>
      </StyleableContainer>
    </M.Container>
  );
};
