import { ENV } from '../../constants';
import de from './portal_de.json';
import en from './portal_en.json';
import en_XL from './portal_en_XL.json';
import es from './portal_es.json';
import es_MX from './portal_es_MX.json';
import fr from './portal_fr.json';
import fr_CA from './portal_fr_CA.json';

const resources = () => {
  let loadResources = {
    en: {
      translations: en,
    },
    de: {
      translations: de,
    },
    es: {
      translations: es,
    },
    es_MX: {
      translations: es_MX,
    },
    fr: {
      translations: fr,
    },
    fr_CA: {
      translations: fr_CA,
    },
  };
  if (['development', 'staging', 'sandbox'].includes(ENV)) {
    loadResources = {
      ...loadResources,
      en_XL: {
        translations: en_XL,
      },
    };
  }
  return loadResources;
};

export default resources;
