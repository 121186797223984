import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { Field } from 'redux-form';

import Checkbox from '../../../fields/Checkbox';
import * as V from '../../../../lib/validations';

const WrongPersonConfirmation = () => (
  <>
    <div className='card wrong-person-confirmation'>
      <div className='card-body'>
        <p>
          <strong>
            <M.Icon icon='InformationFilled' />
            &nbsp;
            <T value='components.Report.Dispute.wrongPersonConfirmation.title' />
          </strong>
        </p>
        <T value='components.Report.Dispute.wrongPersonConfirmation.message' />
      </div>
    </div>
    <div>
      <Field
        type='checkbox'
        name='accepted'
        label='components.Report.Dispute.wrongPersonConfirmation.label'
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        className='mb-0'
        boldLabel
        i18n
      />
    </div>
  </>
);

export default WrongPersonConfirmation;
