import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

const Logo = ({ logoUri }) => (
  <div className='container text-center'>
    <img
      className='company-logo'
      src={logoUri}
      alt={i18n.getStr('components.companyLogo')}
    />
  </div>
);

Logo.propTypes = {
  logoUri: PropTypes.string.isRequired,
};

export default i18n.renderTranslation()(Logo);
