import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import CandidateStoryItemTextField from './CandidateStoryItemTextField';

const GeneralInformationTextForm = ({ company, disabled, field }) => {
  return (
    <div className='form-group' hidden={disabled}>
      <p>
        <T
          value='components.Report.CandidateStories.Modal.GeneralInformation.pleaseShare'
          company={company}
          dangerousHTML
        />
      </p>

      <ul className='text-muted'>
        <T value='components.Report.examples' />
        <li>
          <T
            value='components.Report.CandidateStories.Modal.GeneralInformation.example1'
            company={company}
          />
        </li>
        <li>
          <T value='components.Report.CandidateStories.Modal.GeneralInformation.example2' />
        </li>
        <li>
          <T value='components.Report.CandidateStories.Modal.GeneralInformation.example3' />
        </li>
        <li>
          <T value='components.Report.CandidateStories.Modal.GeneralInformation.example4' />
        </li>
      </ul>

      <CandidateStoryItemTextField field={field} />
    </div>
  );
};

GeneralInformationTextForm.propTypes = {
  company: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.string.isRequired,
};

GeneralInformationTextForm.defaultProps = {
  disabled: false,
};

export default GeneralInformationTextForm;
