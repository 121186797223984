import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Type, TypeParams } from './Type';

interface IconProps {
  type?: Type;
}

export const Icon: React.FC<IconProps> = ({ type }) => {
  const {
    color: typeColor,
    icon: typeIcon,
    height: typeHeight,
    width: typeWidth,
  } = TypeParams[type || Type.Default];
  const color = typeIcon ? typeColor : 'none';
  const icon = typeIcon || 'CheckmarkFilled';
  const height = typeHeight || 32;
  const width = typeWidth || 32;
  return (
    <M.Icon
      icon={icon}
      style={{
        alignSelf: 'center',
        marginRight: '2%',
        fill: color,
        height,
        width,
      }}
    />
  );
};
