import { useMutation } from '@tanstack/react-query';

import { patchCandidate } from './client';
import { UpdateCandidateParams } from './types';

export const useUpdateCandidate = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: (params: UpdateCandidateParams) => {
      return patchCandidate(params);
    },
    ...{ onSuccess },
  });

  const updateCandidate = (params: UpdateCandidateParams) => {
    mutation.mutate(params);
  };

  return { updateCandidate, candidateUpdateStatus: mutation.status };
};

export default useUpdateCandidate;
