import { decamelizeKeys } from 'humps';
import { getAPIBase } from '../api/verifications/getAPIBase';
import { request } from './helper';
import { trackAnalyticsEvent } from './analytics';
import { ANALYTICS_EVENTS } from '../lib/analytics';
import { stripEmojis } from '../lib/helpers';
import { BACKEND_FLAG_PATHS } from '../constants';

/*
 * Action Types
 */

export const SUBMIT_DISPUTE_REQUEST = 'SUBMIT_DISPUTE_REQUEST';
export const submitDisputeRequest = () => ({ type: SUBMIT_DISPUTE_REQUEST });

export const SUBMIT_DISPUTE_SUCCESS = 'SUBMIT_DISPUTE_SUCCESS';
export const submitDisputeSuccess = reportId => ({
  type: SUBMIT_DISPUTE_SUCCESS,
  reportId,
});

export const SUBMIT_INTAKE_SUCCESS = 'SUBMIT_INTAKE_SUCCESS';
export const submitIntakeSuccess = () => ({ type: SUBMIT_INTAKE_SUCCESS });

/*
 * Actions
 */

export const submitDispute = values => dispatch => {
  dispatch(submitDisputeRequest());

  values.disputedItems.forEach(item => {
    item.explanation = stripEmojis(item.explanation);
  });

  const submissionValues = {
    report: values.report,
    disputedItems: values.disputedItems,
    userActions: values.userActions,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(decamelizeKeys(submissionValues)),
  };

  const accountId = values.report.account.id;

  return getAPIBase({
    path: BACKEND_FLAG_PATHS.DISPUTE_INTAKE,
    accountId,
  }).then(baseURL =>
    request(`${baseURL}/dispute_intake`, options).then(json => {
      dispatch(
        json.result === 'intake'
          ? submitIntakeSuccess()
          : submitDisputeSuccess(values.report.id),
      );
      dispatch(
        trackAnalyticsEvent(ANALYTICS_EVENTS.REPORT_ERROR_BUTTON_CLICKED),
      );
    }),
  );
};
