import React from 'react';
import { Outlet } from 'react-router-dom';
import PrivacyPage from 'containers/Privacy/Privacy';

const InternationalPrivacy = () => (
  <PrivacyPage international>
    <Outlet />
  </PrivacyPage>
);

export default InternationalPrivacy;
