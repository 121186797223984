import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

interface ErrorPanelProps {
  componentName: string;
}

const ErrorPanel: React.FC<ErrorPanelProps> = ({ componentName }) => {
  return (
    <div className='alert alert-danger login-error' role='alert'>
      <div>
        <M.Icon icon='WarningAltFilled' />{' '}
        <b>
          <T value={`components.${componentName}.Error.title`} />
        </b>
      </div>
      <T value={`components.${componentName}.Error.message`} dangerousHTML />
    </div>
  );
};

export default ErrorPanel;
