import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { CHECKR_EXPUNGEMENTS_PORTAL_BASE } from '../../../../constants';

const getTranslationKey = status => {
  switch (status) {
    case 'potential':
    case 'eligible':
      return 'components.expungementBanner.qualifyForRelief';
    case 'approved':
    case 'previously_approved':
      return 'components.expungementBanner.completedExpungement';
    default:
      return 'components.expungementBanner.needAdditionalInformation';
  }
};

const linkToExpungement = status => {
  switch (status) {
    case 'potential':
    case 'eligible':
      return true;
    default:
      return false;
  }
};

const ExpungementBanner = ({ expungement, report }) => {
  const email = report?.candidate?.email;
  const expungementUrl = `${CHECKR_EXPUNGEMENTS_PORTAL_BASE}/expungements/${expungement.id}`;
  const translationKey = getTranslationKey(expungement.status);

  return (
    <div className='info-panel'>
      <div className='container-fluid'>
        {linkToExpungement(expungement.status) && (
          <M.Link
            href={expungementUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            <M.Button kind='primary float-right ml-3'>
              <T value='components.expungementBanner.startMyExpungement' />
            </M.Button>
          </M.Link>
        )}
        <div className='info-panel-icon'>
          <M.Icon icon='InformationFilled' />
          <span className='title'>
            &nbsp;&nbsp;
            <T value={`${translationKey}.title`} dangerousHTML />
          </span>
        </div>
        <div>
          <T value={`${translationKey}.details`} email={email} dangerousHTML />
        </div>
      </div>
    </div>
  );
};

ExpungementBanner.propTypes = {
  expungement: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  report: state.portal.reports[0],
});

export default connect(mapStateToProps)(ExpungementBanner);
