import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { uuid4 } from '@sentry/utils';

interface TextInputProps extends WrappedFieldProps {
  id: string;
  placeholder: string;
  labelControl?: React.ReactNode;
  autoFocus: boolean;
  disabled: boolean;
  onPaste: (e: any) => void;
  testId: string;
  t: object;
  tReady: object;
}

const TextInput: React.FC<TextInputProps> = ({
  id = uuid4(),
  input,
  autoFocus = false,
  disabled = false,
  onPaste = (e: any) => {},
  placeholder = '',
  labelControl = '',
  testId = '',
  meta,
  t: _t,
  tReady: _tReady,
  ...props
}: TextInputProps) => {
  return (
    <M.TextInput
      id={id}
      {...input}
      {...props}
      invalid={meta.touched && !meta.valid}
      invalidText={i18n.getStr(meta.error)}
      labelText={labelControl}
      autoFocus={autoFocus}
      disabled={disabled}
      onPaste={onPaste}
      placeholder={i18n.getStr(placeholder || labelControl)}
      data-testid={testId}
    />
  );
};

export default i18n.renderTranslation()(TextInput);
