import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';

import { VERIFICATION_FORM_NAME } from '../constants';

const BasicForm = ({ children, handleSubmit }) => (
  <form onSubmit={handleSubmit}>{children}</form>
);

BasicForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default reduxForm({
  form: VERIFICATION_FORM_NAME,
  forceUnregisterOnUnmount: true,
  destroyOnUnmount: false,
})(BasicForm);
