import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { ReportSummary } from 'api/candidates/types/Report';

import useReportCardListItem from './hooks/useReportCardListItem';

export const DetailText: React.FC<{ report: ReportSummary }> = ({ report }) => {
  const listItems = useReportCardListItem(report);

  return (
    <>
      <M.KeyValueList items={listItems.list} />
      {listItems.footerItems.length ? (
        <M.KeyValueList className='footer-item' items={listItems.footerItems} />
      ) : null}
    </>
  );
};
