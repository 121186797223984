import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n, moment } from '@international/mastodon-i18n';
import Panel from '../Panels';

const Unsubscribed = ({ unsubscribedAt }) => (
  <Panel
    i18n
    title='components.Verification.Unsubscribed.title'
    className='text-center'
  >
    <p>
      <T value='components.Verification.Unsubscribed.text' />{' '}
      <strong>
        {moment(unsubscribedAt).format(i18n.DateFormats.DATE_LONG)}
      </strong>
    </p>
  </Panel>
);

Unsubscribed.propTypes = {
  unsubscribedAt: PropTypes.string.isRequired,
};

export default Unsubscribed;
