import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import IntlLoginForm from 'components/Reports/IntlLoginForm';
import MainContainer from 'components/MainContainer';
import { fetchCandidateReportsIntl, clearLoggedOut } from 'actions';
import ToastNotification from 'components/ToastNotification';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import useToken from 'utils/useToken';
import { PATHS } from '../../../constants';

const InternationalLogin: React.FC = () => {
  const { loggedOut, processing, error, loggedIn } = useSelector(
    (state: any) => state.portal,
  );
  const dispatch = useDispatch();
  const token = useToken();
  const { state, hash } = useLocation();
  const handleSubmit = React.useCallback(
    (values: any) => {
      dispatch(fetchCandidateReportsIntl(values));
    },
    [dispatch],
  );
  if (loggedIn || token) {
    return (
      <Navigate
        to={state?.prevLocation || PATHS.INTERNATIONAL_REPORTS_OVERVIEW}
      />
    );
  }
  if (error && hash.indexOf('login_error') < 0) {
    return <Navigate to='#login_error' state={state} />;
  }
  return (
    <MainContainer>
      {loggedOut && (
        <ToastNotification
          title={i18n.getStr('components.successFullyLoggedOut')}
          kind='success'
          onClose={() => {
            clearLoggedOut();
          }}
        />
      )}
      <IntlLoginForm
        onSubmit={handleSubmit}
        processing={processing}
        fetchError={error}
        loginSource='InternationalLoginForm'
      />
    </MainContainer>
  );
};

export default InternationalLogin;
