import React from 'react';

import { Translate as T } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import { i18n } from '@international/mastodon-i18n';

import { useSelector } from 'react-redux';
import useToken from 'utils/useToken';
import { M } from '@dashboard-experience/mastodon';
import Auth from '../../containers/Auth';
import IconPanel from '../IconPanel';
import reportIcon from '../../images/icons/Report.svg';
import MainContainer from '../MainContainer';

const AccessData: React.FC = () => {
  const navigate = useNavigate();
  const reports = useSelector((state: any) => state.portal.reports);
  const reportIconImg = (
    <img src={reportIcon} alt={i18n.getStr('components.dataFound')} />
  );
  const token = useToken();
  // If we are logged in, but there are no reports, all reports are stale.
  if (!reports.length) {
    return (
      <Auth token={token}>
        <MainContainer>
          <IconPanel
            data-testid='privacy-access-data'
            translationKey='components.Privacy.infoFoundAndOld'
            icon={reportIconImg}
            nonBoldInfo
            noDetails
          />
        </MainContainer>
      </Auth>
    );
  }

  const viewReportBtn = (
    <M.Button
      kind='primary'
      className='btn-mobile'
      onClick={() => navigate('/')}
    >
      <T value='buttons.viewMyReport' />
    </M.Button>
  );
  return (
    <Auth token={token}>
      <MainContainer>
        <IconPanel
          data-testid='privacy-access-data'
          translationKey='components.Privacy.infoFound'
          icon={reportIconImg}
          actions={viewReportBtn}
          noDetails
        />
      </MainContainer>
    </Auth>
  );
};

export default AccessData;
