import * as Sentry from '@sentry/react';
import { camelizeKeys } from 'humps';
import { i18n } from '@international/mastodon-i18n';
import { setLocale } from 'react-redux-i18n';
import { getResourceLocale } from 'lib/helpers';
import { getAPIBase } from '../api/verifications/getAPIBase';
import resources from '../lib/translations/resources';
import { parseErrorResponse, request, initializeDDRUM } from './helper';
import {
  BACKEND_FLAG_PATHS,
  CANDIDATES_DOCUMENTS_SIZE_MIN_BYTES,
  CANDIDATES_DOCUMENTS_SIZE_MAX_BYTES,
  IS_PRODUCTION,
  ENV,
} from '../constants';
import { trackAnalyticsEvent } from './analytics';
import { ANALYTICS_EVENTS } from '../lib/analytics';

// eslint-disable-next-line import/no-cycle
import { fetchLocations } from './index';
/*
 * Action Types
 */

export const FETCH_VERIFICATION_FAILURE = 'FETCH_VERIFICATION_FAILURE';
export const FETCH_VERIFICATION_REQUEST = 'FETCH_VERIFICATION_REQUEST';
export const FETCH_VERIFICATION_SUCCESS = 'FETCH_VERIFICATION_SUCCESS';

export const SUBMIT_VERIFICATION_FAILURE = 'SUBMIT_VERIFICATION_FAILURE';
export const SUBMIT_VERIFICATION_REQUEST = 'SUBMIT_VERIFICATION_REQUEST';
export const SUBMIT_VERIFICATION_SUCCESS = 'SUBMIT_VERIFICATION_SUCCESS';

export const UNSUBSCRIBE_VERIFICATION_FAILURE =
  'UNSUBSCRIBE_VERIFICATION_FAILURE';
export const UNSUBSCRIBE_VERIFICATION_REQUEST =
  'UNSUBSCRIBE_VERIFICATION_REQUEST';
export const UNSUBSCRIBE_VERIFICATION_SUCCESS =
  'UNSUBSCRIBE_VERIFICATION_SUCCESS';

const driver_license = require('../fixtures/verifications/driver-license.json');
const driver_license_history = require('../fixtures/verifications/driver-license-history.json');

/*
 * Action Type Helpers
 */

export const fetchVerificationSuccess = verification => ({
  type: FETCH_VERIFICATION_SUCCESS,
  verification,
});

export const submitVerificationFailure = error => ({
  error,
  type: SUBMIT_VERIFICATION_FAILURE,
});

export const submitVerificationRequest = verification => ({
  type: SUBMIT_VERIFICATION_REQUEST,
  verification,
});

export const submitVerificationSuccess = verification => ({
  type: SUBMIT_VERIFICATION_SUCCESS,
  verification,
});

/*
 * Action Helpers
 */

export const combineDocuments = documents => {
  return Object.entries(documents).flatMap(([type, docs]) => {
    const mappedDocs = docs.map(doc => {
      return { ...doc, type };
    });
    return mappedDocs;
  });
};

const verificationPath = (id, method) =>
  getAPIBase({
    path: BACKEND_FLAG_PATHS.VERIFICATIONS_ID,
    method,
  }).then(baseURL => `${baseURL}/verifications/${id}`);

const initializeIntegrations = ({ candidateId, email }) => {
  initializeDDRUM(email, candidateId);
};
/*
 * Actions
 */

export const fetchVerificationRequest = verificationId => ({
  type: FETCH_VERIFICATION_REQUEST,
  id: verificationId,
});

export const fetchVerificationFailure = () => ({
  type: FETCH_VERIFICATION_FAILURE,
});

export const fetchVerification = verificationId => async dispatch => {
  dispatch(fetchVerificationRequest(verificationId));

  const verification_path = await verificationPath(verificationId, 'get');
  try {
    const verifications = await request(verification_path);

    i18n.setLocale({
      value: verifications.locale,
      resources: resources(),
      country: verifications.dataResidencyLocation,
    });
    dispatch(
      setLocale(
        getResourceLocale(
          resources(),
          verifications.locale,
          verifications.dataResidencyLocation,
        ),
      ),
    );

    if (IS_PRODUCTION) {
      initializeIntegrations(verifications);
    }

    if (verifications.object === 'schedule_verification') {
      verifications.ready = {
        ready: false,
      };

      dispatch(fetchVerificationSuccess(verifications));

      return fetchLocations(verificationId)
        .then(locations => {
          if (locations.data.length > 3) {
            locations.data.length = 3;
          }

          verifications.ready = {
            ready: true,
          };

          locations = camelizeKeys(locations);
          verifications.locations = locations.data;

          dispatch(fetchVerificationSuccess(verifications));
        })
        .catch();
    }
    return dispatch(fetchVerificationSuccess(verifications));
  } catch (error) {
    dispatch(fetchVerificationFailure());

    const approvedErrors = /4\d\d/;

    if (!approvedErrors.test(error.status)) {
      Sentry.captureException(error);
    }
    return null;
  }
};

export const fetchMockVerification = verificationType => {
  const verificationFixtures = {
    driver_license,
    driver_license_history,
  };

  const verification = verificationFixtures[verificationType];

  return (v => val => dispatch => {
    if (ENV === 'development') {
      setTimeout(() => {
        dispatch(fetchVerificationSuccess(camelizeKeys(v)));
      }, 10);
    }
  })(verification);
};

export const handleVerificationError = (error, dispatch) => {
  if (!error.response) throw error;
  parseErrorResponse(error).then(({ errors }) => {
    error.message = errors;
    dispatch(submitVerificationFailure(error));
  });
};

export const submitMatrixVerification = data => async (dispatch, getState) => {
  const {
    verifications: { verification },
  } = getState();

  dispatch(submitVerificationRequest(verification));

  const baseURL = await getAPIBase({
    path: BACKEND_FLAG_PATHS.V2_VERIFICATIONS_ID,
  });
  const path = `${baseURL}/v2/verifications/${verification.id}`;

  const options = {
    method: 'POST',
    body: JSON.stringify({ submissions: data }),
  };

  try {
    const json = await request(path, options);
    dispatch(submitVerificationSuccess(json));
    dispatch(
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.EXCEPTION_FLOW_INFORMATION_COMPLETED,
      ),
    );
  } catch (error) {
    handleVerificationError(error, dispatch);
  }
};

export const submitSSNVerificationWithoutDocs =
  data => async (dispatch, getState) => {
    const {
      verifications: { verification },
    } = getState();

    dispatch(submitVerificationRequest(verification));

    const baseURL = await getAPIBase({
      path: BACKEND_FLAG_PATHS.SSN_CHECKS_ID,
    });
    const path = `${baseURL}/ssn_checks/${verification.id}`;

    const options = {
      method: 'PATCH',
      body: JSON.stringify(data),
    };

    try {
      const json = await request(path, options);
      dispatch(submitVerificationSuccess(json));
      dispatch(
        trackAnalyticsEvent(
          ANALYTICS_EVENTS.EXCEPTION_FLOW_INFORMATION_COMPLETED,
        ),
      );
    } catch (error) {
      handleVerificationError(error, dispatch);
    }
  };

const submitVerificationWithoutDocs = data => async (dispatch, getState) => {
  const {
    verifications: { verification },
  } = getState();

  dispatch(submitVerificationRequest(verification));

  const path = await verificationPath(verification.id, 'post');
  const options = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  try {
    const json = await request(path, options);
    dispatch(submitVerificationSuccess(json));
    dispatch(
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.EXCEPTION_FLOW_INFORMATION_COMPLETED,
      ),
    );
  } catch (error) {
    handleVerificationError(error, dispatch);
  }
};

const isFileValid = doc =>
  doc.size >= CANDIDATES_DOCUMENTS_SIZE_MIN_BYTES &&
  doc.size <= CANDIDATES_DOCUMENTS_SIZE_MAX_BYTES;

const hasSomeFileInvalid = data =>
  Object.values(data).some(group => !group.some(isFileValid));

export const submitVerificationDefault = data => async (dispatch, getState) => {
  const {
    verifications: { verification },
  } = getState();

  if (hasSomeFileInvalid(data)) {
    dispatch(
      submitVerificationFailure({
        id: 'components.Verification.DocumentsPanel.invalid_size',
        value: [
          (CANDIDATES_DOCUMENTS_SIZE_MIN_BYTES || 1000) / 1000,
          (CANDIDATES_DOCUMENTS_SIZE_MAX_BYTES || 30000000) / 1000000,
        ],
      }),
    );
    return null;
  }

  dispatch(submitVerificationRequest(verification));

  const postData = { documents: combineDocuments(data) };
  const path = await verificationPath(verification.id, 'post');
  const options = {
    method: 'POST',
    body: JSON.stringify(postData),
  };

  return request(path, options)
    .then(json => {
      dispatch(submitVerificationSuccess(json));
      dispatch(
        trackAnalyticsEvent(
          ANALYTICS_EVENTS.EXCEPTION_FLOW_INFORMATION_COMPLETED,
        ),
      );
    })
    .catch(error => {
      handleVerificationError(error, dispatch);
    });
};

// Overrides "education_proof" to the specific doc type selected by candidate.
// This causes monolith to instantiate the correct subclass of EducationProof.
// Essentially it renames the "education_proof" key to the specific doc type.
//
// Transforms...
// {
//   education_proof__educationDocType: 'diploma',
//   education_proof: [ doc1 ]
// }
// into...
// {
//   diploma: [ doc1 ]
// }
export const submitEducationVerification =
  data => async (dispatch, getState) => {
    // Make a copy of the data so we can mutate it.
    const newData = { ...data };

    // Assume a document was submitted if no_document_available was selected
    const documentSubmitted =
      newData.education_proof__educationDocType !== 'no_document_available';

    if (documentSubmitted) {
      // Move the array of documents to under the key of the specific doc type selected by candidate.
      newData[newData.education_proof__educationDocType] =
        newData.education_proof;
      delete newData.candidate_explanation;
    }

    // These fields are not expected by the server and can be deleted.
    delete newData.education_proof;
    delete newData.education_proof__educationDocType;

    return documentSubmitted
      ? submitVerificationDefault(newData)(dispatch, getState)
      : submitVerificationWithoutDocs(newData)(dispatch, getState);
  };

// Overrides "employment_proof" to the specific doc type selected by candidate.
// This causes monolith to instantiate the correct subclass of EmploymentProof.
// Essentially it renames the "employment_proof" key to the specific doc type.
//
// Transforms...
// {
//   employment_proof__employmentDocType: 'tax_form_w2',
//   employment_proof: [ doc1 ]
// }
// into...
// {
//   tax_form_w2: [ doc1 ]
// }
export const submitEmploymentVerification =
  data => async (dispatch, getState) => {
    const newData = { ...data };

    // Assume a document was submitted if no_document_available was selected
    const documentSubmitted =
      newData.employment_proof__employmentDocType !== 'no_document_available';

    if (documentSubmitted) {
      // Move the array of documents to under the key of the specific doc type selected by candidate.
      newData[newData.employment_proof__employmentDocType] =
        newData.employment_proof;
      delete newData.candidate_explanation;
    }

    // These fields are not expected by the server and can be deleted.
    delete newData.employment_proof;
    delete newData.employment_proof__employmentDocType;

    return documentSubmitted
      ? submitVerificationDefault(newData)(dispatch, getState)
      : submitVerificationWithoutDocs(newData)(dispatch, getState);
  };

export const unsubscribeVerificationRequest = id => ({
  type: UNSUBSCRIBE_VERIFICATION_REQUEST,
  id,
});
export const unsubscribeVerificationSuccess = verification => ({
  type: UNSUBSCRIBE_VERIFICATION_SUCCESS,
  verification,
});
export const unsubscribeVerificationFailure = error => ({
  type: UNSUBSCRIBE_VERIFICATION_FAILURE,
  error,
});

export const unsubscribeVerification = verification => async dispatch => {
  dispatch(unsubscribeVerificationRequest(verification.id));

  const baseURL = await verificationPath(verification.id, 'post');
  const path = `${baseURL}/unsubscribe`;

  const options = {
    method: 'POST',
  };

  try {
    const json = await request(path, options);
    dispatch(unsubscribeVerificationSuccess(json));
  } catch (error) {
    if (!error.response) throw error;
    parseErrorResponse(error).then(({ errors }) => {
      error.message = errors;

      dispatch(unsubscribeVerificationFailure(error));
    });
  }
};
