import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { Verification } from 'api/verifications/types';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { getFieldKey } from 'utils';
import { VERIFICATION_FORM_NAME } from '../../constants';
import DriverLicenseInfo from './Fields/DriverLicenseInfo';

export interface DrivingExperienceProps {
  verification: Verification;
}

type Props = DrivingExperienceProps &
  InjectedFormProps<{}, DrivingExperienceProps>;

const DrivingExperience = ({ verification }: Props) => {
  const field = verification.fieldConfigurations[0];

  return (
    <>
      <h1>{verification.company}</h1>
      <M.Container>
        <T
          value='components.Report.Verification.MatrixVerification.DrivingExperience.heading'
          tag='h2'
        />
        <hr />
        <M.Grid className='form-group'>
          <M.Container.Row>
            <T
              value='components.Report.Verification.MatrixVerification.DrivingExperience.formHeading'
              tag='h3'
            />
          </M.Container.Row>
          <M.Container.Row>
            <T
              value='components.Report.Verification.MatrixVerification.DrivingExperience.formDescription'
              tag='p'
            />
          </M.Container.Row>
          <M.Container.Row>
            <DriverLicenseInfo fieldKey={getFieldKey(field)} />
          </M.Container.Row>
        </M.Grid>
      </M.Container>
    </>
  );
};

export default reduxForm<{}, DrivingExperienceProps>({
  form: VERIFICATION_FORM_NAME,
})(DrivingExperience);
