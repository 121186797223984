import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import MainContainer from '../../MainContainer';
import CandidatePasswordlessLogin from './LoginForm/CandidatePasswordlessLogin';
import CandidateEmailConfirmation from './CandidateEmailConfirmation';

import {
  checkForActiveAuthSession,
  submitCandidateEmail,
  submitCandidateLinkLogin,
  trackAnalyticsEvent,
} from '../../../actions';

import '../../../scss/CandidatePasswordlessLogin.scss';
import { PATHS } from '../../../constants';
import useLocationQuery from '../../../utils/useLocationQuery';
import { ANALYTICS_EVENTS } from '../../../lib/analytics';

export const EmailLoginContainer = ({
  submitCandidateLogin,
  checkAuthSession,
  submitEmail,
  candidateLogin,
}) => {
  const { candidateEmailSubmitted, checkedAuth, processing, submitError } =
    candidateLogin;
  const queryParams = useLocationQuery();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.CANDIDATE_PORTAL_LOGIN_PAGE_VIEWED,
        {},
      ),
    );
  }, [dispatch]);

  React.useEffect(() => {
    const verificationCode = queryParams?.verification_code || null;
    const email = queryParams?.email || null;

    if (checkedAuth) {
      if (verificationCode && email && !submitError && !processing) {
        submitCandidateLogin({
          verificationCode,
          email,
        });
      }
    } else {
      checkAuthSession();
    }
  }, [
    queryParams,
    checkedAuth,
    processing,
    submitError,
    checkAuthSession,
    submitCandidateLogin,
  ]);

  return (
    <MainContainer data-testid='email-login'>
      {candidateEmailSubmitted ? (
        <CandidateEmailConfirmation resetPath={PATHS.EMAIL_AUTH} />
      ) : (
        <CandidatePasswordlessLogin onSubmit={submitEmail} />
      )}
    </MainContainer>
  );
};

EmailLoginContainer.propTypes = {
  submitEmail: PropTypes.func.isRequired,
  submitCandidateLogin: PropTypes.func.isRequired,
  checkAuthSession: PropTypes.func.isRequired,
  candidateLogin: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  candidateLogin: state.candidateLogin,
  portal: state.portal,
});

const mapDispatchToProps = dispatch => ({
  submitEmail: values => {
    dispatch(submitCandidateEmail(values));
  },
  submitCandidateLogin: values => dispatch(submitCandidateLinkLogin(values)),
  checkAuthSession: email => dispatch(checkForActiveAuthSession(email)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailLoginContainer);
