import checkrAnalytics, {
  getEventPropertiesFromStore,
  getUserPropertiesFromStore,
} from '../lib/analytics';
import { AMPLITUDE_ENABLED } from '../constants';

export const trackAnalyticsEvent =
  (eventTitle, eventProperties = {}) =>
  (_dispatch, getState) => {
    if (!AMPLITUDE_ENABLED) {
      // eslint-disable-next-line no-console
      return console.log('Amplitude is disabled');
    }

    const eventPropertiesFromStore = getEventPropertiesFromStore(
      eventTitle,
      getState(),
    );
    try {
      return checkrAnalytics.track(
        {},
        eventTitle,
        {
          ...eventPropertiesFromStore,
          ...eventProperties,
        },
        getUserPropertiesFromStore(eventTitle, getState()),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      return console.log('Analytics disabled due to cookies disabled.');
    }
  };

export default { trackAnalyticsEvent };

export const CHANGE_REPORT_INDEX = 'CHANGE_REPORT_INDEX';
export const changeReportIndex = newReportIndex => dispatch =>
  dispatch({
    newReportIndex,
    type: CHANGE_REPORT_INDEX,
  });
