import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

import DisputeSelect from '../../../fields/DisputeSelect';

import {
  allSearchRecords,
  formatSearchTitle,
  getChargeTitle,
  getChargeDate,
} from '../lib/screenings';

const ChargesByRecordForm = ({ search, trackAction }) => (
  <div className='mb-4'>
    <div className='mb-2'>
      <b data-testid={DISPUTES_FLOW.disputeChargeSearchTitle}>
        {formatSearchTitle(search)}
      </b>
    </div>
    <div>
      {allSearchRecords(search).map(record => (
        <Field
          name='charge'
          key={record.id}
          id={record.id}
          component={DisputeSelect}
          displayValue={getChargeTitle(record)}
          className='tile-option-select p-4 mb-2 rounded'
          date={getChargeDate(record)}
          selectValue={record}
          trackAction={trackAction}
        />
      ))}
    </div>
  </div>
);

ChargesByRecordForm.propTypes = {
  search: PropTypes.object.isRequired,
  trackAction: PropTypes.func.isRequired,
};

export default ChargesByRecordForm;
