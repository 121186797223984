import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { Report } from 'types/report';
import { Translate as T } from 'react-redux-i18n';
import CandidateStoryModal from './CandidateStoryModal';
import { appBreakpoints } from '../../../../reducers';

export interface CandidateStoryWorkflowCardProps {
  candidateStoriesGeneralInfoOnly: boolean;
  candidateStorySubmitted: boolean;
  isOpen: boolean;
  report: Report;
  toggleCandidateStoryModal: () => void;
  stories: string[];
  submitError: boolean;
  toggleDisputeModal: () => void;
}

const CardStyled = styled(M.Container)`
  padding: 39px 23px !important;
`;

export const ContainerStyled = styled.div`
  font-size: 14px;
  padding: 1rem 0 0 0;

  @media (min-width: ${appBreakpoints.small}px) {
    padding: 0 18px;
  }

  button {
    display: block;
    margin-bottom: 16px !important;
  }

  h5 {
    font-size: 16px;
  }

  section {
    padding: 16px 0;

    h3 {
      margin-bottom: 4px !important;
    }

    ul {
      margin: 0;
      padding: 0 1rem;
    }
  }
`;

export const IconStyled = styled(M.Icon)`
  @media (min-width: ${appBreakpoints.small}px) {
    height: 54px !important;
    width: 54px !important;
  }
`;

interface CardProps {
  companyName: string;
  onShareMoreInfoClick: () => void;
}

const Card = ({ companyName, onShareMoreInfoClick }: CardProps) => {
  return (
    <CardStyled data-testid='candidate-story-cta-container'>
      <M.Grid>
        <M.Container.Row>
          <M.Container.Col lg={1} sm={12}>
            <IconStyled icon='Chat' size='28' />
          </M.Container.Col>
          <M.Container.Col>
            <ContainerStyled>
              <T
                value='components.Report.CandidateStories.NewCTA.title'
                tag='h5'
                companyName={companyName}
              />
              <T value='components.Report.CandidateStories.NewCTA.subtitle' />
              <section>
                <T
                  value='components.Report.CandidateStories.NewCTA.example'
                  tag='h3'
                />
                <ul>
                  <li>
                    <T
                      value='components.Report.CandidateStories.NewCTA.example1context'
                      tag='strong'
                    />{' '}
                    <T value='components.Report.CandidateStories.NewCTA.example1explanation' />
                  </li>
                  <li>
                    <T
                      value='components.Report.CandidateStories.NewCTA.example2context'
                      tag='strong'
                    />{' '}
                    <T value='components.Report.CandidateStories.NewCTA.example2explanation' />
                  </li>
                </ul>
              </section>
              <M.Button
                onClick={onShareMoreInfoClick}
                data-testid='candidate-stories-share-more-info-btn'
              >
                <T value='components.Report.CandidateStories.NewCTA.shareMoreInfo' />
              </M.Button>
              <T
                value='components.Report.CandidateStories.NewCTA.helpCenterLink'
                dangerousHTML
              />
            </ContainerStyled>
          </M.Container.Col>
        </M.Container.Row>
      </M.Grid>
    </CardStyled>
  );
};

const CandidateStoryWorkflowCard = ({
  candidateStoriesGeneralInfoOnly,
  candidateStorySubmitted,
  isOpen,
  report,
  stories,
  submitError,
  toggleCandidateStoryModal,
  toggleDisputeModal,
}: CandidateStoryWorkflowCardProps) => {
  return (
    <>
      {!stories.length && (
        <Card
          companyName={report.account.name}
          onShareMoreInfoClick={toggleCandidateStoryModal}
        />
      )}

      <CandidateStoryModal
        companyName={report.account.name}
        isOpen={isOpen}
        toggleCandidateStoryModal={toggleCandidateStoryModal}
        report={report}
        candidateStorySubmitted={candidateStorySubmitted}
        submitError={submitError}
        toggleDisputeModal={toggleDisputeModal}
        generalInformationOnly={candidateStoriesGeneralInfoOnly}
      />
    </>
  );
};

CandidateStoryWorkflowCard.propTypes = {
  candidateStoriesGeneralInfoOnly: PropTypes.bool.isRequired,
  candidateStorySubmitted: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  report: PropTypes.object.isRequired,
  toggleCandidateStoryModal: PropTypes.func.isRequired,
  stories: PropTypes.array.isRequired,
  submitError: PropTypes.bool.isRequired,
  toggleDisputeModal: PropTypes.func.isRequired,
};

export default CandidateStoryWorkflowCard;
