import httpHelper from './reducerHelpers';

import {
  saveOnceToSessionStorage,
  clearFromSessionStorage,
} from '../lib/helpers';

import {
  FETCH_REPORTS_REQUEST,
  FETCH_REPORTS_FAILURE,
  FETCH_REPORTS_SUCCESS,
} from '../actions/reports';

import {
  PII_DELETION_REQUEST,
  PII_DELETION_SUCCESS,
  PII_DELETION_FAILURE,
} from '../actions/privacy';

import {
  SESSION_STORAGE_CANDIDATE_IDS_KEY,
  SESSION_STORAGE_CONTACT_EMAIL_KEY,
  SESSION_STORAGE_CONTACT_NAME_KEY,
} from '../constants';

export const initialState = {
  disclosureRequest: { ...httpHelper.initialState },
  deletionRequest: { ...httpHelper.initialState },
  candidate_ids: undefined,
  contact_email: undefined,
  contact_name: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REPORTS_REQUEST:
      saveOnceToSessionStorage(
        SESSION_STORAGE_CONTACT_EMAIL_KEY,
        action.search.email,
      );

      return {
        ...state,
        contact_email: action.search.email,
      };
    case FETCH_REPORTS_FAILURE:
      clearFromSessionStorage(SESSION_STORAGE_CONTACT_EMAIL_KEY);

      return {
        ...state,
        contact_email: undefined,
      };
    case FETCH_REPORTS_SUCCESS:
      // If we have a token saved instead of a fresh request, monolith returns []
      // This preserves the IDs on the initial success where we don't use a token
      saveOnceToSessionStorage(
        SESSION_STORAGE_CANDIDATE_IDS_KEY,
        action.privacyCandidateIds,
      );
      saveOnceToSessionStorage(
        SESSION_STORAGE_CONTACT_NAME_KEY,
        action.privacyCandidateName,
      );

      return {
        ...state,
        candidate_ids: action.privacyCandidateIds,
        contact_name: action.privacyCandidateName,
      };
    case PII_DELETION_REQUEST:
      return {
        ...state,
        deletionRequest: { ...httpHelper.onRequest },
      };
    case PII_DELETION_SUCCESS:
      return {
        ...state,
        deletionRequest: { ...httpHelper.onSuccess },
      };
    case PII_DELETION_FAILURE:
      return {
        ...state,
        deletionRequest: { ...httpHelper.onFailure },
      };
    default:
      return state;
  }
};
