import React, { useState } from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { uuid4 } from '@sentry/utils';
import './SensitiveTextInput.scss';
import styled from 'styled-components';

const ToolTipWrapper = styled.span`
  .mastodon-tooltip-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    &.tooltip-highlighted.cds--tooltip__label {
      border-bottom: 0;
    }
  }
`;

const ButtonWrapper = styled.span`
  .cds--btn--primary {
    background-color: rgba(0, 0, 0, 0);
  }
  position: absolute !important;
  right: 0;
  top: 15px;
  width: 34px;
  height: 40px;
  z-index: 2;
  padding: 5px 0 0 0 !important;
`;

// Based on the 'components/fields/SensitiveInput.js'

interface SensitiveTextInputInputType {
  name?: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  value: string;
}

interface SensitiveInputTooltipType {
  show: string;
  hide: string;
}

export interface SensitiveTextInputProps {
  id?: string;
  input: SensitiveTextInputInputType;
  placeholder?: string;
  labelControl?: React.ReactNode;
  autoFocus?: boolean;
  disabled?: boolean;
  onPaste?: (e: any) => void;
  testId?: string;
  mask?: RegExp;
  maskChar?: string;
  tooltip?: SensitiveInputTooltipType;
  i18n?: object;
  meta: { touched: boolean; error: string; valid: boolean };
  t?: object;
  tReady?: object;
}

const masked = (value: string, mask: RegExp, maskChar: string) =>
  value.replace(mask, maskChar);

const SensitiveTextInput: React.FC<SensitiveTextInputProps> = ({
  id = uuid4(),
  input = {
    name: uuid4(),
    onChange: (e: any) => {},
    onBlur: (e: any) => {},
    value: '',
  },
  autoFocus = false,
  disabled = false,
  onPaste = (e: any) => {},
  placeholder = '',
  labelControl = '',
  testId = '',
  mask = /./g,
  maskChar = '•',
  tooltip = {
    show: i18n.getStr('components.SensitiveTextInput.tooltip.show'),
    hide: i18n.getStr('components.SensitiveTextInput.tooltip.hide'),
  },
  meta,
  i18n: _i18n,
  t: _t,
  tReady: _tReady,
  ...props
}: SensitiveTextInputProps) => {
  const [shouldMaskValue, setShouldMaskValue] = useState(true);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const valueChangeHandler = (e: any) => {
    e.preventDefault();
    const originalValue = input.value;
    const updatedValue = e.target.value;

    if (!shouldMaskValue) {
      return input.onChange(updatedValue);
    }

    // If they select all and then start typing to overwrite the field
    if (updatedValue.length === 1 && updatedValue.charAt(0) !== maskChar) {
      return input.onChange(updatedValue);
    }

    // Clear the field if they remove a character, since we can't tell which character was removed
    if (updatedValue.length < originalValue.length) {
      return input.onChange('');
    }

    // Nothing changed
    if (updatedValue.length === originalValue.length) {
      return input.onChange(originalValue);
    }

    // Figure out which character changed, and splice it into the real value
    const maskedValue = masked(originalValue, mask, maskChar);
    for (let i = 0; i < updatedValue.length; i += 1) {
      if (updatedValue.charAt(i) !== maskedValue.charAt(i)) {
        return input.onChange(
          originalValue.slice(0, i) +
            updatedValue.charAt(i) +
            originalValue.slice(i),
        );
      }
    }

    return input.onChange(originalValue);
  };
  const handlePaste = (e: any) => {
    e.preventDefault();
    const { clipboardData } = e;

    if (clipboardData) {
      input.onChange(clipboardData.getData('text'));
    }
  };
  const handleBlur = (e: any) => {
    e.preventDefault();
    input.onBlur(input.value);
  };
  const { value } = input;

  const invalid = meta.touched && !meta.valid;

  const toggleMask = (e: any) => {
    e.preventDefault();
    setShouldMaskValue(!shouldMaskValue);
  };

  return (
    <div style={{ width: '90%' }} className='sensitive-text-input-wrapper'>
      <M.TextInput
        id={id}
        {...input}
        {...props}
        labelText={labelControl}
        autoFocus={autoFocus}
        disabled={disabled}
        onPaste={handlePaste}
        onBlur={handleBlur}
        placeholder={i18n.getStr(placeholder || labelControl)}
        onChange={valueChangeHandler}
        value={shouldMaskValue ? masked(value, mask, maskChar) : value}
        invalid={invalid}
        invalidText={i18n.getStr(meta.error)}
        data-testid={testId}
      />
      <ButtonWrapper style={{ right: '28px' }}>
        <M.Icon
          style={{
            fill: invalid ? '#CD2F1D' : 'rgba(0, 0, 0, 0)',
            height: '20px',
            width: '20px',
            marginTop: '6px',
          }}
          icon='WarningFilled'
        />
      </ButtonWrapper>
      <ButtonWrapper>
        <M.Button
          kind='button'
          // className='sensitive-text-input-hide-button'
          onClick={toggleMask}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          onFocus={() => setShowTooltip(true)}
          onBlur={() => setShowTooltip(false)}
          style={{
            padding: '0px',
          }}
        >
          <ToolTipWrapper>
            <M.Tooltip
              label={shouldMaskValue ? tooltip.show : tooltip.hide}
              align='bottom'
              open={showTooltip}
              className='sensitive-text-input-hide-button-tooltip'
              tabIndex={-1}
            >
              <M.Icon
                style={{ fill: '#1A2026A6', margin: '0 auto' }}
                icon={shouldMaskValue ? 'ViewOffFilled' : 'ViewFilled'}
              />
            </M.Tooltip>
          </ToolTipWrapper>
        </M.Button>
      </ButtonWrapper>
    </div>
  );
};

export default i18n.renderTranslation()(SensitiveTextInput);
