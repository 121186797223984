import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const DownloadPDFNote = props => {
  const { encryption } = props;

  if (!encryption) {
    return null;
  }

  return (
    <div className='download-pdf-note'>
      <small>
        <T value='components.Report.DownloadPDFNote.Encryption' />
      </small>
    </div>
  );
};

DownloadPDFNote.propTypes = {
  encryption: PropTypes.bool.isRequired,
};

export default DownloadPDFNote;
