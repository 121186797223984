import * as ActionTypes from '../actions';
import classify from '../lib/classify';

export const initialState = {
  verification: null,
  errors: null,
  fetching: false,
  processing: false,
  submitting: false,
  steps: [],
};

const IdDocumentVerificationTypes = [
  'IdVerification',
  'IdDocumentVerification',
  'LicenseDocumentVerification',
  'PassportDocumentVerification',
  'PreviousLicenseDocumentVerification',
];

const handleIdDocumentVerificationSteps = (verification, push) => {
  const DOCUMENT_LIST = [
    {
      flag: 'idRequired',
      name: 'stateIdCard',
      model: 'state_id_card',
      input: 'upload',
    },
    {
      flag: 'dlRequired',
      name: 'driverLicense',
      model: 'driver_license',
      input: 'upload',
    },
    {
      flag: 'passportRequired',
      name: 'passport',
      model: 'passport',
      input: 'upload',
    },
    {
      flag: 'previousDlRequired',
      name: 'previousDriverLicense',
      model: 'previous_driver_license',
      input: 'upload',
    },
    {
      flag: 'ssnRequired',
      name: 'ssnCard',
      model: 'ssn_card',
      input: 'upload',
    },
  ];

  if (
    verification.openReportVerifications &&
    verification.openReportVerifications.length > 0
  ) {
    verification.openReportVerifications.forEach(v => {
      DOCUMENT_LIST.forEach(doc => {
        v[doc.flag] && push(doc);
      });
    });
  } else {
    DOCUMENT_LIST.forEach(doc => {
      verification[doc.flag] && push(doc);
    });
  }
};

const stepNormalizers = {
  EducationVerification: (verification, push) => {
    verification.records.forEach(({ school }) =>
      push({
        model: 'education_proof',
        input: 'upload',
        school,
      }),
    );
  },
  CandidateEmploymentHistoryVerification: (verification, push) => {
    verification.records.forEach(({ employer }) =>
      push({
        model: 'employment_proof',
        input: 'upload',
        employer,
      }),
    );
  },
  PhotoVerification: (verification, push) => {
    push({
      model: 'selfie',
      input: 'upload',
      name: 'photo',
    });
  },
  DrugScheduleVerification: (verification, push) => {
    push({
      model: 'location',
      input: 'locationSelector',
      name: 'locationForm',
    });
  },
  DriverLicenseVerification: (verification, push) => {
    push({
      model: 'driver_license',
      input: 'upload',
      name: 'driverLicense',
    });
  },
  DriverLicenseHistoryVerification: (verification, push) => {
    push({
      model: 'driver_license_history',
      input: 'upload',
      name: 'driverLicenseHistory',
    });
  },
  SsnDocumentVerification: (verification, push) => {
    push({
      model: 'ssn_card',
      name: 'ssnCard',
      input: 'upload',
    });
  },
  ScheduleVerification: (verification, push) => {
    push({
      model: 'location',
      input: 'locationSelector',
      name: 'locationForm',
    });
  },
  SsnCheck: (verification, push) => {
    push({
      model: 'ssn_confirmation',
      input: 'ssn',
    });
  },
  SsnCheckFailure: (verification, push) => {
    push({
      model: 'ssn_check_failure',
    });
  },
  MatrixVerification: (verification, push) => {
    push({
      model: 'matrix_verification',
    });
  },
};

const getVerificationSteps = ({ object, ...verification }) => {
  let normalizer;

  if (IdDocumentVerificationTypes.includes(classify(object))) {
    normalizer = handleIdDocumentVerificationSteps;
  } else {
    normalizer = stepNormalizers[classify(object)];
  }

  const steps = [{}];

  normalizer(verification, steps.push.bind(steps));
  const uniqueSteps = [...new Set(steps)];

  return uniqueSteps;
};

const verifications = (state = initialState, action) => {
  const { verification, type } = action;

  switch (type) {
    case ActionTypes.FETCH_VERIFICATION_REQUEST:
      return {
        ...state,
        fetching: true,
      };
    case ActionTypes.FETCH_VERIFICATION_SUCCESS:
      return {
        ...state,
        verification,
        errors: null,
        fetching: false,
        steps: getVerificationSteps(verification),
        expired: !verification.completionAllowed,
      };
    case ActionTypes.SUBMIT_VERIFICATION_REQUEST:
      return {
        ...state,
        errors: null,
        submitting: true,
      };
    case ActionTypes.SUBMIT_VERIFICATION_SUCCESS:
      return {
        ...state,
        errors: null,
        verification,
        submitting: false,
      };
    case ActionTypes.SUBMIT_VERIFICATION_FAILURE:
      return {
        ...state,
        errors: action.error,
        submitting: false,
      };
    case ActionTypes.FETCH_VERIFICATION_FAILURE:
      return {
        ...state,
        errors: {},
        verification: null,
        fetching: false,
      };
    case ActionTypes.UNSUBSCRIBE_VERIFICATION_SUCCESS:
      return {
        ...state,
        verification,
      };
    case ActionTypes.DRUG_SCHEDULE_LOCATION_UPDATED:
      return {
        ...state,
        verification,
        processing: false,
      };
    case ActionTypes.DRUG_SCHEDULE_VERIFICATION_READY:
      return {
        ...state,
        verification,
      };
    case ActionTypes.DRUG_SCHEDULE_LOCATION_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case ActionTypes.DRUG_SCHEDULE_SCHEDULE_REQUEST:
      return {
        ...state,
        submitting: true,
      };
    case ActionTypes.DRUG_SCHEDULE_SCHEDULE_SUCCESS:
      return {
        ...state,
        verification,
        submitting: false,
      };
    case ActionTypes.PROCESSING:
      return {
        ...state,
        processing: true,
      };
    case ActionTypes.UPDATE_LOCATIONS_REQUEST:
      return {
        ...state,
        verification,
        processing: true,
      };
    case ActionTypes.UPDATE_LOCATIONS_SUCCESS:
      return {
        ...state,
        verification,
        processing: false,
      };
    default:
      return state;
  }
};

export default verifications;
