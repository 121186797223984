import React, { ReactNode } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { StatusTypes } from '@dashboard-experience/utils';
import { i18n as mastodonI18n } from '@international/mastodon-i18n';
import styled from 'styled-components';

const CardHeaderWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface PanelProps {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  i18n?: boolean;
  icon?: string;
  noBlock?: boolean;
  status?: string;
  timestamp?: string;
  title?: string | ReactNode;
  tooltip?: string | ReactNode;
}

const PanelComponent: React.FC<PanelProps> = ({
  children,
  className = '',
  dataTestId,
  i18n = false,
  icon = '',
  noBlock = false,
  status = '',
  timestamp = '',
  title = '',
  tooltip = '',
}) => {
  const statusSubmitted = status === 'submitted';

  return (
    <div
      data-testid={dataTestId}
      className={`no-pagebreak card clearfix ${
        status ? `card-${status}` : ''
      } ${className}`}
    >
      {title && (
        <div className='card-header'>
          <CardHeaderWrapperStyled>
            <div>
              {icon && (
                <>
                  <M.Icon style={{ marginRight: 0 }} icon={icon} />{' '}
                </>
              )}
              {i18n && typeof title === 'string' ? (
                <T value={title} dangerousHTML />
              ) : (
                title
              )}
              {tooltip && (
                <span>
                  <M.Tooltip align='top' label={tooltip}>
                    <M.Icon icon='InformationFilled' />
                  </M.Tooltip>
                </span>
              )}
              {timestamp && <small className='timestamp'>{timestamp}</small>}
            </div>
            {status && !statusSubmitted && (
              <M.Tooltip
                label={mastodonI18n.getStr(
                  `components.Report.BadgeStatusExplanation.${status}`,
                )}
              >
                <M.StatusBadge
                  data-testid='status-badge'
                  className='text-capitalize'
                  statusType={StatusTypes.Legacy}
                  label={mastodonI18n.getStr(`badges.${status}`)}
                  status={status}
                />
              </M.Tooltip>
            )}
            {status && statusSubmitted && (
              <M.StatusBadge
                data-testid='status-badge'
                className='text-capitalize'
                statusType={StatusTypes.Legacy}
                label='Submitted'
                status='complete'
              />
            )}
          </CardHeaderWrapperStyled>
        </div>
      )}
      {noBlock || !children || React.Children.count(children) === 0 ? (
        children
      ) : (
        <div className='card-body'>{children}</div>
      )}
    </div>
  );
};

export default PanelComponent;
