/* istanbul ignore file */
import 'core-js/stable';
import 'isomorphic-fetch';
import 'regenerator-runtime/runtime';

import './scss/main.scss';
import 'url-polyfill';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { RouterProvider as Router } from 'react-router-dom';

import Providers from './providers';
import initializeTranslations from './initializeTranslations';
import initializeSentry from './sentry';
import initializeDataDog from './initializeDD';
import clientRoutes from './routes/clientRoutes';

initializeSentry();
initializeDataDog();
initializeTranslations();

const rootContainer = document.getElementById('root');

if (rootContainer?.hasChildNodes()) {
  hydrate(
    <Providers>
      <Router router={clientRoutes} />
    </Providers>,
    rootContainer,
  );
} else {
  render(
    <Providers>
      <Router router={clientRoutes} />
    </Providers>,
    rootContainer,
  );
}
