import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { logoutCandidate } from 'actions';
import portalStore from 'providers/portalStore';

export class CheckrApiClient {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create();
    this.instance.interceptors.response.use(
      res => res,
      this.responseErrorHandler,
    );
  }

  responseErrorHandler(error: AxiosError) {
    if (error.response?.status === 401) {
      portalStore.dispatch(logoutCandidate());
    }
    return Promise.reject(error);
  }

  get<T>(url: string, params: AxiosRequestConfig = {}) {
    return this.instance.get<T>(url, params);
  }

  patch(url: string, data: any = {}) {
    return this.instance.patch(url, data);
  }

  post<T>(url: string, data: any, config?: object) {
    return this.instance.post<T>(url, data, config);
  }

  setAuthToken(token: string) {
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export default new CheckrApiClient();
