import React, { Component } from 'react';
import { I18n, Translate as T } from 'react-redux-i18n';
import {
  change,
  Field,
  formValueSelector,
  getFormValues,
  reduxForm,
  untouch,
} from 'redux-form';
import { M } from '@dashboard-experience/mastodon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import DOMPurify from 'isomorphic-dompurify';
import { merge } from '../../lib/helpers';
import { Select, SensitiveTextInput, TextInput } from '../fields/mastodon';
import { Checkbox, Input } from '../fields';
import * as V from '../../lib/validations';
import * as N from '../../lib/normalizations';
import '../../scss/MatrixVerificationForm.scss';
import {
  MAX_ALLOWED_UPLOAD_DOCS_QTY,
  STANDARD_ALLOWED_UPLOAD_FILETYPES,
  VERIFICATION_FORM_NAME,
} from '../../constants';
import MatrixDocumentUpload from './MatrixDocumentUpload';
import MatrixAddress from './MatrixAddress';
import { getFieldKey } from '../../utils';
import DatePicker from '../fields/mastodon/DatePicker';
import {
  bestAvailableDisplayText,
  bestAvailableDisplayTextForMvrConsent,
} from './helpers';

const PERSONAL_DETAILS = ['short_text', 'drop_down', 'address'];

const byType = (a, b) => {
  if (a.type < b.type) {
    return 1;
  }
  if (a.type > b.type) {
    return -1;
  }
  return 0;
};

class MatrixVerificationForm extends Component {
  renderField = (field, mvrConsent = false) => {
    switch (field.type) {
      case 'short_text':
        return this.renderTextField(field);
      case 'ssn':
        return this.renderSsnConfirmationField(field);
      case 'html_text':
        return this.renderHTMLField(field);
      case 'consent_collection':
        return this.renderConsent(field);
      case 'document':
        return this.renderDocumentUpload(field, {}, mvrConsent);
      case 'multi_documents':
        return this.renderDocumentUpload(field, {
          isMultiDocumentUpload: true,
        });
      case 'document_download_upload':
        return this.renderDocumentUpload(field, {
          hasDownloadFile: true,
          isMultiDocumentUpload: true,
        });
      case 'drop_down':
        return this.renderDropDownField(field);
      case 'address':
        return (
          <MatrixAddress
            label={bestAvailableDisplayText(field, 'displayTitle')}
            field={field}
            setField={this.setField}
            isInternational={Boolean(field.displayCommentInCandidatePortal)}
          />
        );
      case 'date':
        return this.renderDateField(field);
      default:
        return null;
    }
  };

  renderDropDownField = field => (
    <Field
      type='text'
      name={getFieldKey(field)}
      label={bestAvailableDisplayText(field, 'displayTitle')}
      component={Select}
      context={getFieldKey(field)}
      validate={this.getValidations(field)}
      options={[
        {
          value: '',
          name: I18n.t(
            'components.Report.Verification.MatrixVerification.selectValue',
          ),
        },
      ].concat(
        field.options.map(option => ({
          value: option,
          name: option,
        })),
      )}
    />
  );

  renderTextField = field => (
    <M.Grid className='form-group'>
      <M.Container.Row>
        <M.Container.Col className='form-group-col' sm={4} md={4} lg={8}>
          <Field
            className='matrix-label-style'
            type='text'
            testId={getFieldKey(field)}
            name={getFieldKey(field)}
            component={TextInput}
            context={getFieldKey(field)}
            validate={this.getValidations(field)}
            placeholder={bestAvailableDisplayText(field, 'placeholder')}
            helperText={field.displayCommentInCandidatePortal && field.comment}
            labelControl={bestAvailableDisplayText(field, 'displayTitle')}
          />
        </M.Container.Col>
      </M.Container.Row>
    </M.Grid>
  );

  renderDateField = field => (
    <M.Grid>
      <M.Container.Row>
        <M.Container.Col
          className='form-group-col matrix-label-style'
          sm={4}
          md={4}
          lg={8}
        >
          <Field
            type='text'
            testId={getFieldKey(field)}
            name={getFieldKey(field)}
            datePickerType='single'
            component={DatePicker}
            validate={[V.required, V.datePickerValidation]}
            placeholder={bestAvailableDisplayText(field, 'placeholder')}
            labelControl={bestAvailableDisplayText(field, 'displayTitle')}
          />
        </M.Container.Col>
      </M.Container.Row>
    </M.Grid>
  );

  onNoSsnUpdate = ({ target: { checked } }, fieldKey) => {
    const fieldKeys = [`${fieldKey}.ssn`, `${fieldKey}.ssnConfirmation`];
    const { untouchField } = this.props;
    if (checked) {
      fieldKeys.forEach(key => {
        this.setField(key);
        untouchField(VERIFICATION_FORM_NAME, key);
      });
    }
  };

  renderConsentContent = field => {
    const { company } = this.props;

    return (
      <div className='well well-sm'>
        <T
          value={`components.Report.Verification.MatrixVerification.${field.name}.contentTitle`}
          dangerousHTML
        />

        <p>
          <T
            data-testid='consentDynamicContent'
            value={`components.Report.Verification.MatrixVerification.${field.name}.content`}
            company={company}
          />
        </p>
      </div>
    );
  };

  renderSsnConfirmationField = field => {
    const { canDeclareNoSsn, comment, displayCommentInCandidatePortal, name } =
      field;
    const { noSsn } = this.props;

    return (
      <M.Grid className='form-group'>
        {displayCommentInCandidatePortal && (
          <M.GridRow className='form-content form-comment'>{comment}</M.GridRow>
        )}
        <M.Container.Row>
          <M.Container.Col className='form-group-col' sm={4} md={4} lg={8}>
            <Field
              className='matrix-label-style'
              type='input'
              testId='ssn'
              name={`${getFieldKey(field)}.ssn`}
              placeholder={bestAvailableDisplayText(field, 'placeholder')}
              component={SensitiveTextInput}
              normalize={N[name]}
              validate={this.getValidations(field)}
              onPaste={e => e.preventDefault()}
              disabled={noSsn}
              labelControl={bestAvailableDisplayText(field, 'displayTitle')}
            />
          </M.Container.Col>
          <M.Container.Col sm={4} md={4} lg={8}>
            <Field
              type='input'
              testId='ssnConfirmation'
              className='ssnConfirmation matrix-label-style'
              name={`${getFieldKey(field)}.ssnConfirmation`}
              placeholder={bestAvailableDisplayText(field, 'placeholder')}
              component={SensitiveTextInput}
              normalize={N[name]}
              validate={merge(this.getValidations(field), [
                V.confirmationMatch,
              ])}
              onPaste={e => e.preventDefault()}
              disabled={noSsn}
              labelControl={bestAvailableDisplayText(
                field,
                'confirmationTitle',
              )}
            />
          </M.Container.Col>
        </M.Container.Row>
        {canDeclareNoSsn && (
          <M.Container.Row>
            <Field
              type='checkbox'
              testId={getFieldKey(field)}
              name={`${getFieldKey(field)}.noSsn`}
              label='labels.noSsn'
              className='inline-checkbox top-inline bottom-inline noSsn'
              onChange={e => this.onNoSsnUpdate(e, getFieldKey(field))}
              component={Checkbox}
              i18n
            />
          </M.Container.Row>
        )}
      </M.Grid>
    );
  };

  renderConsent = field => (
    <div data-testid={`consent-field-${field.name}`} className='html-field'>
      <h2>{bestAvailableDisplayText(field, 'header')}</h2>
      <hr />
      {field.content ? (
        <div
          className='well well-sm'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(field.content),
          }}
        />
      ) : (
        this.renderConsentContent(field)
      )}
      <M.Container.Row>
        <h3>
          {I18n.t(
            'components.Report.Verification.MatrixVerification.nameAuthorization',
          )}
        </h3>
      </M.Container.Row>
      <M.Container.Row>
        <M.Container.Col>
          <Field
            type='text'
            testId={getFieldKey(field)}
            name={getFieldKey(field)}
            label={I18n.t(
              'components.Report.Verification.MatrixVerification.fullName',
            )}
            component={Input}
            context={getFieldKey(field)}
            validate={[V.required, V.nameMatches]}
            placeholder={I18n.t(
              'components.Report.Verification.MatrixVerification.fullName',
            )}
          />
        </M.Container.Col>
      </M.Container.Row>
    </div>
  );

  renderHTMLField = field => (
    <div className='html-field'>
      <div
        className='well well-sm'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(field.content) }}
      />
      {this.renderConfirmation(field)}
    </div>
  );

  renderDocumentUpload = (field, uploadOptions = {}, mvrConsent = false) => {
    const { formValues, array } = this.props;
    const { hasDownloadFile, isMultiDocumentUpload } = uploadOptions;
    const key = getFieldKey(field);
    const options = isMultiDocumentUpload
      ? this.multiUploadPropsOptions(field)
      : { maxFiles: 1 };

    return (
      <>
        <M.GridRow>
          <M.GridCol lg={16}>
            <h2>
              <T
                value={
                  hasDownloadFile
                    ? 'components.Report.Verification.MatrixVerification.documents.download_upload'
                    : 'components.Report.Verification.MatrixVerification.documents.upload'
                }
              />
            </h2>
          </M.GridCol>
          <M.GridCol lg={16}>
            <hr />
          </M.GridCol>
        </M.GridRow>
        <br />
        <M.GridRow>
          <M.GridCol lg={16}>
            <MatrixDocumentUpload
              label={
                mvrConsent
                  ? bestAvailableDisplayTextForMvrConsent(field, 'displayTitle')
                  : bestAvailableDisplayText(field, 'displayTitle')
              }
              formValue={formValues[key] || {}}
              matrixField={field}
              removeFile={index => {
                array.remove(`${key}.${field.type}`, index);
              }}
              options={options}
              hasDownloadFile={hasDownloadFile}
              mvrConsent={mvrConsent}
            />
          </M.GridCol>
        </M.GridRow>
      </>
    );
  };

  renderConfirmation = field =>
    !field.confirmation ? null : (
      <Field
        type='checkbox'
        testId={getFieldKey(field)}
        name={`${getFieldKey(field)}.confirmation`}
        label={bestAvailableDisplayText(field, 'confirmationText')}
        component={Checkbox}
        validate={[V.requiredCheckbox]}
        className='mb-0'
        boldLabel
        i18n
      />
    );

  getValidations = field => {
    const vals = [];
    const { validations } = field;
    const { canDeclareNoSsn } = field;

    if (!canDeclareNoSsn) {
      vals.push(V.required);
    }

    if (validations) {
      validations.forEach(validation => {
        vals.push(V[validation]);
      });
    }
    return vals.filter(Boolean);
  };

  setField = (name, value) => {
    const { changeField } = this.props;

    changeField(VERIFICATION_FORM_NAME, name, value);
  };

  multiUploadPropsOptions = ({ allowedFiletypes, maxAllowedUploadQty }) => ({
    accept: allowedFiletypes || STANDARD_ALLOWED_UPLOAD_FILETYPES,
    maxFiles: maxAllowedUploadQty || MAX_ALLOWED_UPLOAD_DOCS_QTY,
  });

  renderPersonalDetailsFields = fields => (
    <M.Container>
      <M.GridRow>
        <M.GridCol lg={16}>
          <h4>
            <T value='components.Report.Verification.MatrixVerification.title' />
          </h4>
        </M.GridCol>
        <M.GridCol lg={16}>
          <hr />
        </M.GridCol>
      </M.GridRow>
      {fields.map(field => (
        <M.GridRow key={field.id}>
          <M.GridCol lg={16}>{this.renderField(field)}</M.GridCol>
        </M.GridRow>
      ))}
      <br />
    </M.Container>
  );

  getPersonalDetailsFields = fields => {
    const filteredFields = fields.filter(({ type }) =>
      PERSONAL_DETAILS.includes(type),
    );

    return filteredFields.sort(byType);
  };

  getNonPersonalDetailsFields = fields => {
    return fields.filter(({ type }) => !PERSONAL_DETAILS.includes(type));
  };

  isMvrConsent = fields => {
    return fields.map(fc => fc.name).includes('mvr_consent');
  };

  render() {
    const { fields } = this.props;

    if (this.isMvrConsent(fields)) {
      return (
        <>
          {fields.map(field => (
            <M.Container key={getFieldKey(field)}>
              {this.renderField(field, true)}
            </M.Container>
          ))}
        </>
      );
    }

    const personalDetails = this.getPersonalDetailsFields(fields);
    const otherDetails = this.getNonPersonalDetailsFields(fields);

    return (
      <>
        {personalDetails.length
          ? this.renderPersonalDetailsFields(personalDetails)
          : null}
        {otherDetails.map(field => (
          <M.Container key={getFieldKey(field)}>
            {this.renderField(field)}
          </M.Container>
        ))}
      </>
    );
  }
}

MatrixVerificationForm.propTypes = {
  fields: PropTypes.array.isRequired,
  company: PropTypes.string,
  formValues: PropTypes.any.isRequired,
  array: PropTypes.any.isRequired,
  changeField: PropTypes.func.isRequired,
  untouchField: PropTypes.func.isRequired,
  noSsn: PropTypes.bool,
};

MatrixVerificationForm.defaultProps = {
  company: '',
  noSsn: false,
};

const MatrixVerificationReduxForm = reduxForm({
  form: VERIFICATION_FORM_NAME,
})(MatrixVerificationForm);

const selector = formValueSelector(VERIFICATION_FORM_NAME);
const mapStateToProps = state => {
  const values = getFormValues(VERIFICATION_FORM_NAME)(state) || {};
  let submissionId = '';

  // Need this to get dynamic submission id from noSsn field
  Object.entries(values).forEach(([key, value]) => {
    if (value.noSsn) {
      submissionId = key;
    }
  });

  return {
    formValues: values,
    noSsn: selector(state, `${submissionId}.noSsn`) || false,
  };
};

export default i18n.renderTranslation()(
  connect(mapStateToProps, { changeField: change, untouchField: untouch })(
    MatrixVerificationReduxForm,
  ),
);
