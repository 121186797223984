import { getFlagrQuery } from '../flagr';
import {
  CHECKR_VERIFICATIONS_API_BASE,
  CHECKR_BFF_API_BASE,
  FLAGR_KEYS,
} from '../../constants';

export const getAPIBase = ({ path = '', accountId = '', method = '' }) => {
  return getFlagrQuery({
    id: FLAGR_KEYS.useBFF,
    context: { path, accountId, method },
    fallbackVariant: 'off',
  }).then(({ flag: { variantKey } }) =>
    variantKey === 'on' ? CHECKR_BFF_API_BASE : CHECKR_VERIFICATIONS_API_BASE,
  );
};
