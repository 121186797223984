import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Link } from 'react-router-dom';

import { PATHS } from '../../constants';

export const Navigation: React.FC<{
  text: string;
  className?: string;
  isInternational?: boolean;
}> = ({ text, className = '', isInternational = false }) => {
  const linkTo = isInternational
    ? PATHS.INTERNATIONAL_REPORTS_OVERVIEW
    : PATHS.REPORTS_OVERVIEW;
  return (
    <nav className={`report-nav${className ? ` ${className}` : ''}`}>
      <ul className='nav-list'>
        <li className='nav-list--item'>
          <Link
            to={linkTo}
            className='report-nav--item report-nav--item__home'
            data-testid='back-to-all-reports'
          >
            <M.Icon icon='ChevronLeft' />
            {text}
          </Link>
        </li>
      </ul>
    </nav>
  );
};
