const orderByKey = (arr, key, orderArr) => {
  const newArr = arr.slice(0);
  newArr.sort((a, b) => orderArr.indexOf(a[key]) - orderArr.indexOf(b[key]));

  return newArr;
};

const orderNullFirst = key => {
  return (a, b) => {
    if (!a[key]) {
      return -1;
    }
    return 1;
  };
};

export { orderByKey, orderNullFirst };
