import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Charge from '../../Charge/Criminal';
import { formatSearchTitle } from '../../lib/screenings';
import Status from '../../Status';
import { trackAnalyticsEvent } from '../../../../../actions';
import {
  AMPLITUDE_NAME_MAP,
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../../../lib/analytics';

const SUBSTATUSES = {
  NO_RECORD_VERIFIED: {
    icon: {
      name: 'CheckmarkFilled',
      className: 'status-clear',
    },
    title: 'components.intlCriminal.noRecordVerifiedTitle',
    subtitle: 'components.intlCriminal.ca.noRecordVerifiedSubtitle',
    description: 'components.intlCriminal.ca.noRecordVerifiedDescription',
  },
  RECORD_VERIFIED: {
    icon: {
      name: 'CheckmarkFilled',
      className: 'status-clear',
    },
    title: 'components.intlCriminal.recordVerifiedTitle',
    subtitle: 'components.intlCriminal.ca.recordVerifiedSubtitle',
    description: 'components.intlCriminal.ca.recordVerifiedDescription',
  },
  NOT_VERIFIED: {
    icon: {
      name: 'CheckmarkFilled',
      className: 'status-consider',
    },
    title: 'components.intlCriminal.notVerifiedTitle',
    subtitle: 'components.intlCriminal.ca.notVerifiedSubtitle',
    description: 'components.intlCriminal.ca.notVerifiedDescription',
  },
};

const IntlCriminalV2 = ({ record }) => {
  const dispatch = useDispatch();
  if (!record) return null;

  const subStatusInfo = SUBSTATUSES[record.resultSubstatus];
  const charges = record.criminalRecords?.flatMap(item => item.charges) || [];

  return (
    <div data-testid='international_criminal_search_v2'>
      <div className='d-flex mb-4'>
        <div>
          <strong>
            {formatSearchTitle(record)}{' '}
            {record.turnaroundTime && (
              <span>
                {i18n.getLocalizedShortDateTimeFormat(record.completedAt)} (
                {i18n.getLocalizedDuration(record.turnaroundTime)})
              </span>
            )}
          </strong>
        </div>
        <div className='ml-auto'>
          <Status status={record.status} />
        </div>
      </div>
      {record.resultSubstatus && (
        <div
          data-testid='sub-status-section'
          id='sub-status-section'
          className='mb-4'
        >
          <div className='mt-2 mb-2'>
            <i className={subStatusInfo.icon} />
            <strong className='icon-text'>
              <T id='title' value={subStatusInfo.title} />
            </strong>
          </div>
          <div>
            <T id='subtitle' value={subStatusInfo.subtitle} />
          </div>
          <div className='mt-2'>
            <T id='description' value={subStatusInfo.description} />
          </div>
        </div>
      )}
      {charges.map((charge, i) => (
        <div key={`div-${i + 1}`}>
          <Charge charge={charge} index={i} />
          {i < charges.length - 1 && <br />}
        </div>
      ))}
      {record.pdfUrl && (
        <div className='mt-4'>
          <a
            href={record.pdfUrl}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              dispatch(
                trackAnalyticsEvent(ANALYTICS_EVENTS.SCREENING_DOWNLOADED, {
                  [ANALYTICS_PROPERTIES.SCREENING_NAME]:
                    AMPLITUDE_NAME_MAP.criminal,
                }),
              );
            }}
          >
            <T value='components.viewReport' />
          </a>
        </div>
      )}
    </div>
  );
};

IntlCriminalV2.propTypes = {
  record: PropTypes.object,
};

IntlCriminalV2.defaultProps = {
  record: null,
};

export default IntlCriminalV2;
export { IntlCriminalV2 as IntlCriminal, SUBSTATUSES };
