import React from 'react';
import Attribute from '../Attribute';
import { MVR_MEDICAL_CERTIFICATE_FIELDS } from '../../../../constants';
import { getRandomKey } from '../../../../lib/helpers';

const MedicalCertificateAttributes = ({ medicalCertificate }) => {
  const {
    status,
    issuedDate,
    expirationDate,
    selfCertification,
    examiner: {
      fullName,
      licenseNumber,
      licenseState,
      registrationNumber,
      phoneNumber,
    },
  } = medicalCertificate;

  const destructuredMedicalCertificate = {
    status,
    issuedDate,
    expirationDate,
    selfCertification,
    examinerFullName: fullName,
    examinerLicenseNumber: licenseNumber,
    examinerLicenseState: licenseState,
    examinerRegistrationNumber: registrationNumber,
    examinerPhoneNumber: phoneNumber,
  };

  return MVR_MEDICAL_CERTIFICATE_FIELDS.map((key, i) => {
    const value = destructuredMedicalCertificate[key];

    return <Attribute key={getRandomKey()} attr={key} value={value} />;
  });
};

export default MedicalCertificateAttributes;
