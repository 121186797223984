/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import { searchParamsToObject } from 'utils/useLocationQuery';
import { Navigate } from 'react-router-dom';
import {
  clearLoggedOut,
  fetchCandidateReports,
  fetchCandidateReportsIntl,
} from '../actions';
import { PATHS } from '../constants';
import Loading from '../components/Loading';
import MainContainer from '../components/MainContainer';
import {
  isInternational as getIsInternational,
  isSessionStorageSupported,
} from '../lib/helpers';
import { withRouter } from '../utils/withRouter';

export const LOCALSTORAGE_TOKEN_KEY = 'token';

const getRoot = (isInternational, location) => {
  if (['#login', '#login_failed'].includes(location.hash))
    return isInternational ? PATHS.INTERNATIONAL : '/';
  if (isInternational) return PATHS.INTERNATIONAL_REPORTS_OVERVIEW;
  return PATHS.REPORTS_OVERVIEW;
};

export class AuthComponent extends PureComponent {
  componentDidUpdate() {
    document.title = i18n.getStr('containers.page.title');
    const {
      portal: { error, loggedIn, processing },
      routeParams,
      isInternational,
      location,
      navigate,
      isVerification,
    } = this.props;

    if (error && isSessionStorageSupported()) {
      window.sessionStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
    }

    if (
      !(processing || (!error && routeParams.mock) || isVerification) &&
      loggedIn &&
      ['/', PATHS.INTERNATIONAL].includes(location.pathname)
    ) {
      navigate(getRoot(isInternational, location), {
        replace: true,
      });
    }
  }

  getShouldFetch() {
    const {
      routeParams: { mock },
      portal: { error, reports, processing, loggedOut },
      token,
    } = this.props;
    return (
      !loggedOut &&
      Boolean(mock || token) &&
      !error &&
      !reports.length &&
      !processing
    );
  }

  render() {
    const {
      portal: { error, reports, processing, loggedIn },
      token,
      children,
      isInternational,
      isVerification,
      isLoading,
      location,
    } = this.props;
    const isPrivacy = location.pathname.indexOf('/privacy') >= 0;

    const shouldFetch = this.getShouldFetch();
    if (shouldFetch) {
      if (isInternational) {
        this.props.fetchCandidateReportsIntl({ token });
      } else {
        this.props.fetchCandidateReports({ token });
      }
    }
    if (shouldFetch || processing || isLoading) {
      return (
        <MainContainer>
          <Loading data-testid='loading-reports' />
          <div className='text-muted text-center'>
            {i18n.getStr('components.searchLoadingText')}
          </div>
        </MainContainer>
      );
    }
    if (!loggedIn && !isVerification) {
      return (
        <Navigate
          to={`${isInternational ? '/international' : ''}${
            isPrivacy ? '/privacy' : ''
          }/login${error ? '#login_fail' : ''}`}
          state={{ prevLocation: location.pathname }}
        />
      );
    }

    // All successfully authenticated routes get `reports` passed to them:
    return React.cloneElement(children, { reports });
  }
}

AuthComponent.propTypes = {
  children: PropTypes.node,
  token: PropTypes.string,
  fetchCandidateReports: PropTypes.func.isRequired,
  fetchCandidateReportsIntl: PropTypes.func.isRequired,
  portal: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  isInternational: PropTypes.bool,
  isVerification: PropTypes.bool,
  isLoading: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};
AuthComponent.defaultProps = {
  children: [],
  isInternational: false,
  isVerification: false,
  isLoading: false,
  token: '',
};

const mapStateToProps = (state, props) => {
  const { mock, utm_source, utm_medium, utm_content, utm_campaign, utm_term } =
    searchParamsToObject(new URLSearchParams(props.location?.search || ''));
  const { portal = {} } = state;

  const routeParams = {
    mock,
    utm_source,
    utm_medium,
    utm_content,
    utm_campaign,
    utm_term,
  };

  return {
    portal,
    routeParams,
    isInternational: state.international.international || getIsInternational(),
  };
};

const actions = {
  fetchCandidateReports,
  fetchCandidateReportsIntl,
  clearLoggedData: clearLoggedOut,
};

export default connect(mapStateToProps, actions)(withRouter(AuthComponent));
