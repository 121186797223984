/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import { normalizeToSize } from '@sentry/utils';
import traverse from 'traverse';

import { SENTRY_DSN, ENV, IS_BOT, SENTRY_RELEASE } from './constants';

const sanitizeKeys = [
  /ssn/i,
  /phone/i,
  /dob/i,
  /driverlicense/i,
  /motherMaidenName/i,
  /i18n/i,
];

const ignoreErrors = [
  // Error generated by a bug in auto-fill library from browser
  // https://github.com/getsentry/sentry/issues/5267
  /Blocked a frame with origin "https?:\/\/candidate\.checkr\.com" from accessing/,
  'invalid token',
  'no candidate found',
  'Bad authentication error',
  /Failed to execute 'createElement' on 'Document'/,
  'https://privacyportal.onetrust.com/request/v1/consentreceipts',
  /Timeout \(.\)/,
  "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedDebugHandler.postMessage')",
];

if (IS_BOT) {
  const ignoreBot = /Invalid captcha/;
  ignoreErrors.push(ignoreBot);
}

const paths = [
  /https?:\/\/candidate\.checkr\.com/,
  /https?:\/\/applicant-portal\.checkrhq-staging\.net/,
  /https?:\/\/candidate\.checkrhq-staging\.net/,
  /https?:\/\/candidate\.checkr\.localhost/,
];

// team tags
const tags = {
  team: 'dash_experience',
};

const filteredReduxActions = ['@@i18n/LOAD_TRANSLATIONS'];

// 125kB, as 200kB is max payload size for sentry, sounds reasonable
const MAX_SIZE = 125 * 1024;

/**
 * @name sanitizeState
 * @function
 * @memberOf sentry
 * @description Sanitize state based on sanitizeKeys
 * @param {(array|object)} state - state to sanitize
 * @returns {array}
 */
const sanitizeState = state => {
  traverse(state).forEach(function (_) {
    sanitizeKeys.forEach(k => {
      if (this.key && this.key.match(k)) this.update(null);
    });
  });
};

const initializeSentry = () => {
  if (ENV === 'development' || !SENTRY_RELEASE) return;
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENV,
    release: SENTRY_RELEASE,
    // normalize context depth
    normalizeDepth: 12,
    ignoreErrors,
    allowUrls: [/checkr\.com/, /checkrhq-staging\.net/, /checkr\.localhost/],
    integrations: [
      new RewriteFrames({
        iteratee: frame => {
          const fr = frame;
          if (fr.filename) {
            for (const path of paths) {
              if (path.test(fr.filename)) {
                fr.in_app = true;
              }
            }
          }
          return fr;
        },
      }),
    ],
    // clean up redux state from sensitive information
    beforeSend(event, hint) {
      // https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
      if (hint.originalException === 'Timeout') return null;

      const reduxState = event?.contexts?.['redux.state'];

      const clonedReduxState = JSON.parse(JSON.stringify(reduxState));
      const newState = { ...clonedReduxState, i18n: null };

      // Disabling this linting rule as it's unclear if we want to change this map to a forEach.
      // eslint-disable-next-line array-callback-return
      const transformedState = traverse(newState).map(function (_) {
        // remove circular references
        if (this.circular) this.remove();
      });

      // sanitize redux state
      sanitizeState(transformedState);

      // filter desired redux actions due to size limitations in breadcrumbs
      const breadcrumbs = event.breadcrumbs.filter(
        breadcrumb => !filteredReduxActions.includes(breadcrumb.data?.type),
      );

      // sanitize breadcrumbs
      sanitizeState(breadcrumbs);

      const newEvent = {
        ...event,
        breadcrumbs,
        contexts: {
          ...event?.contexts,
          'redux.state': transformedState,
        },
      };

      // ensure that the final event payload is less than the max size accepted by Sentry
      const normalizedEventToSize = normalizeToSize(newEvent, 12, MAX_SIZE);

      return normalizedEventToSize;
    },
    beforeBreadcrumb(breadcrumb) {
      // filter non console error events due to size limitations in breadcrumbs
      if (breadcrumb.category === 'console' && breadcrumb.level !== 'error') {
        return null;
      }
      return breadcrumb;
    },
  });

  Object.keys(tags).forEach(tag => {
    Sentry.configureScope(scope => {
      scope.setTag(tag, tags[tag]);
    });
  });
};

export default initializeSentry;
