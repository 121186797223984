/*
 * Action Types
 */

export const SET_INTERNATIONAL = 'SET_INTERNATIONAL';

/*
 * Actions
 */

export const setInternational = isInternational => ({
  type: SET_INTERNATIONAL,
  value: isInternational,
});
