import React, { useEffect, useState } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { useLocation, useParams } from 'react-router-dom';
import { reduxForm, change } from 'redux-form';
import { useDispatch } from 'react-redux';
import { trackAnalyticsEvent } from 'actions/analytics';
import {
  CHECKR_BFF_API_BASE,
  INSTANT_EXCEPTIONS_FORM_NAME,
} from '../../constants';
import MainContainer from '../../components/MainContainer';
import { StartedYourBackgroundCheck as StartedYourBackgroundCheckPage } from './StartedYourBackgroundCheck';
import { ReceivedYourInfo as ReceivedYourInfoPage } from './ReceivedYourInfo';
import { UploadDocumentLater as UploadDocumentLaterPage } from './UploadDocumentLater';
import { SsnReentry as SsnReentryPage } from './SsnReentry';
import { SsnUpload as SsnUploadPage } from './SsnUpload';
import { UploadDeadend as UploadDeadendPage } from './UploadDeadend';
import { DocumentUpload as DocumentUploadPage } from './DocumentUpload';
import { Waiting as WaitingPage } from './Waiting';
import { Greeting } from './Greeting';
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from '../../lib/analytics';
import { Page } from './Page';
import { ContactCheckrDeadend as ContactCheckrDeadendPage } from './ContactCheckrDeadend';

export const PageInfo: Map<
  Page,
  {
    title: string;
    component:
      | typeof WaitingPage
      | typeof StartedYourBackgroundCheckPage
      | typeof ReceivedYourInfoPage
      | typeof UploadDocumentLaterPage
      | typeof SsnReentryPage
      | typeof SsnUploadPage
      | typeof UploadDeadendPage
      | typeof DocumentUploadPage
      | typeof ContactCheckrDeadendPage;
    analyticsEvent: string;
    submitAnalyticsEvent: string | null;
  }
> = new Map([
  [
    Page.DocumentUpload,
    {
      title: Page.DocumentUpload,
      component: DocumentUploadPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_ID_DOC_UPLOAD_VIEWED,
      submitAnalyticsEvent:
        ANALYTICS_EVENTS.SSN_EXCEPTION_ID_DOC_UPLOAD_COMPLETED,
    },
  ],
  [
    Page.UploadDeadend,
    {
      title: Page.UploadDeadend,
      component: UploadDeadendPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_CHECK_NOT_COMPLETED,
      submitAnalyticsEvent: null,
    },
  ],
  [
    Page.ContactCheckrDeadend,
    {
      title: Page.ContactCheckrDeadend,
      component: ContactCheckrDeadendPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_CONTACT_CHECKR_VIEWED,
      submitAnalyticsEvent: null,
    },
  ],
  [
    Page.Waiting,
    {
      title: Page.Waiting,
      component: WaitingPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_WAITING_SCREEN_VIEWED,
      submitAnalyticsEvent: null,
    },
  ],
  [
    Page.StartedYourBackgroundCheck,
    {
      title: Page.StartedYourBackgroundCheck,
      component: StartedYourBackgroundCheckPage,
      analyticsEvent: ANALYTICS_EVENTS.APPLY_FLOW_BACKGROUND_CHECK_STARTED,
      submitAnalyticsEvent: null,
    },
  ],
  [
    Page.ReceivedYourInfo,
    {
      title: Page.ReceivedYourInfo,
      component: ReceivedYourInfoPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_INFO_RECEIVED_VIEWED,
      submitAnalyticsEvent: null,
    },
  ],
  [
    Page.UploadDocumentLater,
    {
      title: Page.UploadDocumentLater,
      component: UploadDocumentLaterPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_UPLOAD_DOC_LATER_VIEWED,
      submitAnalyticsEvent: null,
    },
  ],
  [
    Page.SsnReentry,
    {
      title: Page.SsnReentry,
      component: SsnReentryPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_SSN_REENTRY_VIEWED,
      submitAnalyticsEvent:
        ANALYTICS_EVENTS.SSN_EXCEPTION_SSN_REENTRY_COMPLETED,
    },
  ],
  [
    Page.SsnUpload,
    {
      title: Page.SsnUpload,
      component: SsnUploadPage,
      analyticsEvent: ANALYTICS_EVENTS.SSN_EXCEPTION_SSN_CARD_UPLOAD_VIEWED,
      submitAnalyticsEvent:
        ANALYTICS_EVENTS.SSN_EXCEPTION_SSN_CARD_UPLOAD_COMPLETED,
    },
  ],
]);

interface ExceptionJson {
  status: string;
  resolution?: string;
  exception_id?: string;
  exception_entry_ids?: string[];
  info_required_type?: string;
  candidate_first_name?: string;
  account_name?: string;
}

interface InstantExceptionsFormProps {
  handleSubmit: any;
}

interface Names {
  candidate_first_name?: string;
  account_name?: string;
}

const InstantExceptionsForm: React.FC<InstantExceptionsFormProps> = ({
  handleSubmit,
}) => {
  const params = useParams();

  // allow the page to start with the select page toggle if that query param is set
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectPageValue = queryParams.get('selectPage') === 'true';

  const reportId = params?.reportId?.replace(/[^a-fA-F0-9]/g, '').slice(0, 24);

  const [page, setPageState] = useState(Page.Waiting);
  const [previousPage, setPreviousPage] = useState(Page.Waiting);

  const [names, setNames] = useState<Names>({
    candidate_first_name: undefined,
    account_name: undefined,
  });
  const [exceptionId, setExceptionId] = useState<string | undefined>(undefined);
  const [exceptionEntryId, setExceptionEntryId] = useState<string | undefined>(
    undefined,
  );

  const dispatch = useDispatch();
  const is_names_set = names.candidate_first_name && names.account_name;

  // toggle visibility of page select on konami code (wwssadad)
  const [, setKeySequence] = useState('');
  const [isPageSelecVisible, setIsPageSelectVisible] =
    useState(selectPageValue);
  const targetSequence = 'wwssadad';
  const handleKeyDown = (event: KeyboardEvent) => {
    setKeySequence(prevSequence => {
      const updatedSequence = (prevSequence + event.key).slice(
        -targetSequence.length,
      );
      if (updatedSequence === targetSequence) {
        setIsPageSelectVisible(prevState => !prevState);
      }
      return updatedSequence;
    });
  };

  // For tracking time spent polling for a non-waiting response
  const [pollingStartTime, setPollingStartTime] = useState(Date.now());

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  useEffect(() => {
    dispatch(change(INSTANT_EXCEPTIONS_FORM_NAME, 'exceptionId', exceptionId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceptionId]);

  useEffect(() => {
    dispatch(
      change(
        INSTANT_EXCEPTIONS_FORM_NAME,
        'exceptionEntryId',
        exceptionEntryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceptionEntryId]);

  // log initial Waiting analytics event on page load
  useEffect(() => {
    dispatch(
      trackAnalyticsEvent(
        ANALYTICS_EVENTS.SSN_EXCEPTION_WAITING_SCREEN_VIEWED,
        { [ANALYTICS_PROPERTIES.REPORT_RESOURCE_ID]: reportId },
      ),
    );

    pollForException(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setPage = (pageToSet: Page) => {
    setPageState(currentPage => {
      if (currentPage !== pageToSet) {
        setPreviousPage(page);
        dispatch(
          trackAnalyticsEvent(PageInfo.get(pageToSet)?.analyticsEvent, {
            [ANALYTICS_PROPERTIES.REPORT_RESOURCE_ID]: reportId,
          }),
        );
      }
      dispatch(change(INSTANT_EXCEPTIONS_FORM_NAME, 'currentPage', pageToSet));
      return pageToSet;
    });
  };

  const POLLING_INTERVAL = 5000;
  const POLLING_MAX_TRIES = 6;
  const pollForException = (attempts: number) => {
    let actualStartTime: number = pollingStartTime;
    if (attempts === 0) {
      setPollingStartTime(Date.now());
      actualStartTime = Date.now();
    }

    if (attempts >= POLLING_MAX_TRIES) {
      setPage(Page.StartedYourBackgroundCheck);
      trackPollingTime('timeout', actualStartTime);
      return;
    }

    fetchException(reportId).then(json => {
      if (json.candidate_first_name) {
        setNames(({ account_name }) => {
          return {
            account_name,
            candidate_first_name: json.candidate_first_name,
          };
        });
      }
      if (json.account_name) {
        setNames(({ candidate_first_name }) => {
          return {
            candidate_first_name,
            account_name: json.account_name,
          };
        });
      }

      if (json.status === 'done') {
        setPage(Page.StartedYourBackgroundCheck);
        trackPollingTime('done', actualStartTime);
      } else if (json.status === 'info_required') {
        const result = handleInfoRequired(json);
        trackPollingTime(result, actualStartTime);
      } else if (json.status === 'contact_checkr') {
        setPage(Page.ContactCheckrDeadend);
        trackPollingTime('contact_checkr', actualStartTime);
      } else {
        setTimeout(pollForException, POLLING_INTERVAL, attempts + 1);
      }
    });
  };

  function trackPollingTime(result: string, actualStartTime: number) {
    const timeElapsedInMilliseconds = Date.now() - actualStartTime;
    dispatch(
      trackAnalyticsEvent(ANALYTICS_EVENTS.SSN_EXCEPTION_POLLING_COMPLETED, {
        'Time Elapsed (ms)': timeElapsedInMilliseconds,
        Result: result,
      }),
    );
  }

  async function fetchException(
    id: string | undefined,
  ): Promise<ExceptionJson> {
    try {
      let url = `${CHECKR_BFF_API_BASE}/ssn_trace_status?report_id=${id}`;
      if (!is_names_set) {
        url += '&include_names=true';
      }
      const response = await fetch(url);
      const json = await response.json();
      return json;
    } catch (e) {
      return { status: 'errror' };
    }
  }

  const handleInfoRequired = (exceptionJson: ExceptionJson) => {
    setExceptionId(exceptionJson.exception_id);
    setExceptionEntryId(exceptionJson.exception_entry_ids?.[0]);

    if (exceptionJson.resolution === 'ssn_reentry') {
      setPage(Page.SsnReentry);
      return 'ssn_reentry';
    }

    if (exceptionJson.resolution === 'ssn_document_upload') {
      setPage(Page.SsnUpload);
      return 'ssn_document_upload';
    }

    if (exceptionJson.resolution === 'id_document_upload') {
      setPage(Page.DocumentUpload);
      return 'id_document_upload';
    }

    setPage(Page.StartedYourBackgroundCheck);
    return 'unknown';
  };

  const greetingPart = is_names_set ? (
    <Greeting
      company={names.account_name}
      firstName={names.candidate_first_name}
    />
  ) : (
    <div>
      <M.LoadingBlock style={{ height: '31px', width: '43%' }} />
      <M.LoadingBlock style={{ height: '31px', width: '74%' }} />
    </div>
  );

  const pageComponent = PageInfo.get(page);
  const pagePart = pageComponent ? (
    <pageComponent.component
      previousPage={previousPage}
      setPage={setPage}
      greeting={greetingPart}
    />
  ) : (
    <h1>NYI</h1>
  );

  const handleSubmitPage = (values: any, currentPage: Page) => {
    handleSubmit(values);
    const submitAnalyticsEvent =
      PageInfo.get(currentPage)?.submitAnalyticsEvent;
    if (submitAnalyticsEvent) {
      dispatch(
        trackAnalyticsEvent(submitAnalyticsEvent, {
          [ANALYTICS_PROPERTIES.REPORT_RESOURCE_ID]: reportId,
          [ANALYTICS_PROPERTIES.EXCEPTION_FLOW_VERIFICATION_ID]: exceptionId,
        }),
      );

      if (currentPage === Page.SsnReentry) {
        setPageState(Page.Waiting);
        setTimeout(pollForException, POLLING_INTERVAL, 0);
      } else {
        setPageState(Page.ReceivedYourInfo);
      }
    }
  };

  return (
    <MainContainer style={{ background: colors.uiGrey50 }}>
      <form onSubmit={e => handleSubmitPage(e, page)}>
        {isPageSelecVisible ? (
          <M.Container style={{ border: 'none', background: colors.uiGrey50 }}>
            <M.Select
              hideLabel
              id='page'
              defaultValue={PageInfo.get(page)?.title || 'Waiting'}
              onChange={(e: { target: { value: Page } }) =>
                setPage(e.target.value)
              }
            >
              {Object.values(Page).map(key => (
                <M.Select.Item
                  key={key}
                  value={key}
                  text={PageInfo.get(key as Page)?.title || 'Waiting'}
                />
              ))}
            </M.Select>
            <p>selected page {PageInfo.get(page)?.title || 'Waiting'}</p>
            {pagePart}
          </M.Container>
        ) : (
          pagePart
        )}
      </form>
    </MainContainer>
  );
};
const ContactForm = reduxForm({
  form: INSTANT_EXCEPTIONS_FORM_NAME,
})(InstantExceptionsForm);

async function submitException(values: any) {
  const { exceptionId, exceptionEntryId, currentPage } = values;
  if (!exceptionId || !exceptionEntryId || !currentPage) {
    return { status: 'error' };
  }

  try {
    const url = `${CHECKR_BFF_API_BASE}/v2/verifications/${exceptionId}`;
    const body: any = {};
    body.submissions = {};
    body.submissions[exceptionEntryId] = {};

    if (currentPage === Page.SsnReentry) {
      body.submissions[exceptionEntryId].ssn = values.ssn;
      body.submissions[exceptionEntryId].ssnConfirmation =
        values.ssnConfirmation;
    } else if (currentPage === Page.DocumentUpload) {
      body.submissions[exceptionEntryId].multi_documents = [values.idDocument];
    } else if (currentPage === Page.SsnUpload) {
      body.submissions[exceptionEntryId].multi_documents = [values.ssnDocument];
    } else {
      return { status: 'error' };
    }

    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    return { status: 'success' };
  } catch (e) {
    return { status: 'errror' };
  }
}

class InstantExceptions extends React.Component {
  submit = (values: any) => {
    submitException(values);
  };

  render() {
    return <ContactForm onSubmit={this.submit} />;
  }
}

export default InstantExceptions;
