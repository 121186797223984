import { i18n } from '@international/mastodon-i18n';
import { STATES } from 'types/states';

const inputErrorClass = (touched: boolean, error: string) => {
  let formInputClass = '';
  if (touched) {
    formInputClass = error ? 'is-invalid' : 'is-valid';
  }

  return formInputClass;
};

const labelErrorClass = (touched: boolean, error: string) => {
  let formLabelClass = '';
  if (touched && error) {
    formLabelClass = 'invalid-feedback';
  }

  return formLabelClass;
};

/** This function return and array of options of US States to be used on M.Select */
export const generateUSStatesOptions = () =>
  STATES.map(state => ({ value: state, name: state }));

// custom i18n text for upload popup
const getUploadCustomText = () => {
  return {
    'Select Files to Upload': i18n.getStr(
      'components.Upload.clickToSelectFiles',
    ),
    'or Drag and Drop, Copy and Paste Files': i18n.getStr(
      'components.Upload.dragAndDrop',
    ),
    'Selected Files': i18n.getStr('components.Upload.selectedFiles'),
    Upload: i18n.getStr('components.Upload.upload'),
    'Deselect All': i18n.getStr('components.Upload.deselectAll'),
    Images: i18n.getStr('components.Upload.images'),
    Filter: i18n.getStr('components.Upload.filter'),
    Files: i18n.getStr('components.Upload.files'),
    'Upload more': i18n.getStr('components.Upload.uploadMore'),
    'View/Edit Selected': i18n.getStr('components.Upload.viewEditSelected'),
    Uploading: i18n.getStr('components.Upload.uploading'),
    Uploaded: i18n.getStr('components.Upload.uploaded'),
    Completed: i18n.getStr('components.Upload.completed'),
    'File {displayName} is not an accepted file type. The accepted file types are {types}':
      i18n.getStr('components.Upload.fileError'),
    'File {displayName} is too big. The accepted file size is less than {roundFileSize}':
      i18n.getStr('components.Upload.fileTooBig'),
    'Search images': i18n.getStr('components.Upload.searchImages'),
    'Select From': i18n.getStr('components.Upload.selectFrom'),
    'more file': i18n.getStr('components.Upload.moreFile'),
    'more files': i18n.getStr('components.Upload.moreFiles'),
    'This folder is empty.': i18n.getStr('components.Upload.thisFolderIsEmpty'),
    'This image cannot be edited': i18n.getStr(
      'components.Upload.thisImageCannotBeEdited',
    ),
    'Our file upload limit is {maxFiles} {filesText}': i18n.getStr(
      'components.Upload.ourFileUploadLimit',
    ),
    'No search results found for "{search}"': i18n.getStr(
      'components.Upload.noSearchResults',
    ),
    Next: i18n.getStr('components.Upload.next'),
    'Drop your files anywhere': i18n.getStr(
      'components.Upload.dropYourFilesAnywhere',
    ),
    Done: i18n.getStr('components.Upload.done'),
    'Failed While Uploading': i18n.getStr(
      'components.Upload.failedWhileUploading',
    ),
    Add: i18n.getStr('components.Upload.add'),
    'An error occurred. Please try again.': i18n.getStr(
      'components.Upload.AnErrorOccurred',
    ),
    Reset: i18n.getStr('components.Upload.reset'),
    'Go back': i18n.getStr('components.Upload.goBack'),
  };
};

export { inputErrorClass, labelErrorClass, getUploadCustomText };
