/* eslint-disable react/button-has-type */
import useFilestackPolicy from 'components/SecureFilestack/hooks/hooks';
import React, { useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import ReactFilestack from 'filestack-react';
import { request } from 'actions/helper';
import { i18n } from '@international/mastodon-i18n';
import {
  FILE_STACK_API_KEY,
  MAX_FILE_SIZE,
  BACKEND_FLAG_PATHS,
} from '../../constants';
import { getAPIBase } from '../../api/verifications/getAPIBase';
import { getUploadCustomText } from '../../components/fields/helper';
import { Type } from './Type';
import { Icon } from './Icon';
import Typography2024Hack from './Typography2024Hack';

type FilestackSecurity = {
  security: { policy: string; signature: string };
};

type FilestackFile = {
  filename: string;
  size: number;
};

const deleteFileStackLink = (file: { file: any; url: string }) => {
  return getAPIBase({ path: BACKEND_FLAG_PATHS.DELETE_FILE }).then(baseURL => {
    const path = `${baseURL}/delete_file`;
    const options = {
      method: 'POST',
      body: JSON.stringify({ url: file.url }),
      retryDelay: 200,
    };
    return request(path, options);
  });
};

const MAX_FILE_NAME_CHARACTERS = 20;

const customSelectFilesText: { [key: string]: string } = {
  idDocument: i18n.getStr(
    'components.InstantExceptions.DocumentUpload.filestackRequirements',
  ),
  ssnDocument: i18n.getStr(
    'components.InstantExceptions.SsnUpload.filestackRequirements',
  ),
};

export const ReduxFileInput = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}: {
  input: any;
  label: any;
  meta: {
    touched: any;
    error: any;
  };
}) => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const [selectedFile, setSelectedFile] = useState<FilestackFile | null>(null);

  delete input.value; // Avoid "cannot set property value of undefined" error
  const { data, isSuccess } = useFilestackPolicy(FILE_STACK_API_KEY);

  if (!isSuccess || !data?.data) {
    return <M.LoadingInline />;
  }

  const filestackSecurity: FilestackSecurity = { security: { ...data?.data } };
  const fileStackOptions = {
    accept: ['image/*', '.pdf'],
    storeTo: { location: 'S3' },
    fromSources: ['local_file_system'],
    maxFiles: 1,
    disableTransformer: true,
    uploadInBackground: false,
    onFileUploadFinished: deleteFileStackLink,
    customText: {
      ...getUploadCustomText(),
      'Select Files to Upload': customSelectFilesText[input.name],
    },
    lang: i18n.getLocale().substring(0, 2),
    maxSize: MAX_FILE_SIZE,
    disableThumbnails: false,
  };

  const uploadSuccess = (res: { filesUploaded: FilestackFile[] }) => {
    setSelectedFile((res.filesUploaded || [''])[0]);
    input.onChange((res.filesUploaded || [''])[0]);
  };

  let truncatedFilename = '';
  if (selectedFile) {
    if (selectedFile.filename.length >= MAX_FILE_NAME_CHARACTERS + 4) {
      truncatedFilename = `${selectedFile.filename.slice(
        0,
        MAX_FILE_NAME_CHARACTERS,
      )}${selectedFile.filename.slice(-4)}`;
    } else {
      truncatedFilename = selectedFile.filename;
    }
  }

  const customRender: (arg0: { onPick: () => void }) => JSX.Element = ({
    onPick,
  }) => (
    <button
      style={{
        display: 'flex',
        height: '40px',
        width: screenWidth >= 768 ? '300px' : undefined,
        padding: '10px 18px',
        margin: '8px 0px',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        alignSelf: 'stretch',
        borderRadius: '4px',
        border: '1px solid #E1E6EB',
        background: '#FFF',
        boxShadow: '0px 2px 4px -2px rgba(72, 82, 94, 0.14)',
      }}
      onClick={onPick}
    >
      <Icon type={Type.Upload} />
      <p
        style={{
          color: '#1A2026',
          textAlign: 'center',
          fontFamily: '"Helvetica Neue"',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '100%',
        }}
      >
        {i18n.getStr('buttons.uploadCard')}
      </p>
    </button>
  );

  return selectedFile === null ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
      }}
    >
      <label style={{ display: 'none' }} htmlFor='fhqwgads'>
        {label}
      </label>
      <div>
        <input
          style={{
            display: 'none',
          }}
          id='fhqwgads'
          type='hidden'
          accept='*/*'
          onChange={e => input.onChange((e?.target?.files || [''])[0])}
          {...custom}
        />
        {touched && error && <span>{error}</span>}
      </div>
      <ReactFilestack
        actionOptions={fileStackOptions}
        apikey={FILE_STACK_API_KEY}
        customRender={customRender}
        onSuccess={uploadSuccess}
        clientOptions={filestackSecurity}
      />
    </div>
  ) : (
    <M.Container
      onClick={() => {
        setSelectedFile(null);
        input.onChange(null);
      }}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        alignSelf: 'stretch',
        width: '100%',
        padding: screenWidth < 768 ? '12px 16px' : '12px 24px',
        margin: 0,
      }}
    >
      <M.Container
        style={{
          border: 'none',
          padding: 0,
          margin: 0,
        }}
      >
        <p style={{ ...Typography2024Hack.p4 }}>{truncatedFilename}</p>
        <p style={{ ...Typography2024Hack.p4 }}>
          {selectedFile.size / 1000} KB
        </p>
      </M.Container>
      <p
        style={{
          color: '#CD2F1D',
          textAlign: 'center',
          padding: '20px 0px',
          ...Typography2024Hack.button5,
        }}
      >
        Remove
      </p>
    </M.Container>
  );
};
