import React from 'react';

import UploadButton from '../fields/UploadButton';
import DocumentsPanel from './DocumentsPanel';
import Verification, { connectToVerifications } from './VerificationBase';
import VerificationHeaderPanel from './VerificationHeaderPanel';

class IdVerification extends Verification {
  renderCustomPanel() {
    const { doc, isSmallOrDown, isValid, needUpload, verification } =
      this.getRenderAttributes();

    let headerData;
    let docAdditionalData;
    const ssnVerificationFlowV2 = doc.model === 'ssn_card';

    if (ssnVerificationFlowV2) {
      docAdditionalData = {
        name: 'ssnDocumentVerification',
        floatIcon: true,
        noHeader: true,
        noDivider: true,
        detailsWide: true,
      };

      headerData = {
        hideLogo: true,
        customTitle: `components.Verification.DocumentsPanel.${docAdditionalData.name}.title`,
      };
    }

    return (
      needUpload && (
        <>
          {ssnVerificationFlowV2 && (
            <VerificationHeaderPanel {...verification} {...headerData} />
          )}
          <DocumentsPanel {...doc} {...docAdditionalData}>
            {isValid && isSmallOrDown && (
              <div
                data-testid='upload-button-container-card-footer'
                className='card-footer'
              >
                <UploadButton
                  name={doc.model}
                  buttonClass='btn-block'
                  kind={isValid ? 'secondary' : 'primary'}
                  context='verifications'
                />
              </div>
            )}
          </DocumentsPanel>
        </>
      )
    );
  }
}

export default connectToVerifications(IdVerification);
