import React from 'react';
import { Outlet } from 'react-router-dom';
import Auth from 'containers/Auth';
import { AuthenticatedView } from 'containers/ReportsOverview/AuthenticatedView';

const Verifications: React.FC = () => (
  <Auth isVerification>
    <AuthenticatedView>
      <Outlet />
    </AuthenticatedView>
  </Auth>
);

export default Verifications;
