import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import Record from '../Record/Employment';
import withNonCanceledSearch from './WithNonCanceledSearch';

const EmploymentVerification = ({ search }) => (
  <Panel
    title={i18n.getStr('components.employment.title')}
    status={search.status}
  >
    {search.records.length === 0 && (
      <span>
        <T value='components.employment.noEmploymentHistory' />
      </span>
    )}
    {search.records.length > 0 &&
      search.records.map((record, i) => (
        <div className='mb-4' key={`div-${i + 1}`}>
          <Record record={record} />
          {i < search.records.length - 1 && <hr />}
        </div>
      ))}
  </Panel>
);
EmploymentVerification.propTypes = {
  search: PropTypes.object,
};

EmploymentVerification.defaultProps = {
  search: null,
};

export default withNonCanceledSearch(EmploymentVerification);
