import React, { ReactNode } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { Type, TypeParams } from './Type';

interface StyleableContainerProps {
  type?: Type;
  children?: ReactNode;
  style?: React.CSSProperties;
  styledTopBorder?: boolean;
}

export const defaultStyle = {
  display: 'flex',
  margin: 0,
  maginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  padding: '24px 16px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  background: colors.uiGray0,
  borderRadius: '8px',
  border: '0px solid white',
} as React.CSSProperties;

export const StyleableContainer: React.FC<StyleableContainerProps> = ({
  type,
  children,
  style = { ...defaultStyle },
  styledTopBorder = false,
}) => {
  if (styledTopBorder) {
    const colorPart = TypeParams[type || Type.Default].color;
    style.borderTop = `4px solid ${colorPart}`;
    // style.borderWidth = '4px';
    // style.borderTopStyle = 'solid';
    // style.borderTopColor = TypeParams[type || Type.Default].color;
  }

  return <M.Container style={style}>{children}</M.Container>;
};
