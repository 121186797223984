import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { SortByOptions, ReportsOrderBy } from '../lib/sortReportCardsBy';
import './ReportsFilter.scss';

interface ReportsFilterProps {
  options: SortByOptions;
  onChange: React.MouseEventHandler<HTMLInputElement> | null;
  selected?: ReportsOrderBy;
}

const ReportsFilter: React.FC<ReportsFilterProps> = ({
  options,
  onChange,
  selected,
}) => {
  const [buttonClass, setButtonClass] = React.useState('');
  return (
    <div className='reports-filter'>
      <p className='reports-filter__label'>
        {i18n.getStr('components.CandidateLandingPage.filters.sort.label')}
      </p>
      <M.RadioButtonGroup name='report-order-by' defaultSelected={selected}>
        {Object.values(options).map(option => {
          return (
            <M.RadioButton
              key={option.value}
              value={option.value}
              id={`sort-report-by-${option.value}`}
              data-testid={`sort-report-by-${option.value}`}
              checked={option.value === selected}
              selected={option.value === selected}
              labelText={i18n.getStr(
                `components.CandidateLandingPage.filters.sort.${option.value}`,
              )}
              className={buttonClass}
              onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                const nativeEvent = e.nativeEvent as MouseEvent;
                const isRealMouseEvent =
                  nativeEvent.pageY !== 0 && nativeEvent.pageX !== 0;
                if (isRealMouseEvent) {
                  setButtonClass('-clicked');
                } else {
                  setButtonClass('');
                }
                if (onChange) onChange(e);
              }}
              onBlur={() => {
                setButtonClass('');
              }}
            />
          );
        })}
      </M.RadioButtonGroup>
    </div>
  );
};

export default ReportsFilter;
