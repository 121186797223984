import styled from 'styled-components';
import { appBreakpoints } from '../../reducers';
import headerButtonStyle from './headerButtonStyle';

const HeaderButton = styled.button`
  ${headerButtonStyle}
  svg {
    color: inherit;
    fill: currentColor;
  }
  @media (min-width: ${appBreakpoints.small}px) {
    display: none;
  }
`;

export default HeaderButton;
