import { camelizeKeys } from 'humps';
import originalFetch from 'isomorphic-fetch';
import fetchRetry from 'fetch-retry';
import { datadogRum } from '@datadog/browser-rum';

const fetch = fetchRetry(originalFetch);

const defaultRequestOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const parseErrorResponse = error => {
  return error.response
    .json()
    .catch(() => {
      const errors = { base: [error.response.statusText] };
      return Promise.resolve({ errors });
    })
    .then(camelizeKeys);
};

const parseResponse = response => {
  if (!response.ok) {
    if (response.body) {
      return response.json().then(({ error, errors = [] }) => {
        const errorMessage = [...errors, error].filter(x => x).join(', ');
        const customError = new Error(errorMessage);
        customError.response = response;
        customError.status = response.status;
        throw customError;
      });
    }
    const customError = new Error(response.statusText);
    customError.response = response;
    customError.status = response.status;
    throw customError;
  }

  return response.json();
};

const request = (path, options = {}) => {
  const requestOptions = { ...defaultRequestOptions, ...options };
  return fetch(path, requestOptions).then(parseResponse).then(camelizeKeys);
};

const poll = (fn, check, timeout, interval) => {
  const endTime = Number(new Date()) + (timeout || 120000);
  interval = interval || 500;

  const checkCondition = (resolve, reject) => {
    fn()
      .then(result => {
        if (check(result)) {
          resolve(result);
        } else if (Number(new Date()) < endTime) {
          setTimeout(checkCondition, interval, resolve, reject);
        } else {
          reject(new Error(`timed out for ${fn}`));
        }
      })
      .catch(error => error);
  };
  return new Promise(checkCondition);
};

const initializeDDRUM = (email, applicantId) => {
  datadogRum.setGlobalContextProperty('usr', {
    email,
    id: applicantId,
  });
};

export { parseErrorResponse, parseResponse, request, poll, initializeDDRUM };
