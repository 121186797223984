import * as ActionTypes from '../actions';

export const initialState = {
  processing: false,
  candidateStorySubmitted: false,
  submitError: false,
  candidateStoriesEnabled: false,
  candidateStoriesGeneralInfoOnly: false,
  stories: [],
};

const candidateStories = (state = initialState, action) => {
  const {
    type,
    candidateStoriesEnabled,
    candidateStoriesGeneralInfoOnly,
    candidateStorySubmitted,
    stories,
  } = action;

  switch (type) {
    case ActionTypes.SUBMIT_CANDIDATE_STORY_REQUEST:
      return {
        ...state,
        processing: true,
      };
    case ActionTypes.SUBMIT_CANDIDATE_STORY_SUCCESS:
      return {
        ...state,
        candidateStorySubmitted: true,
        processing: false,
        submitError: false,
      };
    case ActionTypes.SUBMIT_CANDIDATE_STORY_FAILURE:
      return {
        ...state,
        candidateStorySubmitted: true,
        processing: false,
        submitError: true,
      };
    case ActionTypes.SET_CANDIDATE_STORIES_ENABLED:
      return {
        ...state,
        candidateStoriesEnabled,
      };
    case ActionTypes.SET_CANDIDATE_STORIES_GENERAL_INFO_ONLY:
      return {
        ...state,
        candidateStoriesGeneralInfoOnly,
      };
    case ActionTypes.SET_CANDIDATE_STORIES_SUBMISSION_STATUS:
      return {
        ...state,
        candidateStorySubmitted,
      };
    case ActionTypes.SET_CANDIDATE_STORIES_FROM_SUBMISSION:
      return {
        ...state,
        stories,
      };
    default:
      return state;
  }
};

export default candidateStories;
