import PropTypes from 'prop-types';
import React from 'react';
import School from './School';
import CandidateInput from './CandidateInput';
import Status from '../../Status';
import { EducationRecordObject } from '../../../../../types/report';

interface EducationHeaderProps {
  record: EducationRecordObject;
}
const EducationHeader = ({ record }: EducationHeaderProps) => (
  <div className='mb-3'>
    <div className='d-flex mb-2'>
      <div>
        {record.school && (
          // @ts-ignore record.result
          <School school={record.school} result={record.result} />
        )}
      </div>
      <div className='ml-auto'>
        <Status status={record.status} />
      </div>
    </div>
    <hr />
    {record.school && <CandidateInput school={record.school} />}
  </div>
);

EducationHeader.propTypes = {
  record: PropTypes.object.isRequired,
};

export default EducationHeader;
