// eslint-disable jsx-a11y/no-noninteractive-element-interactions
import React from 'react';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { M } from '@dashboard-experience/mastodon';
import { Input } from '../fields';
import * as V from '../../lib/validations';
import { getFieldKey } from '../../utils';

class MatrixAddress extends React.Component {
  constructor(props) {
    const { field, setField } = props;
    // Set values for any fields that are disabled
    Object.entries(field.disabledFields || {}).forEach(([key, value]) => {
      setField(`${getFieldKey(field)}.${key}`, value);
    });
    super(props);
  }

  renderAddressFields = (isInternational, field) =>
    isInternational
      ? this.renderInternationalAddressFields()
      : this.renderDomesticAddressFields();

  renderInternationalAddressFields = () => {
    return (
      <M.Grid>
        <M.Container.Row>
          <M.Container.Col>
            {this.renderAddressField('street', 'internationalStreet', [])}
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col>
            {this.renderAddressField('city', 'internationalCity', [])}
          </M.Container.Col>
          <M.Container.Col>
            {this.renderAddressField('state', 'internationalState', [])}
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col>
            {this.renderAddressField(
              'postalCode',
              'internationalPostalCode',
              [],
            )}
          </M.Container.Col>
          <M.Container.Col>
            {this.renderAddressField('country', 'internationalCountry', [])}
          </M.Container.Col>
        </M.Container.Row>
      </M.Grid>
    );
  };

  // Country is hardcoded to United States since it's the only country expected for domestic address
  // Backend supports this, see EMVR-6834 MR description for more specific code examples:
  // https://gitlab.checkrhq.net/dashboard-experience/candidate-portal/-/merge_requests/1781
  renderDomesticAddressFields = () => {
    return (
      <M.Grid>
        <M.Container.Row>
          <M.Container.Col sm={2} md={6} lg={12}>
            {this.renderAddressField('street', 'street', [])}
          </M.Container.Col>
          <M.Container.Col>
            {this.renderAddressField('unit', 'unit', [])}
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col>
            {this.renderAddressField('city', 'city', [])}
          </M.Container.Col>
          <M.Container.Col>
            {this.renderAddressField('zipcode', 'zipcode', [V.zipcodeFormat])}
          </M.Container.Col>
        </M.Container.Row>
        <M.Container.Row>
          <M.Container.Col>
            {this.renderAddressField('state', 'state', [])}
          </M.Container.Col>
          <M.Container.Col>
            {this.renderCountryField('United States')}
          </M.Container.Col>
        </M.Container.Row>
      </M.Grid>
    );
  };

  renderAddressField = (name, displayName, validations) => {
    const { field } = this.props;
    const isDisabled = Boolean(
      field.disabledFields && field.disabledFields[name],
    );

    if (name !== 'unit') validations.push(V.required);

    return (
      <Field
        testId={name}
        label={I18n.t(`labels.${displayName}`)}
        placeholder={I18n.t(`placeholders.matrixAddress.${displayName}`)}
        type='text'
        name={`${getFieldKey(field)}.${name}`}
        component={Input}
        validate={validations}
        disabled={isDisabled}
      />
    );
  };

  renderCountryField = country => {
    const { field } = this.props;
    const inputName = `${getFieldKey(field)}.country`;
    return (
      <Field
        label={I18n.t('labels.country')}
        placeholder={I18n.t(`placeholders.matrixAddress.country`)}
        type='text'
        name={inputName}
        component={Input}
        input={{
          defaultValue: country,
          name: inputName,
        }}
        disabled
      />
    );
  };

  render() {
    const { label, field, isInternational } = this.props;
    return (
      <M.Grid className='form-group matrix-label-style'>
        <M.GridRow>
          <label htmlFor='address' className='cds--label'>
            {label}
          </label>
        </M.GridRow>
        {isInternational && field.displayCommentInCandidatePortal && (
          <M.GridRow className='form-content form-comment'>
            {field.comment}
          </M.GridRow>
        )}
        <M.Container.Row>
          <M.Container.Col lg={16}>
            {this.renderAddressFields(isInternational, field)}
          </M.Container.Col>
        </M.Container.Row>
      </M.Grid>
    );
  }
}

MatrixAddress.propTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  isInternational: PropTypes.bool.isRequired,
};

export default MatrixAddress;
