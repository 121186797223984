import { request } from './helper';
import { API_STATUS_PROXY_URL } from '../constants';

/*
 * Action Types
 */

export const API_STATUS_REQUEST = 'API_STATUS_REQUEST';

export const apiStatusRequest = incidents => ({
  type: API_STATUS_REQUEST,
  incidents,
});

export const API_STATUS_SUCCESS = 'API_STATUS_SUCCESS';

export const apiStatusSuccess = incidents => ({
  type: API_STATUS_SUCCESS,
  incidents,
});

export const API_STATUS_FAILURE = 'API_STATUS_FAILURE';

export const apiStatusFailure = error => ({
  type: API_STATUS_FAILURE,
  error,
});

export const ADD_CUSTOM_INCIDENT = 'ADD_CUSTOM_INCIDENT';

export const addCustomIncident = customIncidentContentKeys => ({
  type: ADD_CUSTOM_INCIDENT,
  customIncidentContentKeys,
});

/*
 * Actions
 */

export const requestApiStatus = data => {
  return dispatch => {
    dispatch(apiStatusRequest());
    if (!API_STATUS_PROXY_URL)
      return Promise.resolve(() => {
        dispatch(apiStatusSuccess({ incidents: [] }));
      });
    return request(API_STATUS_PROXY_URL)
      .then(res => {
        const { incidents } = res;
        dispatch(apiStatusSuccess({ incidents }));
      })
      .catch(error => {
        dispatch(apiStatusFailure(error));
      });
  };
};
