import React, { ReactNode } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import styles from './NavigationFooter.module.css';

type NavigationFooterProps = {
  back?: (() => void) | null;
  backButtonClassName?: string;
  rightButtonContent?: ReactNode;
};

const NavigationFooter = ({
  back = null,
  backButtonClassName,
  rightButtonContent = null,
}: NavigationFooterProps) => {
  const backButton = () => {
    if (!back) {
      return <div />;
    }

    return (
      <M.Button
        data-testid={DISPUTES_FLOW.disputeFooterBackButton}
        className={backButtonClassName}
        kind='tertiary'
        onClick={back}
      >
        {i18n.getStr('buttons.back')}
      </M.Button>
    );
  };

  const rightButton = () => {
    if (!rightButtonContent) {
      return <div />;
    }

    return (
      <div data-testid={DISPUTES_FLOW.disputeRightButton}>
        {rightButtonContent}
      </div>
    );
  };

  return (
    <div className={`d-flex justify-content-between ${styles.styled}`}>
      {backButton()}
      {rightButton()}
    </div>
  );
};

export default NavigationFooter;
