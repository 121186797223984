import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n, moment } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { CHECKR_APPLY_BASE_URL } from '../../../../constants';
import {
  formatPhoneNumber,
  getFullLocationHours,
} from '../../../../lib/helpers';

const LocationInformation = ({
  location,
  changeLocationUrl,
  appointmentUpdateable,
}) => {
  const locationHours = getFullLocationHours(location);

  return (
    <>
      <M.GridCol md={3} style={{ marginBottom: '1.5rem' }}>
        <span className='text-very-muted'>
          <T value='components.Report.ScheduleComponent.infoPanel.screeningLocation' />
        </span>
        <br />
        {location.name}
        <br />
        {location.street}
        <br />
        {location.unit}
        {location.unit && <br />}
        {location.city}, {location.state} {location.zipcode}
        <br />
        {formatPhoneNumber(location.phoneNumber)}
        <br />
        {appointmentUpdateable && (
          <a
            href={changeLocationUrl}
            target='_blank'
            rel='noopener noreferrer'
            className='change-location-btn'
          >
            <T value='components.Report.ScheduleComponent.infoPanel.changeLocation' />
          </a>
        )}
      </M.GridCol>
      <M.GridCol md={2} style={{ marginBottom: '1.5rem' }}>
        <span className='text-very-muted'>
          <T value='components.Report.ScheduleComponent.infoPanel.businessHours' />
        </span>
        <br />
        {locationHours &&
          locationHours.map((row, idx) => {
            return (
              <p style={{ marginTop: '10px' }} key={`hours--${idx + 1}`}>
                {row.days}
                <br />
                <span className='text-very-muted'>{row.hours}</span>
              </p>
            );
          })}
      </M.GridCol>
    </>
  );
};

LocationInformation.propTypes = {
  location: PropTypes.object,
  changeLocationUrl: PropTypes.string,
  appointmentUpdateable: PropTypes.bool,
};

LocationInformation.defaultProps = {
  location: null,
  changeLocationUrl: '',
  appointmentUpdateable: null,
};

const HealthScreeningInformation = ({
  report: { drugScreening, occupationalHealthScreening, documents, account },
}) => {
  if (!drugScreening && !occupationalHealthScreening) {
    return null;
  }
  const screening = drugScreening || occupationalHealthScreening;
  const changeLocationUrl = `${CHECKR_APPLY_BASE_URL}/appointments/${account.uriName}/${screening.appointmentId}`;
  const { location, status } = screening;
  const screeningPass = documents.find(doc => doc.type === 'screening_pass');
  const pendingWithLocation = location && screening.status === 'pending';
  const pendingWithoutLocation = !location && screening.status === 'pending';

  return (
    <>
      <span>
        {pendingWithLocation && (
          <div className='alert alert-success'>
            <T value='components.Report.ScheduleComponent.confirm.postConfirm.details' />
          </div>
        )}
      </span>
      <M.Grid style={{ marginBottom: '10px' }}>
        <M.GridRow>
          {location && (
            <LocationInformation
              location={location}
              changeLocationUrl={changeLocationUrl}
              appointmentUpdateable={screening.appointmentUpdateable}
            />
          )}
          {pendingWithoutLocation && (
            <M.GridCol md={5} style={{ marginBottom: '1.5rem' }}>
              <span className='text-very-muted'>
                <T value='components.Report.ScheduleComponent.infoPanel.screeningLocation' />
              </span>
              <br />
              <a
                href={changeLocationUrl}
                target='_blank'
                rel='noopener noreferrer'
                className='change-location-btn'
              >
                <T value='components.Report.ScheduleComponent.infoPanel.chooseLocation' />
              </a>
            </M.GridCol>
          )}
          <M.GridCol md={2} style={{ marginBottom: '1.5rem' }}>
            <span className='text-very-muted'>
              <T value='components.Report.ScheduleComponent.infoPanel.passExpiration' />
            </span>
            <br />
            {screening.screeningPassExpiresAt && (
              <span>
                {moment(screening.screeningPassExpiresAt).format(
                  i18n.getStr('dateFormats.monthDayYear'),
                )}
              </span>
            )}
          </M.GridCol>
          <M.GridCol md={1} style={{ marginBottom: '1.5rem' }}>
            <span className='text-very-muted'>
              <T value='components.Report.ScheduleComponent.infoPanel.status' />
            </span>
            <br />
            <span className='status'>{status}</span>
          </M.GridCol>
        </M.GridRow>
      </M.Grid>
      {screeningPass && (
        <div style={{ float: 'right' }}>
          <M.Link
            href={screeningPass.downloadUri}
            target='_blank'
            rel='noopener noreferrer'
          >
            <M.Button kind='secondary' className='ml-auto'>
              <T value='buttons.downloadScreeningPass' />
            </M.Button>
          </M.Link>
        </div>
      )}
    </>
  );
};

HealthScreeningInformation.propTypes = {
  report: PropTypes.object.isRequired,
};

export default HealthScreeningInformation;
