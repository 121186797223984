export const validateFormat = (value, format, error) => {
  if (!value || value === '') {
    return;
  }

  if (!format.test(value)) {
    // eslint-disable-next-line consistent-return
    return error;
  }
};

// Validates dynamic submission key values for matrix exceptions
export const validateHasNoSsn = values => {
  const { noSsn } = values;
  let hasNoSsn = false;

  if (noSsn) {
    return true;
  }

  // needed for matrix exceptions dynamic field names
  for (const value of Object.values(values)) {
    if (typeof value === 'object' && value !== null && 'noSsn' in value) {
      hasNoSsn = value.noSsn;
    }
  }

  return hasNoSsn;
};
