import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';

const DrugScreening = ({ search }) =>
  !!search && (
    <Panel
      title={i18n.getStr('components.drugScreening.title')}
      status={search.status}
    >
      <M.TableContainer
        title={
          <T
            value='components.drugScreening.testResult'
            result={search.result}
          />
        }
      >
        <M.Table>
          <M.TableHead>
            <M.TableRow>
              <M.TableCell className='text-very-muted'>
                <T value='components.drugScreening.substance' />
              </M.TableCell>
              <M.TableCell className='text-very-muted'>
                <T value='components.drugScreening.result' />
              </M.TableCell>
            </M.TableRow>
          </M.TableHead>
          <M.TableBody>
            {search.illicitDrugRecords.map((record, i) => (
              <M.TableRow key={`tr-${i + 1}`}>
                <M.TableCell>{record.substanceType}</M.TableCell>
                <M.TableCell className='d-flex'>
                  <span>{record.result}</span>
                  {record.result === 'POSITIVE' && (
                    <span className='ml-auto'>
                      <M.Icon icon='WarningAltFilled' />
                    </span>
                  )}
                </M.TableCell>
              </M.TableRow>
            ))}
          </M.TableBody>
        </M.Table>
      </M.TableContainer>
    </Panel>
  );

DrugScreening.propTypes = {
  search: PropTypes.object,
};

DrugScreening.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(DrugScreening);
