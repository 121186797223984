import React, { HTMLProps } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';
import SubmitButton from '../../fields/SubmitButton';

const ButtonStyled = styled(M.Button)`
  .mastodon-icon {
    margin-right: 5px;
  }
`;

const VerificationNavigationStyled = styled.div`
  background: ${colors.white};
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.75rem;
  margin: 0;
  width: 100%;
  z-index: 1001;

  @media (min-width: 768px) {
    background: none;
    border: 0;
    padding: 0;
    position: static;
    width: auto;
    left: auto;
    bottom: auto;
  }

  display: flex;
`;

const SubmitButtonWrapperStyled = styled.span`
  margin-left: auto;
`;

interface PreviousButtonProps extends HTMLProps<HTMLButtonElement> {}

const PreviousButton: React.FC<PreviousButtonProps> = ({ onClick }) => (
  <ButtonStyled kind='secondary' type='button' onClick={onClick}>
    <M.Icon icon='ChevronLeft' /> <T value='buttons.previous' />
  </ButtonStyled>
);

interface VerificationNavigationProps {
  children?: any;
  isFinalStep: boolean;
  isValid: boolean;
  previousStep: React.MouseEventHandler<HTMLButtonElement>;
  showNextStepBtn?: boolean;
  showPreviousBtn?: boolean;
  submitting?: boolean;
  hideNavigation?: boolean;
}

const VerificationNavigation: React.FC<VerificationNavigationProps> = ({
  children = null,
  isFinalStep = false,
  isValid = false,
  previousStep = () => {},
  showNextStepBtn = false,
  showPreviousBtn = false,
  submitting = false,
  hideNavigation = false,
}) => {
  if (hideNavigation) {
    return <>children</>;
  }
  return (
    <VerificationNavigationStyled role='navigation'>
      {showPreviousBtn && (
        <div>
          <PreviousButton onClick={previousStep} />
        </div>
      )}
      {children}
      {showNextStepBtn && (
        <SubmitButtonWrapperStyled>
          &nbsp;
          <SubmitButton
            submitting={submitting}
            isFinalStep={isFinalStep}
            disabled={!isValid}
          />
        </SubmitButtonWrapperStyled>
      )}
    </VerificationNavigationStyled>
  );
};
export const GenericNavigation: React.FC<{
  previousBtn: React.ReactNode;
  nextBtn: React.ReactNode;
  children: React.ReactNode;
}> = ({ previousBtn, nextBtn, children }) => (
  <VerificationNavigationStyled role='navigation'>
    <div>{previousBtn}</div>
    {children}
    <SubmitButtonWrapperStyled>{nextBtn}</SubmitButtonWrapperStyled>
  </VerificationNavigationStyled>
);

export default VerificationNavigation;
