import { ReportSummary } from 'api/candidates/types/Report';
import isActionRequired from './isActionRequired';
import compareDates from './compareDates';

export type ReportsOrderBy = 'date' | 'status' | 'company';

export interface SortByOption<T extends string> {
  value: T;
  compare: (a: ReportSummary, b: ReportSummary) => number;
}

export type SortByOptions = {
  [key in ReportsOrderBy]: SortByOption<key>;
};

const sortByOptions: SortByOptions = {
  date: {
    value: 'date',
    compare: (a: ReportSummary, b: ReportSummary) =>
      compareDates(a.lastTouchedAt, b.lastTouchedAt),
  },
  status: {
    value: 'status',
    compare: (a: ReportSummary, b: ReportSummary): -1 | 0 | 1 => {
      const statusOrder = [
        'pending',
        'consider',
        'complete',
        'canceled',
        'suspended',
        'closed',
        'dispute',
      ];
      if (isActionRequired(a) && !isActionRequired(b)) return -1;
      if (!isActionRequired(a) && isActionRequired(b)) return 1;
      if (isActionRequired(a) && isActionRequired(b)) {
        return compareDates(a.completeByDate, b.completeByDate, {
          ascending: true,
        });
      }
      if (statusOrder.indexOf(a.status) < statusOrder.indexOf(b.status))
        return -1;
      if (statusOrder.indexOf(a.status) > statusOrder.indexOf(b.status))
        return 1;
      return compareDates(a.lastTouchedAt, b.lastTouchedAt);
    },
  },
  company: {
    value: 'company',
    compare: (a: ReportSummary, b: ReportSummary) => {
      if (a.accountName < b.accountName) return -1;
      if (a.accountName > b.accountName) return 1;
      return 0;
    },
  },
};

export default sortByOptions;
