import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { CHECKR_APPLICANT_FAQ_PAGE } from '../../constants';

const FaqButton = () => (
  <M.Link
    href={CHECKR_APPLICANT_FAQ_PAGE}
    target='_blank'
    rel='noopener noreferrer'
    className='btn-faq'
  >
    <M.Button kind='secondry'>
      <T value='buttons.helpCenter' />
    </M.Button>
  </M.Link>
);

export default FaqButton;
