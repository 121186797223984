import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { M } from '@dashboard-experience/mastodon';
import { Report } from 'types/report';
import Workflow from 'components/Workflow/Disputes/Workflow';
import {
  useWorkflowScreenStates,
  WorkflowScreenStates,
} from 'components/Workflow/hooks';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import styles from './BifurcationSelector.module.css';

interface BifurcationSelectorProps {
  candidateStoriesEnabled: boolean;
  toggle: () => void;
  toggleCandidateStoryModal: () => void;
  toggleUpdateDLModal: () => void;
  toggleLegacy: () => void;
  report: Report;
  setHasSubmitted: (param: boolean) => void;
}

const BifurcationSelector = ({
  candidateStoriesEnabled,
  toggle,
  toggleCandidateStoryModal,
  toggleUpdateDLModal,
  toggleLegacy,
  report,
  setHasSubmitted,
}: BifurcationSelectorProps) => {
  const instructionText = i18n.getStr(
    'workflows.dispute.bifurcationSelector.instructionsText',
  );
  const title = i18n.getStr('workflows.dispute.bifurcationSelector.title');

  const bifurcationScreenStates: WorkflowScreenStates = {
    bifurcationSelector: {
      transitions: {
        workflow: 'workflow',
      },
    },
    workflow: {
      transitions: {
        previous: 'bifurcationSelector',
      },
    },
  };

  const { currentScreen, transitionToScreen, previousScreen } =
    useWorkflowScreenStates('bifurcationSelector', bifurcationScreenStates);

  const cancelButton = () => {
    return (
      <M.Button
        className='analytics_bifurcation_cancel_button'
        size='sm'
        kind='secondary'
        onClick={toggle}
      >
        {i18n.getStr('buttons.cancel')}
      </M.Button>
    );
  };

  const workflow = () => {
    return (
      <Workflow
        back={previousScreen}
        candidateStoriesEnabled={candidateStoriesEnabled}
        toggle={toggle}
        toggleCandidateStoryModal={toggleCandidateStoryModal}
        toggleUpdateDLModal={toggleUpdateDLModal}
        report={report}
        setHasSubmitted={setHasSubmitted}
      />
    );
  };

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case 'bifurcationSelector':
        return bifurcationSelector();
      case 'workflow':
        return workflow();
      default:
        return '';
    }
  };

  const transitionToLegacyFlow = () => {
    toggleLegacy();
    toggle();
  };

  const bifurcationSelector = () => {
    return (
      <div>
        <ContentContainer>
          <p className={styles.styled}>{instructionText}</p>
          <p className='mb-4'>{title}</p>
          <form
            aria-label={i18n.getStr(
              'workflows.dispute.bifurcationSelector.formName',
            )}
          >
            <M.TileOptionList
              ariaLabel={i18n.getStr(
                'workflows.dispute.bifurcationSelector.formName',
              )}
            >
              <M.TileOption
                className='analytics_bifurcation_mvr_error'
                title={i18n.renderHTML(
                  'workflows.dispute.bifurcationSelector.motorVehicleReportOption',
                )}
                optionSelected={() => transitionToScreen('workflow')}
                ariaLabel={i18n
                  .getStr(
                    'workflows.dispute.bifurcationSelector.motorVehicleReportOption',
                  )
                  .replace(/<[^>]+>/g, '')}
              />
              <M.TileOption
                data-testid={DISPUTES_FLOW.disputeBifurcationNoOption}
                className='analytics_bifurcation_other_screening_error'
                title={i18n.renderHTML(
                  'workflows.dispute.bifurcationSelector.otherOption',
                )}
                optionSelected={transitionToLegacyFlow}
                ariaLabel={i18n
                  .getStr('workflows.dispute.bifurcationSelector.otherOption')
                  .replace(/<[^>]+>/g, '')}
              />
            </M.TileOptionList>
          </form>
        </ContentContainer>
        <NavigationFooter rightButtonContent={cancelButton()} />
      </div>
    );
  };

  return <div>{renderCurrentScreen()}</div>;
};

export default BifurcationSelector;
