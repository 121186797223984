import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { CANDIDATE_STORIES } from 'identifiers/identifiers';
import IconPanel from '../../../IconPanel';
import completeIcon from '../../../../images/icons/Complete.svg';

const CandidateStorySuccess = ({ company }) => (
  <IconPanel
    data-testid={CANDIDATE_STORIES.candidateStorySuccess}
    translationKey='components.Report.CandidateStories.success'
    detailsCustomValue={{ company }}
    icon={
      <img
        src={completeIcon}
        alt={i18n.getStr('components.Report.CandidateStories.success.sent')}
      />
    }
    detailsCentered
  />
);

CandidateStorySuccess.propTypes = {
  company: PropTypes.string.isRequired,
};

export default i18n.renderTranslation()(CandidateStorySuccess);
