import { validateFormat } from './helperValidations';
import { EMAIL, NAME, SIGNATURE_FORMAT, ZIPCODE_FORMAT } from './messages';

const emailFormat = value => {
  const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;

  return validateFormat(value, regexp, EMAIL);
};

const nameFormat = value => {
  const regexp = /^[a-zA-Z0-9ªµºÀ-ÖØ-öø-ÿ\\-\\'\\.\\, ]*$/;

  return validateFormat(value, regexp, NAME);
};

const signatureFormat = (value, allValues) => {
  const firstName = `${allValues.firstName.trim()} `;
  const lastName = allValues.lastName.trim();

  const middleName = allValues.middleName
    ? `${allValues.middleName.trim()} `
    : '';

  const validNames = [firstName + middleName + lastName, firstName + lastName];

  const isValid = validNames.indexOf(value) !== -1;

  if (!isValid) {
    return {
      message: SIGNATURE_FORMAT,
      firstName,
      middleName,
      lastName,
    };
  }
  return null;
};

const zipcodeFormat = value => {
  const regexp = /^\d{5}$/;

  return validateFormat(value, regexp, ZIPCODE_FORMAT);
};

export { emailFormat, nameFormat, signatureFormat, zipcodeFormat };
