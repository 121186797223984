import { OccupationalHealthScreeningTest } from 'types/report';
import { CANCELED_STATUS } from './constants';

interface OccupationalHealthCategorizedTestResults {
  name: string;
  tests: OccupationalHealthScreeningTest[];
}

export const shouldShowScreening = (status: string) =>
  status !== CANCELED_STATUS;

export const showDrugDispositionIndicator = (
  disposition: string,
  quantity: number,
): boolean => !!(disposition.toLowerCase() === 'positive' || quantity > 0);

export const showOccupationalHealthDispositionIndicator = (
  disposition: string | undefined,
  status: string,
): boolean => !!(disposition && status?.toLowerCase() !== 'clear');

export const getOccupationalHealthCategorizedTestResults = (
  tests: OccupationalHealthScreeningTest[],
): OccupationalHealthCategorizedTestResults[] => {
  const filteredTests = tests.filter(({ status }) =>
    shouldShowScreening(status),
  );

  return filteredTests.reduce(
    (
      accumulator: OccupationalHealthCategorizedTestResults[],
      test: OccupationalHealthScreeningTest,
    ) => {
      const category = accumulator.find(({ name }) => name === test.category);
      if (category) {
        const newObj = { ...category, tests: [...category.tests, test] };
        const exisitingCategoies = accumulator.filter(
          ({ name }) => name !== test.category,
        );
        return [...exisitingCategoies, newObj];
      }
      return [...accumulator, { name: test.category, tests: [test] }];
    },
    [],
  );
};
