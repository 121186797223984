import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { getSections, getArticles, allScreenings } from '../lib/screenings';

const DisputeTree = () => (
  <div className='p-5'>
    <h3>
      <T value='components.disputeTree.title' />
    </h3>
    <ul>
      {getSections(allScreenings()).map(section => {
        return (
          <li className='mb-4' key={section.id}>
            <strong>{section.name}</strong>{' '}
            {section.labels && section.labels.indexOf('charges') > -1 && (
              <span className='badge badge-success'>
                <T value='components.disputeTree.showCharges' />
              </span>
            )}
            <ul>
              {getArticles(section.name).map(article => {
                return (
                  <li className='mb-1' title={article.body} key={article.id}>
                    <a
                      href={
                        `https://checkr.zendesk.com/knowledge/` +
                        `articles/${article.id}/en-us?brand_id=7699428&return_to=%2Fhc%2Fadmin%2Farrange_contents`
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {article.name}
                    </a>{' '}
                    {article.labels && article.labels.length === 0 && (
                      <span className='badge badge-dark'>
                        <T value='components.disputeTree.showMessage' />
                      </span>
                    )}
                    {article.labels &&
                      article.labels.indexOf('reason_mvr') > -1 && (
                        <span className='badge badge-warning'>
                          <T value='components.disputeTree.motorVehicleRecord' />
                        </span>
                      )}
                    {article.labels &&
                      article.labels.indexOf('reason_wrong_person') > -1 && (
                        <span className='badge badge-primary'>
                          <T value='components.disputeTree.wrongPerson' />
                        </span>
                      )}
                    {article.labels &&
                      article.labels.indexOf('reason_inaccurate') > -1 && (
                        <span className='badge badge-primary'>
                          <T value='components.disputeTree.inaccurateIncompleteInformation' />
                        </span>
                      )}
                    {article.labels &&
                      article.labels.indexOf('reason_employment') > -1 && (
                        <span className='badge badge-danger'>
                          <T value='components.disputeTree.employment' />
                        </span>
                      )}
                    {article.labels &&
                      article.labels.indexOf('reason_eviction') > -1 && (
                        <span className='badge badge-danger'>
                          <T value='components.disputeTree.eviction' />
                        </span>
                      )}
                    {article.labels &&
                      article.labels.indexOf('reason_credit') > -1 && (
                        <span className='badge badge-danger'>
                          <T value='components.disputeTree.credit' />
                        </span>
                      )}
                  </li>
                );
              })}
            </ul>
          </li>
        );
      })}
    </ul>
  </div>
);

export default DisputeTree;
