import React, { ReactNode } from 'react';

import styled from 'styled-components';

const LegendStyled = styled.legend`
  font-size: 1rem;
  font-weight: bold;
`;

const TileCheckboxGroup = ({
  showLegend = false,
  children,
  name,
}: {
  showLegend?: boolean;
  children: ReactNode;
  name: string;
}) => (
  <fieldset className='tile-checkbox-group mb-4' aria-label={name}>
    {showLegend ? <LegendStyled>{name}</LegendStyled> : null}
    {children}
  </fieldset>
);

export default TileCheckboxGroup;
