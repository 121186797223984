import {
  syncTranslationWithStore,
  loadTranslations,
  setLocale,
} from 'react-redux-i18n';

import { i18n, moment } from '@international/mastodon-i18n';
import portalStore from 'providers/portalStore';
import translations from './lib/translations';
import resources from './lib/translations/resources';

import { getResourceLocale, isInternational } from './lib/helpers';

const initializeTranslations = () => {
  const international = isInternational();
  let currentLocale = i18n.defaultLocale;
  let redirectUrl = null;
  if (international && window?.location?.href) {
    const url = new URL(window.location.href);
    if (url.searchParams.has('lang')) {
      currentLocale = url.searchParams.get('lang');
      url.searchParams.delete('lang');
      redirectUrl = url;
    } else {
      currentLocale = i18n.getCookieLocale();
    }
  }
  // Sync and load internationalizations
  syncTranslationWithStore(portalStore);
  portalStore.dispatch(loadTranslations(translations));
  i18n.init({ resources: resources() });
  i18n.setLocale({
    value: currentLocale,
    resources: resources(),
    country: international ? i18n.getWorkLocation() : null,
  });
  portalStore.dispatch(
    setLocale(
      international
        ? getResourceLocale(resources(), currentLocale)
        : currentLocale,
    ),
  );

  if (!international) {
    moment.locale(i18n.defaultLocale);
  }
  if (document?.documentElement?.lang) {
    document.documentElement.lang = currentLocale;
  }
  if (redirectUrl) {
    window.history.pushState({}, '', redirectUrl.href);
  }
};

export default initializeTranslations;
