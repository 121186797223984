import PropTypes from 'prop-types';
import React from 'react';

import ClearinghouseSearch from './Screenings/ClearinghouseSearch';

const ClearinghouseReport = ({ report }) => (
  <div data-testid='clearing-house-report'>
    <ClearinghouseSearch search={report.clearinghouseSearch} />
  </div>
);

ClearinghouseReport.propTypes = {
  report: PropTypes.object.isRequired,
};

export default ClearinghouseReport;
