import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import PropTypes from 'prop-types';
import { MVR_MATRIX_TYPES } from '../../constants';

import SecurityNotice from './SecurityNotice';

const MissingInformationHeader = ({ matrixFields }) => {
  const isMvrMatrixType =
    matrixFields?.some(field => MVR_MATRIX_TYPES.includes(field)) ?? false;
  return (
    <M.Grid>
      <M.Container>
        <M.GridRow>
          <M.GridCol lg={16}>
            <h2 data-testid='missing-information-header'>
              <T value='components.Verification.MissingInformationHeader.title' />
            </h2>
          </M.GridCol>
        </M.GridRow>
        <br />
        <M.GridRow>
          <M.GridCol>
            <p>
              {isMvrMatrixType ? (
                <T value='components.Verification.MissingInformationHeader.mvr_matrix_type.details' />
              ) : (
                <T value='components.Verification.MissingInformationHeader.details' />
              )}
            </p>
          </M.GridCol>
        </M.GridRow>
        <br />
        {isMvrMatrixType && (
          <M.Container.Row>
            <SecurityNotice logoHeight='32px' smallerTitle />
          </M.Container.Row>
        )}
      </M.Container>
    </M.Grid>
  );
};

MissingInformationHeader.propTypes = {
  matrixFields: PropTypes.node,
};

MissingInformationHeader.defaultProps = {
  matrixFields: [],
};

export default MissingInformationHeader;
