import React, { useCallback, useLayoutEffect } from 'react';
import { i18n } from '@international/mastodon-i18n';

import IntlLoginForm from 'components/Reports/IntlLoginForm';
import MainContainer from 'components/MainContainer';
import { fetchCandidateReportsIntl, clearLoggedOut } from 'actions';
import ToastNotification from 'components/ToastNotification';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { PATHS } from '../../../../constants';

const InternationalPrivacyLogin: React.FC = () => {
  const { loggedOut, processing, error, loggedIn } = useSelector(
    (state: any) => state.portal,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleSubmit = useCallback(
    (values: any) => {
      dispatch(fetchCandidateReportsIntl(values));
    },
    [dispatch],
  );

  useLayoutEffect(() => {
    if (loggedIn) {
      navigate(state?.prevLocation || `${PATHS.INTERNATIONAL}/privacy/delete`);
    } else if (error) {
      navigate('#login_error', { state });
    }
  }, [error, loggedIn, navigate, state]);

  return (
    <MainContainer>
      {loggedOut && (
        <ToastNotification
          title={i18n.getStr('components.successFullyLoggedOut')}
          kind='success'
          onClose={() => {
            clearLoggedOut();
          }}
        />
      )}
      <IntlLoginForm
        onSubmit={handleSubmit}
        processing={processing}
        fetchError={error}
        loginSource='InternationalLoginForm'
      />
    </MainContainer>
  );
};

export default InternationalPrivacyLogin;
