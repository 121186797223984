// eslint-disable jsx-a11y/no-noninteractive-element-interactions
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { I18n } from 'react-redux-i18n';
import { Upload } from '../fields';
import { required1Document } from '../../lib/validations';
import '../../scss/MatrixDocumentUpload.scss';
import MatrixDocumentUploadDetail, {
  DETAIL_LIST,
} from './MatrixDocumentUploadDetail';

class MatrixDocumentUpload extends React.Component {
  constructor(props) {
    super(props);

    // If download button is rendered then disable the upload button untill download button is clicked.
    const { hasDownloadFile } = this.props;
    this.state = { disableUploadButton: hasDownloadFile };
  }

  renderUploadedDocuments = () => {
    const { matrixField, formValue, removeFile } = this.props;
    const fieldType = matrixField.type;

    if (formValue && formValue[fieldType] && formValue[fieldType].length > 0) {
      return (
        <M.Container.Row className='form-content'>
          {formValue[fieldType].map((doc, index) => (
            <M.Container.Col
              lg={16}
              key={doc.uploadId}
              className='v1-success-block'
            >
              <div>
                <M.Icon icon='CheckmarkFilled' size={16} />{' '}
              </div>
              <span>
                <strong>{doc.filename}</strong>
              </span>
              <M.Button kind='tertiary' onClick={() => removeFile(index)}>
                {I18n.t('buttons.remove')}
              </M.Button>
            </M.Container.Col>
          ))}
        </M.Container.Row>
      );
    }

    return null;
  };

  renderDocumentUploadButton = () => {
    const { matrixField, options, formValue } = this.props;
    // 10MB file size limit
    const key = matrixField.id || matrixField.name;
    const fieldType = matrixField.type;

    // condition for supporting initial button upload and for mulliple files upload
    if (
      !formValue ||
      !formValue[fieldType] ||
      formValue[fieldType].length < options.maxFiles
    ) {
      const { disableUploadButton } = this.state;
      return (
        <Field
          type='upload'
          name={`${key}.${fieldType}`}
          buttonText={i18n.getStr('buttons.upload')}
          component={Upload}
          options={{ maxSize: 10485760, ...options }}
          validate={[required1Document]}
          v1
          disabled={disableUploadButton}
        />
      );
    }

    return null;
  };

  renderDownload = () => {
    const { matrixField, hasDownloadFile } = this.props;
    if (hasDownloadFile) {
      return (
        <M.GridRow className='form-content v1-success-block'>
          <span>{matrixField.document.filename}</span>
          <M.Button
            kind='secondary'
            href={matrixField.document.downloadUri}
            download={matrixField.document.filename}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              // Make upload Button accessible after download button is cliked.
              this.setState({ disableUploadButton: false });
            }}
          >
            <M.Icon icon='Download' size={16} />
            &nbsp;{i18n.getStr('buttons.download')}
          </M.Button>
        </M.GridRow>
      );
    }

    return null;
  };

  renderDetails = () => {
    const { matrixField, mvrConsent } = this.props;

    const fieldName =
      mvrConsent && matrixField.name === 'dl_documentation_requested'
        ? 'mvr_consent_documentation_requested'
        : matrixField.name;

    if (Object.keys(DETAIL_LIST).includes(matrixField.name)) {
      return (
        <MatrixDocumentUploadDetail
          matrixField={{ ...matrixField, ...{ name: fieldName } }}
        />
      );
    }

    return null;
  };

  render() {
    const { matrixField, label } = this.props;

    return (
      <M.Grid className='form-group matrix-label-style'>
        <M.GridRow className='form-content form-title'>
          <label htmlFor={matrixField.name} className='cds--label'>
            {label}
          </label>
        </M.GridRow>
        {this.renderDetails(matrixField)}
        {matrixField.displayCommentInCandidatePortal && (
          <M.GridRow className='form-content form-comment'>
            {matrixField.commentCandidate || matrixField.comment}
          </M.GridRow>
        )}
        {this.renderDownload()}
        {this.renderUploadedDocuments()}
        <M.Container.Row>{this.renderDocumentUploadButton()}</M.Container.Row>
      </M.Grid>
    );
  }
}

MatrixDocumentUpload.propTypes = {
  label: PropTypes.string.isRequired,
  formValue: PropTypes.object.isRequired,
  matrixField: PropTypes.object.isRequired,
  removeFile: PropTypes.func.isRequired,
  options: PropTypes.object,
  hasDownloadFile: PropTypes.bool,
  mvrConsent: PropTypes.bool,
};

MatrixDocumentUpload.defaultProps = {
  options: {},
  hasDownloadFile: false,
  mvrConsent: false,
};

export default MatrixDocumentUpload;
