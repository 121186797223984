import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../../Panels';
import Record from '../../Record';
import withNonCanceledSearch from '../WithNonCanceledSearch';

const IntlEmploymentVerification = ({ search }) => {
  return (
    <Panel
      data-testid='intl-emp-verification'
      title={i18n.getStr('components.internationalEmploymentVerification')}
      status={search.status}
    >
      {Array.isArray(search.records) &&
        (!search.records.length ? (
          <span>
            <T value='components.noEmploymentHistory' />
          </span>
        ) : (
          search.records.map((record, i) => (
            <React.Fragment key={`intl-emp-record-${i + 1}`}>
              <Record
                record={record}
                type='internationalEmploymentVerification'
              />
              {i < search.records.length - 1 && <hr />}
            </React.Fragment>
          ))
        ))}
    </Panel>
  );
};

IntlEmploymentVerification.propTypes = {
  search: PropTypes.object,
};

IntlEmploymentVerification.defaultProps = {
  search: null,
};

export default withNonCanceledSearch(IntlEmploymentVerification);
