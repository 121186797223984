import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const PicVSRegulatoryRequirements = () => {
  return (
    <div>
      <strong>
        <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsTitle' />
      </strong>
      <br />
      <br />
      <div className='mastodon-unordered-list-container'>
        <ul className='cds--list--unordered mastodon-unordered-list'>
          <li className='cds--list__item mastodon-list-item'>
            <p>
              <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection1' />
            </p>
            <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
              <li className='cds--list__item mastodon-list-item '>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                  </strong>
                </p>
              </li>
            </ul>
          </li>
          <li className='cds--list__item mastodon-list-item'>
            <p>
              <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection2' />
            </p>
            <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
              <li className='cds--list__item mastodon-list-item '>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                  </strong>
                </p>
              </li>
            </ul>
          </li>
          <li className='cds--list__item mastodon-list-item'>
            <p>
              <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3' />
            </p>
            <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_1' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_2' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_3' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_4' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_5' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_6' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_7' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_8' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_9' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_10' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_11' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_12' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_13' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_14' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection3_15' />
                </p>
              </li>
            </ul>
          </li>
          <li className='cds--list__item mastodon-list-item'>
            <p>
              <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection4' />
            </p>
            <ul className='cds--list--unordered mastodon-unordered-list cds--list--nested'>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection4_1' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection4_2' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection4_3' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection4_4' />
                </p>
              </li>
              <li className='cds--list__item mastodon-list-item'>
                <p>
                  <strong>
                    <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsNotEligible' />
                    &nbsp;
                  </strong>
                  <T value='components.intlCanadaBcEnhancedCriminal.picVsRegulatoryRequirementsSection4_5' />
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PicVSRegulatoryRequirements;
