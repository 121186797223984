import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import { inputErrorClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const Text = ({
  autoFocus,
  disabled,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  type,
  onPaste,
  dateformat,
  testId,
}) => {
  const [value, setValue] = React.useState('');
  const [placeholderText, setPlaceholder] = React.useState('');
  const inputRef = React.useRef(null);
  const onChangeHandler = e => {
    setValue(e.target.value);
    input.onChange(e);
  };

  React.useEffect(() => {
    if (placeholder || label) setPlaceholder(placeholder || label);
    const initialValue = inputRef.current?.value;
    if (initialValue) setValue(initialValue);
  }, [placeholder, label]);

  return (
    <div className='form-group'>
      <Label
        label={label}
        htmlFor={input.name}
        error={Boolean(error)}
        touched={touched}
        dateformat={dateformat}
      />
      <input
        {...input}
        id={input.name ? input.name.replace('.', '-') : 'input-name'}
        type={type}
        className={`form-control ${inputErrorClass(touched, error)}`}
        placeholder={i18n.getStr(placeholderText)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        ref={inputRef}
        disabled={disabled}
        onPaste={onPaste}
        value={value}
        onInput={onChangeHandler}
        data-testid={testId}
      />
      <FeedbackLabel {...{ touched, error, warning }} />
    </div>
  );
};

Text.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  onPaste: PropTypes.func,
  type: PropTypes.string,
  dateformat: PropTypes.string,
  testId: PropTypes.string,
};

Text.defaultProps = {
  autoFocus: false,
  disabled: false,
  input: {},
  label: '',
  meta: {},
  placeholder: '',
  onPaste: () => {},
  type: '',
  dateformat: '',
  testId: '',
};

export default i18n.renderTranslation()(Text);
