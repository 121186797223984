import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Auth from 'containers/Auth';
import useToken from 'utils/useToken';
import { ErrorPanel } from '../Panels';
import { requestPiiDeletion } from '../../actions/privacy';
import reportIcon from '../../images/icons/Report.svg';
import IconPanel from '../IconPanel';
import MainContainer from '../MainContainer';
import Loading from '../Loading';
import { readFromSessionStorage } from '../../lib/helpers';
import {
  PATHS,
  SESSION_STORAGE_CANDIDATE_IDS_KEY,
  SESSION_STORAGE_CONTACT_EMAIL_KEY,
  SESSION_STORAGE_CONTACT_NAME_KEY,
} from '../../constants';

interface DeleteComponentProps {
  international?: boolean;
}

const getFromStore = (state: any) => {
  return {
    deletionRequest: state.privacy.deletionRequest,
    candidate_ids: readFromSessionStorage(
      SESSION_STORAGE_CANDIDATE_IDS_KEY,
      state.privacy.candidate_ids,
    ),
    contact_email: readFromSessionStorage(
      SESSION_STORAGE_CONTACT_EMAIL_KEY,
      state.privacy.contact_email,
    ),
    contact_name: readFromSessionStorage(
      SESSION_STORAGE_CONTACT_NAME_KEY,
      state.privacy.contact_name,
    ),
  };
};

// TODO Link Delete MY Data button to API and show deleteSubmitted panel upon return
const DeleteComponent: React.FC<DeleteComponentProps> = ({
  international = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useToken();
  const { candidate_ids, contact_email, contact_name, deletionRequest } =
    useSelector(getFromStore);
  const [isChecked, setIsChecked] = React.useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = React.useState(false);
  const [keepData, setKeepData] = React.useState(false);

  const toggleCheckbox = () => {
    setIsChecked(state => !state);
  };

  const onDeletionClick = () => {
    dispatch(
      requestPiiDeletion({
        candidate_ids,
        contact_email,
        contact_name,
        international,
      }),
    );
    setDeleteSubmitted(true);
  };

  const redirectKeep = () => {
    setKeepData(true);
  };

  const viewMyReport = () => {
    navigate(
      international
        ? PATHS.INTERNATIONAL_REPORTS_OVERVIEW
        : PATHS.REPORTS_OVERVIEW,
    );
  };

  const btnColor = isChecked ? 'btn-danger' : 'btn-grey';
  const { processing, success, error } = deletionRequest;

  if (processing) {
    return <Loading />;
  }

  const viewReportBtn = (
    <M.Button kind='primary' className='btn-mobile' onClick={viewMyReport}>
      <T value='buttons.viewMyReport' />
    </M.Button>
  );

  // TODO: What to do with failure? Special IconPanel string? ????
  if (deleteSubmitted && success) {
    return (
      <Auth token={token}>
        <MainContainer>
          <IconPanel
            data-testid='privacy-delete-data'
            translationKey='components.Privacy.deleteSubmitted'
            icon={<M.Icon icon='TrashCan' />}
            nonBoldInfo
            infoTranslationProps={{ contact_email }}
            noDetails
          />
        </MainContainer>
      </Auth>
    );
  }

  if (keepData) {
    return (
      <MainContainer>
        <IconPanel
          data-testid='privacy-delete-data'
          translationKey='components.Privacy.keepData'
          icon={
            <img
              src={reportIcon}
              alt={i18n.getStr('components.Privacy.deleteData.dataFound')}
            />
          }
          actions={viewReportBtn}
          noDetails
        />
      </MainContainer>
    );
  }

  return (
    <Auth token={token}>
      <MainContainer>
        {error && <ErrorPanel componentName='Privacy' />}
        <IconPanel
          translationKey='components.Privacy.deleteData'
          icon={<M.Icon icon='TrashCan' />}
          nonBoldInfo
          noDetails
          panelHeaderRed
          data-testid='privacy-delete-data'
          actions={
            <>
              <div>
                <label className='no-bold' htmlFor='deletePanelInput'>
                  <input
                    id='deletePanelInput'
                    type='checkbox'
                    checked={isChecked}
                    onChange={toggleCheckbox}
                  />
                  <T
                    value='components.Privacy.deleteData.label'
                    dangerousHTML
                  />
                </label>
              </div>
              <div>
                <M.Button
                  kind='secondary'
                  className='panel-btn btn-mobile'
                  onClick={redirectKeep}
                >
                  <T value='buttons.keepMyData' />
                </M.Button>
              </div>
              <div>
                <button
                  type='button'
                  disabled={!isChecked}
                  className={`btn panel-btn ${btnColor} btn-mobile`}
                  onClick={onDeletionClick}
                >
                  <T value='buttons.deleteMyData' />
                </button>
              </div>
            </>
          }
        />
      </MainContainer>
    </Auth>
  );
};

export default DeleteComponent;
