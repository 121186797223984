import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import Breadcrumb from 'components/Workflow/Breadcrumb';
import ContentContainer from 'components/Workflow/ContentContainer';
import { Report } from 'types/report';

const ConfirmDriverLicenseUpdate = ({
  isDifferentLicenseNumber,
  isSameLicenseNumber,
  report,
}: {
  isDifferentLicenseNumber: () => void;
  isSameLicenseNumber: () => void;
  report: Report;
}) => {
  const {
    candidate: { driverLicenseState, driverLicenseNumber },
  } = report;

  return (
    <div>
      <Breadcrumb
        crumbKeys={[
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.title',
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.confirmScreen.title',
        ]}
      />

      <ContentContainer>
        <div role='document' data-testid='confirm-dl-update'>
          {i18n.renderHTML(
            'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.confirmScreen.confirmationMessage',
            [driverLicenseNumber, driverLicenseState],
          )}
        </div>
        <div className='d-flex justify-content-center p-4'>
          <M.Button kind='secondary' onClick={isSameLicenseNumber}>
            {i18n.getStr(
              'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.confirmScreen.confirmationNo',
            )}
          </M.Button>

          <M.Button kind='primary' onClick={isDifferentLicenseNumber}>
            {i18n.getStr(
              'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.confirmScreen.confirmationYes',
            )}
          </M.Button>
        </div>
      </ContentContainer>
    </div>
  );
};

export default ConfirmDriverLicenseUpdate;
