import PropTypes from 'prop-types';
import React from 'react';

import Attribute from '../Attribute';

const ChildAbuseRecord = ({ record }) => (
  <div className='criminal-record'>
    <div className='mb-4 mt-4'>
      <Attribute
        key='attribute-registry'
        attr='registry'
        value={record.registry}
      />
    </div>
  </div>
);

ChildAbuseRecord.propTypes = {
  record: PropTypes.object.isRequired,
};

export default ChildAbuseRecord;
