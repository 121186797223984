import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import EducationRecord from '../Record/Education/Education';
import withNonCanceledSearch from './WithNonCanceledSearch';
import { EducationVerificationObject } from '../../../../types/report';

interface EducationVerificationProps {
  search: EducationVerificationObject;
}

const EducationVerification = ({ search }: EducationVerificationProps) => (
  <Panel
    title={i18n.getStr('components.education.title')}
    status={search.status}
  >
    {!search.records.length ? (
      <span>
        <T value='components.education.noEducationHistory' />
      </span>
    ) : (
      search.records.map((record, i) => (
        <div key={`div-${i + 1}`}>
          <EducationRecord record={record} />
          {i < search.records.length - 1 && <hr />}
        </div>
      ))
    )}
  </Panel>
);
EducationVerification.propTypes = {
  search: PropTypes.object,
};

EducationVerification.defaultProps = {
  search: null,
};

// @ts-ignore
export default withNonCanceledSearch(EducationVerification);
