import { QueryKey } from '@tanstack/react-query';
import portalQueryClient from '../../providers/queryClient';
import { UseFlagrParams } from './useFlagr';
import { fetchFlagr } from './flagrActions';
import { UseFlagrResponse } from './lib/setFlagrResponse';

export const getFlagrQuery = async (
  { id, context = {}, entity = {}, fallbackVariant = '' }: UseFlagrParams,
  queryClient = portalQueryClient,
) => {
  const queryKey: QueryKey = ['flagr', id, context, entity];
  const queryFn = () => fetchFlagr(id, context, entity);
  const response = await queryClient?.fetchQuery<UseFlagrResponse>({
    queryKey,
    queryFn,
    ...{
      staleTime: Infinity,
      retry: false,
    },
  });
  if (!response) return { flag: { variantKey: fallbackVariant } };
  return {
    flag: response.flag,
    context: response.context,
  };
};
