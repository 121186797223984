import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import Faq from 'components/Faq';
import HelpfulToKnow from 'components/HelpfulToKnow';

export const StyledFaq = styled(Faq)`
  width: 100%;
  ul {
    list-style-type: none;
    padding-left: 0;
    li {
      padding-top: 20px;
      padding-bottom: 14px;
      border-bottom: 1px solid #cbd2db;
    }
  }
`;
export const StyledDivider = styled(M.Divider)`
  width: 100%;
  margin: 28px 0 !important;
`;
export const StyledHelpfulToKnow = styled(HelpfulToKnow)`
  padding-bottom: 0;
  margin-bottom: 0 !important;
  .mastodon-key-value-list .mastodon-key-value-line-item:last-child {
    margin-bottom: 0 !important;
  }
`;
