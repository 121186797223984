export interface UseFlagrResponse extends Record<string, any> {
  flag: any;
  context: Record<string, any>;
}

const setFlagrResponse = ({
  context,
  entity,
  data,
}: {
  context: Record<string, any>;
  entity: Record<string, any>;
  data: Record<string, any>;
}): UseFlagrResponse => {
  return {
    flag: data,
    context,
    type: entity.type || 'account',
  };
};

export default setFlagrResponse;
