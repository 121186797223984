import React from 'react';
import UploadButton from 'components/fields/UploadButton';
import { useFlagr } from 'api/flagr';
import {
  FLAGR_KEYS,
  MVR_DOC_TYPES_WITH_ONE_FILE_LIMIT,
} from '../../../constants';
import Form from '../../BasicForm';
import SsnVerificationWelcomePage from '../VerificationWelcomePage/SsnVerificationWelcomePage';
import VerificationNavigation from './VerificationNavigation';
import { DisplayComponents } from './DisplayComponents';

interface VerificationWrapperProps {
  showFirstPage: any;
  children: any;
  canceled: any;
  completed: any;
  currentPage: any;
  doc: any;
  firstStep: any;
  showNextStepBtn: any;
  showPreviousBtn: any;
  steps: any;
  expired: any;
  isValid: any;
  isFinalStep: any;
  isSmallOrDown: any;
  needUpload: any;
  submitting: boolean;
  showNav: boolean;
  verification: any;
  handlePreviousPage: any;
  wizardOnSubmit: any;
  suppressNavUpload?: boolean;
}

export const VerificationWrapper: React.FC<VerificationWrapperProps> = ({
  showFirstPage,
  children,
  canceled,
  completed,
  currentPage,
  doc,
  firstStep,
  showNextStepBtn,
  showPreviousBtn,
  steps,
  expired,
  isValid,
  isFinalStep,
  isSmallOrDown,
  needUpload,
  submitting,
  verification,
  handlePreviousPage,
  wizardOnSubmit,
  suppressNavUpload = false,
  showNav,
}) => {
  const {
    flag: { variantKey: newSsnResolutionFlow },
  } = useFlagr({
    context: {
      locationHost: window.location.host,
      candidateEmail: verification.email,
    },
    id: FLAGR_KEYS.newSsnResolutionFlow,
    entity: { id: verification.email, type: 'candidateEmail' },
    fallbackVariant: 'off',
  });
  if (newSsnResolutionFlow === '') return null;
  if (
    newSsnResolutionFlow === 'on' &&
    showFirstPage &&
    firstStep &&
    !completed &&
    !expired &&
    !canceled
  ) {
    return (
      // @ts-ignore
      <Form onSubmit={wizardOnSubmit} key='form-1'>
        <SsnVerificationWelcomePage verification={verification} />
      </Form>
    );
  }
  return (
    <div>
      <DisplayComponents
        showFirstPage={showFirstPage}
        firstStep={firstStep}
        completed={completed}
        expired={expired}
        verification={verification}
        steps={steps}
        canceled={canceled}
      />
      {!completed &&
        !canceled &&
        !expired &&
        /* Redux-form does not collect field-level validations correctly on step change.
          It does not detect new fields registering and
          the validation that iterates through field-level validations
          function will be returned as undefined.
          unless the Form component is remounted on each Step.
          Looping through the steps forces remount
        */
        steps.map(
          (_: unknown, i: number) =>
            currentPage === i && (
              // @ts-ignore
              <Form onSubmit={wizardOnSubmit} key={`form-${i + 1}`}>
                {children}
                {showNav && (
                  <VerificationNavigation
                    isValid={isValid}
                    previousStep={handlePreviousPage}
                    submitting={submitting}
                    isFinalStep={isFinalStep}
                    showPreviousBtn={showPreviousBtn}
                    showNextStepBtn={showNextStepBtn}
                  >
                    {needUpload &&
                      !suppressNavUpload &&
                      (!isValid || !isSmallOrDown) && (
                        <UploadButton
                          kind={isValid ? 'secondary' : 'primary'}
                          name={doc.model}
                          context='verifications'
                          fileOptions={
                            MVR_DOC_TYPES_WITH_ONE_FILE_LIMIT.includes(
                              doc.model,
                            )
                              ? { maxFiles: 1 }
                              : {}
                          }
                        />
                      )}
                  </VerificationNavigation>
                )}
              </Form>
            ),
        )}
    </div>
  );
};
