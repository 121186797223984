import { i18n } from '@international/mastodon-i18n';

/**
 * @name shortDatePattern
 * @function
 * @memberOf helpers
 * @description Returns localized short date patterns in country default locale
 * @returns {String}
 */
export const shortDatePattern = (): any => {
  const shortDate = i18n.getLocalizedDateFormat(
    i18n.DateFormats.DATE_SHORT,
    i18n.getMomentLocale(),
  );
  return shortDate;
};

/**
 * @name longDatePattern
 * @function
 * @memberOf helpers
 * @description Returns localized short date patterns in country default locale
 * @returns {String}
 */
export const longDatePattern = (): any => {
  const longDate = i18n.getLocalizedDateFormat(
    i18n.DateFormats.DATE_LONG,
    i18n.getMomentLocale(),
  );
  return longDate;
};

/**
 * @name shortDateTimePattern
 * @function
 * @memberOf helpers
 * @description Returns localized short datetime patterns in country default locale
 * @returns {String}
 */
export const shortDateTimePattern = (): any => {
  const shortDateTime = i18n.getLocalizedDateFormat(
    i18n.DateFormats.DATETIME_SHORT,
    i18n.getMomentLocale(),
  );
  return shortDateTime;
};

/**
 * @name slashDatePattern
 * @function
 * @memberOf helpers
 * @description Returns localized slash date patterns in country default locale
 * @returns {String}
 */
export const slashDatePattern = (): any => {
  const slashDate = i18n.getLocalizedDateFormat(
    i18n.DateFormats.DATE_SLASH,
    i18n.getMomentLocale(),
  );
  return slashDate;
};
