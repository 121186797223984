import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { useClickOutside } from '@dashboard-experience/utils';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LanguageToggle from './LanguageToggle';
import { logoutCandidate } from '../../actions';
import HeaderMenuContainer from './HeaderMenuContainer';
import HeaderButton from './HeaderButton';
import HeaderHelpcenterLink from './HeaderHelpCenterLink';
import { PATHS } from '../../constants';

const LogOutButtonStyled = styled(M.Button)`
  color: ${colors.white} !important;
`;

const LiWrapperStyled = styled.li`
  @media (max-width: 767px) {
    padding-left: 1.75rem;
  }
`;

const HeaderMenu: React.FC<{
  isInternational: boolean;
}> = ({ isInternational }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogged = false, isGreaterThanSmall = true } = useSelector<
    any,
    { isLogged: boolean; isGreaterThanSmall: boolean }
  >(state => ({
    isLogged: state.portal.loggedIn || state.candidateLogin.loggedIn,
    isGreaterThanSmall: state.browser.greaterThan.small,
  }));
  const menuRef = React.useRef<HTMLDivElement>(null);
  const closeMenuOpen =
    ({ cb = () => {}, blur = false }: { cb?: Function; blur?: boolean } = {}) =>
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (blur) (e.target as HTMLButtonElement | HTMLAnchorElement).blur();
      setTimeout(() => {
        setIsOpen(false); // setTimeout to avoids menu button conflict
        cb();
      }, 50);
    };
  const toggleMenuOpen = () =>
    setIsOpen((previousValue: boolean) => !previousValue);
  const tabIndex = React.useCallback(() => {
    if (isGreaterThanSmall || isOpen) return 0;
    return -1;
  }, [isOpen, isGreaterThanSmall]);
  const logoutButtonHandler = React.useMemo(
    () =>
      closeMenuOpen({
        cb: () => {
          dispatch(logoutCandidate());
          navigate(isInternational ? PATHS.INTERNATIONAL : '/');
        },
        blur: true,
      }),
    [dispatch, isInternational, navigate],
  );
  const closeHandler = React.useMemo(() => closeMenuOpen(), []);

  useClickOutside({
    ref: menuRef,
    handler: isOpen ? closeMenuOpen() : () => {},
  });

  return (
    <>
      <HeaderButton
        id='header-menu-open'
        className='menu__opener'
        onClick={toggleMenuOpen}
      >
        <span>{i18n.getStr('components.Header.menu')}</span>
        <M.Icon icon={isOpen ? 'ChevronUp' : 'ChevronDown'} size={16} />
      </HeaderButton>
      <HeaderMenuContainer isOpen={isOpen} ref={menuRef}>
        <ul
          role='menu'
          aria-hidden={!(isGreaterThanSmall || isOpen)}
          className='menu__items'
        >
          <li role='menuitem' className='menu__item'>
            <LanguageToggle
              tabIndex={tabIndex()}
              buttonClassName='header__menu-item'
              international={isInternational}
              onItemClick={closeHandler}
            />
          </li>
          <li role='menuitem' className='menu__item'>
            <HeaderHelpcenterLink
              tabIndex={tabIndex()}
              onClick={closeHandler}
            />
          </li>
          {isLogged && (
            <LiWrapperStyled role='menuitem' className='menu__item'>
              <LogOutButtonStyled
                kind='tertiary'
                type='button'
                data-testid='header-logout-button'
                tabIndex={tabIndex()}
                onClick={logoutButtonHandler}
              >
                {i18n.getStr('components.Header.logout')}
              </LogOutButtonStyled>
            </LiWrapperStyled>
          )}
        </ul>
      </HeaderMenuContainer>
    </>
  );
};

export default HeaderMenu;
