import PropTypes from 'prop-types';
import React from 'react';

import Attribute from '../Attribute';

const Record = ({ record }) => (
  <div className='base-record'>
    {Object.keys(record)
      .sort()
      .map((key, i) => (
        <Attribute key={`attribute-${i + 1}`} attr={key} value={record[key]} />
      ))}
  </div>
);

Record.propTypes = {
  record: PropTypes.object.isRequired,
};

export default Record;
