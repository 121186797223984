import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { useStore, useDispatch } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';

import NavigationFooter from '../../../../Workflow/NavigationFooter';
import Breadcrumb from '../../../../Workflow/Breadcrumb';
import ContentContainer from '../../../../Workflow/ContentContainer';
import { trackAnalyticsEvent } from '../../../../../actions';
import { ANALYTICS_EVENTS } from '../../../../../lib/analytics';

const UpdateDriverLicenseSuccess = ({ closed }: { closed: () => void }) => {
  const doneButton = () => {
    return (
      <M.Button size='sm' kind='primary' onClick={closed}>
        {i18n.getStr('buttons.done')}
      </M.Button>
    );
  };
  const dispatch = useDispatch();
  const store = useStore();
  React.useEffect(() => {
    trackAnalyticsEvent(ANALYTICS_EVENTS.PERSONAL_INFO_UPDATED)(
      dispatch,
      store.getState,
    );
  }, [dispatch, store]);

  return (
    <div>
      <Breadcrumb
        crumbKeys={[
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.title',
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLSuccess.title',
        ]}
      />

      <ContentContainer>
        <h6 className='d-flex justify-content-center mb-4'>
          <strong>
            {i18n.getStr(
              'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLSuccess.heading',
            )}
          </strong>
        </h6>
        <M.Icon className='checkr-blue' icon='CheckmarkFilled' />
        <p>
          {i18n.renderHTML(
            'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLSuccess.message',
          )}
        </p>
      </ContentContainer>
      <NavigationFooter rightButtonContent={doneButton()} />
    </div>
  );
};

export default UpdateDriverLicenseSuccess;
