import React from 'react';
import { useDispatch } from 'react-redux';
import { M } from '@dashboard-experience/mastodon';
import { trackAnalyticsEvent } from '../../actions';
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from '../../lib/analytics';

export interface QuestionData {
  question: string;
  url: string;
}
export interface FaqQuestionProps extends QuestionData {
  className?: string;
  id?: string;
}
export const FaqQuestion: React.FC<FaqQuestionProps> = ({
  question,
  url,
  id,
  className,
}) => {
  const dispatch = useDispatch();
  return (
    <li key={url}>
      <p className={`faq-question ${className}`}>
        <M.Link
          href={url}
          size='lg'
          id={id}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() =>
            dispatch(
              trackAnalyticsEvent(ANALYTICS_EVENTS.FAQ_SECTION_LINK_CLICKED, {
                [ANALYTICS_PROPERTIES.QUESTION]: question,
              }),
            )
          }
        >
          {question}
        </M.Link>
      </p>
    </li>
  );
};
