import React from 'react';
import { useDispatch } from 'react-redux';
import { setLocale } from 'react-redux-i18n';

import { i18n, moment } from '@international/mastodon-i18n';
import { DropdownMenuItem } from '../../DropdownMenu/DropdownMenu';

import { trackAnalyticsEvent } from '../../../actions';
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from '../../../lib/analytics';
import resources from '../../../lib/translations/resources';
import {
  getLanguages,
  getLanguageFromLocale,
  getResourceLocale,
} from '../../../lib/helpers';

const useSupportedLanguages = (locale: string, isInternational: boolean) => {
  const dispatch = useDispatch();
  const supportedLanguages: Record<string, string> =
    getLanguages(isInternational);
  const items: { value: string; label: string }[] = Object.entries(
    supportedLanguages,
  ).map(([value, label]) => ({ value, label }));

  let language = getLanguageFromLocale(locale);
  if (!supportedLanguages[language]) {
    language = i18n.defaultLocale;
  }

  const onLanguageChangeHandler = React.useCallback(
    (selectedLanguage: DropdownMenuItem) => {
      i18n.setLocale({
        value: selectedLanguage.value,
        resources: resources(),
        country: isInternational ? i18n.getWorkLocation() : null,
        useCookie: isInternational,
      });
      if (!isInternational) {
        moment.locale(i18n.defaultLocale);
      }
      dispatch(
        setLocale(
          isInternational
            ? getResourceLocale(resources(), selectedLanguage.value)
            : selectedLanguage.value,
        ),
      );
      dispatch(
        trackAnalyticsEvent(ANALYTICS_EVENTS.LANGUAGE_SELECTED, {
          [ANALYTICS_PROPERTIES.LANGUAGE]: selectedLanguage.label,
        }),
      );
    },
    [dispatch, isInternational],
  );
  return {
    language,
    currentLanguage: items.find(({ value }) => value === language),
    supportedLanguages,
    onLanguageChangeHandler,
    items,
  };
};

export default useSupportedLanguages;
