import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Panel from '../../../Panels';

import { computeSearchesStatus } from '../lib/status';
import Status from '../Status';
import Attribute from '../Attribute';

const HealthcareSanctionsSearch = ({ searches }) => {
  const title =
    searches && searches.length > 1
      ? i18n.getStr('components.Report.HealthcareSanctionsSearch.title_plural')
      : i18n.getStr('components.Report.HealthcareSanctionsSearch.title');

  const search_title = search => {
    const source_type = i18n.getStr(
      `components.Report.HealthcareSanctionsSearch.${search.sourceType}`,
    );
    const completed_at =
      i18n.getLocalizedShortDateFormat(search.completedAt) || '';
    return `${source_type} ${completed_at}`;
  };

  const filterOutUnverifiedAndHistoricalRecords = records => {
    return records.filter(
      record => !record.reinstatementDate && record.verified,
    );
  };

  return (
    !!searches.length && (
      <Panel title={title} status={computeSearchesStatus(searches)}>
        {searches.map((search, i) => (
          <div key={`div-${i + 1}`} className='mb-3' data-testid='search'>
            <div className='d-flex mb-3'>
              <b className='text mr-auto'>{search_title(search)}</b>
              <div className='ml-auto'>
                <Status status={search.status} />
              </div>
            </div>

            {search.records &&
              filterOutUnverifiedAndHistoricalRecords(search.records).map(
                (record, index) => (
                  <div key={`div-${index + 1}`} data-testid='record'>
                    <div className='mb-3'>
                      {index > 0 && <hr className='mb-4' />}
                      <div className='d-flex'>
                        <span className='text-uppercase'>
                          {i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.sanction',
                          )}{' '}
                          {index + 1}
                        </span>
                      </div>
                    </div>

                    <div className='card-plain' key={`hss-panel-${i + 1}`}>
                      <div
                        className='mt-2 mb-4'
                        key={`hss-attributes-${i + 1}`}
                      >
                        <Attribute
                          attr={i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.authority',
                          )}
                          value={record.authority}
                        />
                        <Attribute
                          attr={i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.state',
                          )}
                          value={record.address?.state}
                        />
                        <Attribute
                          attr={i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.full_name',
                          )}
                          value={record.fullName}
                        />
                        <Attribute
                          attr={i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.dob',
                          )}
                          value={
                            i18n.getLocalizedShortDateFormat(record.dob) || '-'
                          }
                        />
                        <br />
                        <Attribute
                          attr={i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.database_registry',
                          )}
                          value={record.databaseRegistry}
                        />
                        {record.sanction && (
                          <Attribute
                            attr={i18n.getStr(
                              'components.Report.HealthcareSanctionsSearch.sanction',
                            )}
                            value={record.sanction}
                          />
                        )}
                        <Attribute
                          attr={i18n.getStr(
                            'components.Report.HealthcareSanctionsSearch.exclusion_date',
                          )}
                          value={
                            i18n.getLocalizedShortDateFormat(
                              record.exclusionDate,
                            ) || '-'
                          }
                        />
                        {record.claimRejectDate && (
                          <Attribute
                            attr={i18n.getStr(
                              'components.Report.HealthcareSanctionsSearch.claim_reject_date',
                            )}
                            value={
                              i18n.getLocalizedShortDateFormat(
                                record.claimRejectDate,
                              ) || '-'
                            }
                          />
                        )}
                        {record.details && (
                          <Attribute
                            attr={i18n.getStr(
                              'components.Report.HealthcareSanctionsSearch.details',
                            )}
                            value={record.details}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ),
              )}
          </div>
        ))}
      </Panel>
    )
  );
};

HealthcareSanctionsSearch.propTypes = {
  searches: PropTypes.array,
};

HealthcareSanctionsSearch.defaultProps = {
  searches: [],
};

export default i18n.renderTranslation()(HealthcareSanctionsSearch);
