import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import { getRandomKey } from 'lib/helpers';
import CandidateStoryHeader from './CandidateStoryHeader';
import ChargesByRecordForm from './ChargesByRecordForm';
import CandidateStoriesMultiSelect from './CandidateStoriesMultiSelect';

import { CANS_STEP_SELECT } from '../../../../constants';

interface ChargesFormProps {
  searches: { [key: string]: any } | any[];
  selectedRecords: any[];
  generalInfoEnabled: boolean;
  generalInformation: any;
  toggleGeneralInformation: (value: string) => void;
  toggleRecord: (value: string) => void;
}

const ChargesForm = ({
  searches,
  toggleRecord,
  selectedRecords,
  generalInfoEnabled,
  generalInformation,
  toggleGeneralInformation,
}: ChargesFormProps) => {
  const renderSearches = () => {
    if (Array.isArray(searches)) {
      const considerSearches = searches.filter(
        search => search?.status === 'consider',
      );
      return considerSearches.map((search, i) => (
        <ChargesByRecordForm
          key={getRandomKey()}
          search={search}
          toggleRecord={toggleRecord}
          selectedRecords={selectedRecords}
        />
      ));
    }
    return (
      searches?.status === 'consider' && (
        <ChargesByRecordForm
          search={searches}
          toggleRecord={toggleRecord}
          selectedRecords={selectedRecords}
        />
      )
    );
  };

  return (
    <div>
      <CandidateStoryHeader step={CANS_STEP_SELECT} />

      {generalInfoEnabled && (
        <>
          <CandidateStoriesMultiSelect
            displayValue={i18n.getStr(
              'components.Report.CandidateStories.Modal.SelectRecord.generalInfo',
            )}
            selectValue
            toggleValue={toggleGeneralInformation}
            selected={generalInformation}
          />
          <hr />
        </>
      )}
      <div className='form-group'>{renderSearches()}</div>
    </div>
  );
};

export default i18n.renderTranslation()(ChargesForm);
