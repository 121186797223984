import { AxiosError, AxiosResponse } from 'axios';
import { QueryKey, useQuery } from '@tanstack/react-query';
import checkrApiClient from 'api/checkrApiClient';
import { RecentFinalizedDisputeDateResponse } from './types';
import { CHECKR_BFF_API_BASE } from '../../constants';

const useRecentFinalizedDisputeDate = (reportId: string) => {
  const key: QueryKey = [
    '/reports/:reportId/recent_finalized_dispute_date',
    { reportId },
  ];
  const url = `${CHECKR_BFF_API_BASE}/reports/${reportId}/recent_finalized_dispute_date`;
  const request = () =>
    checkrApiClient.get<RecentFinalizedDisputeDateResponse>(url);

  const { isLoading, isError, data, error } = useQuery<
    AxiosResponse<RecentFinalizedDisputeDateResponse>,
    AxiosError
  >({
    queryKey: key,
    queryFn: request,
    ...{
      refetchOnWindowFocus: false,
      retry: false,
    },
  });

  const responseDate = data?.data.recent_finalized_dispute_date || '';
  const date = new Date(responseDate.toString());
  const recentFinalizedDisputeDate = isNaN(date.getTime())
    ? ''
    : date.toLocaleDateString('en-US');
  const email = data?.data.email || '';

  return {
    isLoading,
    isError,
    recentFinalizedDisputeDate,
    email,
    error,
  };
};

export default useRecentFinalizedDisputeDate;
