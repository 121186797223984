import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import 'scss/ProvideInformationForm.scss';
import { DisputeReasonSummaryMap } from 'components/Workflow/Disputes/Types';
import { UpdateDraft } from 'components/Workflow/hooks';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const ProvideInformationSingleForm = ({
  continueNext,
  back,
  draftDisputeReasonSummaries,
  updateDraft,
}: {
  continueNext: () => void;
  back: () => void;
  draftDisputeReasonSummaries: DisputeReasonSummaryMap;
  updateDraft: UpdateDraft;
}) => {
  const currentSummary = draftDisputeReasonSummaries.motorVehicleRecord;
  const { reasonCode, subreasons } = currentSummary;

  const handleChange = (event: { target: HTMLInputElement }) => {
    const explanation = event.target.value;
    updateDraft('motorVehicleRecord', {
      explanation,
    });
  };

  const continueButtonDisabled = () => !currentSummary.explanation;

  const continueButton = () => {
    return (
      <M.Button
        className='analytics_provide_information_continue_button'
        disabled={continueButtonDisabled()}
        aria-disabled={continueButtonDisabled()}
        size='sm'
        kind='primary'
        onClick={continueNext}
      >
        {i18n.getStr('buttons.continue')}
      </M.Button>
    );
  };

  return (
    <div>
      <ContentContainer>
        <M.Form
          aria-label={i18n.getStr(
            'workflows.dispute.provideInformationForm.instructionsText',
          )}
        >
          <h2 className='mb-0'>
            {i18n.getStr(`workflows.dispute.reasons.${reasonCode}`)}
          </h2>
          {subreasons.map(({ name, details }) => {
            return (
              <div key={name}>
                <h3
                  key={name}
                  id='provide-information-subreason-heading'
                  className='mt-0 mb-0'
                >
                  {name}
                </h3>
                {details &&
                  details.map(detail => {
                    return (
                      <p key={detail} className='mb-0 mt-0 grey-text'>
                        <span>{detail}</span>
                      </p>
                    );
                  })}
              </div>
            );
          })}
          <div
            role='note'
            id='provide-information-instructions-note'
            className='mt-2'
          >
            {i18n.renderHTML(
              'workflows.dispute.provideInformationForm.instructionsText',
            )}
          </div>
          <div>
            {i18n.renderHTML('workflows.dispute.provideInformationForm.prompt')}
          </div>
          <M.TextArea
            data-testid={DISPUTES_FLOW.provideInformationExplanation}
            name='licenseInformationInaccurate.licenseInformation.explanation'
            placeholder={i18n.getStr(
              'workflows.dispute.provideInformationForm.inputPlaceholder',
            )}
            value={currentSummary.explanation}
            onChange={handleChange}
            light
          />
        </M.Form>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_provide_information_back_button'
        rightButtonContent={continueButton()}
      />
    </div>
  );
};

export default ProvideInformationSingleForm;
