import { request } from 'actions/helper';
import { getAPIBase } from '../../../api/verifications/getAPIBase';
import { BACKEND_FLAG_PATHS } from '../../../constants';

// TODO: Handle error from ReactFilestack

// FileStack link is deleted asynchronously to allow time
// to upload actual documents to Checkr API
const deleteFileStackLink = (file: any) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ url: file.url }),
    retryDelay: 200,
  };
  return getAPIBase({ path: BACKEND_FLAG_PATHS.DELETE_FILE }).then(baseURL =>
    request(`${baseURL}/delete_file`, options),
  );
};

export default deleteFileStackLink;
