import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import InputFile from '../../fields/InputFile/UploadField';
import Verification, { connectToVerifications } from '../VerificationBase';
import './IdDocumentVerification.scss';
import {
  StyledDivider,
  StyledFaq,
  StyledHelpfulToKnow,
} from '../VerificationFooter';

class IdDocumentVerification extends Verification {
  // @ts-ignore
  componentDidMount(
    _previousProps: Partial<{ showNav: boolean }>,
    previousState: Partial<{ showNav: boolean }>,
  ) {
    if (previousState && this.state.showNav !== previousState.showNav) {
      this.setState({ showNav: false });
    }
  }

  renderCustomPanel() {
    const { doc, isValid, needUpload } = this.getRenderAttributes();

    return (
      needUpload && (
        <>
          <M.Container className='verification-exception'>
            <M.Container.Row as='header'>
              <h1>
                {i18n.getStr(
                  'components.Verifications.IdDocumentVerification.title',
                )}
              </h1>
              <p>
                {i18n.getStr(
                  'components.Verifications.IdDocumentVerification.subtitle',
                )}
              </p>
            </M.Container.Row>
            <M.Container.Row className='verification-exception__panel verification-exception__panel--required'>
              <h1>
                {i18n.getStr(
                  'components.Verifications.IdDocumentVerification.requirements.title',
                )}
              </h1>
              <ol>
                {Array.from({ length: 5 }, (_, i) => (
                  <li key={`item-${i + 1}`}>
                    {i18n.getStr(
                      `components.Verifications.IdDocumentVerification.requirements.items.${i}`,
                    )}
                  </li>
                ))}
              </ol>
            </M.Container.Row>
            <M.Container.Row className='verification-exception__panel'>
              <h1>
                {i18n.getStr(
                  'components.Verifications.IdDocumentVerification.accepts.title',
                )}
              </h1>
              <ol>
                {Array.from({ length: 5 }, (_, i) => (
                  <li key={`item-${i + 1}`}>
                    {i18n.getStr(
                      `components.Verifications.IdDocumentVerification.accepts.items.${i}`,
                    )}
                  </li>
                ))}
              </ol>
            </M.Container.Row>
            <M.Container.Row className='verification-exception__resolution'>
              <InputFile
                isValid={isValid}
                name={doc.model}
                options={{ maxFiles: 2 }}
                buttonText={{
                  default: `+ ${i18n.getStr('buttons.uploadImage')}`,
                  more: `+ ${i18n.getStr('buttons.uploadMore')}`,
                }}
                labelText={i18n.getStr(
                  `components.Verifications.IdDocumentVerification.inputLabel`,
                )}
                validate={value => {
                  if (!value || value.length === 0) {
                    return i18n.getStr(
                      `components.Verifications.IdDocumentVerification.inputErrorMessage.required`,
                    );
                  }
                  if (value.length > 2) {
                    return i18n.getStr(
                      `components.Verifications.IdDocumentVerification.inputErrorMessage.maxFiles`,
                      2,
                    );
                  }
                  return undefined;
                }}
                context='verifications'
              />
            </M.Container.Row>
            <M.Container.Row>
              <M.Button
                className='verification-exception__submit'
                type='submit'
              >
                {i18n.getStr('buttons.submit')}
              </M.Button>
            </M.Container.Row>
          </M.Container>
          <M.Container className='verification-footer'>
            <M.Container.Row>
              <StyledHelpfulToKnow translationKey='components.Verifications.IdDocumentVerification.helpfulToKnow' />
            </M.Container.Row>
            <StyledDivider />
            <M.Container.Row>
              <StyledFaq translationKey='components.Verifications.IdDocumentVerification.faq' />
            </M.Container.Row>
          </M.Container>
        </>
      )
    );
  }
}

export default connectToVerifications(
  i18n.renderTranslation()(IdDocumentVerification),
);
