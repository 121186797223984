import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';

interface ProLicenseVerificationManualEntriesProps {
  companyName: string;
}

const ProLicenseVerificationManualEntries = ({
  companyName = '',
}: ProLicenseVerificationManualEntriesProps) => (
  <M.Card className='panel-warning-notice'>
    <p>
      <strong>
        <M.Icon icon='WarningAltFilled' />
        &nbsp;
        <T value='components.Report.ProLicenseVerification.manualEntryNotice.title' />
      </strong>
    </p>
    <p>
      <T
        value='components.Report.ProLicenseVerification.manualEntryNotice.message'
        companyName={companyName}
      />
    </p>
  </M.Card>
);

export default ProLicenseVerificationManualEntries;
