import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { M } from '@dashboard-experience/mastodon';
import { DropDown } from 'components/fields';
import { i18n } from '@international/mastodon-i18n';

const DobSelect = ({ fields, validate, error, disabled }) => {
  // get the translated 'month', 'day', 'year'
  const label = name => {
    const array = name.split(' ');
    let singleWordLabel = array[array.length - 1];
    singleWordLabel =
      singleWordLabel[0].toUpperCase() + singleWordLabel.slice(1);
    return singleWordLabel;
  };

  return (
    <>
      <M.GridRow>
        {fields.map(field => (
          <M.GridCol key={field.name}>
            <Field
              type='select'
              component={DropDown}
              validate={validate}
              options={field.options}
              name={field.name}
              label={label(i18n.getStr(field.options[0].name))}
              showDobError={!!error}
              disabled={disabled}
            />
          </M.GridCol>
        ))}
      </M.GridRow>
      {error && (
        <M.GridRow>
          <M.GridCol>
            <div className='cds--form-item cds--text-input-wrapper'>
              <span className='error'>{i18n.getStr(error)}</span>
            </div>
          </M.GridCol>
        </M.GridRow>
      )}
    </>
  );
};

DobSelect.propTypes = {
  fields: PropTypes.array.isRequired,
  validate: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

DobSelect.defaultProps = {
  disabled: false,
};

export default DobSelect;
