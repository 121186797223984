import React, { AriaRole } from 'react';
import { fonts, colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { appBreakpoints } from '../../reducers';

export const NOTIFICATION_KIND = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
} as const;

export type NotificationKind =
  typeof NOTIFICATION_KIND[keyof typeof NOTIFICATION_KIND];

interface ToastNotificationProps {
  title?: string;
  animationDuration?: number;
  appearanceDuration?: number;
  showTimeout?: number;
  kind?: NotificationKind;
  onClose?: () => void;
  message?: string;
}

const NotificationIcon: React.FC<{
  kind: NotificationKind;
  role?: AriaRole;
  description?: string;
}> = ({ kind, ...props }) => {
  switch (kind) {
    case NOTIFICATION_KIND.SUCCESS:
      return <M.Icon icon='CheckmarkFilled' {...props} />;
    case NOTIFICATION_KIND.INFO:
      return <M.Icon icon='InformationFilled' {...props} />;
    case NOTIFICATION_KIND.WARNING:
      return <M.Icon icon='WarningAltFilled' {...props} />;
    case NOTIFICATION_KIND.ERROR:
      return <M.Icon icon='ErrorFilled' {...props} />;
    default:
      return null;
  }
};

const ToastWrapper = styled.div<{ animationDuration: number }>`
  &.notification-toast {
    position: fixed;
    padding: 24px;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 36px);
    max-width: 920px;
    z-index: 2;
    border-radius: 4px;
    background-color: ${colors.coolBlack20};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), -4px 0 0 ${colors.coolBlack};
    font-family: ${fonts.legacy};
    color: ${colors.coolBlack};
    opacity: 0;
    transition: opacity ${props => props.animationDuration}ms;
    .notification-toast__header {
      display: grid;
      grid-template-columns: 39px 1fr;
      grid-gap: 15px;
    }
    .mastodon-icon {
      fill: ${colors.slate06};
    }
    h1 {
      font-size: 1.3rem !important;
      margin: 0 !important;
    }
    &.notification-toast--success {
      background-color: ${colors.achievementGreenBg};
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
        -4px 0 0 ${colors.successGreen100};
      .mastodon-icon {
        fill: ${colors.successGreen100};
      }
    }
    &.notification-toast--warning {
      background-color: ${colors.cautionYellowBg};
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25),
        -4px 0 0 ${colors.cautionYellow100};
      .mastodon-icon {
        fill: ${colors.cautionYellow100};
      }
    }
    &.notification-toast--error {
      background-color: ${colors.dangerRedBg};
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), -4px 0 0 ${colors.dangerRed100};
      .mastodon-icon {
        fill: ${colors.dangerRed100};
      }
    }
    &.--fade-in {
      opacity: 1;
      &.--fade-out {
        opacity: 0;
      }
    }
    @media (min-width: ${appBreakpoints.small}px) {
      top: 100px;
    }
  }
`;
const ToastNotification: React.FC<ToastNotificationProps> = ({
  title = '',
  kind = NOTIFICATION_KIND.INFO,
  showTimeout = 1000,
  appearanceDuration = 2000,
  animationDuration = 200,
  message = '',
  onClose,
}) => {
  const [fadeIn, setFadeIn] = React.useState(false);
  const [fadeOut, setFadeOut] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setFadeIn(true);
      setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          onClose?.();
        }, animationDuration);
      }, appearanceDuration + animationDuration);
    }, showTimeout);
  }, [showTimeout, appearanceDuration, animationDuration, onClose]);
  return (
    <ToastWrapper
      className={`notification-toast notification-toast--${kind} ${
        fadeIn ? '--fade-in' : ''
      } ${fadeOut ? '--fade-out' : ''}`}
      role='status'
      animationDuration={animationDuration}
    >
      {title && (
        <div className='notification-toast__header'>
          <NotificationIcon kind={kind} role='img' description='Success Icon' />
          <h1>{title}</h1>
        </div>
      )}
      {message && <p>{message}</p>}
    </ToastWrapper>
  );
};

export default ToastNotification;
