import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n, moment } from '@international/mastodon-i18n';
import Panel from '../../../Panels';

const CommonQuestions = ({ report }) => {
  const screening = report.drugScreening || report.occupationalHealthScreening;

  return (
    !!report && (
      <Panel
        data-testid='common-questions'
        title={i18n.getStr(
          'components.Report.ScheduleComponent.commonQuestions.title',
        )}
        className='card-plain card-border'
      >
        <strong>
          <T value='components.Report.ScheduleComponent.commonQuestions.q1' />
        </strong>
        <p>
          <T
            value='components.Report.ScheduleComponent.commonQuestions.a1'
            date={moment(screening.screeningPassExpiresAt).format(
              i18n.getStr('dateFormats.monthDayYear'),
            )}
            dangerousHTML
          />
        </p>

        <strong>
          <T value='components.Report.ScheduleComponent.commonQuestions.q2' />
        </strong>
        <p>
          <T
            value='components.Report.ScheduleComponent.commonQuestions.a2'
            dangerousHTML
          />
        </p>

        <strong>
          <T value='components.Report.ScheduleComponent.commonQuestions.q3' />
        </strong>
        <p>
          <T
            value='components.Report.ScheduleComponent.commonQuestions.a3'
            dangerousHTML
          />
        </p>
      </Panel>
    )
  );
};

CommonQuestions.propTypes = {
  report: PropTypes.object,
};

CommonQuestions.defaultProps = {
  report: {},
};

export default i18n.renderTranslation()(CommonQuestions);
