import loginDefaultValues from './loginDefaultValues';
import * as ActionTypes from '../actions';

export const initialState = {
  values: loginDefaultValues,
};

const login = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case ActionTypes.FETCH_REPORTS_FAILURE:
      return {
        ...state,
        values: {
          ...state.values,
        },
      };
    default:
      return state;
  }
};

export default login;
