import PropTypes from 'prop-types';
import React from 'react';
import { text } from '../../lib/formatting';
import { SchoolObject } from '../../../../../types/report';

interface SchoolProps {
  school: SchoolObject;
  result: {
    name?: string;
  };
}

const School = ({ school, result }: SchoolProps) => {
  const schoolName = result.name || school.name;

  return (
    <strong>
      {schoolName ? `${text(schoolName)}, ` : ''}
      {school.address.state}
    </strong>
  );
};

School.propTypes = {
  school: PropTypes.object.isRequired,
  result: PropTypes.object.isRequired,
};

export default School;
