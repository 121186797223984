import React from 'react';
/* @ts-ignore-line */
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from '@international/mastodon-i18n';

const StyledVerificationHeader = styled.header`
  height: 32px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .company-name {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 4px 0;
  }
`;

const StyledLink = styled(Link)`
  border: 1px solid #043f93;
  border-radius: 2px;
  height: 32px;
  padding: 4px 12px;
`;

const VerificationHeader: React.FC<{
  companyName: string;
  showLogin?: boolean;
  isLogged?: boolean;
}> = ({ companyName, showLogin = true, isLogged = false }) => {
  return (
    <StyledVerificationHeader>
      <h1 className='company-name'>{companyName}</h1>
      {showLogin && (
        <StyledLink id='upload-btn' to='/'>
          {isLogged
            ? i18n.getStr(
                'components.Verification.VerificationHeader.loginButtonTextLoggedIn',
              )
            : i18n.getStr(
                'components.Verification.VerificationHeader.loginButtonTextNotLoggedIn',
              )}
        </StyledLink>
      )}
    </StyledVerificationHeader>
  );
};

export default i18n.renderTranslation()(VerificationHeader);
