import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { TitledContainer } from './TitledContainer';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { BulletedItem } from './BulletedItem';
import { Icon } from './Icon';
import { Paragraph } from './Paragraph';
import FooterButtons from './FooterButtons';
import { Page } from './Page';

interface UploadDocumentLaterProps {
  greeting: React.ReactNode;
  setPage: (pageToSet: Page) => void;
  previousPage: Page;
}

export const UploadDocumentLater: React.FC<UploadDocumentLaterProps> = ({
  greeting,
  setPage,
  previousPage,
}) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
        padding: '24px 16px 56px 16px',
        border: 'none',
      }}
    >
      {greeting}
      <StyleableContainer type={Type.Info} styledTopBorder>
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.UploadDocumentLater.heading'
          align={HeadingAlignment.Center}
        />
        <Paragraph
          textKey='components.InstantExceptions.UploadDocumentLater.detail'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%', margin: '0px' }} />
        <TitledContainer titleKey='components.InstantExceptions.StartedYourBackgroundCheck.whatNext'>
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.UploadDocumentLater.contactYou'
          />
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.StartedYourBackgroundCheck.logIn'
          >
            <a href='https://candidate.checkr.com'>
              {i18n.getStr('components.Header.title')}
            </a>
            <span>
              {i18n.getStr(
                'components.InstantExceptions.UploadDocumentLater.toUpload',
              )}
            </span>
          </BulletedItem>
        </TitledContainer>
      </StyleableContainer>
      <FooterButtons>
        <M.Button onClick={() => setPage(previousPage)}>
          {i18n.getStr(
            'components.InstantExceptions.UploadDocumentLater.backToEdit',
          )}
        </M.Button>
      </FooterButtons>
    </M.Container>
  );
};
