class CreditReportUtils {
  constructor(report) {
    this.report = report;
  }

  isDomestic() {
    if (!this.report?.creditReport) return false;

    const domesticCredit = this.report.creditReport.records.filter(
      creditRecord => creditRecord.countryName === 'United States',
    );

    return !!(domesticCredit && domesticCredit.length > 0);
  }

  isInternationalHasNonEmptyRecords() {
    if (!this.report?.creditReport) return false;

    const internationalEmptyRecord = this.report.creditReport.records.filter(
      creditRecord => creditRecord.countryName && creditRecord.pdfUrl,
    );

    return !!(internationalEmptyRecord && internationalEmptyRecord.length > 0);
  }

  findDomesticRecords() {
    if (!this.report?.creditReport) return [];

    return this.report.creditReport.records.filter(
      creditRecord => !creditRecord.pdfUrl,
    );
  }

  findInternationalRecords() {
    if (!this.report?.creditReport) return undefined;

    return this.report.creditReport.records.filter(
      creditRecord => creditRecord.countryName && creditRecord.pdfUrl,
    );
  }

  findInternationalEmptyObject() {
    if (!this.report?.creditReport) return undefined;

    return this.report.creditReport.records.filter(
      creditRecord => creditRecord.countryName !== 'United States',
    );
  }
}

export default CreditReportUtils;
