import React from 'react';

import UploadButton from '../fields/UploadButton';
import DocumentsPanel from './DocumentsPanel';
import Verification, { connectToVerifications } from './VerificationBase';

function oldVerificationResponseProps(name: string) {
  return {
    name: `${name}.drivingExperienceRule`,
  };
}

function propsByExceptionType(
  verification: Record<string, any>,
  doc: {
    name: string;
    model: string;
  },
) {
  const { metadata } = verification;
  const { name, ...otherProps } = doc;

  if (metadata.previous) {
    if (metadata.inquiryDetails) {
      const { years, cause } = metadata.inquiryDetails;

      const driverLicenseHistorySubtype =
        cause === 'inquiry_scope' ? 'inquiryScope' : 'drivingExperienceRule';
      const nameWithSubtype = `${name}.${driverLicenseHistorySubtype}`;

      return {
        name: nameWithSubtype,
        infoTranslationProps: { years },
        ...otherProps,
      };
    }

    // TODO: Delete this after all exceptions using the old response type
    // are no longer valid for their reports
    return { ...oldVerificationResponseProps(name), ...otherProps };
  }
  return {
    noDetails: doc.model === 'driver_license',
    ...doc,
  };
}

class DriverLicenseVerification extends Verification {
  renderCustomPanel() {
    const { doc, isSmallOrDown, isValid, needUpload } =
      this.getRenderAttributes();
    if (!needUpload) return null;

    const docPanelProps = propsByExceptionType(this.props.verification, doc);

    return (
      <DocumentsPanel {...docPanelProps} noDivider>
        {isValid && isSmallOrDown && (
          <div className='card-footer'>
            <UploadButton
              name={doc.model}
              buttonClass='btn-block'
              kind={isValid ? 'secondary' : 'primary'}
              context='verifications'
              fileOptions={{ maxFiles: 1 }}
            />
          </div>
        )}
      </DocumentsPanel>
    );
  }
}

export default connectToVerifications(DriverLicenseVerification);
