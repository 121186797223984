import React from 'react';

import EducationDocumentsPanel from './EducationDocumentsPanel';

import Verification, { connectToVerifications } from './VerificationBase';

class EducationVerification extends Verification {
  getRenderAttributes() {
    return {
      ...super.getRenderAttributes(),
      suppressNavUpload: true,
    };
  }

  renderCustomPanel() {
    const { doc } = this.getRenderAttributes();
    return doc.model && <EducationDocumentsPanel {...doc} />;
  }
}

export default connectToVerifications(EducationVerification);
