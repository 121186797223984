import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';
import '../../scss/MatrixDocumentUpload.scss';
import DOMPurify from 'isomorphic-dompurify';

export const DETAIL_LIST = {
  ssn_card: {
    translationPrefix:
      'components.Report.Verification.MatrixVerification.ssn_card',
    instructionListLength: 2,
    showDetails: false,
  },
  id: {
    translationPrefix: 'components.Report.Verification.MatrixVerification.id',
    instructionListLength: 9,
    showDetails: false,
  },
  dl_documentation_requested: {
    translationPrefix:
      'components.Report.Verification.MatrixVerification.dl_documentation_requested',
    instructionListLength: 5,
    showDetails: true,
  },
  mvr_consent_documentation_requested: {
    translationPrefix:
      'components.Report.Verification.MatrixVerification.mvr_consent_documentation_requested',
    instructionListLength: 3,
    showDetails: true,
  },
  education_proof: {
    translationPrefix:
      'components.Report.Verification.MatrixVerification.education_proof',
    instructionListLength: 3,
    showDetails: true,
  },
  employment_proof: {
    translationPrefix:
      'components.Report.Verification.MatrixVerification.employment_proof',
    instructionListLength: 4,
    showDetails: true,
  },
};

// Any field names listed here are read from matrix item configuration
// and made available as variables when resolving translation strings
const I18N_VAR_NAMES = ['schoolName', 'employerName'];

class MatrixDocumentUploadDetail extends Component {
  renderDetailList = (translationPrefix, instructionListLength) => {
    const { matrixField } = this.props;

    if (!instructionListLength) {
      return null;
    }

    return (
      <ul className='matrix-document-upload-details-ul'>
        {Array.from({ length: instructionListLength }, (_, i) => (
          <li
            key={`item-${i + 1}`}
            data-testid={`upload-details-instruction-${i}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: i18n.getStr(
                `${translationPrefix}.instruction.items.${i}`,
                this.i18nVarsFromField(matrixField),
              ),
            }}
          />
        ))}
      </ul>
    );
  };

  i18nVarsFromField = matrixField => {
    return Object.entries(matrixField).reduce((outVars, kv) => {
      const [k, v] = kv;
      if (I18N_VAR_NAMES.includes(k)) {
        outVars[k] = DOMPurify.sanitize(v);
      }
      return outVars;
    }, {});
  };

  render() {
    const { matrixField } = this.props;
    const { translationPrefix, instructionListLength, showDetails } =
      DETAIL_LIST[matrixField.name];

    return (
      <>
        {showDetails && (
          <>
            <p
              data-testid='upload-details'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18n.getStr(
                  `${translationPrefix}.details`,
                  this.i18nVarsFromField(matrixField),
                ),
              }}
            />
            <br />
          </>
        )}
        <label
          htmlFor='instructions'
          className='form-control-label text-aqua-04'
        >
          <h3>{i18n.getStr(`${translationPrefix}.instruction.title`)}</h3>
        </label>
        {this.renderDetailList(translationPrefix, instructionListLength)}
      </>
    );
  }
}

MatrixDocumentUploadDetail.propTypes = {
  matrixField: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};
export default i18n.renderTranslation()(MatrixDocumentUploadDetail);
