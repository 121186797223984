import React, { MouseEventHandler } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { useDispatch } from 'react-redux';
import { trackAnalyticsEvent } from 'actions';
import { ANALYTICS_EVENTS } from 'lib/analytics';
import { TFunction } from 'types/i18n';
import DOMPurify from 'isomorphic-dompurify';
import { CHECKR_APPLICANTS_BASE_URL } from '../../../constants';
import { VerificationWelcomePageProps } from './types';

import './VerificationWelcomePage.scss';

const getTranslationParagraphs = (
  translationKey: string,
  varName: any = false,
) => {
  return i18n.getStr(translationKey, varName).split(/\n/);
};

type ParagraphWithLinkContent =
  | string
  | { text: string; url: string; urlText: string };

interface AccordionParagraphProps {
  content: ParagraphWithLinkContent;
  className?: string;
  onLinkClick?: MouseEventHandler<HTMLAnchorElement>;
  linkTarget?: string;
}

const AccordionParagraph: React.FC<AccordionParagraphProps> = ({
  content,
  className = '',
  onLinkClick,
  linkTarget = '_blank',
}) => {
  if (typeof content === 'string') {
    return <p className={className}>{content}</p>;
  }
  const { text, url, urlText } = content;
  const [preLinkText, postLinkText] = text.split(/{{var}}/);
  return (
    <p className={className}>
      {preLinkText}
      <M.Link
        href={url}
        onClick={onLinkClick}
        size='lg'
        inline
        target={linkTarget}
      >
        {urlText}
      </M.Link>
      {postLinkText}
    </p>
  );
};

interface AccordionItem {
  title: string;
  text: {
    content: ParagraphWithLinkContent;
    className?: string;
  }[];
}

const WelcomePageFaq: React.FC<{ translationKey: string; t: TFunction }> = ({
  translationKey,
  t,
}) => {
  const accordionItems = t(translationKey, {
    returnObjects: true,
  }) as AccordionItem[];
  return (
    <M.Accordion align='end'>
      {accordionItems.map(item => (
        <M.AccordionItem key={item.title} title={item.title}>
          {item.text.map(({ content, className }) => {
            return (
              <AccordionParagraph
                key={content.toString()}
                content={content}
                className={className}
              />
            );
          })}
        </M.AccordionItem>
      ))}
    </M.Accordion>
  );
};

const TranslatedWelcomePageFaq = i18n.renderTranslation()(
  WelcomePageFaq,
) as React.FC<{ translationKey: string }>;

const SsnVerificationWelcomePage: React.FC<
  Pick<VerificationWelcomePageProps, 'verification'>
> = ({ verification }) => {
  const dispatch = useDispatch();
  return (
    <M.Container className='verification__welcome'>
      <M.Container.Row className='verification__greeting'>
        <h2>
          {i18n.getStr(
            'components.Verifications.WelcomePage.greeting',
            verification.firstName,
          )}
        </h2>
      </M.Container.Row>
      <M.Container.Row className='verification__body'>
        {getTranslationParagraphs(
          'components.Verifications.WelcomePage.description',
          verification.company,
        ).map((text: string) => (
          <p
            key={text}
            className='verification__text'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
          />
        ))}
      </M.Container.Row>
      <M.Container.Row className='verification__body'>
        <M.Button className='verification__next-button' type='submit'>
          {i18n.getStr('components.Verifications.WelcomePage.button')}
        </M.Button>
      </M.Container.Row>
      <M.Container.Row className='verification__body'>
        <M.Link
          href={CHECKR_APPLICANTS_BASE_URL}
          target='_self'
          onClick={() => {
            dispatch(
              trackAnalyticsEvent(ANALYTICS_EVENTS.NOT_YOU_BUTTON_CLICKED),
            );
          }}
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                i18n.getStr('components.Verifications.WelcomePage.notYou'),
              ),
            }}
          />
        </M.Link>
      </M.Container.Row>
      <M.Container.Row className='verification__footer'>
        <TranslatedWelcomePageFaq translationKey='components.Verifications.WelcomePage.faq' />
      </M.Container.Row>
    </M.Container>
  );
};

export default i18n.renderTranslation()(SsnVerificationWelcomePage);
