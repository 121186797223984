import PropTypes from 'prop-types';
import React from 'react';

import CandidateStoryDocsForm from './CandidateStoryDocsForm';
import CandidateStoryHeader from './CandidateStoryHeader';
import CandidateStoryItemTitle from './CandidateStoryItemTitle';
import GeneralInformationTextForm from './GeneralInformationTextForm';

import { CANS_STEP_PROVIDE } from '../../../../constants';
import { settingIsEnabled } from '../../../../lib/helpers';

const GeneralInformationForm = ({
  account,
  candidateStories,
  remove,
  selectedItems,
}) => {
  const setting = account.candidateStoriesGeneralInfo;
  const docsEnabled = settingIsEnabled(setting, {
    nullish: true,
    keys: ['docs'],
  });
  const textEnabled = settingIsEnabled(setting, {
    nullish: true,
    keys: ['text'],
  });

  if (!candidateStories.general) {
    candidateStories.general = { documents: [] };
  }

  const removeDocuments = i => {
    remove(i, 'candidateStories.general.documents');
  };

  return (
    <div>
      <CandidateStoryHeader
        step={CANS_STEP_PROVIDE}
        provide_step={1}
        provide_total={selectedItems}
      />
      <div className='form-question-wrapper'>
        <span className='form-question'>
          <CandidateStoryItemTitle />
        </span>
      </div>
      <div>
        <GeneralInformationTextForm
          company={account.name}
          disabled={!textEnabled}
          field='candidateStories.content.general'
          data-testid='candidate-stories-general-information-text-box'
        />
        <CandidateStoryDocsForm
          documents={candidateStories.general.documents}
          disabled={!docsEnabled}
          field='candidateStories.general.documents'
          remove={removeDocuments}
        />
      </div>
    </div>
  );
};

GeneralInformationForm.propTypes = {
  account: PropTypes.shape({
    candidateStoriesGeneralInfo: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({ docs: PropTypes.bool, text: PropTypes.bool }),
    ]),
    name: PropTypes.string,
  }),
  remove: PropTypes.func.isRequired,
  selectedItems: PropTypes.number.isRequired,
  candidateStories: PropTypes.object.isRequired,
};

GeneralInformationForm.defaultProps = {
  account: {},
};

export default GeneralInformationForm;
