import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { CHECKR_APPLICANT_PORTAL } from '../../constants';

const LoginButton = () => (
  <M.Link href={CHECKR_APPLICANT_PORTAL} target='_self' className='btn-faq'>
    <M.Button kind='secondary'>
      <T value='buttons.login' />
    </M.Button>
  </M.Link>
);

export default LoginButton;
