import React from 'react';
import { useDispatch, useStore } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';

import Panel from '../Panels';
import Logo from '../Logo';
import { CHECKR_APPLICANTS_BASE_URL } from '../../constants';
import { trackAnalyticsEvent } from '../../actions';
import { ANALYTICS_EVENTS } from '../../lib/analytics';

interface VerificationHeaderPanelProps {
  company?: string;
  companyLogoUri?: string;
  firstName?: string;
  hideLogo?: boolean;
  customTitle?: string;
}

const H1Styled = styled.h1`
  &:not(:only-child) {
    margin-bottom: 0 !important;
  }
`;

const VerificationHeaderPanel: React.FC<VerificationHeaderPanelProps> = ({
  company = '',
  companyLogoUri = '',
  firstName = '',
  hideLogo = false,
  customTitle = null,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  React.useEffect(() => {
    trackAnalyticsEvent(ANALYTICS_EVENTS.EXCEPTION_FLOW_WELCOME_PAGE_VIEWED)(
      dispatch,
      store.getState,
    );
  }, [dispatch, store]);
  return (
    <Panel data-testid='verification-header-panel' className='text-center'>
      {!hideLogo && companyLogoUri && <Logo logoUri={companyLogoUri} />}
      <H1Styled>
        {!customTitle && (
          <>
            <T value='components.Verification.Header.hello' /> {firstName}
          </>
        )}
        {customTitle && <T value={`${customTitle}`} />}
      </H1Styled>
      <hr />
      <p>
        <span>{company}</span>{' '}
        <T value='components.Verification.Header.introText' />
        <br />
        <a
          href={CHECKR_APPLICANTS_BASE_URL}
          target='_self'
          onClick={() => {
            dispatch(
              trackAnalyticsEvent(ANALYTICS_EVENTS.NOT_YOU_BUTTON_CLICKED),
            );
          }}
        >
          <T value='components.Verification.Header.notYou' />
        </a>
      </p>
    </Panel>
  );
};

export default VerificationHeaderPanel;
