/* eslint-disable consistent-return */
import { TOO_MANY_DOCUMENTS, CONTENT_OR_DOC_REQUIRED } from './messages';
import {
  CANDIDATE_STORY_MAX_CHARACTER_LIMIT,
  MAX_DOCUMENTS,
} from '../../constants';

const contentOrDocRequired = (value, allValues, props, name) => {
  const key = name.split('.')[2];
  if (
    (!props.candidateStories[key] ||
      !props.candidateStories[key].documents ||
      props.candidateStories[key].documents.length < 1) &&
    (!value || value.length < 3)
  ) {
    return CONTENT_OR_DOC_REQUIRED;
  }
};

const maxDocuments = (value, allValues, props, name) => {
  const key = name.split('.')[2];
  if (
    !props.candidateStories[key] ||
    !props.candidateStories[key].documents ||
    props.candidateStories[key].documents.length > MAX_DOCUMENTS
  ) {
    return { message: TOO_MANY_DOCUMENTS, maxDocuments: MAX_DOCUMENTS };
  }
};

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

const maxContentLength = maxLength(CANDIDATE_STORY_MAX_CHARACTER_LIMIT);

export { contentOrDocRequired, maxDocuments, maxContentLength };
