import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import './ReportListHeader.scss';
import ReportsFilter from '../ReportsFilter/ReportsFilter';
import sortByOptions from '../lib/sortReportCardsBy';

export const ReportListHeader: React.FC<{
  reportCount?: number;
  actionRequiredCount?: number;
  sortedBy?: 'date' | 'status' | 'company';
  onSortChange?: React.MouseEventHandler<HTMLInputElement> | null;
}> = ({
  reportCount = 0,
  actionRequiredCount = 0,
  sortedBy = 'status',
  onSortChange = null,
}) => {
  return (
    <header className='report-list--header'>
      <div className='report-list-header--summary'>
        <h2 id='report-list-title'>
          {i18n.getStr(
            reportCount <= 1
              ? 'components.CandidateLandingPage.cardsHeader.yourReports_one'
              : 'components.CandidateLandingPage.cardsHeader.yourReports_other',
          )}
        </h2>
        {actionRequiredCount > 0 ? (
          <p
            className='action-count'
            role='status'
            id='report-list-need-action'
          >
            <span
              id='report-list-need-action-count'
              className='action-count--number'
            >
              {actionRequiredCount}
            </span>
            {i18n.getStr(
              actionRequiredCount <= 1
                ? 'components.CandidateLandingPage.cardsHeader.reportsNeedAction_one'
                : 'components.CandidateLandingPage.cardsHeader.reportsNeedAction_other',
            )}
          </p>
        ) : null}
      </div>
      {reportCount > 1 ? (
        <ReportsFilter
          options={sortByOptions}
          selected={sortedBy}
          onChange={onSortChange}
        />
      ) : null}
    </header>
  );
};

export default ReportListHeader;
