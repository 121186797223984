/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// REFACTOR FOR LINT LATER
import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { labelErrorClass } from './helper';

const Label = ({ label, htmlFor, touched, error, dateformat }) => {
  if (!label) {
    return null;
  }

  return (
    <label
      htmlFor={htmlFor ? htmlFor.replace('.', '-') : 'label-field'}
      className={`form-control-label ${labelErrorClass(
        touched,
        Boolean(error),
      )}`}
    >
      <T value={label} dateformat={dateformat} />
    </label>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.bool,
  dateformat: PropTypes.string,
};

Label.defaultProps = {
  label: '',
  htmlFor: '',
  touched: false,
  error: false,
  dateformat: '',
};

export default Label;
