import PropTypes from 'prop-types';
import React from 'react';

import Attribute from '../Attribute';

const CriminalRecord = ({ record }) => (
  <div className='sexual-record'>
    <div className='mb-3'>
      <hr className='mb-2 mt-2' />
      <span className='text-uppercase'>{record.registry}</span>
      <hr className='mb-2 mt-2' />
    </div>

    <div className='mb-4 mt-4'>
      {Object.keys(record)
        .sort()
        .map((key, i) => (
          <Attribute
            key={`attribute-${i + 1}`}
            attr={key}
            value={record[key]}
          />
        ))}
    </div>
  </div>
);

CriminalRecord.propTypes = {
  record: PropTypes.object.isRequired,
};

export default CriminalRecord;
