/*
 * Action Types
 */

export const PROCESSING = 'PROCESSING';

/*
 * Actions
 */

export const processingAction = () => ({ type: PROCESSING });
export const markProcessing = () => dispatch => dispatch(processingAction());
