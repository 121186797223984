/* eslint-disable consistent-return */

import { NUMBERS_NOT_ALLOWED } from './messages';

const noNumbers = value => {
  if (/\d/.test(value)) {
    return NUMBERS_NOT_ALLOWED;
  }
};

const nameMatches = (value, field, props) => {
  const { nameValidation } = props.fields.filter(
    f => f.type === 'consent_collection',
  )[0];

  if (value !== nameValidation) {
    return `Your signature must match the name you submitted: ${nameValidation}`;
  }
};

const confirmationMatch = (value, allValues, props, nameWithConfirmation) => {
  const submissionId = nameWithConfirmation.replace('.ssnConfirmation', '');
  const valid =
    allValues[submissionId]?.ssn === allValues[submissionId]?.ssnConfirmation;

  if (!valid) {
    return `Confirmation field must match`;
  }
};

export { noNumbers, nameMatches, confirmationMatch };
