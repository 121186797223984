import React from 'react';
import { useClickOutside } from '@dashboard-experience/utils';
import { colors, fonts, M } from '@dashboard-experience/mastodon';

import styled from 'styled-components';
import { appBreakpoints } from '../../reducers';
import useItemKeysNavigation from './useItemKeysNavigation';
import headerButtonStyle from '../HeaderMenu/headerButtonStyle';

export interface DropdownMenuItem {
  value: string;
  label: string;
}

interface DropdownMenuProps {
  id: string;
  tabIndex?: 0 | -1 | '0' | '-1';
  titleRender?: (selected: DropdownMenuItem) => React.ReactNode;
  onSelectItem?: (
    e: React.MouseEvent<HTMLButtonElement>,
    selectedItem: DropdownMenuItem,
  ) => void;
  items: DropdownMenuItem[];
  className?: string;
  buttonClassName?: string;
  selected: DropdownMenuItem;
  'aria-label'?: string;
}

const TitleButton = styled.button<{ isOpen: boolean; className?: string }>`
  &${({ className }) =>
      className ? `.${className.split(/\s+/g).join('.')}` : ''} {
    display: flex !important;
    outline: none;
    background-color: ${({ isOpen }) =>
      isOpen ? colors.navy01 : 'transparent'} !important;
    border-color: ${({ isOpen }) =>
      isOpen ? colors.aqua01 : 'transparent'} !important;
    @media (min-width: ${appBreakpoints.small}px) {
      ${headerButtonStyle}
    }
  }
`;
const MenuItemButton = styled.button`
  cursor: pointer;
  background: transparent;
  color: ${colors.white};
  border-color: transparent;
  border-width: 0 4px 0 0;
  border-style: solid;
  outline: none;
  padding: 10px 18px 16px;
  font-family: ${fonts.legacy};
  text-align: left;
  &.selected {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &:focus,
  &:hover {
    outline: none;
    border-color: ${colors.aqua01};
  }
  &:focus {
    background-color: rgba(0, 0, 0, 0.4);
  }
  &:hover {
    background-color: ${colors.navy01};
  }
`;

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  id,
  className = '',
  buttonClassName = '',
  tabIndex = 0,
  titleRender = ({ label }: DropdownMenuItem) => label,
  onSelectItem = () => {},
  items,
  selected,
  'aria-label': ariaLabel = '',
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const openButton = React.useRef<HTMLButtonElement>(null);
  const menuList = React.createRef<HTMLDivElement>();

  const toggleMenuOpen = () => setIsOpen(prevValue => !prevValue);
  const closeMenu = React.useCallback(() => {
    setIsOpen(false);
  }, []);
  const onEscKeyDown = React.useCallback(() => {
    closeMenu();
    openButton.current?.focus();
  }, [openButton, closeMenu]);
  useItemKeysNavigation(menuList, isOpen, onEscKeyDown);
  useClickOutside({ ref: wrapperRef, handler: closeMenu });

  return (
    <div
      className={`drop-down-menu ${className} ${
        isOpen ? `--opened` : '--closed'
      }`}
      ref={wrapperRef}
      id={id}
    >
      <TitleButton
        type='button'
        className={buttonClassName}
        onClick={toggleMenuOpen}
        tabIndex={+tabIndex}
        isOpen={isOpen}
        ref={openButton}
        aria-label={ariaLabel}
        aria-expanded={isOpen}
        aria-haspopup='menu'
        aria-controls={id}
      >
        {titleRender(selected)}
        <M.Icon icon={isOpen ? 'ChevronUp' : 'ChevronDown'} size='16' />
      </TitleButton>
      {isOpen && (
        <div className='language-toggle__options' role='menu' ref={menuList}>
          {items.map(item => (
            <MenuItemButton
              role='menuitem'
              className={`${item.value} ${
                selected.value === item.value ? 'selected' : ''
              }`}
              key={item.value}
              type='button'
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                onSelectItem(e, item);
                closeMenu();
              }}
            >
              {item.label}
            </MenuItemButton>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
