import React from 'react';
import { M } from '@dashboard-experience/mastodon';

import styled from 'styled-components';

import '../scss/TileSelect.scss';

const ContainerStyled = styled.div`
  margin-bottom: 0.5rem;

  &:last-of-type {
    margin-bottom: 0;
  }

  .cds--form-item {
    padding-left: 1.5rem;

    .cds--checkbox-label {
      margin-bottom: 0;
    }

    .cds--checkbox-label-text {
      padding-left: 1rem;
    }
  }
`;

const TileCheckbox = ({
  checked = false,
  id,
  labelText,
  onChange,
  shouldDisplay = true,
}: {
  checked?: boolean;
  id: string;
  labelText: string;
  onChange: (option: string) => void;
  shouldDisplay?: boolean;
}) => {
  if (shouldDisplay) {
    return (
      <ContainerStyled>
        <M.Checkbox
          data-testid={id}
          className='tile-option-select pt-4 pb-4 pr-4 rounded w-100'
          checked={checked}
          id={id}
          labelText={labelText}
          onChange={onChange}
        />
      </ContainerStyled>
    );
  }
  return null;
};

export default TileCheckbox;
