import React from 'react';

import { ReportSummary } from 'api/candidates/types/Report';
import { useSelector } from 'react-redux';
import { isInternational } from 'lib/helpers';
import MainContainer from '../../../components/MainContainer';
import TermsOfService from '../../../components/Reports/TermsOfService';
import LoginErrorNotification from '../../../components/LoginErrorNotification/LoginErrorNotification';

export interface SingleReportData extends Record<string, any> {
  id: string;
  status: string;
  [key: string]: any;
}
export interface Portal<T = ReportSummary> {
  reports: Array<T>;
  count: number;
  termsOfService: {
    content: string;
    accepted: boolean;
    contentHash: string;
  };
  commentSubmitted?: boolean;
  disputeSubmitted?: boolean;
  firstName?: string;
  loggedIn?: boolean;
  applicantId: string | null;
  processing?: boolean;
  loggedOut?: boolean;
  token?: string;
  error: any;
  notices: any[];
  expungements: any[];
}

export const AuthenticatedView: React.FC<{ children: any }> = ({
  children,
}) => {
  const {
    portal: { reports, termsOfService, error },
    international,
  } = useSelector((state: any) => ({
    portal: state.portal,
    international: state.international?.international || isInternational(),
  }));
  if (error) {
    return <LoginErrorNotification fetchError={error} />;
  }
  if (!international && !termsOfService.accepted && reports.length) {
    return (
      <MainContainer>
        <TermsOfService
          content={termsOfService.content}
          contentHash={termsOfService.contentHash}
        />
      </MainContainer>
    );
  }
  return <>{children}</>;
};
