import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import DownloadPDFNote from './DownloadPDFNote';
import { trackAnalyticsEvent } from '../../../actions';
import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from '../../../lib/analytics';
import CreditReportUtils from './CreditReportCommon/CreditReportUtils';

const StyledPopoverContent = styled.div`
  .cds--popover-content {
    width: auto;
    max-width: fit-content;
    background-color: #fff;
  }
  .mastodon-button {
    font-weight: 400 !important;
  }
`;

const DownloadPDFDocuments = ({
  consentLink,
  documents,
  candidate,
  reportDownloadedAmplitudeEvent,
  report,
}) => {
  const [open, setOpen] = useState(false);

  const find = type => {
    let lastDocument = null;

    if (!documents.length) {
      return null;
    }

    if (documents.length > 1 && type === 'pdf_international_report') {
      documents = documents.filter(doc =>
        doc.locale?.startsWith(candidate.locale),
      );
    }

    documents.forEach(doc => {
      if (doc.type !== type) {
        return;
      }

      if (lastDocument === null || lastDocument.createdAt < doc.createdAt) {
        lastDocument = doc;
      }
    });

    return lastDocument;
  };

  const criminalReport = find('pdf_report_encrypted') || find('pdf_report');
  const drugAlcoholClearinghouseReport = find(
    'pdf_drug_alcohol_clearinghouse_report',
  );
  const internationalReport = find('pdf_international_report');
  const healthReport = find('pdf_health_report');
  const creditReport = find('pdf_credit_report');
  const selfDisclosureReport = find('pdf_self_disclosure');
  const paChildAbuseCertificate = find('pa_child_abuse_result_certificate');

  const creditReportUtils = new CreditReportUtils(report);

  const isInternationalHasNonEmptyCreditRecords =
    creditReportUtils.isInternationalHasNonEmptyRecords();

  const internationalCreditRecords =
    creditReportUtils.findInternationalRecords();

  const anyDoc =
    consentLink ||
    criminalReport ||
    drugAlcoholClearinghouseReport ||
    healthReport ||
    creditReport ||
    paChildAbuseCertificate ||
    internationalReport ||
    isInternationalHasNonEmptyCreditRecords ||
    selfDisclosureReport;

  if (!anyDoc) {
    return null;
  }

  const encrypted = !!(
    criminalReport && criminalReport.type === 'pdf_report_encrypted'
  );

  return (
    <>
      <StyledPopoverContent>
        <M.Popover
          caret={false}
          data-testid='download-pdf-toggle'
          dropShadow
          id='download-pdf-toggle'
          open={open}
        >
          <M.Button kind='secondary' size='lg' onClick={() => setOpen(!open)}>
            {i18n.getStr('buttons.downloadPDF')}{' '}
            <M.Icon icon='CaretDown' size='16' />
          </M.Button>
          <M.PopoverContent
            style={{ width: 'inherit', 'max-width': 'inherit' }}
          >
            {consentLink && (
              <>
                <M.Link
                  data-testid='download-consent'
                  href={consentLink}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => {
                    setOpen(false);
                    reportDownloadedAmplitudeEvent(`Consent Link`);
                  }}
                >
                  <T value='buttons.downloadConsentFormPDF' />
                </M.Link>
                {(criminalReport ||
                  drugAlcoholClearinghouseReport ||
                  healthReport ||
                  internationalReport ||
                  creditReport ||
                  paChildAbuseCertificate ||
                  selfDisclosureReport) && <M.Divider />}
              </>
            )}
            {criminalReport && (
              <M.Link
                data-testid='download-report'
                href={criminalReport.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`Criminal Report`);
                }}
              >
                <M.Button kind='tertiary' size='md'>
                  <T value='buttons.downloadCriminalReportPDF' />
                </M.Button>
              </M.Link>
            )}
            {drugAlcoholClearinghouseReport && (
              <M.Link
                data-testid='download-dac-report'
                href={drugAlcoholClearinghouseReport.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`DAC Report`);
                }}
              >
                <M.Button kind='tertiary' size='md'>
                  <T value='buttons.downloadDrugAlcoholClearinghouseReportPDF' />
                </M.Button>
              </M.Link>
            )}
            {healthReport && (
              <M.Link
                href={healthReport.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`Health Report`);
                }}
              >
                <M.Button kind='tertiary'>
                  <T value='buttons.downloadHealthReportPDF' />
                </M.Button>
              </M.Link>
            )}
            {internationalReport && (
              <M.Link
                href={internationalReport.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`International Report`);
                }}
              >
                <M.Button kind='tertiary'>
                  <T value='buttons.downloadInternationalReportPDF' />
                </M.Button>
              </M.Link>
            )}
            {creditReport && (
              <M.Link
                href={creditReport.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`Credit Report`);
                }}
              >
                <M.Button kind='tertiary'>
                  <T value='buttons.downloadCreditReportPDF' />
                </M.Button>
              </M.Link>
            )}
            {isInternationalHasNonEmptyCreditRecords && (
              <M.Link
                href={internationalCreditRecords[0].pdfUrl}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`Credit Report`);
                }}
              >
                <M.Button kind='tertiary'>
                  <T value='buttons.downloadCreditReportPDF' />
                </M.Button>
              </M.Link>
            )}
            {paChildAbuseCertificate && (
              <M.Link
                href={paChildAbuseCertificate.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`Child Abuse Certificate`);
                }}
              >
                <M.Button kind='tertiary'>
                  <T value='buttons.downloadPaChildAbuseCertificate' />
                </M.Button>
              </M.Link>
            )}
            {selfDisclosureReport && (
              <M.Link
                href={selfDisclosureReport.downloadUri}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => {
                  reportDownloadedAmplitudeEvent(`Self Disclosure Report`);
                }}
              >
                <M.Button kind='tertiary'>
                  <T value='buttons.downloadSelfDisclosureReportPDF' />
                </M.Button>
              </M.Link>
            )}
          </M.PopoverContent>
        </M.Popover>
        <DownloadPDFNote encryption={encrypted} />
      </StyledPopoverContent>
    </>
  );
};

DownloadPDFDocuments.propTypes = {
  consentLink: PropTypes.string,
  documents: PropTypes.array,
  candidate: PropTypes.object,
  reportDownloadedAmplitudeEvent: PropTypes.func,
  report: PropTypes.object,
};

DownloadPDFDocuments.defaultProps = {
  consentLink: '',
  documents: [],
  candidate: {},
  reportDownloadedAmplitudeEvent: () => {},
  report: {},
};

const mapDispatchToProps = {
  reportDownloadedAmplitudeEvent: reportName =>
    trackAnalyticsEvent(ANALYTICS_EVENTS.REPORT_DOWNLOADED, {
      [ANALYTICS_PROPERTIES.REPORT_NAME]: reportName,
    }),
};
export default connect(null, mapDispatchToProps)(DownloadPDFDocuments);
