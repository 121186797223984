const upper = value => value && value.toUpperCase();

const monthYear = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 6)}`;
};

const monthDayYear = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2)}`;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8,
  )}`;
};

const phone = value => {
  if (!value) {
    return value;
  }

  return value.replace(/[^\d()+]/g, '');
};

const zipcode = value => {
  return value.replace(/[^\d]/g, '').slice(0, 5);
};

const numbersOnly = value => {
  return value.replace(/[^\d]/g, '');
};

const year = value => {
  return value.replace(/[^\d]/g, '').slice(0, 4);
};

const ssn = (value, previousValue) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return `${onlyNums}-`;
    }
    if (onlyNums.length === 5) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`;
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 5)}-${onlyNums.slice(
    5,
    9,
  )}`;
};

const ccCVC = value => {
  return value.replace(/[^\d]/g, '').slice(0, 3);
};

const ccZipcode = value => {
  return value.replace(/[^\d]/g, '').slice(0, 5);
};

const ccMonthYear = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }

  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
};

const ccNumber = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4)}`;
  }

  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
      8,
      12,
    )}`;
  }

  return `${onlyNums.slice(0, 4)} ${onlyNums.slice(4, 8)} ${onlyNums.slice(
    8,
    12,
  )} ${onlyNums.slice(12, 16)}`;
};

const normalizeDriverLicenseNumber = number => {
  return number.replace(/( |-)/g, '').toUpperCase();
};

export {
  ccCVC,
  ccNumber,
  ccMonthYear,
  ccZipcode,
  monthYear,
  monthDayYear,
  normalizeDriverLicenseNumber,
  numbersOnly,
  phone,
  ssn,
  upper,
  year,
  zipcode,
};
