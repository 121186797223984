import PropTypes from 'prop-types';
import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';

const FIELD_MULTI_SELECT = 'field_multi_select';
const FORM = 'form';
const MACRO = 'macro';
const SELECT = 'select';
const SELECT_WITHOUT_BACK = 'select_without_back';
const SUMMARY = 'summary';
const DESCRIBE_INACCURATE_SCREEN = 'describeInaccurate';

const SummaryErrorButtonStyled = styled(M.Button)`
  background-color: ${colors.checkrBlue60} !important;
`;

const FooterWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
`;

const footerMapping: { [key: string]: string } = {
  articleSelect: SELECT,
  articleForm: FORM,
  articleMacro: MACRO,
  chargeSelect: SELECT,
  fieldSelect: FIELD_MULTI_SELECT,
  sectionSelect: SELECT_WITHOUT_BACK,
  summary: SUMMARY,
  describeInaccurate: DESCRIBE_INACCURATE_SCREEN,
};

interface Props {
  actions: {
    [key: string]: () => void;
  };
  canContinue?: boolean;
  currentPage: string;
  hasItems: boolean;
  processing: boolean;
  valid: boolean;
  anyFieldsSelected: boolean;
}

const Footer = ({
  actions,
  canContinue = true,
  currentPage,
  hasItems,
  processing,
  valid,
  anyFieldsSelected,
}: Props) => {
  const footerType = footerMapping[currentPage];

  const addItemButton = () => (
    <M.Button
      data-testid='dispute-footer-add-button'
      type='button'
      onClick={actions.addItem}
      disabled={!valid}
    >
      <T value='buttons.continue' />
    </M.Button>
  );

  const addAdditionalErrorButton = () => (
    <SummaryErrorButtonStyled
      data-testid='dispute-footer-add-error-button'
      type='button'
      disabled={processing}
      onClick={actions.disputeAdditionalItem}
    >
      <T value='buttons.addAdditionalError' />
    </SummaryErrorButtonStyled>
  );

  const backButton = () => (
    <M.Button
      data-testid='dispute-footer-back-button'
      kind='tertiary'
      type='button'
      onClick={actions.handleBack}
    >
      &lt; <T value='buttons.back' />
    </M.Button>
  );

  const closeButton = () => (
    <M.Button
      data-testid='dispute-footer-close-button'
      type='button'
      kind='secondary'
      onClick={actions.toggleDisputeModal}
    >
      <T value='buttons.close' />
    </M.Button>
  );

  const fieldSelectContinueButton = () => (
    <M.Button
      data-testid='dispute-footer-continue-button'
      type='button'
      onClick={actions.completeFieldSelection}
      disabled={!valid || !anyFieldsSelected}
    >
      <T value='buttons.continue' />
    </M.Button>
  );
  const describeContinueButton = () => (
    <M.Button
      data-testid='dispute-footer-continue-button'
      type='button'
      onClick={actions.inaccurateContinue}
    >
      <T value='buttons.continue' />
    </M.Button>
  );

  const submitButton = () => (
    <M.Button
      data-testid='dispute-footer-submit-button'
      type='submit'
      disabled={processing || !valid}
    >
      {processing ? (
        <M.LoadingInline className='button-loading-icon' />
      ) : (
        <T value='buttons.submit' />
      )}
    </M.Button>
  );

  const viewSummaryButton = () => (
    <SummaryErrorButtonStyled
      data-testid='dispute-footer-view-button'
      type='button'
      onClick={actions.viewSummary}
    >
      <T value='buttons.summary' />
    </SummaryErrorButtonStyled>
  );

  return (
    <FooterWrapperStyled>
      <div>
        {footerType !== SELECT_WITHOUT_BACK &&
          footerType !== SUMMARY &&
          backButton()}
      </div>
      <div data-testid='dispute-footer-button-wrapper'>
        {footerType !== SUMMARY && hasItems && viewSummaryButton()}
        {footerType === SUMMARY && addAdditionalErrorButton()}
        {footerType === SUMMARY && submitButton()}
        {footerType === DESCRIBE_INACCURATE_SCREEN && describeContinueButton()}
        {footerType === FORM && canContinue && addItemButton()}
        {footerType === FIELD_MULTI_SELECT &&
          canContinue &&
          fieldSelectContinueButton()}
        {!canContinue && closeButton()}
      </div>
    </FooterWrapperStyled>
  );
};

Footer.defaultProps = {
  canContinue: true,
};

Footer.propTypes = {
  anyFieldsSelected: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  canContinue: PropTypes.bool,
  currentPage: PropTypes.string.isRequired,
  hasItems: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default Footer;
