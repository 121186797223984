import intlLoginDefaultValues from './intlLoginDefaultValues';

export const initialState = {
  values: intlLoginDefaultValues,
};

const intlLogin = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    default:
      return state;
  }
};

export default intlLogin;
