import React from 'react';
import SsnCheckVerificationForm from './SsnCheckVerificationForm';
import Verification, {
  connectToVerifications,
  VerificationProps,
} from '../VerificationBase';

class SsnCheckVerification extends Verification {
  constructor(props: VerificationProps) {
    super(props);
    this.state = {
      showNav: false,
    };
  }

  renderCustomPanel() {
    const { doc } = this.getRenderAttributes();

    const {
      verification: { canDeclareNoSsn },
      submitting,
    } = this.props;
    return (
      <div>
        {doc.input && (
          <SsnCheckVerificationForm
            formSubmitted={submitting}
            canDeclareNoSsn={canDeclareNoSsn}
          />
        )}
      </div>
    );
  }
}

export default connectToVerifications(SsnCheckVerification);
