const initialState = Object.freeze({
  processing: false,
  success: false,
  error: false,
  successMsg: '',
  errorMsg: '',
});
const onRequest = Object.freeze({
  processing: true,
  success: false,
  error: false,
  successMsg: '',
  errorMsg: '',
});
const onSuccess = Object.freeze({
  processing: false,
  success: true,
  error: false,
  successMsg: '',
  errorMsg: '',
});
const onFailure = Object.freeze({
  processing: false,
  success: false,
  error: true,
  successMsg: '',
  errorMsg: '',
});

const httpHelper = {
  initialState,
  onRequest,
  onSuccess,
  onFailure,
};

export default httpHelper;
