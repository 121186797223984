const setStatusFromResult = entry => {
  if (
    entry.status &&
    entry.status !== 'dispute' &&
    typeof entry.result === 'string'
  ) {
    return {
      ...entry,
      status: entry.result || entry.status,
    };
  }
  return entry;
};

const mapEntryStatusFromResult = item => {
  if (item === null) return item;
  if (typeof item === 'object' && Array.isArray(item)) {
    return item.map(entry => mapEntryStatusFromResult(entry));
  }
  if (typeof item === 'object') {
    return Object.entries(setStatusFromResult(item)).reduce(
      (acc, [key, entry]) => {
        return {
          ...acc,
          [key]: mapEntryStatusFromResult(entry),
        };
      },
      {},
    );
  }
  return item;
};

const setReportStatusfromResult = report => {
  const result = mapEntryStatusFromResult(report);
  return result;
};

export default setReportStatusfromResult;
