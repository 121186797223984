import { Translate as T } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { colors, M } from '@dashboard-experience/mastodon';
import React, { useEffect, useState } from 'react';
import { i18n } from '@international/mastodon-i18n';
import { Report } from 'types/report';
import styled from 'styled-components';
import { appBreakpoints } from 'reducers';
import { REPORT_PAGE } from 'identifiers/identifiers';
import RequestConfirmation from '../../../Workflow/Disputes/RequestConfirmation';
import Workflow from '../../../Workflow/Disputes/Workflow';
import BifurcationSelector from '../../../Workflow/Disputes/MotorVehicleReport/BifurcationSelector';
import DisputeModal from './DisputeModal';
import Modal from '../../../Workflow/Disputes/Modal';
import { considerScreenings } from '../lib/screenings';
import ConfirmPreviouslyDisputedReport from '../../../Workflow/Disputes/ConfirmPreviouslyDisputedReport';
import useRecentFinalizedDisputeDate from '../../../../api/report/useRecentFinalizedDisputeDate';
import { HELP_CENTER_LINKS } from '../../../../constants';

export interface ReportAnErrorWorkflowCardProps {
  candidateStoriesEnabled: boolean;
  disputeSubmitted: boolean;
  isOpen: boolean;
  isNewOpen: boolean;
  isCPDOpen: boolean;
  isSuccessDisputeOpenedOnce: boolean;
  report: Report;
  toggleCandidateStoryModal: () => void;
  toggleDisputeModal: () => void;
  toggleNewDisputeModal: () => void;
  toggleUpdateDLModal: () => void;
  openSuccessDisputeModal: () => void;
  toggleConfirmPreviouslyDisputedReportModal: () => void;
}

const CardStyled = styled(M.Container)`
  padding: 39px 23px !important;
`;

export const ContainerStyled = styled.div`
  font-size: 14px;
  padding: 1rem 0 0 0;

  @media (min-width: ${appBreakpoints.small}px) {
    padding: 0 18px;
  }

  button {
    background-color: ${colors.navy04} !important;
    display: block;
    margin-bottom: 16px !important;
  }

  h5 {
    font-size: 16px;
  }

  section {
    padding: 16px 0;

    h3 {
      margin-bottom: 4px !important;
    }

    ul {
      margin: 0;
      padding: 0 1rem;
    }
  }
`;

export const IconStyled = styled(M.Icon)`
  @media (min-width: ${appBreakpoints.small}px) {
    height: 54px !important;
    width: 54px !important;
  }
`;

const ReportAnErrorWorkflowCard: React.FC<ReportAnErrorWorkflowCardProps> = ({
  candidateStoriesEnabled,
  disputeSubmitted,
  isOpen,
  isNewOpen,
  isCPDOpen,
  report,
  toggleCandidateStoryModal,
  toggleDisputeModal,
  toggleNewDisputeModal,
  toggleUpdateDLModal,
  openSuccessDisputeModal,
  toggleConfirmPreviouslyDisputedReportModal,
  isSuccessDisputeOpenedOnce,
}) => {
  const MVR_SCREENING_NAME = 'motorVehicleReport';

  const CLEARING_HOUSE_NAME = 'clearinghouse';
  const isClearingHouseReport =
    (report.package && report.package.toLowerCase() === CLEARING_HOUSE_NAME) ||
    report.account.uriName.toLowerCase().includes(CLEARING_HOUSE_NAME);

  const allConsiderScreenings = considerScreenings(report);
  const ssn_trace_consider_only =
    allConsiderScreenings.length === 1 &&
    allConsiderScreenings[0] === 'ssnTrace';
  const isDisputeAvailable = !!allConsiderScreenings.length;

  const isMvrConsider = allConsiderScreenings?.some(
    screening => screening === MVR_SCREENING_NAME,
  );

  const areOtherScreeningsConsider = allConsiderScreenings?.some(
    screening => screening !== MVR_SCREENING_NAME,
  );

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [confirmNewInformation, setConfirmNewInformation] = useState(false);
  const { recentFinalizedDisputeDate, email } = useRecentFinalizedDisputeDate(
    report.id,
  );

  useEffect(() => {
    if (!window.adaEmbed) return;
    if (isOpen || isNewOpen || isCPDOpen) {
      window.adaEmbed.stop().catch((e: Error) => {
        if (e.name !== 'AdaEmbedError') {
          throw e;
        }
      });
    } else {
      window.adaEmbed.start(window.adaSettings).catch((e: Error) => {
        if (e.name !== 'AdaEmbedError') {
          throw e;
        }
      });
    }
  }, [isOpen, isNewOpen, isCPDOpen]);

  const renderPreviouslyDisputedModal = () => {
    return (
      <ConfirmPreviouslyDisputedReport
        toggle={toggleConfirmPreviouslyDisputedReportModal}
        email={email}
        createdAt={recentFinalizedDisputeDate}
        isOpen={isCPDOpen}
        confirmNewInformation={setConfirmNewInformation}
        continueFlow={continueFlow}
      />
    );
  };
  // MVR Dispute flow
  const renderNewDisputeEnabledFlow = () => {
    if (isMvrConsider) {
      if (hasSubmitted) {
        return <RequestConfirmation setHasSubmitted={setHasSubmitted} />;
      }
      if (areOtherScreeningsConsider) {
        return (
          <BifurcationSelector
            candidateStoriesEnabled={candidateStoriesEnabled}
            toggleLegacy={toggleDisputeModal}
            toggle={toggleNewDisputeModal}
            toggleCandidateStoryModal={toggleCandidateStoryModal}
            toggleUpdateDLModal={toggleUpdateDLModal}
            report={report}
            setHasSubmitted={setHasSubmitted}
          />
        );
      }
      return (
        <Workflow
          candidateStoriesEnabled={candidateStoriesEnabled}
          toggle={toggleNewDisputeModal}
          toggleCandidateStoryModal={toggleCandidateStoryModal}
          toggleUpdateDLModal={toggleUpdateDLModal}
          report={report}
          setHasSubmitted={setHasSubmitted}
        />
      );
    }
    return <></>;
  };

  const continueFlow = () => {
    if (!isClearingHouseReport && isMvrConsider) {
      return toggleNewDisputeModal();
    }
    return toggleDisputeModal();
  };

  const handleClick = () => {
    if (!confirmNewInformation && recentFinalizedDisputeDate !== '') {
      return toggleConfirmPreviouslyDisputedReportModal();
    }
    return continueFlow();
  };

  const showDisputeSuccessSubmittedModal =
    disputeSubmitted && !isOpen && !isSuccessDisputeOpenedOnce;

  if (showDisputeSuccessSubmittedModal) {
    openSuccessDisputeModal();
  }

  if (!isDisputeAvailable) return null;

  return (
    <>
      <CardStyled data-testid='file-a-dispute-card'>
        <M.Grid>
          <M.Container.Row>
            <M.Container.Col lg={1} sm={12}>
              <IconStyled
                icon='Error'
                size='28'
                style={{
                  fill: colors.pinkAccent02,
                }}
              />
            </M.Container.Col>
            <M.Container.Col>
              {ssn_trace_consider_only && (
                <ContainerStyled>
                  <T
                    value='components.Report.SSNTrace.reportable.dispute.title'
                    tag='h5'
                  />
                  <T value='components.Report.SSNTrace.reportable.dispute.subtitle' />
                  <section>
                    <M.Link
                      href={HELP_CENTER_LINKS.contactUs}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <M.Button kind='primary'>
                        <T value='components.Report.SSNTrace.reportable.dispute.fileADispute' />
                      </M.Button>
                    </M.Link>
                  </section>
                </ContainerStyled>
              )}
              {!ssn_trace_consider_only && (
                <ContainerStyled>
                  <T value='components.Report.Dispute.NewCTA.title' tag='h5' />
                  <T value='components.Report.Dispute.NewCTA.subtitle' />
                  <section>
                    <T
                      value='components.Report.Dispute.NewCTA.example'
                      tag='h3'
                    />
                    <ul>
                      <li>
                        <T value='components.Report.Dispute.NewCTA.example1' />
                      </li>
                      <li>
                        <T value='components.Report.Dispute.NewCTA.example2' />
                      </li>
                    </ul>
                  </section>
                  <M.Button
                    data-testid={REPORT_PAGE.disputeButton}
                    kind='primary'
                    onClick={handleClick}
                  >
                    <T value='components.Report.Dispute.NewCTA.fileADispute' />
                  </M.Button>
                  <T
                    value='components.Report.Dispute.NewCTA.helpCenterLink'
                    dangerousHTML
                  />
                </ContainerStyled>
              )}
            </M.Container.Col>
          </M.Container.Row>
        </M.Grid>
      </CardStyled>

      <Modal
        isOpen={isNewOpen}
        toggle={toggleNewDisputeModal}
        title={i18n.getStr('components.Report.Dispute.newTitle')}
      >
        {renderNewDisputeEnabledFlow()}
      </Modal>
      <DisputeModal
        isOpen={isOpen}
        toggleDisputeModal={toggleDisputeModal}
        report={report}
        disputeSubmitted={disputeSubmitted}
        toggleCandidateStoryModal={toggleCandidateStoryModal}
      />
      {renderPreviouslyDisputedModal()}
    </>
  );
};

ReportAnErrorWorkflowCard.propTypes = {
  candidateStoriesEnabled: PropTypes.bool.isRequired,
  disputeSubmitted: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isNewOpen: PropTypes.bool.isRequired,
  report: PropTypes.any.isRequired,
  toggleCandidateStoryModal: PropTypes.func.isRequired,
  toggleDisputeModal: PropTypes.func.isRequired,
  toggleNewDisputeModal: PropTypes.func.isRequired,
};

export default ReportAnErrorWorkflowCard;
