/* eslint-disable no-underscore-dangle */
import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { responsiveStoreEnhancer } from 'redux-responsive';
import * as Sentry from '@sentry/react';
import { StoreState } from 'types/redux';
import rootReducer from '../reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (
      action?.type === '@@redux-form/CHANGE' &&
      action?.meta?.field === 'ssn'
    ) {
      // sanitize ssn from on change in login form
      return {
        ...action,
        payload: null,
      };
    }

    return action;
  },
});

const configureStore = (preloadedState: StoreState) =>
  createStore(
    rootReducer,
    preloadedState,
    compose(
      responsiveStoreEnhancer,
      applyMiddleware(thunk),
      sentryReduxEnhancer,
    ),
  );

export default configureStore;
