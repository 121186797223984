import PropTypes from 'prop-types';
import React from 'react';

import {
  allSearchRecords,
  formatSearchTitle,
  getChargeTitle,
  getChargeDate,
} from '../lib/screenings';
import CandidateStoriesMultiSelect from './CandidateStoriesMultiSelect';

const ChargesByRecordForm = ({ search, toggleRecord, selectedRecords }) => {
  const recordSelected = recordId => {
    return !!selectedRecords.find(item => item.id === recordId);
  };

  return (
    <div className='mb-4'>
      <div className='mb-2'>
        <b>{formatSearchTitle(search)}</b>
      </div>
      <div className='form-group'>
        {allSearchRecords(search).map(record => (
          <CandidateStoriesMultiSelect
            id={record.id}
            name='record'
            key={record.id}
            displayValue={getChargeTitle(record)}
            selectValue={record}
            toggleValue={toggleRecord}
            date={getChargeDate(record)}
            selected={recordSelected(record.id)}
          />
        ))}
      </div>
    </div>
  );
};

ChargesByRecordForm.propTypes = {
  search: PropTypes.object.isRequired,
  toggleRecord: PropTypes.func.isRequired,
  selectedRecords: PropTypes.array.isRequired,
};

export default ChargesByRecordForm;
