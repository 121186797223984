import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import React from 'react';

const Loading = ({ className = '', ...props }) => (
  <div className={`loading text-muted ${className}`} {...props}>
    <M.LoadingInline />
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.defaultProps = {
  className: '',
};

export default Loading;
