import { analytics } from '@dashboard-experience/utils';
import countries from 'i18n-iso-countries';

import { queryReportCache } from '../api/report/queryCache';
import { AMPLITUDE_API_KEY } from '../constants';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const services = [
  {
    name: 'amplitude',
    apiKey: AMPLITUDE_API_KEY,
  },
];

// analytics(services) should only be run on client side
// since it requires sessionStorage

let analyticsCheck;

try {
  analyticsCheck = analytics(services);
} catch (e) {
  // eslint-disable-next-line no-console
  console.log('Analytics disabled due to cookies being disabled');
}
const checkrAnalytics = analyticsCheck;

const ANALYTICS_EVENTS = {
  CANDIDATE_STORY_SHARED: 'Candidate Portal Story Shared',
  EXCEPTION_FLOW_INFORMATION_COMPLETED:
    'Candidate Portal Exception Information Completed',
  EXCEPTION_FLOW_WELCOME_PAGE_VIEWED:
    'Candidate Portal Exception Welcome Page Viewed',
  FAQ_SECTION_LINK_CLICKED: 'Candidate Portal FAQ Viewed',
  HELP_CENTER_VIEWED: 'Candidate Portal Help Center Viewed',
  LANDING_PAGE_VIEWED: 'Candidate Portal Landing Page Viewed',
  LANGUAGE_SELECTED: 'Candidate Portal Language Selected',
  NOT_YOU_BUTTON_CLICKED: 'Candidate Portal Exception Not You Button Clicked',
  PERSONAL_INFO_UPDATED: 'Candidate Portal Personal Information Updated',
  REPORT_DOWNLOADED: 'Candidate Portal Report Downloaded',
  REPORT_ERROR_BUTTON_CLICKED: 'Candidate Portal Error Reported',
  REPORT_VIEWED: 'Candidate Portal Report Viewed',
  SCREENING_DOWNLOADED: 'Candidate Portal Screening Downloaded',
  CANDIDATE_PORTAL_LOGIN_PAGE_VIEWED: 'Candidate Portal Login Page Viewed',
  SSN_EXCEPTION_CHECK_NOT_COMPLETED: 'SSN Exception Check Not Completed',
  SSN_EXCEPTION_ID_DOC_UPLOAD_COMPLETED:
    'SSN Exception ID Doc Upload Completed',
  SSN_EXCEPTION_ID_DOC_UPLOAD_VIEWED: 'SSN Exception ID Doc Upload Viewed',
  SSN_EXCEPTION_INFO_RECEIVED_VIEWED: 'SSN Exception Info Received Viewed',
  SSN_EXCEPTION_POLLING_COMPLETED: 'SSN Exception Polling Completed',
  SSN_EXCEPTION_SSN_CARD_UPLOAD_COMPLETED:
    'SSN Exception SSN Card Upload Completed',
  SSN_EXCEPTION_SSN_CARD_UPLOAD_VIEWED: 'SSN Exception SSN Card Upload Viewed',
  SSN_EXCEPTION_SSN_REENTRY_COMPLETED: 'SSN Exception SSN Reentry Completed',
  SSN_EXCEPTION_SSN_REENTRY_VIEWED: 'SSN Exception SSN Reentry Viewed',
  SSN_EXCEPTION_UPLOAD_DOC_LATER_VIEWED:
    'SSN Exception Upload Doc Later Viewed',
  SSN_EXCEPTION_WAITING_SCREEN_VIEWED: 'SSN Exception Waiting Screen Viewed',
  APPLY_FLOW_BACKGROUND_CHECK_STARTED: 'Apply Flow Background Check Started',
  SSN_EXCEPTION_CONTACT_CHECKR_VIEWED: 'SSN Exception Contact Checkr Viewed',
};

const PERSONAL_CHECKS_ANALYTICS_EVENTS = {
  PERSONAL_CHECKS_BANNER_VIEWED: 'Personal Checks Banner Viewed',
  PERSONAL_CHECKS_LEARN_MORE_CLICKED:
    'Personal Checks Banner Learn More Clicked',
  PERSONAL_CHECKS_DISMISS_CLICKED: 'Personal Checks Banner Dismiss Clicked',
  PERSONAL_CHECKS_SUBSCRIBE_CLICKED: 'Personal Checks Banner Subscribe Clicked',
  PERSONAL_CHECKS_CLOSE_CLICKED: 'Personal Checks Banner Close Clicked',
  PERSONAL_CHECKS_SSN_MONITORING_CLICKED:
    'Personal Checks Banner SSN Monitoring Clicked',
  PERSONAL_CHECKS_GLOBAL_IDENTITY_MONITORING_CLICKED:
    'Personal Checks Banner Global Identity Monitoring Clicked',
  PERSONAL_CHECKS_RECORDS_MONITORING_CLICKED:
    'Personal Checks Banner Records Monitoring Clicked',
  PERSONAL_CHECKS_EXPUNGEMENT_CLICKED:
    'Personal Checks Banner Expungement Clicked',
  PERSONAL_CHECKS_PERSONAL_PROFILE_CLICKED:
    'Personal Checks Banner Personal Profile Clicked',
  PERSONAL_CHECKS_PRE_APPROVAL_CLICKED:
    'Personal Checks Banner Pre-Approval Clicked',
};

const VERIFICATION_EVENT_TITLE = [
  ANALYTICS_EVENTS.EXCEPTION_FLOW_INFORMATION_COMPLETED,
  ANALYTICS_EVENTS.EXCEPTION_FLOW_WELCOME_PAGE_VIEWED,
  ANALYTICS_EVENTS.NOT_YOU_BUTTON_CLICKED,
];

const NO_EVENT_PROPS_EVENTS = [
  ANALYTICS_EVENTS.FAQ_SECTION_LINK_CLICKED,
  ANALYTICS_EVENTS.HELP_CENTER_VIEWED,
  ANALYTICS_EVENTS.LANDING_PAGE_VIEWED,
  ANALYTICS_EVENTS.LANGUAGE_SELECTED,
];

const USER_PROPERTIES = {
  APPLICANT_ID: 'Applicant ID',
};

const ANALYTICS_PROPERTIES = {
  ACCOUNT_RESOURCE_ID: 'Account Resource ID',
  ACCOUNT_URI_NAME: 'Account URI Name',
  ATTRIBUTION_SOURCE: 'Attribution Source',
  CANDIDATE_RESOURCE_ID: 'Candidate Resource ID',
  CANDIDATE_DATABASE_SOURCE: 'Candidate Database Source',
  CONTENT_KEYS: 'Content Keys',
  CUSTOM_CANDIDATE_ID: 'Custom Candidate ID',
  EXCEPTION_FLOW_VERIFICATION_ID: 'Exception ID',
  EXCEPTION_TYPE: 'Exception Type',
  INVITATION_SOURCE: 'Invitation Source',
  LANGUAGE: 'Language',
  QUESTION: 'Question',
  REPORT_GEO_COUNTRY: 'Report Geo Country',
  REPORT_NAME: 'Report Name',
  REPORT_RESOURCE_ID: 'Report Resource ID',
  SCREENING_ARRAY: 'Screening Array',
  SCREENING_NAME: 'Screening Name',
  WORK_COUNTRY_LOCATION: 'Work Country Location',
  LINK: 'Clicked Link',
};

const COUNTRIES_IN_US_DATA_CENTER = ['AS', 'GU', 'PH', 'PR', 'SG', 'US', 'VI'];
const LOCALE = 'en';

const SCREENING_NAME_MAPPING = {
  arrestSearch: 'Arrest Search',
  childAbuseSearches: 'Child Abuse Searches',
  clearinghouseSearch: 'Clearinghouse Search',
  countyCivilSearches: 'County Civil Searches',
  countyCriminalSearches: 'County Criminal Searches',
  creditReport: 'Credit Report',
  drugScreening: 'Drug Screening',
  educationVerification: 'Education Verification',
  employmentVerification: 'Employment Verification',
  evictionSearch: 'Eviction Search',
  facisSearch: 'Facis Search',
  federalCivilSearch: 'Federal Civil Search',
  federalCriminalSearch: 'Federal Criminal Search',
  federalDistrictCivilSearches: 'Federal District Civil Searches',
  federalDistrictCriminalSearches: 'Federal District Criminal Searches',
  globalWatchlistSearch: 'Global Watchlist Search',
  healthcareSanctionsSearches: 'Healthcare Sanctions Searches',
  internationalAdverseMediaSearches: 'Adverse Media',
  internationalCanadaBcEnhancedCriminalSearch: 'Canada Bc Enhanced Criminal',
  internationalCriminalSearches: 'International Criminal Searches',
  internationalCriminalSearchesV2: 'International Criminal',
  internationalEducationVerification: 'International Education',
  internationalEmploymentVerification: 'International Employment',
  internationalGlobalWatchlistSearch: 'Global Watchlist',
  internationalIdentityDocumentValidation:
    'International Identity Document Validation',
  internationalMotorVehicleReports: 'International Motor Vehicle Report',
  motorVehicleReport: 'Motor Vehicle Report',
  municipalCriminalSearches: 'Municipal Criminal Searches',
  nationalCriminalSearch: 'National Criminal Search',
  occupationalHealthScreening: 'Occupational Health Screening',
  personalReferenceVerifications: 'Personal Reference Verifications',
  pointerStateCriminalSearches: 'Pointer State Criminal Searches',
  professionalLicenseVerifications: 'Professional License Verifications',
  professionalReferenceVerifications: 'Professional Reference Verifications',
  program: 'Program',
  sexOffenderSearch: 'Sex Offender Search',
  ssnTrace: 'Ssn Trace',
  stateCriminalSearches: 'State Criminal Searches',
  terroristWatchlistSearch: 'Terrorist Watchlist Search',
};

const VERIFICATION_MODEL_TO_EXCEPTION_TYPE_MAPPING = {
  driver_license: 'Driver License Document Upload Exception',
  driver_license_history: 'Driver License History Document Upload Exception',
  education_proof: 'Education Proof Upload Exception',
  employment_proof: 'Employment Proof Upload Exception',
  location: 'Drug Schedule Location Form Exception',
  matrix_verification: 'Matrix Verification Exception',
  passport: 'Passport Document Upload Exception',
  previous_driver_license: 'Previous Driver License Upload Exception',
  selfie: 'Photo Verification Document Upload Exception',
  ssn_card: 'SSN Card Document Upload Exception',
  ssn_check_failure: 'SSN Check Failure Exception',
  ssn_confirmation: 'SSN Check Confirmation Form Exception',
  state_id_card: 'State ID Card Upload Exception',
};

const AMPLITUDE_NAME_MAP = {
  canada_bc_enhanced_criminal: 'Canada BC Enhanced Criminal',
  criminal: 'International Criminal',
  education: 'International Education',
  employment: 'International Employment',
  global: 'Global Watchlist',
  identity_document_validation: 'International Identity Document Validation',
  media_search: 'Adverse Media',
  motor_vehicle_report: 'International Motor Vehicle Report',
  right_to_work: 'International Right To Work',
};

const getCurrentReport = state => {
  const reportIndex = state?.navigation?.currentReportIndex;
  const reportId = state?.portal?.reports?.[reportIndex]?.id;

  return queryReportCache(reportId);
};

const getUserPropertiesFromStore = (_, state) => {
  const userProps = {};
  const applicantId = state?.portal?.applicantId;
  userProps[USER_PROPERTIES.APPLICANT_ID] =
    applicantId === null ? null : applicantId ?? '';

  return userProps;
};

const getExceptionTypeArray = state => {
  const exceptionTypeArray = [];
  const stepsArray = state?.verifications?.steps;

  for (const step in stepsArray) {
    if (
      stepsArray[step].model in VERIFICATION_MODEL_TO_EXCEPTION_TYPE_MAPPING
    ) {
      exceptionTypeArray.push(
        VERIFICATION_MODEL_TO_EXCEPTION_TYPE_MAPPING[stepsArray[step].model],
      );
    }
  }

  return exceptionTypeArray;
};

const getEventPropertiesFromStore = (title, state) => {
  const eventProps = {};
  const report = getCurrentReport(state);
  eventProps[ANALYTICS_PROPERTIES.ACCOUNT_RESOURCE_ID] = report?.account?.id;
  eventProps[ANALYTICS_PROPERTIES.ACCOUNT_URI_NAME] = report?.account?.uriName;
  eventProps[ANALYTICS_PROPERTIES.CANDIDATE_RESOURCE_ID] =
    report?.candidate?.id;
  eventProps[ANALYTICS_PROPERTIES.CUSTOM_CANDIDATE_ID] =
    report?.candidate?.customId;
  const workLocationCountry =
    report?.candidate?.work_locations?.[0]?.country ??
    state?.portal?.country ??
    '';
  eventProps[ANALYTICS_PROPERTIES.REPORT_RESOURCE_ID] = report?.id;
  eventProps[ANALYTICS_PROPERTIES.WORK_COUNTRY_LOCATION] = countries.getName(
    workLocationCountry,
    LOCALE,
  );
  eventProps[ANALYTICS_PROPERTIES.REPORT_GEO_COUNTRY] = countries.getName(
    report?.candidate?.geos?.[0]?.country ?? state?.portal?.country ?? '',
    LOCALE,
  );
  eventProps[ANALYTICS_PROPERTIES.CANDIDATE_DATABASE_SOURCE] =
    COUNTRIES_IN_US_DATA_CENTER.includes(workLocationCountry) ? 'US' : 'EU';
  eventProps[ANALYTICS_PROPERTIES.SCREENING_ARRAY] = Object.entries(report)
    .map(([key, value]) =>
      Array.isArray(value) && value.length > 0
        ? SCREENING_NAME_MAPPING[key]
        : null,
    )
    .filter(Boolean);

  if (VERIFICATION_EVENT_TITLE.includes(title)) {
    eventProps[ANALYTICS_PROPERTIES.EXCEPTION_FLOW_VERIFICATION_ID] =
      state?.verifications?.verification?.id;
    eventProps[ANALYTICS_PROPERTIES.EXCEPTION_TYPE] =
      getExceptionTypeArray(state);
    eventProps[ANALYTICS_PROPERTIES.REPORT_RESOURCE_ID] =
      state?.verifications?.verification?.reportId;
    eventProps[ANALYTICS_PROPERTIES.ACCOUNT_RESOURCE_ID] =
      state?.verifications?.verification?.accountId;
    eventProps[ANALYTICS_PROPERTIES.ACCOUNT_URI_NAME] =
      state?.verifications?.verification?.company;
    eventProps[ANALYTICS_PROPERTIES.CANDIDATE_RESOURCE_ID] =
      state?.verifications?.verification?.candidateId;
  }

  if (NO_EVENT_PROPS_EVENTS.includes(title)) {
    return {};
  }
  return eventProps;
};

export {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  AMPLITUDE_NAME_MAP,
  getUserPropertiesFromStore,
  getEventPropertiesFromStore,
  PERSONAL_CHECKS_ANALYTICS_EVENTS,
};

export default checkrAnalytics;
