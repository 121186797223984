import React from 'react';
import Canceled from '../Canceled';
import Expired from '../Expired';
import { SSN_CHECK_FAILURE } from '../../../constants';
import MissingInfoPanel from '../MissingInfoPanel';
import VerificationWelcomePage from '../VerificationWelcomePage/VerificationWelcomePage';
import Completed from '../Completed';
import { FieldConfiguration } from '../Types';

interface DisplayComponentsProps {
  showFirstPage: any;
  firstStep: any;
  completed: any;
  expired: any;
  verification: {
    fieldConfigurations: FieldConfiguration[];
    object: string;
    email: string;
    item_submission_errors?: Array<any>;
  };
  steps: any;
  canceled: any;
}

const hasMatrixSsnCheckError = ({ matrixFields, verification = {} }: any) => {
  if (
    matrixFields &&
    Array.isArray(matrixFields) &&
    matrixFields.includes('ssn') &&
    verification.itemSubmissionErrors
  ) {
    return true;
  }

  return false;
};

export const DisplayComponents: React.FC<DisplayComponentsProps> = ({
  showFirstPage,
  firstStep,
  completed,
  expired,
  verification,
  steps,
  canceled,
}) => {
  const { object } = verification;
  const matrixFields = verification?.fieldConfigurations?.map(
    (fc: FieldConfiguration) => fc.name,
  );

  if (expired && !completed && !canceled) {
    return <Expired />;
  }
  if (canceled) {
    return <Canceled />;
  }
  if (
    object === SSN_CHECK_FAILURE ||
    hasMatrixSsnCheckError({ matrixFields, verification })
  ) {
    return (
      <MissingInfoPanel object={SSN_CHECK_FAILURE} company='checkr support' />
    );
  }
  if (showFirstPage && firstStep && !completed) {
    return (
      <VerificationWelcomePage
        verification={verification}
        steps={steps}
        matrixFields={matrixFields}
      />
    );
  }
  if (completed) {
    return (
      <Completed
        matrixFields={matrixFields}
        candidateEmail={verification.email}
      />
    );
  }
  return null;
};
