import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';

const toKebabCase = str => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .replace(/_+/g, '-')
    .toLowerCase();
};

const Document = ({ document }) => (
  <M.Link href={document.downloadUri} target='_blank' rel='noopener noreferrer'>
    <M.Button kind='secondary' className={toKebabCase(document.contentType)}>
      {document.filename}
    </M.Button>
  </M.Link>
);

Document.propTypes = {
  document: PropTypes.object.isRequired,
};

export default Document;
