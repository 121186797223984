import { findEnvVar } from '@dashboard-experience/utils';
import { i18n, moment } from '@international/mastodon-i18n';
import Cookie from 'js-cookie';
import emojiRegex from 'emoji-regex';
import { SEARCHES } from '../components/Reports/Report/lib/constants';
import {
  CHECKR_REGION,
  INTERNATIONAL_TEST_ACCOUNT,
  US_TERRITORIES,
} from '../constants';
import { shortDatePattern } from './dateFormat';

const isSessionStorageSupported = () => {
  try {
    const testKey = 'test';
    window.sessionStorage.setItem(testKey, '1');
    window.sessionStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};

const saveOnceToSessionStorage = (key, data) => {
  if (isSessionStorageSupported() && !sessionStorage.getItem(key)) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
};

const readFromSessionStorage = (key, fallback) => {
  if (isSessionStorageSupported() && sessionStorage.getItem(key)) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  return fallback;
};

const clearFromSessionStorage = key => {
  if (isSessionStorageSupported()) {
    sessionStorage.removeItem(key);
  }
};

const toQuery = obj => {
  const str = [];
  for (const p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

const onlySSN = report => {
  const ssnTraceComplete =
    report.ssnTrace && report.ssnTrace.status === 'complete';

  const allOtherScreeningsCanceled = Object.values(SEARCHES).every(
    screeningKey => {
      const screening = report[screeningKey];
      if (!screening) {
        return true;
      }
      if (screeningKey === 'ssnTrace') {
        return screening.status === 'complete';
      }
      if (Array.isArray(screening)) {
        return screening.every(item => item.status === 'canceled');
      }
      return screening.status === 'canceled';
    },
  );

  return ssnTraceComplete && allOtherScreeningsCanceled;
};

const days = [
  {
    name: 'Monday',
    shortName: 'Mon',
  },
  {
    name: 'Tuesday',
    shortName: 'Tue',
  },
  {
    name: 'Wednesday',
    shortName: 'Weds',
  },
  {
    name: 'Thursday',
    shortName: 'Thurs',
  },
  {
    name: 'Friday',
    shortName: 'Fri',
  },
  {
    name: 'Saturday',
    shortName: 'Sat',
  },
  {
    name: 'Sunday',
    shortName: 'Sun',
  },
];

const isBooleanish = value => ['True', 'False'].includes(value);

const ensureBooleanizedValues = object => {
  Object.keys(object).forEach(key => {
    if (isBooleanish(object[key])) {
      object[key] = object[key] === 'True';
    }
  });

  return object;
};

const getScreenings = report => {
  return Object.keys(SEARCHES).filter(key => {
    const search = report[SEARCHES[key]];
    return search !== null && search !== undefined && search.length !== 0;
  });
};

const normalizeLocation = location => {
  location.name = location.name || location.siteName;
  location.street = location.street || location.address1;
  location.unit = location.unit || location.address2;
  delete location.siteId;
  delete location.siteName;
  delete location.address1;
  delete location.address2;

  location.hours && ensureBooleanizedValues(location.hours);

  return location;
};

/**
 * @name convertTime
 * @function
 * @memberOf lib/helpers
 * @description Helper to convert 24hr format to 12hr
 * @returns {string} - The converted time
 * @param {string} time - The 24hr time string
 */
const convertTime = time => {
  // Check correct time format and split into components
  time = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

/**
 * @name formatPhoneNumber
 * @function
 * @memberOf lib/helpers
 * @description Formats a US phone number
 * @returns {string}
 * @param {string} phoneNumber - The unformatted phone number
 */
const formatPhoneNumber = phoneNumber => {
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

/**
 * @name getAbbreviatedLocationHours
 * @function
 * @memberOf lib/helpers
 * @description Processes e-screen location hours data into a more presentable
 *              string of abbreviated hours
 * @returns {object} A list for displaying clean abbreviated location hours
 * @param {object} location - The location object
 */
const getAbbreviatedLocationHours = location => {
  let timePeriodStr = null;
  let timePeriodStrEnd = null;
  let currentTimePeriodStillOpen = false;
  const cleaned = [];

  days.forEach((day, idx) => {
    let timePeriodDone = false;
    const dayClosedKey = `closed${day.name}`;

    if (location.hours[dayClosedKey] === 'False') {
      if (!currentTimePeriodStillOpen) {
        timePeriodStr = `Open ${day.shortName}`;
        currentTimePeriodStillOpen = true;
      } else if (idx === days.length - 1) {
        timePeriodStrEnd = day.shortName;
        timePeriodDone = true;
      }
    } else if (currentTimePeriodStillOpen) {
      timePeriodStrEnd = days[idx - 1].shortName;
      timePeriodDone = true;
    }

    if (timePeriodDone) {
      if (timePeriodStr) {
        timePeriodStr += ' - ';
      } else {
        timePeriodStr = '';
      }
      timePeriodStr += timePeriodStrEnd;
      cleaned.push(timePeriodStr);
      currentTimePeriodStillOpen = false;
      timePeriodStr = null;
      timePeriodStrEnd = null;
    }
  });

  return cleaned;
};

/**
 * @name getFullLocationHours
 * @function
 * @memberOf lib/helpers
 * @description Processes e-screen location hours data into a more presentable
 *              list of full hours
 * @returns {object} A list for displaying clean full location hours
 * @param {object} location - The location object
 */
const getFullLocationHours = location => {
  if (!location) {
    return null;
  }
  let timePeriodDaysStr = null;
  let timePeriodDaysStrEnd = null;
  let timePeriodHoursStr = null;
  let currentTimePeriodStillOpen = false;
  let previousDayHoursStr = null;
  const cleaned = [];

  // define a helper function to push a row to the list
  // since we need this under multiple conditions
  const pushRowToDetailedHoursList = () => {
    if (timePeriodDaysStr !== timePeriodDaysStrEnd) {
      if (timePeriodDaysStr) {
        timePeriodDaysStr += ' - ';
      } else {
        timePeriodDaysStr = '';
      }
      timePeriodDaysStr += timePeriodDaysStrEnd;
    }

    cleaned.push({
      days: timePeriodDaysStr,
      hours: timePeriodHoursStr,
    });
  };

  const clearValues = () => {
    currentTimePeriodStillOpen = false;
    timePeriodDaysStr = null;
    timePeriodDaysStrEnd = null;
    timePeriodHoursStr = null;
  };

  days.forEach((day, idx) => {
    const dayClosedKey = `closed${day.name}`;
    const dayClosedForLunchKey = `closedForLunch${day.name}`;
    const dayOpenTimeKey = `${day.name.toLowerCase()}HoursOpen`;
    const dayClosedForLunchTimeKey = `${day.name.toLowerCase()}LunchFrom`;
    const dayOpenAfterLunchTimeKey = `${day.name.toLowerCase()}LunchTo`;
    const dayClosedTimeKey = `${day.name.toLowerCase()}HoursClose`;
    let dayHoursStr = null;

    // set this day's hours string
    if (location.hours[dayClosedKey] === 'True') {
      // this day is closed
      dayHoursStr = 'Closed';
    } else if (location.hours[dayClosedForLunchKey] === 'True') {
      // day has lunch hours
      dayHoursStr = [
        [
          convertTime(location.hours[dayOpenTimeKey]),
          convertTime(location.hours[dayClosedForLunchTimeKey]),
        ].join(' - '),
        [
          convertTime(location.hours[dayOpenAfterLunchTimeKey]),
          convertTime(location.hours[dayClosedTimeKey]),
        ].join(' - '),
      ].join(', ');
    } else {
      // day does not have lunch hours
      dayHoursStr = [
        convertTime(location.hours[dayOpenTimeKey]),
        convertTime(location.hours[dayClosedTimeKey]),
      ].join(' - ');
    }

    if (previousDayHoursStr && previousDayHoursStr !== dayHoursStr) {
      // day is mismatched with previous day
      // push time period to list and start a new one
      timePeriodDaysStrEnd = days[idx - 1].name;
      pushRowToDetailedHoursList();
      clearValues();
    }

    if (!currentTimePeriodStillOpen) {
      // new time period, set the day and hours strings
      timePeriodDaysStr = day.name;
      timePeriodHoursStr = dayHoursStr;
      currentTimePeriodStillOpen = true;
    }

    if (idx === days.length - 1) {
      // last day in the week
      timePeriodDaysStrEnd = day.name;
      pushRowToDetailedHoursList();
      clearValues();
    }

    previousDayHoursStr = timePeriodHoursStr;
  });

  return cleaned;
};

/**
 * @name includesAppointment
 * @function
 * @memberOf helpers
 * @description Determines if the report includes appointment information
 * @returns {boolean}
 * @param {object} report - The report object
 */
const includesAppointment = ({
  occupationalHealthScreening,
  drugScreening,
}) => {
  const haveAppointment = screening =>
    screening && !!screening.appointmentId && screening.status !== 'canceled';
  return [occupationalHealthScreening, drugScreening].some(haveAppointment);
};

/**
 * @name isV2DrugScreening
 * @function
 * @memberOf helpers
 * @description Identifies a V2 Drug Screening
 * @returns {boolean} - True if V2 screening
 * @param {object} screening - API screening object
 */
const isV2DrugScreening = screening => {
  return Object.prototype.hasOwnProperty.call(screening, 'mroNotes');
};

/**
 * @name hasEScreenDrugScreening
 * @function
 * @memberOf helpers
 * @description Determines if the report contains an e-screen drug screening
 * @returns {boolean}
 * @param {report} report - The report object
 */
const hasEScreenDrugScreening = report => {
  return report.drugScreening && isV2DrugScreening(report.drugScreening);
};

/**
 * @name hasI3ScreenDrugScreening
 * @function
 * @memberOf helpers
 * @description Determines if the report contains an i3-screen drug screening
 * @returns {boolean}
 * @param {report} report - The report object
 */
const hasI3ScreenDrugScreening = report => {
  return report.drugScreening && !isV2DrugScreening(report.drugScreening);
};

/**
 * @name normalizeHealthScreening
 * @function
 * @memberOf helpers
 * @description Helper for transitioning to API standard Health Screening object
 * @returns {object} - API screening object
 * @param {object} screening - New screening object
 */
const normalizeHealthScreening = inputScreening => {
  inputScreening.screeningPassExpiresAt =
    inputScreening.screeningPassExpiresAt || inputScreening.donorPassExpiresAt;
  delete inputScreening.donorPassExpiresAt;

  if (isV2DrugScreening(inputScreening)) {
    inputScreening.analytes = inputScreening.analytes || inputScreening.tests;
    delete inputScreening.tests;
  }

  if (inputScreening.location) {
    inputScreening.location = normalizeLocation(inputScreening.location);
  }

  return { ...inputScreening };
};

/**
 * @name normalizeHealthScreenings
 * @function
 * @memberOf helpers
 * @description Helper for transitioning to API standard Health Screening object
 * @returns {object} - API screening object
 * @param {object} screening - New screening object
 */
const normalizeHealthScreenings = report => {
  if (report.occupationalHealthScreening) {
    report.occupationalHealthScreening = normalizeHealthScreening(
      report.occupationalHealthScreening,
    );
  }
  if (report.drugScreening && isV2DrugScreening(report.drugScreening)) {
    report.drugScreening = normalizeHealthScreening(report.drugScreening);
  }
};

export const settingIsEnabled = (setting, options) => {
  const isPlainObject = valueToBeChecked => {
    if (typeof valueToBeChecked !== 'object' || valueToBeChecked === null) {
      return false;
    }
    const proto = Object.getPrototypeOf(valueToBeChecked);
    return proto === null || proto === Object.prototype;
  };
  if (setting === undefined) {
    return options.undefined ?? options.nullish;
  }
  if (setting === null) {
    return options.null ?? options.nullish;
  }
  if (isPlainObject(setting)) {
    const keys = options.keys || Object.keys(setting);
    return keys.some(key =>
      settingIsEnabled(setting[key], { ...options, keys: null }),
    );
  }

  return setting;
};

/**
 * @name normalizeIncidentCategories
 * @function
 * @memberOf helpers
 * @description Extracts translations for Clearinghouse incident item categories
 * @returns {String}
 */

const normalizeIncidentCategories = incidentItems => {
  return incidentItems
    .map(item =>
      i18n.getStr(
        `components.clearinghouseSearch.incidentCategories.${item.category}`,
      ),
    )
    .join(', ');
};

const isFound = (object, locale) => {
  return !!object[locale];
};

// return locale if in resources
const getResourceLocale = (resources, locale, country = null) => {
  if (!country) {
    country =
      i18n.getCookieLocale().length === 5
        ? i18n.getCookieLocale().split('_').pop()
        : null;
  }
  let resourceLocale =
    locale?.length === 2 && country ? `${locale}_${country}` : locale;
  if (!isFound(resources, resourceLocale)) {
    resourceLocale = resourceLocale?.slice(0, 2);
    if (!isFound(resources, resourceLocale)) {
      resourceLocale = i18n.defaultLocale;
    }
  }
  return resourceLocale;
};

/**
 * @name formatDateWithTimezone
 * @function
 * @memberOf helpers
 * @description formatss `2020-05-20T11:47:30.743-07:00` to `May 20, 2020 10 AM PDT` for readability
 * @returns {String}
 */
const formatDateWithTimezone = dateStr => {
  const rawDate = new Date(dateStr);

  return moment.tz(rawDate, moment.tz.guess()).format('lll z');
};

/**
 * @name isInternational
 * @function
 * @param {candidate} candidate
 * @returns {Boolean}
 */
const isInternational = (candidate = null) => {
  if (CHECKR_REGION === 'EU') return true;
  if (window?.location?.pathname?.startsWith('/international')) return true;
  if (Cookie.get(INTERNATIONAL_TEST_ACCOUNT)) return true;
  if (new URLSearchParams(window?.location?.search).has('lang')) return true;

  const country = candidate?.country || 'US';
  return !US_TERRITORIES.includes(country);
};

const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

const merge = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        merge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return merge(target, ...sources);
};
const getRandomKey = () =>
  new Date().getTime() + Math.random().toString(36).substring(2);

export {
  isSessionStorageSupported,
  saveOnceToSessionStorage,
  clearFromSessionStorage,
  readFromSessionStorage,
  toQuery,
  formatPhoneNumber,
  getAbbreviatedLocationHours,
  getFullLocationHours,
  includesAppointment,
  hasEScreenDrugScreening,
  hasI3ScreenDrugScreening,
  isV2DrugScreening,
  normalizeHealthScreenings,
  normalizeIncidentCategories,
  getScreenings,
  onlySSN,
  getResourceLocale,
  formatDateWithTimezone,
  isInternational,
  isEmpty,
  merge,
  getRandomKey,
};

/**
 * @name titleCase
 * @function
 * @memberOf helpers
 * @description formats 'licenseReport' to 'License Report' for readability. Does not format '2021-01-01'
 * @returns {String}
 */
export const titleCase = str => {
  const anyAlphabetLetterRegExp = /[a-zA-Z]/g;

  // Prevent dates from being formatted incorrectly - only format strings with alphabetical letters
  if (anyAlphabetLetterRegExp.test(str)) {
    const stringWithSpaces = str.replace(/([a-z])([A-Z])/g, '$1 $2');

    const convertedStr = stringWithSpaces
      .replace(/([^\s_-])([^\s_-]*)/g, (match, char1, char2) => {
        return char1.toUpperCase() + char2.toLowerCase();
      })
      .replace(/[_-]/g, ' ');

    return convertedStr;
  }

  return str;
};

/**
 * @name formatDateIfValid
 * @function
 * @memberOf helpers
 * @description formats '2022-01-01' to 'Jan 1, 2022' for readability. Returns '' for non-date strings.
 * @returns {String}
 */
export const formatDateIfValid = date => {
  if (moment(date).isValid()) {
    return moment(date).format(shortDatePattern());
  }
  return '';
};

/**
 * @function
 * @description returns the languages based on the conditions
 * @returns {Object.<string,string>}
 */
export const getLanguages = international => {
  let result = { en: 'English', es: 'Español' };
  const ENV = findEnvVar('ENV');
  if (international) {
    result = i18n.getSupportedLocales(ENV);
  }
  return result;
};

/**
 * @function
 * @param {String} locale
 * @description returns the language
 * @returns {String}
 */
export const getLanguageFromLocale = locale => {
  const QA_LOCALE = 'en_XL';
  let language = locale;
  if (locale) {
    language = locale === QA_LOCALE ? locale : locale.substring(0, 2);
  }
  return language;
};

/**
 * @function
 * @param {ReportSummary} report
 * @description Maps through the exceptions in the report and returns the soonest 'actionRequiredBy' date.
 * @returns {String}
 */
export const getSoonestActionRequiredByDate = report => {
  const dates = report.exceptions.map(
    exception => new Date(exception.actionRequiredBy),
  );
  const soonestDate = dates.reduce((soonerDate, secondDate) =>
    soonerDate < secondDate ? soonerDate : secondDate,
  );

  return soonestDate.toISOString();
};

/**
 * @function
 * @param {String} str
 * @description Removes emojis from string
 * @returns {String}
 */
export const stripEmojis = str => {
  const regex = emojiRegex();
  return str.replace(regex, '');
};
