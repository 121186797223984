import React from 'react';

const MainContainer: React.FC<{
  children?: any;
  className?: string;
  header?: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, header = null, style, className = '', ...restProps }) => {
  return (
    <main style={style} className={className} {...restProps}>
      <div className='container-fluid main-container'>
        {header && <header>{header}</header>}
        {children}
      </div>
    </main>
  );
};

export default MainContainer;
