const getValidDate = (dateString: string | null): Date | null => {
  if (dateString === null) return null;
  const result = new Date(dateString.replace(/z$/i, ''));
  return Number.isNaN(+result) ? null : result;
};

const compareDates = (
  a: string | null,
  b: string | null,
  config: { ascending: boolean } = { ascending: false },
): 1 | -1 | 0 => {
  const dateA = getValidDate(a);
  const dateB = getValidDate(b);
  if (dateA !== null && dateB === null) return -1;
  if (dateA === null && dateB !== null) return 1;
  if (dateA !== null && dateB !== null) {
    if (config.ascending) {
      if (+dateA > +dateB) return 1;
      if (+dateA < +dateB) return -1;
    }
    if (+dateA > +dateB) return -1;
    if (+dateA < +dateB) return 1;
    return 0;
  }
  return 0;
};

export default compareDates;
