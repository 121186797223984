import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import NavigationFooter from '../../../../Workflow/NavigationFooter';
import Breadcrumb from '../../../../Workflow/Breadcrumb';
import ContentContainer from '../../../../Workflow/ContentContainer';

const SameDriverLicense = ({
  back,
  closed,
}: {
  back: () => void;
  closed: () => void;
}) => {
  const closeButton = () => {
    return (
      <M.Button size='sm' kind='primary' onClick={closed}>
        {i18n.getStr('buttons.close')}
      </M.Button>
    );
  };

  return (
    <div>
      <Breadcrumb
        crumbKeys={[
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.title',
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.sameLicenseScreen.title',
        ]}
      />

      <ContentContainer>
        <M.Icon icon='Forum' className='checkr-blue' />
        <p>
          {i18n.getStr(
            'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.sameLicenseScreen.message',
          )}
        </p>
      </ContentContainer>

      <NavigationFooter back={back} rightButtonContent={closeButton()} />
    </div>
  );
};

SameDriverLicense.propTypes = {
  back: PropTypes.func.isRequired,
  closed: PropTypes.func.isRequired,
};

SameDriverLicense.defaultProps = {};

export default SameDriverLicense;
