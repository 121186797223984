import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { useDispatch } from 'react-redux';
import styled from './DisputeSuccess.module.css';
import ContentContainer from '../../../Workflow/ContentContainer';
import NavigationFooter from '../../../Workflow/NavigationFooter';
import completeIcon from '../../../../images/icons/Complete.svg';
import { reloadReport } from '../../../../actions';

const DoneButton = () => {
  const dispatch = useDispatch();

  const reloadPage = () => {
    dispatch(reloadReport());
  };

  return (
    <M.Button size='sm' kind='primary' onClick={reloadPage}>
      {i18n.getStr('buttons.done')}
    </M.Button>
  );
};

const DisputeSuccess = () => {
  return (
    <>
      <M.Grid>
        <M.Container.Row>
          <ContentContainer>
            <M.Container.Col className={styled.mbtc}>
              {i18n.getStr('workflows.dispute.submissionConfirmation.heading')}
            </M.Container.Col>
            <M.Container.Col className={styled.mbtc}>
              <img src={completeIcon} alt='checkmark' />
            </M.Container.Col>
            <M.Container.Col>
              <div
                role='note'
                aria-label={i18n.getStr(
                  'workflows.dispute.submissionConfirmation.message',
                )}
              >
                {i18n.renderHTML(
                  'workflows.dispute.submissionConfirmation.message',
                )}
              </div>
            </M.Container.Col>
          </ContentContainer>
        </M.Container.Row>
      </M.Grid>
      <NavigationFooter rightButtonContent={DoneButton()} />
    </>
  );
};

export default DisputeSuccess;
