import { normalizeDriverLicenseNumber } from '../normalizations';
import { States } from '../../types/states';

type StateRegexPatterns = {
  [key in States]: RegExp[];
};

const stateRegexPatterns: StateRegexPatterns = {
  AK: [/^\d{7}$/],
  AL: [/^\d{7,8}$/],
  AR: [/^\d{9}$/],
  AZ: [/^[A-Z]\d{8}$/, /^\d{9}$/],
  CA: [/^[A-Z]\d{7}$/],
  CO: [/^\d{9}$/],
  CT: [/^\d{9}$/],
  DC: [/^\d{7,9}$/],
  DE: [/^\d{2,7}$/],
  FL: [/^[A-Z]\d{12}$/],
  GA: [/^\d{9}$/],
  GU: [/^[A-Z]\d{14}$/],
  HI: [/^H\d{8}$/, /^\d{9}$/],
  IA: [/(^\d{9}$)/, /(^\d{3}[A-Z]{2}\d{4}$)/],
  ID: [/^[A-Z]{2}\d{6}[A-Z]$/, /^\d{9}$/],
  IL: [/^[A-Z]\d{11,12}$/],
  IN: [/^\d{10}$/],
  KS: [/^[K]\d{8}$/],
  KY: [/^[A-Z]\d{8}$/],
  LA: [/^[0]?\d{7,8}$/],
  MA: [/(^SA\d{7}$)/, /(^S\d{8}$)/, /(^\d{9}$)/],
  MD: [/^[A-Z]\d{12}$/, /^MD\d{11}$/],
  ME: [/^\d{7}$/],
  MI: [/^[A-Z]\d{12}$/],
  MN: [/^[A-Z]\d{12}$/],
  MO: [/(^\d{9}$)/, /(^[A-Z]\d{5,9}$)/, /(^\d{3}[A-Z]\d{6}$)/],
  MS: [/^\d{9}$/],
  MT: [/(^\d{13}$)/, /(^(\d|[A-Z]){9}$)/, /(^[A-Z]{3}\d{10}$)/],
  NC: [/^[0]{0,5}\d{4,12}$/],
  ND: [/^(\d|[A-Z]){9}$/],
  NE: [/^[A-Z]\d{3,8}$/],
  NH: [/(^\d{2}[A-Z]{3}\d{5}$)/, /(^(NHL|NHN)\d{8}$)/],
  NJ: [/^[A-Z]\d{14}$/],
  NM: [/^\d{9}$/],
  NV: [/^\d{10}$/],
  NY: [/(^\d{9}$)/, /(^[A-Z]\d{18}$)/],
  OH: [/(^[A-Z]{1,2}\d{4,7}$)/, /(^\d{8}$)/],
  OK: [/(^\d{9}$)/, /(^[A-Z]\d{9}$)/],
  OR: [/(^\d{5,7}$)/, /(^[A-Z]\d{6}$)/],
  PA: [/^\d{8}$/],
  PR: [/^\d{5,7}$/, /^\d{9}$/],
  RI: [/(^\d{7,8}$)/, /(^V\d{6}$)/],
  SC: [/^\d{3,10}$/],
  SD: [/^\d{6,9}$/],
  TN: [/^\d{7,9}$/],
  TX: [/^\d{7,8}$/],
  UT: [/^\d{4,10}$/],
  VA: [/^[A-Z]\d{8}$/, /^\d{9}$/],
  VI: [/^[A-Z]\d{9}$/],
  VT: [/(^\d{8}$)/, /(^\d{7}A$)/],
  WA: [/^[A-Z*]{4,8}\d{2,3}(\d|[A-Z]){2}$/, /^WDL([A-Z]|\d){9}$/],
  WI: [/^[A-Z]\d{13}$/],
  WV: [/^([A-Z]|\d){7}$/],
  WY: [/^\d{9}$/],
};

export const isValidDriverLicenseFormat = (state: States, number: string) => {
  const regexs = stateRegexPatterns[state] || [];
  const normalizedNumber = normalizeDriverLicenseNumber(number);

  return regexs.some(regex => !!normalizedNumber.match(regex));
};

export const validateUpdateToDriverLicense = ({
  candidateId,
  dlState,
  dlNumber,
  reportId,
  previousDlState,
  previousDlNumber,
}: {
  candidateId: string;
  dlState: States | '';
  dlNumber: string;
  reportId: string;
  previousDlState: States;
  previousDlNumber: string;
}): { dlState?: string; dlNumber?: string } => {
  const result = {} as { dlState?: string; dlNumber?: string };

  if (dlNumber === '') {
    result.dlNumber = 'missingField';
  }

  if (dlState === '') {
    result.dlState = 'missingField';
  }

  if (result.dlState || result.dlNumber) {
    return result;
  }

  if (dlState === previousDlState && dlNumber === previousDlNumber) {
    result.dlNumber = 'sameAsPrevious';
  }

  if (!isValidDriverLicenseFormat(dlState as States, dlNumber)) {
    result.dlNumber = 'invalidFormat';
  }

  return result;
};
