import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { M } from '@dashboard-experience/mastodon';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const CauseConsiderStatusInformation = ({
  back,
  closed,
}: {
  back: () => void;
  closed: () => void;
}) => {
  const closeButton = () => {
    return (
      <M.Button
        data-testid={DISPUTES_FLOW.disputeCauseConsiderStatusCloseButton}
        className='analytics_consider_status_close_button'
        size='sm'
        kind='secondary'
        onClick={closed}
      >
        {i18n.getStr('buttons.close')}
      </M.Button>
    );
  };
  return (
    <div>
      <ContentContainer>
        <div data-testid={DISPUTES_FLOW.disputeConsiderExplanation}>
          {i18n.renderHTML(
            'workflows.dispute.causeConsiderStatusInformation.explanation',
          )}
        </div>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_consider_status_back_button'
        rightButtonContent={closeButton()}
      />
    </div>
  );
};

export default CauseConsiderStatusInformation;
