/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// REFACTOR FOR LINT LATER
import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import DOMPurify from 'isomorphic-dompurify';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const ArticleText = ({ section, article }) => (
  <div data-testid={DISPUTES_FLOW.disputeArticleText}>
    <div className='form-group'>
      <label>
        <span className='text-muted'>{section} &#62; </span>
        <span className='form-question'>{article.title}</span>
      </label>
      <hr />
    </div>
    <div className='d-flex flex-column text-center'>
      <M.Icon
        data-testid={DISPUTES_FLOW.disputeForumIcon}
        icon='Forum'
        className='checkr-blue'
      />
      <div
        data-testid={DISPUTES_FLOW.disputeArticleBody}
        className='m-4'
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.body) }}
      />
    </div>
  </div>
);

ArticleText.propTypes = {
  section: PropTypes.string,
  article: PropTypes.object,
};

ArticleText.defaultProps = {
  section: '',
  article: {},
};

export default ArticleText;
