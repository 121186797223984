import React from 'react';
import { connect } from 'react-redux';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import useSupportedLanguages from './LanguageToggle/useSupportedLanguages';

import './LanguageToggle/LanguageToggle.sass';

interface LanguageToggleProps {
  className?: string;
  buttonClassName?: string;
  international: boolean;
  locale?: string;
  onItemClick?: React.MouseEventHandler<HTMLElement>;
  tabIndex: 0 | -1 | '0' | '-1';
}

const LanguageToggle: React.FC<LanguageToggleProps> = ({
  className = '',
  buttonClassName = '',
  international,
  locale = '',
  onItemClick = () => {},
  tabIndex = 0,
}: LanguageToggleProps) => {
  const { items, currentLanguage, onLanguageChangeHandler } =
    useSupportedLanguages(locale, international);
  const titleRenderer = React.useCallback(
    (selected: {
      label:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | null
        | undefined;
    }) => (
      <>
        <M.Icon icon='Translate' size='16' />
        <span>{selected.label}</span>
      </>
    ),
    [],
  );

  if (!items.length || !currentLanguage) {
    return null;
  }

  return (
    <DropdownMenu
      id='language-toggle'
      className={`language-toggle ${className}`}
      buttonClassName={buttonClassName}
      titleRender={titleRenderer}
      items={items}
      tabIndex={tabIndex}
      aria-label={i18n.getStr(
        'components.LanguageToggle.label',
        currentLanguage.label,
      )}
      onSelectItem={(e: React.MouseEvent<HTMLButtonElement>, selectedItem) => {
        onLanguageChangeHandler(selectedItem);
        onItemClick(e);
      }}
      selected={currentLanguage}
    />
  );
};

const mapStateToProps = ({
  i18n: { locale },
}: {
  i18n: { locale: string };
}) => ({
  locale,
});

export default connect(mapStateToProps)(LanguageToggle);
