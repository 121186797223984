/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import styled from 'styled-components';

import CandidateStoryHeader from './CandidateStoryHeader';
import CandidateStorySummaryItem from './CandidateStorySummaryItem';

import { CANS_STEP_REVIEW } from '../../../../constants';

const AddRecordButtonWrapperStyled = styled.div`
  text-align: center;
`;

const AcknowledgeNotDisputeStyled = styled.div`
  margin-top: 30px;

  #acknowledge-non-dispute-checkbox {
    color: #ff0000;
  }
`;

const CandidateStorySummary = ({
  passAcknowledged,
  candidateStories,
  companyName,
  generalInformationOnly,
  selectedRecords,
  switchToChargeSelection,
}) => {
  const summaryItems = Object.keys(candidateStories).filter(
    key => key !== 'content',
  );

  const [acknowledged, setAcknowledged] = useState(false);

  const handleChange = () => {
    setAcknowledged(true);
    passAcknowledged();
  };

  return (
    <div>
      <CandidateStoryHeader step={CANS_STEP_REVIEW} />
      <div>
        <div>
          <p data-testid='before-you-provide'>
            <T
              value='components.Report.CandidateStories.Modal.Confirm.beforeYouProvide'
              company={companyName}
              dangerousHTML
            />
          </p>

          {summaryItems.map(item => {
            const report = selectedRecords.find(record => {
              return record.id === item;
            });

            return (
              <CandidateStorySummaryItem
                candidateStories={candidateStories}
                type={item}
                key={item}
                report={report}
              />
            );
          })}

          {!generalInformationOnly && (
            <AddRecordButtonWrapperStyled>
              <M.Button
                data-testid='add-another-document-button'
                type='button'
                kind='secondary'
                onClick={switchToChargeSelection}
              >
                <T value='components.Report.CandidateStories.Modal.addAnotherRecord' />
              </M.Button>
            </AddRecordButtonWrapperStyled>
          )}
        </div>
        <AcknowledgeNotDisputeStyled>
          <M.Checkbox
            data-testid='acknowledge-non-dispute-checkbox'
            id='acknowledge-non-dispute-checkbox'
            role='checkbox'
            checked={acknowledged}
            labelText={
              <T
                value='components.Report.CandidateStories.Modal.Confirm.helpCenterLink'
                dangerousHTML
              />
            }
            onChange={() => handleChange()}
            passAcknowledged={passAcknowledged}
          />
        </AcknowledgeNotDisputeStyled>
      </div>
    </div>
  );
};

CandidateStorySummary.propTypes = {
  candidateStories: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
  generalInformationOnly: PropTypes.bool.isRequired,
  selectedRecords: PropTypes.array,
  switchToChargeSelection: PropTypes.func.isRequired,
  passAcknowledged: PropTypes.func.isRequired,
};

CandidateStorySummary.defaultProps = {
  selectedRecords: [],
};

export default CandidateStorySummary;
