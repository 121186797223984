import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Attribute from '../Attribute';
import { dateIfDate, numberIfNumber } from '../lib/formatting';

class CreditReportRecord extends React.Component {
  renderSummaryAmmount(amount, count, title) {
    if (!amount) {
      return null;
    }

    return (
      <tr>
        <th scope='row' className='text-very-muted'>
          {title}
        </th>
        <td>{numberIfNumber(count)}</td>
        <td>${numberIfNumber(amount.highCredit)}</td>
        <td>${numberIfNumber(amount.creditLimit)}</td>
        <td>${numberIfNumber(amount.currentBalance)}</td>
        <td>${numberIfNumber(amount.monthlyPayment)}</td>
        <td>${numberIfNumber(amount.pastDue)}</td>
        <td>{numberIfNumber(amount.percentAvailableCredit)}%</td>
      </tr>
    );
  }

  renderCreditSummary(record) {
    const { creditSummary } = record;

    if (!creditSummary) {
      return null;
    }

    const counts = creditSummary.recordCounts;

    return (
      <div className='mb-4'>
        <div className='mb-4'>
          <Attribute
            attr={i18n.getStr('components.credit.fullName')}
            value={record.fullName}
          />
          <Attribute
            attr={i18n.getStr('components.credit.publicRecords')}
            value={counts.publicRecordCount}
          />
          <Attribute
            attr={i18n.getStr('components.credit.collections')}
            value={counts.collectionCount}
          />
          <Attribute
            attr={i18n.getStr('components.credit.accounts')}
            value={counts.totalTradeCount}
          />
          <Attribute
            attr={i18n.getStr('components.credit.inquiries')}
            value={counts.totalInquiryCount}
          />
          <Attribute
            attr={i18n.getStr('components.credit.negativeAccounts')}
            value={numberIfNumber(counts.negativeTradeCount)}
          />
          <Attribute
            attr={i18n.getStr(
              'components.credit.accountsWithHistoricalNegatives',
            )}
            value={numberIfNumber(counts.historicalNegativeTradeCount)}
          />
          <Attribute
            attr={i18n.getStr('components.credit.occurenceHistoricalNegatives')}
            value={numberIfNumber(counts.historicalNegativeOccurrencesCount)}
          />
        </div>
        <div className='table-responsive'>
          <table className='table table-sm table-bottom-bordered'>
            <thead>
              <tr>
                <td className='text-muted' />
                <td className='text-muted'>
                  <T value='components.credit.count' />
                </td>
                <td className='text-muted'>
                  <T value='components.credit.highCredit' />
                </td>
                <td className='text-muted'>
                  <T value='components.credit.creditLimit' />
                </td>
                <td className='text-muted'>
                  <T value='components.credit.balance' />
                </td>
                <td className='text-muted'>
                  <T value='components.credit.payment' />
                </td>
                <td className='text-muted'>
                  <T value='components.credit.pastDue' />
                </td>
                <td className='text-muted'>
                  <T value='components.credit.availableCredit' />
                </td>
              </tr>
            </thead>
            <tbody>
              {this.renderSummaryAmmount(
                creditSummary.revolvingAmount,
                counts.revolvingTradeCount,
                i18n.getStr('components.credit.resolving'),
              )}
              {this.renderSummaryAmmount(
                creditSummary.installmentAmount,
                counts.installmentTradeCount,
                i18n.getStr('components.credit.installment'),
              )}
              {this.renderSummaryAmmount(
                creditSummary.mortgageAmount,
                counts.mortgageTradeCount,
                i18n.getStr('components.credit.mortgage'),
              )}
              {this.renderSummaryAmmount(
                creditSummary.openAmount,
                counts.openTradeCount,
                i18n.getStr('components.credit.open'),
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderCreditItemHeader(itemName, itemDate) {
    return (
      <div className='mb-3'>
        <hr className='mb-2 mt-2' />
        <div className='d-flex'>
          <span className='text-uppercase'>{itemName}</span>
          <span className='ml-auto'>{dateIfDate(itemDate)}</span>
        </div>
        <hr className='mb-2 mt-2' />
      </div>
    );
  }

  renderTrade(trade, i) {
    return (
      <div key={i} className='mb-4'>
        {this.renderCreditItemHeader(trade.subscriberName, trade.dateOpened)}
        <div className='mb-4 mt-4'>
          <Attribute
            attr={i18n.getStr('components.credit.industry')}
            value={trade.subscriberIndustry}
          />
          <Attribute
            attr={i18n.getStr('components.credit.creditor')}
            value={trade.subscriberName}
          />
          <Attribute
            attr={i18n.getStr('components.credit.portfolioType')}
            value={trade.portfolioType}
          />
          <Attribute
            attr={i18n.getStr('components.credit.accountNumber')}
            value={trade.accountNumber}
          />
          <Attribute
            attr={i18n.getStr('components.credit.designator')}
            value={trade.ecoaDesignator}
          />
          <Attribute
            attr={i18n.getStr('components.credit.dateOpened')}
            value={trade.dateOpened}
          />
          <Attribute
            attr={i18n.getStr('components.credit.dateEffective')}
            value={trade.dateEffective}
          />
          <Attribute
            attr={i18n.getStr('components.credit.datePaidOut')}
            value={trade.datePaidOut}
          />
          <Attribute
            attr={i18n.getStr('components.credit.currentBalance')}
            value={`$${numberIfNumber(trade.currentBalance)}`}
          />
          <Attribute
            attr={i18n.getStr('components.credit.highCredit')}
            value={`$${numberIfNumber(trade.highCredit)}`}
          />
          <Attribute
            attr={i18n.getStr('components.credit.creditLimit')}
            value={`$${numberIfNumber(trade.creditLimit)}`}
          />
          <Attribute
            attr={i18n.getStr('components.credit.accountRating')}
            value={trade.accountRating}
          />
          <Attribute
            attr={i18n.getStr('components.credit.pastDue')}
            value={`$${numberIfNumber(trade.pastDue)}`}
          />
        </div>
      </div>
    );
  }

  renderTrades(trades) {
    if (!trades || trades.length === 0) {
      return null;
    }

    return trades.map(this.renderTrade, this);
  }

  renderCollection(collection, i) {
    return (
      <div key={i} className='mb-4'>
        {this.renderCreditItemHeader(
          collection.subscriberName,
          collection.dateOpened,
        )}
        <div className='mb-4 mt-4'>
          <Attribute
            attr={i18n.getStr('components.credit.dateOpened')}
            value={collection.dateOpened}
          />
          <Attribute
            attr={i18n.getStr('components.credit.dateEffective')}
            value={collection.dateEffective}
          />
          <Attribute
            attr={i18n.getStr('components.credit.currentBalance')}
            value={`$${numberIfNumber(collection.currentBalance)}`}
          />
          <Attribute
            attr={i18n.getStr('components.credit.originalCreditGrantor')}
            value={collection.originalCreditGrantor}
          />
          <Attribute
            attr={i18n.getStr('components.credit.originalCreditClassification')}
            value={collection.originalCreditClassification}
          />
          <Attribute
            attr={i18n.getStr('components.credit.originalBalance')}
            value={`$${numberIfNumber(collection.originalBalance)}`}
          />
          <Attribute
            attr={i18n.getStr('components.credit.pastDue')}
            value={`$${numberIfNumber(collection.pastDue)}`}
          />
          <Attribute
            attr={i18n.getStr('components.credit.accountRating')}
            value={collection.accountRating}
          />
        </div>
      </div>
    );
  }

  renderCollections(collections) {
    const { completedDate } = this.props;
    if (!collections || collections.length === 0) {
      return (
        <div>
          <T
            value='components.credit.noAccountsInCreditReport'
            date={dateIfDate(completedDate)}
          />
        </div>
      );
    }

    return collections.map(this.renderCollection, this);
  }

  renderPublicRecord(publicRecord, i) {
    return (
      <div key={i} className='mb-4'>
        {this.renderCreditItemHeader(
          publicRecord.plaintiff,
          publicRecord.dateFiled,
        )}
        <div className='mb-4 mt-4'>
          <Attribute
            attr={i18n.getStr('components.credit.docketNumber')}
            value={publicRecord.docketNumber}
          />
          <Attribute
            attr={i18n.getStr('components.credit.dateReported')}
            value={publicRecord.dateReported}
          />
          <Attribute
            attr={i18n.getStr('components.credit.dateFiled')}
            value={publicRecord.dateFiled}
          />
          <Attribute
            attr={i18n.getStr('components.credit.datePaid')}
            value={publicRecord.datePaid}
          />
          <Attribute
            attr={i18n.getStr('components.credit.liabilities')}
            value={`$${numberIfNumber(publicRecord.liabilities)}`}
          />
        </div>
      </div>
    );
  }

  renderPublicRecords(publicRecords) {
    const { completedDate } = this.props;
    if (!publicRecords || publicRecords.length === 0) {
      return (
        <div>
          <hr className='mb-2 mt-2' />
          <div className='text-very-muted'>
            <T
              value='components.credit.noRecordsInCreditReport'
              date={dateIfDate(completedDate)}
            />
          </div>
          <hr className='mb-2 mt-2' />
        </div>
      );
    }

    return publicRecords.map(this.renderPublicRecord, this);
  }

  renderInquiry(inquiry, i) {
    return (
      <div key={i}>
        <div className='d-flex'>
          <span>{inquiry.subscriberName}</span>
          <span className='ml-auto'>{dateIfDate(inquiry.date)}</span>
        </div>
        <hr className='mb-2 mt-2' />
      </div>
    );
  }

  renderInquiries(inquiries) {
    const { completedDate } = this.props;
    if (!inquiries || inquiries.length === 0) {
      return (
        <div>
          <hr className='mb-2 mt-2' />
          <div className='text-very-muted'>
            <T
              value='components.credit.noInquiriesOnCreditReport'
              date={dateIfDate(completedDate)}
            />
          </div>
          <hr className='mb-2 mt-2' />
        </div>
      );
    }

    return inquiries.map(this.renderInquiry);
  }

  render() {
    const { record } = this.props;

    if (!record) {
      return null;
    }

    return (
      <div>
        {this.renderCreditSummary(record)}
        <div className='mb-5'>
          <h6 className='mb-3'>
            <T value='components.credit.accounts' />
          </h6>
          {this.renderTrades(record.trades)}
        </div>
        <div className='mb-5'>
          <h6 className='mb-3'>
            <T value='components.credit.collections' />
          </h6>
          {this.renderCollections(record.collections)}
        </div>
        <div className='mb-5'>
          <h6 className='mb-3'>
            <T value='components.credit.publicRecords' />
          </h6>
          {this.renderPublicRecords(record.publicRecords)}
        </div>
        <div className='mb-5'>
          <h6 className='mb-3'>
            <T value='components.credit.inquiries' />
          </h6>
          <hr className='mb-2 mt-2' />
          {this.renderInquiries(record.inquiries)}
        </div>
      </div>
    );
  }
}

CreditReportRecord.propTypes = {
  record: PropTypes.object,
  completedDate: PropTypes.string,
};

CreditReportRecord.defaultProps = {
  record: {},
  completedDate: '',
};

export default i18n.renderTranslation()(CreditReportRecord);
