import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import Typography2024 from './Typography2024Hack';

interface GreetingProps {
  company?: string;
  firstName?: string;
}

export const Greeting: React.FC<GreetingProps> = ({
  company = null,
  firstName = null,
}) => {
  return (
    <M.Container
      style={{
        border: 'none',
        background: colors.uiGrey50,
        flexDirection: 'column',
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        padding: '0px 0px 24px 0px',
        margin: 0,
      }}
    >
      <h4
        style={{
          color: colors.uiTextPrimaryLight,
          ...Typography2024.h4,
          padding: '0px 0px 8px 0px',
        }}
      >
        {i18n.getStr('components.InstantExceptions.greeting.name', firstName)}
      </h4>
      <h5
        style={{
          color: colors.uiTextTertiaryLight,
          ...Typography2024.h5,
          margin: 0,
        }}
      >
        {i18n.getStr('components.InstantExceptions.greeting.company')}
        <strong style={{ color: colors.uiTextPrimaryLight }}>{company}</strong>
      </h5>
    </M.Container>
  );
};
