import React, { ReactNode } from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';
import Typography2024Hack from './Typography2024Hack';

const ListItemStyled = styled(M.ListItem)`
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
`;

interface BulletedItemProps {
  text: string;
  children?: ReactNode;
  arrowIcon?: boolean;
}

export const BulletedItem: React.FC<BulletedItemProps> = ({
  text,
  children = null,
  arrowIcon = false,
}) => {
  return (
    <ListItemStyled>
      <div style={{ width: '20px', marginRight: '14px' }}>
        <M.Icon
          icon='NextOutline'
          style={{
            fill: arrowIcon ? colors.uiAqua600 : 'none',
            height: '20px',
            width: '20px',
          }}
        />
      </div>
      <p
        style={{
          ...Typography2024Hack.textIconBlackSecondary78,
          ...Typography2024Hack.p3,
        }}
        className='p3'
      >
        {i18n.getStr(text)}
        {children}
      </p>
    </ListItemStyled>
  );
};
