import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import Attribute from '../Attribute';
import {
  normalizeIncidentCategories,
  formatDateWithTimezone,
} from '../../../../lib/helpers';

const ClearinghouseSearch = ({ search }) =>
  !!search && (
    <Panel
      title={i18n.getStr('components.clearinghouseSearch.title')}
      status={search.status}
    >
      {search.reportingOrganizationName && (
        <Attribute
          attr={i18n.getStr(
            'components.clearinghouseSearch.reportingOrganizationName',
          )}
          value={search.reportingOrganizationName}
        />
      )}
      {search.incidentDate && (
        <Attribute
          attr={i18n.getStr('components.clearinghouseSearch.incidentDate')}
          value={search.incidentDate}
        />
      )}
      {search.reportedAt && (
        <Attribute
          attr={i18n.getStr('components.clearinghouseSearch.reportedAt')}
          value={formatDateWithTimezone(search.reportedAt)}
        />
      )}
      {search.incidentItems && (
        <Attribute
          attr={i18n.getStr('components.clearinghouseSearch.incidentItems')}
          value={normalizeIncidentCategories(search.incidentItems)}
        />
      )}
    </Panel>
  );

ClearinghouseSearch.propTypes = {
  search: PropTypes.object,
};

ClearinghouseSearch.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(ClearinghouseSearch);
