import React from 'react';

interface CandidateStoriesMultiSelectProps {
  selected: boolean;
  selectValue: any;
  toggleValue: (value: string) => void;
  displayValue: string;
  date?: string;
}

const CandidateStoriesMultiSelect = ({
  displayValue,
  selectValue,
  selected,
  toggleValue,
  date,
}: CandidateStoriesMultiSelectProps) => {
  const handleClick = () => {
    toggleValue(selectValue);
  };

  let classes =
    'd-flex justify-content-between tile-option-select tile-option-multi-select p-4 mb-2 rounded';
  if (selected) {
    classes += ' tile-option-select-selected';
  }

  return (
    <div
      className={`${classes}`}
      onClick={handleClick}
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      role='option'
      tabIndex={0}
      data-testid='candidate-stories-multi-select-checkbox'
    >
      <div>{displayValue}</div>
      <div className='text-very-muted'>{date}</div>
    </div>
  );
};

export default CandidateStoriesMultiSelect;
