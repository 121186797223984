import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import Search from '../Search/Search';

class NationalCriminalSearch extends React.Component {
  render() {
    const { search } = this.props;

    if (!search) {
      return null;
    }

    if (search.status === 'canceled') {
      return null;
    }

    if (search.records.length) {
      return (
        <Search
          type='criminal'
          title={i18n.getStr('components.nationalCriminalSearch.title')}
          search={search}
        />
      );
    }

    return (
      <Panel
        title={i18n.getStr('components.nationalCriminalSearch.title')}
        status={search.status}
      >
        {search.status === 'consider' && (
          <span>
            <T value='components.nationalCriminalSearch.seeCountySearch' />
          </span>
        )}
      </Panel>
    );
  }
}

NationalCriminalSearch.propTypes = {
  search: PropTypes.object,
};

NationalCriminalSearch.defaultProps = {
  search: {},
};

export default i18n.renderTranslation()(NationalCriminalSearch);
