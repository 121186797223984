import React from 'react';
import ConfirmDriverLicenseUpdate from './MotorVehicleReport/ConfirmDriverLicenseUpdate';
import SameDriverLicense from './MotorVehicleReport/SameDriverLicense';
import UpdateDriverLicenseForm from './MotorVehicleReport/UpdateDriverLicenseForm';
import UpdateDriverLicenseSuccess from './MotorVehicleReport/UpdateDriverLicenseSuccess';
import { Report } from '../../../../types/report';
import {
  useWorkflowScreenStates,
  WorkflowScreenStates,
} from '../../../Workflow/hooks';

const workflowScreenStates: WorkflowScreenStates = {
  confirmDriverLicenseUpdate: {
    transitions: {
      isSameLicenseNumber: 'sameDriverLicense',
      isDifferentLicenseNumber: 'updateDriverLicenseForm',
    },
  },
  sameDriverLicense: {
    transitions: {
      previous: 'confirmDriverLicenseUpdate',
    },
  },
  updateDriverLicenseForm: {
    transitions: {
      next: 'updateDriverLicenseSuccess',
      previous: 'confirmDriverLicenseUpdate',
    },
  },
  updateDriverLicenseSuccess: {
    transitions: {},
  },
};

const UpdatePersonalInfoWorkflow = ({
  report,
  toggle,
}: {
  report: Report;
  toggle: () => void;
}) => {
  const { currentScreen, nextScreen, previousScreen, transitionToScreen } =
    useWorkflowScreenStates('confirmDriverLicenseUpdate', workflowScreenStates);

  const updateDriverLicenseForm = () => {
    return (
      <UpdateDriverLicenseForm
        back={previousScreen}
        onUpdateSuccess={nextScreen}
        report={report}
      />
    );
  };

  const updateDriverLicenseSuccess = () => {
    return <UpdateDriverLicenseSuccess closed={toggle} />;
  };

  const sameDriverLicense = () => {
    return <SameDriverLicense back={previousScreen} closed={toggle} />;
  };

  const confirmDriverLicenseUpdate = () => {
    return (
      <ConfirmDriverLicenseUpdate
        isDifferentLicenseNumber={() => {
          transitionToScreen('isDifferentLicenseNumber');
        }}
        isSameLicenseNumber={() => {
          transitionToScreen('isSameLicenseNumber');
        }}
        report={report}
      />
    );
  };

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case 'confirmDriverLicenseUpdate':
        return confirmDriverLicenseUpdate();
      case 'sameDriverLicense':
        return sameDriverLicense();
      case 'updateDriverLicenseForm':
        return updateDriverLicenseForm();
      case 'updateDriverLicenseSuccess':
        return updateDriverLicenseSuccess();
      default:
        return '';
    }
  };

  return (
    <div>
      <div role='region' className='current-screen' aria-live='polite'>
        {renderCurrentScreen()}
      </div>
    </div>
  );
};

export default UpdatePersonalInfoWorkflow;
