/*
 * Action Types
 */

export const CHANGE_PAGE = 'CHANGE_PAGE';

/*
 * Action Helpers
 */

export const changePage = newPage => ({
  newPage,
  type: CHANGE_PAGE,
});

/*
 * Actions
 */

export const handleNextPage = currentPage => dispatch => {
  const nextPage = currentPage + 1;

  dispatch(changePage(nextPage));

  window.requestAnimationFrame(() => window.scrollTo(0, 0));
};

export const handlePreviousPage = currentPage => dispatch => {
  const previousPage = currentPage - 1;

  dispatch(changePage(previousPage));

  window.requestAnimationFrame(() => window.scrollTo(0, 0));
};
