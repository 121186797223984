import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../../Panels';
import { computeSearchesStatus } from '../../lib/status';
import Record from '../../Record';

const IntlAdverseMediaSearches = ({ searches }) => {
  if (!searches || !searches.length) {
    return null;
  }

  return (
    <Panel
      data-testid='international-adverse-media-search'
      title={i18n.getStr('components.internationalAdverseMediaSearch')}
      status={computeSearchesStatus(searches)}
    >
      {searches.map((record, i) => (
        <React.Fragment key={`intl-adverse-media-search-${i + 1}`}>
          <Record record={record} type='internationalAdverseMediaSearch' />
          {i < searches.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </Panel>
  );
};

IntlAdverseMediaSearches.propTypes = {
  searches: PropTypes.array,
};

IntlAdverseMediaSearches.defaultProps = {
  searches: [],
};

export default IntlAdverseMediaSearches;
