const classify = str => {
  const camelCaseStr = str.replace(/([-_]\w)/g, match =>
    match.charAt(1).toUpperCase(),
  );
  const capitalizedStr =
    camelCaseStr.charAt(0).toUpperCase() + camelCaseStr.slice(1);
  return capitalizedStr;
};

export default classify;
