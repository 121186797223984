import PropTypes from 'prop-types';
import React from 'react';

import Panel from '../../../Panels';
import Record from '../Record';

const Search = ({
  search,
  showFilteredByAdjudicationRecords,
  title,
  type,
  dataTestId,
}) => {
  if (!search || search.status === 'canceled') {
    return null;
  }

  let recordsToDisplay = search.records || [];

  if (showFilteredByAdjudicationRecords) {
    recordsToDisplay = recordsToDisplay.concat(
      search.filteredByPositiveAdjudicationRecords || [],
    );
  }

  return (
    <Panel data-testid={dataTestId} title={title} status={search.status}>
      {Array.isArray(search.records) &&
        recordsToDisplay.map((record, i) => (
          <Record key={record.id} record={record} index={i} type={type} />
        ))}
    </Panel>
  );
};

Search.propTypes = {
  search: PropTypes.object,
  showFilteredByAdjudicationRecords: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  dataTestId: PropTypes.string,
};

Search.defaultProps = {
  search: null,
  showFilteredByAdjudicationRecords: false,
  title: '',
  type: '',
  dataTestId: '',
};

export default Search;
