import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Panel from '../../../../Panels';
import Record from '../../Record';
import { computeSearchesStatus } from '../../lib/status';

const IntlMotorVehicleReports = ({ searches }) => {
  searches = searches
    ? searches.filter(search => search.status !== 'canceled')
    : [];

  if (!searches || !searches.length) return null;

  return (
    <Panel
      title={i18n.getStr('components.internationalMotorVehicleReport')}
      status={computeSearchesStatus(searches)}
    >
      {searches.map((record, i) => (
        <React.Fragment key={`intl-motor-vehicle-report-${i + 1}`}>
          <Record record={record} type='internationalMotorVehicleReport' />
          {i < searches.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </Panel>
  );
};

IntlMotorVehicleReports.propTypes = {
  searches: PropTypes.object,
};

IntlMotorVehicleReports.defaultProps = {
  searches: null,
};

export default IntlMotorVehicleReports;
