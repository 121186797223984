import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { i18n } from '@international/mastodon-i18n';
import DOMPurify from 'isomorphic-dompurify';

import { DISPUTES_FLOW } from 'identifiers/identifiers';
import WrongPersonConfirmation from './WrongPersonConfirmation';

import * as V from '../../../../lib/validations';

import UploadButton from '../../../fields/UploadButton';
import DocumentList from '../../../fields/DocumentList';

export interface DisputeArticle {
  id?: number;
  body: string;
  hideFormControls?: boolean;
  labels: string[] | never[];
  name: string;
  newBody?: string;
  bodyAutoDispute?: string;
  provideAdditionalInfo?: string;
  title: string;
  wrongPersonConfirmation?: string;
}

export interface ArticleTicketProps {
  article: DisputeArticle;
  documents: File[];
  remove: (field: string, index: number) => void;
  section: string;
  useAutomaticDisputeModeForNonMvr: boolean;
}

export const getArticleBody = (
  article: DisputeArticle,
  useAutomaticDisputeModeForNonMvr: boolean,
): string => {
  if (useAutomaticDisputeModeForNonMvr && article.bodyAutoDispute) {
    return article.bodyAutoDispute;
  }
  return article.newBody ? article.newBody : article.body;
};

const ArticleTicket = ({
  article,
  documents,
  remove,
  section,
  useAutomaticDisputeModeForNonMvr,
}: ArticleTicketProps) => {
  const articleBody = getArticleBody(article, useAutomaticDisputeModeForNonMvr);

  return (
    <div data-testid='article-ticket'>
      <div className='form-group'>
        <span className='text-muted'>{section} &#62; </span>
        <span className='form-question'>{article.title}</span>
        <hr />
      </div>
      <div>
        <article
          data-testid={DISPUTES_FLOW.disputeArticle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(articleBody) }}
        />

        {!article.hideFormControls && (
          <>
            <p
              data-testid={DISPUTES_FLOW.disputeEmojiNote}
              className='text-muted'
            >
              <T value='components.Report.Dispute.explanation.note' />
            </p>
            <div className='form-group'>
              {!useAutomaticDisputeModeForNonMvr && (
                <label className='form-question' htmlFor='explanation'>
                  <T
                    value='components.Report.Dispute.explanation.question'
                    dangerousHTML
                  />
                </label>
              )}
              <Field
                data-testid={DISPUTES_FLOW.disputeExplanationTextField}
                id='explanation'
                name='explanation'
                component='textarea'
                className='form-control'
                rows='6'
                placeholder={i18n.getStr(
                  'components.Report.Dispute.explanation.pleaseSpecifyHere',
                )}
                validate={[V.explanationRequired]}
              />
            </div>
            <div className='form-group'>
              <label className='form-question' htmlFor='documents'>
                <T
                  value='components.Report.Dispute.documents.question'
                  dangerousHTML
                />
              </label>
              {useAutomaticDisputeModeForNonMvr && (
                <>
                  <T
                    value='components.Report.Dispute.documents.textTitle'
                    dangerousHTML
                  />
                  {article.wrongPersonConfirmation && (
                    <T
                      value='components.Report.Dispute.documents.textIsNotMine'
                      dangerousHTML
                    />
                  )}
                  {!article.wrongPersonConfirmation && (
                    <T
                      value='components.Report.Dispute.documents.textInaccurate'
                      dangerousHTML
                    />
                  )}
                </>
              )}
              {useAutomaticDisputeModeForNonMvr && (
                <UploadButton
                  id='documents'
                  name='documents'
                  kind='primary'
                  context='disputes'
                />
              )}
              {!useAutomaticDisputeModeForNonMvr && (
                <UploadButton
                  id='documents'
                  name='documents'
                  kind='secondary'
                  context='disputes'
                />
              )}
            </div>
            <div className='form-group'>
              <DocumentList
                onRemove={i => remove('documents', i)}
                documents={documents}
              />
            </div>
          </>
        )}
        {article.wrongPersonConfirmation && <WrongPersonConfirmation />}
      </div>
    </div>
  );
};

ArticleTicket.propTypes = {
  article: PropTypes.object,
  documents: PropTypes.array,
  remove: PropTypes.func,
  section: PropTypes.string,
};

ArticleTicket.defaultProps = {
  article: PropTypes.object,
  documents: [],
  remove: () => {},
  section: '',
};

export default ArticleTicket;
