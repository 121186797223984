import PropTypes from 'prop-types';
import React from 'react';

import EmploymentDocumentsPanel from './EmploymentDocumentsPanel';
import Verification, { connectToVerifications } from './VerificationBase';

class CandidateEmploymentHistoryVerification extends Verification {
  getRenderAttributes() {
    return {
      ...super.getRenderAttributes(),
      suppressNavUpload: true,
    };
  }

  renderCustomPanel() {
    const { doc } = this.getRenderAttributes();
    return doc.model && <EmploymentDocumentsPanel {...doc} />;
  }
}

CandidateEmploymentHistoryVerification.propTypes = {
  currentPage: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  verification: PropTypes.object.isRequired,
  handleNextPage: PropTypes.func.isRequired,
  handlePreviousPage: PropTypes.func.isRequired,
};

export default connectToVerifications(CandidateEmploymentHistoryVerification);
