import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import { dateWithTimezone } from '../lib/formatting';
import { orderNullFirst } from '../lib/sort';
import { isInternational } from '../../../../lib/helpers';
import CandidateStory from '../CandidateStories/CandidateStory';

class CandidateStories extends Component {
  render() {
    const { companyName, stories, candidate } = this.props;
    const orderedStories = stories.sort(orderNullFirst('record'));
    return (
      !!orderedStories.length && (
        <Panel
          title={
            <T
              value='components.Report.CandidateStories.title'
              company={companyName}
            />
          }
          tooltip={
            <T
              value='components.Report.CandidateStories.tooltip'
              company={companyName}
            />
          }
          i18n={false}
          status='submitted'
          timestamp={
            isInternational(candidate)
              ? i18n.getLocalizedShortDateTimeFormatWithTz(
                  orderedStories[0].createdAt,
                )
              : dateWithTimezone(orderedStories[0], 'createdAt')
          }
        >
          {orderedStories.map((story, i) => (
            <div key={story.id}>
              <CandidateStory story={story} />
              {i < orderedStories.length - 1 && <hr />}
            </div>
          ))}
        </Panel>
      )
    );
  }
}

CandidateStories.propTypes = {
  companyName: PropTypes.string.isRequired,
  candidate: PropTypes.object.isRequired,
  stories: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => ({
  locale: state.i18n.locale,
  stories: props.stories || state.candidateStories.stories,
});

export default connect(mapStateToProps)(CandidateStories);
