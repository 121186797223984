import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { i18n } from '@international/mastodon-i18n';
import withNonCanceledSearch from '../WithNonCanceledSearch';

import Panel from '../../../../Panels';
import { trackAnalyticsEvent } from '../../../../../actions';
import {
  AMPLITUDE_NAME_MAP,
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
} from '../../../../../lib/analytics';

const IntlIdentityDocumentValidation = ({ search }) => {
  const dispatch = useDispatch();
  return (
    <Panel
      data-testid='international-identity-document-validation'
      title={i18n.getStr('components.internationalIdentityDocumentValidation')}
      status={search.status}
    >
      {search.pdfUrl && (
        <a
          href={search.pdfUrl}
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => {
            dispatch(
              trackAnalyticsEvent(ANALYTICS_EVENTS.SCREENING_DOWNLOADED, {
                [ANALYTICS_PROPERTIES.SCREENING_NAME]:
                  AMPLITUDE_NAME_MAP.identity_document_validation,
              }),
            );
          }}
        >
          {i18n.getStr('components.viewReport')}
        </a>
      )}
    </Panel>
  );
};

IntlIdentityDocumentValidation.propTypes = {
  search: PropTypes.object,
};

IntlIdentityDocumentValidation.defaultProps = {
  search: null,
};

export default withNonCanceledSearch(IntlIdentityDocumentValidation);
