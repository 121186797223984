import React, { ReactNode } from 'react';
import PanelComponent from './PanelComponent';

export interface PanelProps {
  children?: ReactNode;
  className?: string;
  'data-testid'?: string;
  i18n?: boolean;
  icon?: string;
  noBlock?: boolean;
  status?: string;
  timestamp?: string;
  title?: string | ReactNode;
  tooltip?: string | ReactNode;
}

const Panel: React.FC<PanelProps> = ({
  children,
  className = '',
  'data-testid': dataTestId = 'panel',
  i18n = false,
  icon = '',
  noBlock = false,
  status = '',
  timestamp = '',
  title = '',
  tooltip = '',
}) => {
  return (
    <PanelComponent
      {...{
        children,
        className,
        dataTestId,
        i18n,
        icon,
        noBlock,
        status,
        timestamp,
        title,
        tooltip,
      }}
    />
  );
};

export default Panel;
