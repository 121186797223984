import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { Report } from 'types/report';
import {
  ReportView,
  CRIMINAL_VIEW,
  HEALTH_VIEW,
  CREDIT_VIEW,
  CLEARINGHOUSE_VIEW,
  DRUG_ALCOHOL_CLEARINGHOUSE_VIEW,
  PLV_VIEW,
  TOGGLE_SCREENINGS,
} from './lib/constants';

const TabStyled = styled(M.Tab)`
  max-width: fit-content !important;
`;

const TabContainerStyled = styled.div`
  padding-bottom: 1.5rem;
`;

const ViewTypes = [
  {
    type: CRIMINAL_VIEW,
    translation: 'components.Report.types.criminal',
    condition: () => true,
  },
  {
    type: HEALTH_VIEW,
    translation: 'components.Report.types.health',
    condition: (report: Report) =>
      report.drugScreening || report.occupationalHealthScreening,
  },
  {
    type: CREDIT_VIEW,
    translation: 'components.Report.types.credit',
    condition: (report: Report) => report.creditReport,
  },
  {
    type: CLEARINGHOUSE_VIEW,
    translation: 'components.Report.types.clearinghouse',
    condition: (report: Report) => report.clearinghouseSearch,
  },
  {
    type: DRUG_ALCOHOL_CLEARINGHOUSE_VIEW,
    translation: 'components.Report.types.drugAlcoholClearinghouse',
    condition: (report: Report) => report.drugAlcoholClearinghouse,
  },
  {
    type: PLV_VIEW,
    translation: 'components.Report.types.professional_license_verifications',
    condition: (report: Report) =>
      report.professionalLicenseVerifications &&
      report.professionalLicenseVerifications.length > 0,
  },
];

interface ViewTypeToggleProps {
  onClick: (view: ReportView) => void;
  report: Report;
  view: ReportView;
}

const ViewTypeToggle = ({ onClick, report, view }: ViewTypeToggleProps) => {
  const tabsToShow = ViewTypes.filter(({ condition }) => condition(report));
  const currentTab = tabsToShow.findIndex(tab => tab.type === view);
  return (
    <TabContainerStyled data-testid='view-type-toggle'>
      {TOGGLE_SCREENINGS.find(
        (screening: string) => report[screening as keyof Report],
      ) && (
        <>
          <M.Tabs defaultSelectedIndex={currentTab}>
            <M.TabList>
              {tabsToShow.map(({ type, translation }) => (
                <TabStyled
                  data-testid={`view-type-toggle-${type}`}
                  key={type}
                  onClick={() => onClick(type)}
                >
                  <T value={translation} />
                </TabStyled>
              ))}
            </M.TabList>
          </M.Tabs>
        </>
      )}
    </TabContainerStyled>
  );
};

ViewTypeToggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
  view: PropTypes.string.isRequired,
};

export default ViewTypeToggle;
