import PropTypes from 'prop-types';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

const Input = ({
  disabled,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  helperText,
}) => {
  const [value, setValue] = React.useState(input.defaultValue ?? '');
  const inputRef = React.useRef(null);
  const onChangeHandler = e => {
    setValue(e.target.value);
    input.onChange(e);
  };

  React.useEffect(() => {
    const initialValue = inputRef.current?.value;
    if (initialValue) setValue(initialValue);
  }, []);

  return (
    <M.Input
      {...input}
      value={value}
      invalid={touched && error}
      invalidText={i18n.getStr(error)}
      warn={touched && warning}
      disabled={disabled}
      ref={inputRef}
      labelText={i18n.getStr(label)}
      placeholder={i18n.getStr(placeholder)}
      helperText={helperText}
      onChange={onChangeHandler}
    />
  );
};

Input.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

Input.defaultProps = {
  disabled: false,
  input: {},
  label: '',
  meta: {},
  placeholder: '',
  helperText: '',
};

export default Input;
