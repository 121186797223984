import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { MVR_MATRIX_TYPES } from '../constants';
import Panel from './Panels';

const noDividerStyle = {
  borderTop: 'none',
};

const IconPanel = ({
  actions,
  children,
  detailsWide,
  detailsCentered,
  detailsLinkAtEnd,
  detailsLinkHandler,
  detailsLinkHREF,
  detailsCustomValue,
  noDetails,
  noDivider,
  noInfo,
  noHeader,
  nonBoldInfo,
  infoTranslationProps = {},
  panelHeaderRed,
  footer,
  icon,
  translationKey,
  params,
  floatIcon,
  'data-testid': dataTestId,
}) => {
  const isMvrMatrixType = params?.matrixFields?.some(field =>
    MVR_MATRIX_TYPES.includes(field),
  );
  const otherProps = {
    ...(dataTestId ? { 'data-testid': dataTestId } : {}),
  };

  return (
    <Panel noBlock>
      <div className={`card-body ${noHeader ? 'slim' : ''}`} {...otherProps}>
        {!noHeader && (
          <div
            className={`text-center panel-header ${
              panelHeaderRed ? 'text-danger' : ''
            }`}
          >
            <h4 className='panel-description mb-4'>
              <T value={`${translationKey}.title`} />
            </h4>
            <div
              className={`${noInfo && noDetails ? '' : 'panel-icon-padding'}`}
            >
              {icon}
            </div>
          </div>
        )}
        <div>
          {!noInfo && (
            <>
              <div
                className={`justify-content-center panel-info
              ${nonBoldInfo ? '' : ' panel-info-bold'}
              ${floatIcon ? '' : ' text-center'}`}
              >
                <T
                  className='panel-info-max-width'
                  value={`${translationKey}.info`}
                  {...infoTranslationProps}
                  dangerousHTML
                />
              </div>
              <div className='text-center panel-info'>{actions}</div>
            </>
          )}
          <div
            className={`panel-info ${noDetails ? ' d-none' : ''}
          ${floatIcon ? '' : ' justify-content-center'}`}
          >
            <div
              style={noDivider && noDividerStyle ? noDividerStyle : {}}
              className={`panel-details ${
                detailsCentered || isMvrMatrixType ? 'text-center' : ''
              }`}
            >
              {floatIcon && <div className='float-left pr-5'>{icon}</div>}
              <T
                value={
                  isMvrMatrixType
                    ? `${translationKey}.mvr_matrix_type.details`
                    : `${translationKey}.details`
                }
                {...detailsCustomValue}
                {...params}
                dangerousHTML
              />
              {detailsLinkAtEnd && (
                <>
                  <a href={detailsLinkHREF} onClick={detailsLinkHandler}>
                    <T value={`${translationKey}.linkText`} />
                  </a>
                  .
                </>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
      {footer}
    </Panel>
  );
};

IconPanel.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  detailsCentered: PropTypes.bool,
  detailsWide: PropTypes.any,
  footer: PropTypes.node,
  params: PropTypes.object,
  icon: PropTypes.node,
  translationKey: PropTypes.string.isRequired,
  detailsLinkAtEnd: PropTypes.bool,
  detailsLinkHandler: PropTypes.func,
  detailsLinkHREF: PropTypes.string,
  detailsCustomValue: PropTypes.object,
  infoTranslationProps: PropTypes.object,
  noDetails: PropTypes.bool,
  noDivider: PropTypes.any,
  noInfo: PropTypes.any,
  noHeader: PropTypes.any,
  nonBoldInfo: PropTypes.bool,
  panelHeaderRed: PropTypes.bool,
  floatIcon: PropTypes.bool,
  'data-testid': PropTypes.string,
};

IconPanel.defaultProps = {
  actions: [],
  children: [],
  detailsCentered: false,
  footer: [],
  icon: [],
  detailsLinkAtEnd: false,
  detailsLinkHandler: () => {},
  detailsLinkHREF: '',
  detailsCustomValue: {},
  detailsWide: false,
  infoTranslationProps: {},
  noDetails: false,
  noDivider: false,
  noInfo: false,
  noHeader: false,
  nonBoldInfo: false,
  panelHeaderRed: false,
  params: undefined,
  floatIcon: false,
  'data-testid': '',
};

export default IconPanel;
