import {
  COMPLETE_STATUSES,
  PENDING_STATUS,
  PROCESSING_STATUSES,
  SUSPENDED_STATUS,
} from './constants';

const computeSearchesStatusHelper = (priority, searches) => {
  let resp = 'clear';

  for (let i = 0; i < searches.length; i += 1) {
    const { status } = searches[i];
    if (priority[status] && priority[status] > priority[resp]) {
      resp = status;
    }
  }

  return resp;
};

const computeSearchesStatus = searches => {
  const priority = {
    pending: 40,
    consider: 30,
    clear: 20,
    complete: 10,
    canceled: 5,
  };

  if (searches.every(x => x.status === 'suspended')) {
    return 'suspended';
  }
  if (searches.every(x => x.status === 'canceled')) {
    return 'canceled';
  }

  return computeSearchesStatusHelper(priority, searches);
};

const isStatusComplete = status => COMPLETE_STATUSES.indexOf(status) !== -1;

export const isStatusPending = status => status === PENDING_STATUS;

export const isStatusProcessing = status =>
  PROCESSING_STATUSES.includes(status);

export const isStatusSuspended = status => status === SUSPENDED_STATUS;

export { computeSearchesStatus, isStatusComplete };
