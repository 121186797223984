import React from 'react';
import CandidateLandingPage from 'components/Reports/CandidateLandingPage';
import { useSelector } from 'react-redux';
import { isInternational as getIsInternational } from 'lib/helpers';
import useToken from 'utils/useToken';
import Auth from '../Auth';
import { AuthenticatedView } from './AuthenticatedView';
import { getLoginSource } from './lib/getLoginSource';

const getReduxState = (state: any) => {
  return {
    portal: state.portal,
    isInternational: state.international?.international || getIsInternational(),
  };
};

export const MultipleReports: React.FC = () => {
  const { portal, isInternational } = useSelector(getReduxState);
  const token = useToken();

  return (
    <Auth
      loginSource={getLoginSource(isInternational)}
      international={isInternational}
      token={token}
    >
      <AuthenticatedView>
        <CandidateLandingPage
          firstName={portal.firstName}
          reports={portal.reports}
        />
      </AuthenticatedView>
    </Auth>
  );
};
