import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import { Record, SearchEntity } from './types';
import Search from '../Search/Search';
import withNonCanceledSearch from './WithNonCanceledSearch';

interface Props {
  search: SearchEntity;
}

const VIOLENT_REGISTRY_STATES = ['MT'];

const isViolentState = (state: string) =>
  VIOLENT_REGISTRY_STATES.includes(state);

const isRecordInViolentState = (record: Record) => isViolentState(record.state);

const SexOffenderSearch = ({ search }: Props): React.ReactElement => {
  const title = search.records.some(isRecordInViolentState)
    ? i18n.getStr('components.sexualAndViolentOffenderSearch')
    : i18n.getStr('components.sexOffenderSearch');

  return <Search type='sexual' title={title} search={search} />;
};

export default i18n.renderTranslation()(
  // @ts-ignore
  withNonCanceledSearch(SexOffenderSearch),
);
