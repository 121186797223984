import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { uuid4 } from '@sentry/utils';
import './Features.scss';

type FeatureProp = {
  title: string;
  description: string;
  isOpen?: boolean;
};

type FeaturesProps = {
  icon: string;
  title: string;
  features: FeatureProp[];
};

type PersonalChecksFeaturesProps = {
  handleSubscribeClick?: () => void;
  bannerTitle?: React.ReactNode;
};

const ExpandableFeature = ({
  title,
  description,
  isOpen = false,
}: FeatureProp) => {
  const [isExpanded, setIsExpanded] = React.useState(isOpen);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <div
      className={`personal-checks-modal-feature ${
        isExpanded ? 'expanded' : 'collapsed'
      }`}
    >
      <button
        type='button'
        onClick={toggleExpanded}
        aria-expanded={isExpanded}
        className={`personal-checks-modal-feature-button ${
          isExpanded ? 'expanded' : 'collapsed'
        }`}
      >
        <p id='personal-checks-modal-feature-button-text'>
          {i18n.getStr(title)}
        </p>
        {isExpanded ? (
          <M.Icon icon='ChevronUp' />
        ) : (
          <M.Icon icon='ChevronDown' />
        )}
      </button>
      {isExpanded && (
        <div className='personal-checks-modal-feature-description'>
          <T value={description} dangerousHTML />
        </div>
      )}
    </div>
  );
};

const PersonalChecksModalBodyHeader = ({
  children,
  bannerTitle,
}: {
  children: React.ReactNode;
  bannerTitle?: React.ReactNode;
}) => {
  return (
    <section id='personal-checks-modal-body-header'>
      <h3 id='personal-checks-modal-body-header-title'>{bannerTitle}</h3>
      <div id='personal-checks-modal-body-header-description'>
        <p id='personal-checks-modal-body-header-description-price'>
          <T
            value='components.PersonalChecks.modal.headerPrice'
            dangerousHTML
          />
        </p>
        {children}
      </div>
    </section>
  );
};

function Features({ icon, title, features }: FeaturesProps) {
  return (
    <div id='personal-checks-modal-features-container'>
      <section id='personal-checks-modal-features-header'>
        <div id='personal-checks-modal-features-header-icon'>
          <M.Icon icon={icon} size='20' />
        </div>
        <p id='personal-checks-modal-features-header-title'>
          <T value={title} />
        </p>
      </section>
      <section id='personal-checks-modal-features-body'>
        {features.map(feature => (
          <ExpandableFeature
            key={uuid4()}
            title={feature.title}
            description={feature.description}
            isOpen={feature.isOpen || false}
          />
        ))}
      </section>
    </div>
  );
}

const PersonalChecksModalBodyFeatures = () => (
  <section id='personal-checks-modal-body-features'>
    <h3 id='personal-checks-modal-body-features-title'>
      <T value='components.PersonalChecks.modal.featuresTitle' />
    </h3>
    <div id='personal-checks-modal-body-features-container'>
      <Features
        icon='Lightning'
        title='components.PersonalChecks.modal.features.preapproved'
        features={[
          {
            title: 'components.PersonalChecks.modal.features.preapproval',
            description:
              'components.PersonalChecks.modal.features.preapprovalDescription',
            isOpen: true,
          },
          {
            title: 'components.PersonalChecks.modal.features.personalProfile',
            description:
              'components.PersonalChecks.modal.features.personalProfileDescription',
          },
        ]}
      />
      <Features
        icon='Security'
        title='components.PersonalChecks.modal.features.protectYourIdentity'
        features={[
          {
            title: 'components.PersonalChecks.modal.features.ssnMonitoring',
            description:
              'components.PersonalChecks.modal.features.ssnMonitoringDescription',
          },
          {
            title:
              'components.PersonalChecks.modal.features.globalIdentityMonitoring',
            description:
              'components.PersonalChecks.modal.features.globalIdentityMonitoringDescription',
          },
        ]}
      />
      <Features
        icon='Search'
        title='components.PersonalChecks.modal.features.monitorYourRecords'
        features={[
          {
            title: 'components.PersonalChecks.modal.features.recordsMonitoring',
            description:
              'components.PersonalChecks.modal.features.recordsMonitoringDescription',
          },
          {
            title: 'components.PersonalChecks.modal.features.expungement',
            description:
              'components.PersonalChecks.modal.features.expungementDescription',
          },
        ]}
      />
    </div>
  </section>
);

const PersonalChecksFeatures = ({
  handleSubscribeClick,
  bannerTitle,
}: PersonalChecksFeaturesProps) => (
  <>
    <PersonalChecksModalBodyHeader bannerTitle={bannerTitle}>
      <M.Button
        type='button'
        kind='achievement'
        onClick={handleSubscribeClick}
        id='personal-checks-modal-footer-subscribe-button'
      >
        <T value='components.PersonalChecks.modal.subscribe' />
      </M.Button>
    </PersonalChecksModalBodyHeader>
    <PersonalChecksModalBodyFeatures />
  </>
);

export default PersonalChecksFeatures;
