import PropTypes from 'prop-types';
import React from 'react';
import { colors, M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { i18n, moment } from '@international/mastodon-i18n';

const DatePickerContainer = styled.div`
  .cds--date-picker__icon--invalid {
    fill: ${colors.orangeAccent02} !important;
    border-bottom: 1px solid ${colors.orangeAccent02} !important;
  }
`; /* TODO: Remove .cds--date-picker__icon--invalid styling after UIP-25 has been done */

const handleOnChange = onChange => e => {
  const format = 'MM/DD/YYYY';
  const convertedValue = moment(e[0]).format(format);
  onChange(convertedValue);
};

const DatePicker = ({
  input: { name, onBlur, onChange, value },
  meta: { touched, error, warning },
  datePickerType,
  labelControl,
  placeholder,
}) => (
  <DatePickerContainer>
    <M.DatePicker
      id={`date-picker-${name}`}
      data-testId={`date-picker-${name}`}
      name={name}
      dateFormat='m/d/Y'
      datePickerType={datePickerType}
      value={value}
      onChange={handleOnChange(onChange)}
      onBlur={onBlur}
    >
      <M.DatePickerInput
        id={`date-picker-${name}-input`}
        data-testId={`date-picker-${name}-input`}
        labelText={labelControl}
        placeholder={placeholder}
        invalid={error && touched}
        invalidText={i18n.getStr(error)}
        warn={warning}
      />
    </M.DatePicker>
  </DatePickerContainer>
);

DatePicker.propTypes = {
  input: PropTypes.object,
  datePickerType: PropTypes.string,
  labelControl: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.bool,
  }),
};

DatePicker.defaultProps = {
  input: {},
  datePickerType: 'single',
  labelControl: '',
  placeholder: '',
  meta: {
    touched: false,
    error: '',
    warning: false,
  },
};

export default DatePicker;
