import React from 'react';

import { Translate as T } from 'react-redux-i18n';

import Panel from '../components/Panels';

const ResourceNotFound = () => (
  <Panel>
    <h4>
      <T value='components.ResourceNotFound.title' />
    </h4>
    <T value='components.ResourceNotFound.text' dangerousHTML />
  </Panel>
);

export default ResourceNotFound;
