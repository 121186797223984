import { i18n } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';
import { M, colors } from '@dashboard-experience/mastodon';
import React from 'react';
import styled from 'styled-components';

interface DescribeFieldProps {
  selectedField: (selected: string) => void;
  value: string;
  caption: string;
}

const CardStyled = styled(M.Card)`
  cursor: pointer;
  padding: 1.5rem !important;

  &:hover {
    background-color: #f4f4f4 !important;
    border: 1px solid #bbb !important;
    color: ${colors.checkrBlueHover} !important;
  }
`;

const DescribeField = ({
  caption,
  value,
  selectedField = () => {},
}: DescribeFieldProps) => {
  return (
    <CardStyled
      onClick={() => {
        selectedField(i18n.getStr(value));
      }}
      role='button'
      tabIndex={0}
      data-testid='candidate-select-reason-btn'
    >
      <T value={caption} dangerousHTML />
    </CardStyled>
  );
};

export default DescribeField;
