import React from 'react';
import { Navigate } from 'react-router-dom';
import { isInternational } from 'lib/helpers';
import { PATHS } from '../../constants';

const NotFound: React.FC = () => {
  return (
    <Navigate
      to={
        isInternational()
          ? `${PATHS.INTERNATIONAL}${window.location.search}`
          : '/'
      }
    />
  );
};

export default NotFound;
