import countries from 'i18n-iso-countries';
import { i18n, momenttz } from '@international/mastodon-i18n';
import { shortDateTimePattern } from 'lib/dateFormat';

const NON_TITLE_CASE_WORDS = [
  'a',
  'an',
  'and',
  'as',
  'at',
  'but',
  'by',
  'for',
  'from',
  'in',
  'into',
  'nor',
  'of',
  'off',
  'on',
  'onto',
  'or',
  'out',
  'over',
  'so',
  'the',
  'to',
  'with',
];

const ACRONYMS = ['ACD', 'AVD', 'DL', 'DOB', 'ID', 'MVR', 'QA', 'SSN', 'YOB'];

const word = wordStr => {
  if (!wordStr) {
    return undefined;
  }

  if (NON_TITLE_CASE_WORDS.indexOf(wordStr.toLowerCase()) !== -1) {
    return wordStr.toLowerCase();
  }

  if (ACRONYMS.indexOf(wordStr.toUpperCase()) !== -1) {
    return wordStr.toUpperCase();
  }

  return wordStr.charAt(0).toUpperCase() + wordStr.substr(1).toLowerCase();
};

const toStartCase = str => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/[_-]+/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase());
};

const text = input => {
  if (!input) {
    return '';
  }
  if (typeof input !== 'string') {
    input = input.toString();
  }
  return toStartCase(input).replace(/\w\S*/g, word);
};

const date = (input, format = i18n.DateFormats.DATE_SHORT) => {
  if (!input || input === '') {
    return null;
  }

  return momenttz(input).utc().format(format);
};

const isNumeric = input => {
  // last check covers strings containing an "e" that could be interpreted as
  // the power of, translated into Infinity which is a number
  return !isNaN(input) && !/\d+e\d+/gi.test(input);
};

const dateIfDate = (input, attr) => {
  if (attr && !/(date|dob)/i.test(attr)) {
    return input;
  }

  if (typeof input !== 'string') {
    return input;
  }

  if (input.match(/\d{4}-\d{2}-\d{2}/)) {
    const parsedDate = momenttz(input, 'YYYY-MM-DD');
    const aboveLowerBound = parsedDate.isAfter('1900-01-01', 'YYYY-MM-DD');
    const belowUpperBound = parsedDate.isBefore('2100-01-01', 'YYYY-MM-DD');
    if (parsedDate.isValid() && aboveLowerBound && belowUpperBound) {
      return date(input, 'll');
    }
  }

  return input;
};

const formatDate = (input, format) => {
  if (typeof input !== 'string') {
    return input;
  }

  if (typeof format !== 'string') {
    format = shortDateTimePattern();
  }

  const inputDate = momenttz(input);
  return inputDate.isValid() ? inputDate.format(format) : input;
};

const numberIfNumber = input => {
  if (isNaN(input)) {
    return input;
  }

  return parseInt(input || '0', 10).toLocaleString();
};

const address = addressObj => {
  const parts = [];

  if (addressObj.street) {
    let part = '';
    if (addressObj.unit) {
      part += `${addressObj.unit} `;
    }
    part += addressObj.street;
    parts.push(part);
  }

  if (addressObj.city) {
    parts.push(addressObj.city);
  }
  if (addressObj.state) {
    parts.push(addressObj.state);
  }
  if (addressObj.zipcode) {
    parts.push(addressObj.zipcode);
  }

  return parts.join(', ');
};

const titleize = textStr => {
  if (typeof textStr !== 'string') {
    return '';
  }
  return textStr
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/(?:^|\s|-)\S/g, m => {
      return m.toUpperCase();
    });
};

const duration = (input, unit = 'seconds') => {
  return momenttz.duration(input, unit).humanize();
};

const dateWithTimezone = (input, k, pdf) => {
  const timezone = pdf ? 'UTC' : momenttz.tz.guess();
  return momenttz.tz(input[k], timezone).format('lll z');
};

const isoCountry = countries.getName;

export {
  address,
  date,
  dateIfDate,
  formatDate,
  duration,
  isoCountry,
  numberIfNumber,
  text,
  titleize,
  isNumeric,
  dateWithTimezone,
};
