import React, { ReactNode } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import styles from './Modal.module.css';

const Modal = ({
  isOpen,
  toggle,
  title,
  children,
}: {
  isOpen: boolean;
  toggle: () => void;
  title: string;
  children: ReactNode;
}) => {
  return (
    <M.ComposedModal
      open={isOpen}
      data-testid={DISPUTES_FLOW.disputeModal}
      isOpen={isOpen}
      className={styles.pb5}
    >
      <M.ModalHeader buttonOnClick={toggle}>{title}</M.ModalHeader>
      <M.ModalBody>{children}</M.ModalBody>
    </M.ComposedModal>
  );
};

export default Modal;
