import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Charge from '../Charge/Criminal';
import Attribute from '../Attribute';

import { dateIfDate } from '../lib/formatting';

const CivilRecord = ({ record }) => (
  <div className='criminal-record'>
    <div className='mb-3'>
      <hr className='mb-2 mt-2' />
      <div className='dl-flex'>
        <span className='text-uppercase'>
          {record.charges[0]
            ? record.charges[0].charge
            : i18n.getStr('components.unknownCharge')}
        </span>
        <span className='ml-auto'>
          {record.charges[0] && dateIfDate(record.charges[0].offenseDate)}
        </span>
      </div>
      <hr className='mb-2 mt-2' />
    </div>

    <div className='mb-4 mt-4'>
      {Object.keys(record)
        .sort()
        .map((key, i) => (
          <Attribute
            key={`attribute-${i + 1}`}
            attr={key}
            value={record[key]}
          />
        ))}
    </div>

    {record.charges.length > 0 && <hr />}

    {record.charges.map((charge, i) => (
      <div key={`div-${i + 1}`}>
        <Charge charge={charge} index={i} />
        <hr />
      </div>
    ))}
  </div>
);

CivilRecord.propTypes = {
  record: PropTypes.object.isRequired,
};

export default i18n.renderTranslation()(CivilRecord);
