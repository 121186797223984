import React, { useEffect } from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import { Report } from 'types/report';
import CandidateStoryForm from './CandidateStoryForm';
import CandidateStorySuccess from './CandidateStorySuccess';
import CandidateStoryFailure from './CandidateStoryFailure';

const CandidateStoryModal = ({
  companyName,
  isOpen = false,
  toggleCandidateStoryModal = () => {},
  report,
  candidateStorySubmitted = false,
  generalInformationOnly = false,
  submitError,
  toggleDisputeModal = () => {},
}: {
  companyName: string;
  isOpen: boolean;
  toggleCandidateStoryModal: () => void;
  report: Report;
  candidateStorySubmitted: boolean;
  generalInformationOnly: boolean;
  submitError: boolean;
  toggleDisputeModal: () => void;
}) => {
  useEffect(() => {
    if (!window.adaEmbed) return;
    if (isOpen) {
      window.adaEmbed.stop();
    } else {
      window.adaEmbed.start(window.adaSettings).catch((e: Error) => {
        if (e.name !== 'AdaEmbedError') {
          throw e;
        }
      });
    }
  }, [isOpen]);

  return (
    <M.ComposedModal
      data-testid='candidate-story-modal'
      open={isOpen}
      toggle={toggleCandidateStoryModal}
    >
      <M.ModalHeader
        data-testid='candidate-story-modal-title'
        buttonOnClick={toggleCandidateStoryModal}
      >
        <T
          value='components.Report.CandidateStories.Modal.title'
          company={companyName}
        />
      </M.ModalHeader>
      <M.ModalBody data-testid='candidate-story-modal-body'>
        {candidateStorySubmitted && !submitError && (
          <CandidateStorySuccess
            company={companyName}
            formName='CandidateStory'
          />
        )}
        {candidateStorySubmitted && submitError && <CandidateStoryFailure />}
        {!candidateStorySubmitted && (
          <CandidateStoryForm
            companyName={companyName}
            report={report}
            toggleCandidateStoryModal={toggleCandidateStoryModal}
            toggleDisputeModal={toggleDisputeModal}
            generalInformationOnly={generalInformationOnly}
          />
        )}
      </M.ModalBody>
    </M.ComposedModal>
  );
};

export default CandidateStoryModal;
