/* eslint-disable consistent-return */
import { REQUIRED, VALID_CC, EXPLANATION_REQUIRED } from './messages';

const required = value => {
  if (!value) {
    return REQUIRED;
  }

  if (typeof value === 'object' && value.selectedItem) {
    if (value.selectedItem.trim() === '') {
      return REQUIRED;
    }
  } else {
    if (typeof value !== 'string') {
      // eslint-disable-next-line no-console
      console.debug('Attempted to require a ', typeof value);
      return;
    }

    if (value.trim() === '') {
      return REQUIRED;
    }
  }
};

const middleNameRequired = (value, allValues) => {
  if ((!value || value.trim() === '') && !allValues.noMiddleName) {
    return REQUIRED;
  }
};

const requiredCheckbox = value => {
  if (!value) {
    return REQUIRED;
  }
};

const validCC = isValidCC => {
  if (!isValidCC) {
    return VALID_CC;
  }
};

const explanationRequired = value => {
  if (!value || value.length < 3) {
    return EXPLANATION_REQUIRED;
  }
};

export {
  middleNameRequired,
  required,
  requiredCheckbox,
  validCC,
  explanationRequired,
};
