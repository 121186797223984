import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import PropTypes from 'prop-types';
import MedicalCertificateAttributes from './MedicalCertificateAttributes';
import { dateIfDate } from '../lib/formatting';
import { getRandomKey } from '../../../../lib/helpers';

const MedicalCertificates = ({ medicalCertificates }) => {
  return (
    <div className='mb-5'>
      <h6>
        {i18n.getStr(
          'components.Report.MotorVehicleReport.medicalCertificates',
        )}
      </h6>
      {medicalCertificates.map((medicalCertificate, i) => (
        <div className='mvr-medical-certificate mb-4' key={getRandomKey()}>
          <hr className='mb-2 mt-2' />
          <div className='d-flex'>
            <span className='text-uppercase'>Medical Certificate</span>
            <span className='ml-auto'>
              {dateIfDate(medicalCertificate.issuedDate)}
            </span>
          </div>
          <hr className='mb-2 mt-2' />
          <div className='mb-4 mt-4'>
            <MedicalCertificateAttributes
              medicalCertificate={medicalCertificate}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

MedicalCertificates.propTypes = {
  medicalCertificates: PropTypes.array,
};

MedicalCertificates.defaultProps = {
  medicalCertificates: null,
};

export default MedicalCertificates;
