import { TranslationObjects } from 'react-redux-i18n';
import de from './portal_de.json';
import en from './portal_en.json';
import en_XL from './portal_en_XL.json';
import es from './portal_es.json';
import es_MX from './portal_es_MX.json';
import fr from './portal_fr.json';
import fr_CA from './portal_fr_CA.json';

const translations = {
  de,
  en,
  en_XL,
  es,
  es_MX,
  fr,
  fr_CA,
} as Record<string, any> as TranslationObjects;

export default translations;
