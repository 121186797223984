import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import './IconPanel2.scss';

import Panel from 'components/Panels';

const noDividerStyle = {
  borderTop: 'none',
};

const IconPanel2 = ({
  actions,
  children,
  detailsWide,
  detailsCentered,
  detailsLinkAtEnd,
  detailsLinkHandler,
  detailsLinkHREF,
  detailsCustomValue,
  noDetails,
  noDivider,
  noInfo,
  noHeader,
  nonBoldInfo,
  infoTranslationProps = {},
  panelHeaderRed,
  footer,
  icon,
  translationKey,
  params,
  floatIcon,
}) => (
  <Panel className='top-panel-style' noBlock>
    <div
      className={`card-body card-body--icon-panel ${
        noHeader ? 'slim' : ''
      } top-div-style`}
    >
      {!noHeader && (
        <div
          className={`text-center panel-header ${
            panelHeaderRed ? 'text-danger' : ''
          }`}
        >
          <div>{icon}</div>
          <h1 className='icon-panel2-h1'>
            <T value={`${translationKey}.title`} />
          </h1>
        </div>
      )}
      <div>
        {!noInfo && (
          <>
            <div
              className={`justify-content-center panel-info
              ${nonBoldInfo ? '' : ' panel-info-bold'}
              ${floatIcon ? '' : ' text-center'}`}
            >
              <T
                className='panel-info-max-width'
                value={`${translationKey}.info`}
                {...infoTranslationProps}
                dangerousHTML
              />
            </div>
            <div className='text-center panel-info'>{actions}</div>
          </>
        )}
        <div
          className={`panel-info ${noDetails ? ' d-none' : ''}
          ${floatIcon ? '' : ' justify-content-center'}`}
        >
          <div
            style={{
              ...(noDivider && noDividerStyle ? noDividerStyle : {}),
              padding: '28px 0px 0px',
              borderTop: '1px solid #ddd',
            }}
            className={`d-flex justify-content-center ${
              detailsCentered ? 'text-center' : ''
            }`}
          >
            {floatIcon && <div className='float-left pr-5'>{icon}</div>}
            <T
              value={`${translationKey}.details`}
              {...detailsCustomValue}
              {...params}
              dangerousHTML
            />
            {detailsLinkAtEnd && (
              <>
                <a href={detailsLinkHREF} onClick={detailsLinkHandler}>
                  <T value={`${translationKey}.linkText`} />
                </a>
                .
              </>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
    {footer}
  </Panel>
);

IconPanel2.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  detailsCentered: PropTypes.bool,
  detailsWide: PropTypes.any,
  footer: PropTypes.node,
  params: PropTypes.object,
  icon: PropTypes.node,
  translationKey: PropTypes.string.isRequired,
  detailsLinkAtEnd: PropTypes.bool,
  detailsLinkHandler: PropTypes.func,
  detailsLinkHREF: PropTypes.string,
  detailsCustomValue: PropTypes.object,
  infoTranslationProps: PropTypes.object,
  noDetails: PropTypes.bool,
  noDivider: PropTypes.any,
  noInfo: PropTypes.any,
  noHeader: PropTypes.any,
  nonBoldInfo: PropTypes.bool,
  panelHeaderRed: PropTypes.bool,
  floatIcon: PropTypes.bool,
};

IconPanel2.defaultProps = {
  actions: [],
  children: [],
  detailsCentered: false,
  footer: [],
  icon: [],
  detailsLinkAtEnd: false,
  detailsLinkHandler: () => {},
  detailsLinkHREF: '',
  detailsCustomValue: {},
  detailsWide: false,
  infoTranslationProps: {},
  noDetails: false,
  noDivider: false,
  noInfo: false,
  noHeader: false,
  nonBoldInfo: false,
  panelHeaderRed: false,
  params: undefined,
  floatIcon: false,
};

export default IconPanel2;
