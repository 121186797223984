import React, { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import '../../../../../scss/UpdateDriverLicenseForm.scss';
import NavigationFooter from '../../../../Workflow/NavigationFooter';
import Breadcrumb from '../../../../Workflow/Breadcrumb';
import ContentContainer from '../../../../Workflow/ContentContainer';
import { useUpdateCandidate } from '../../../../../api/verifications';
import { validateUpdateToDriverLicense } from '../../../../../lib/validations/driverLicenseValidations';
import { Report } from '../../../../../types/report';
import { States } from '../../../../../types/states';
import { normalizeDriverLicenseNumber } from '../../../../../lib/normalizations';

enum FormField {
  CANDIDATE_ID = 'candidateId',
  DL_NUMBER = 'dlNumber',
  DL_STATE = 'dlState',
  REPORT_ID = 'reportId',
  PREVIOUS_DL_STATE = 'previousDlState',
  PREVIOUS_DL_NUMBER = 'previousDlNumber',
}

const renderErrorNotification = (subtitleKey: string) => {
  return (
    <div className='pb-4'>
      <M.ActionableNotification
        className='update-dl-form-error'
        hideCloseButton
        kind='error'
        title={i18n.getStr(
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.errorTitle',
        )}
        subtitle={i18n.renderHTML(subtitleKey)}
      />
    </div>
  );
};

const renderValidationError = (field: string, error: string) => {
  return renderErrorNotification(`validations.driverLicense.${error}.${field}`);
};

const UpdateDriverLicenseForm = ({
  back,
  onUpdateSuccess,
  report,
}: {
  back: () => void;
  onUpdateSuccess: () => void;
  report: Report;
}) => {
  const [submitButtonIsDisabled, setSubmitButtonIsDisabled] = useState(true);
  const updateSubmitButtonIsDisabled = useCallback(
    () => setSubmitButtonIsDisabled(!submitButtonIsDisabled),
    [submitButtonIsDisabled],
  );
  const formik = useFormik({
    initialValues: {
      [FormField.CANDIDATE_ID]: report.candidate.id,
      [FormField.DL_STATE]: '',
      [FormField.DL_NUMBER]: '',
      [FormField.REPORT_ID]: report.id,
      [FormField.PREVIOUS_DL_STATE]: report.candidate
        .driverLicenseState as States,
      [FormField.PREVIOUS_DL_NUMBER]: report.candidate.driverLicenseNumber,
    },
    onSubmit: submittedValues => {
      const normalizedValues = {
        ...submittedValues,
        [FormField.DL_NUMBER]: normalizeDriverLicenseNumber(
          submittedValues[FormField.DL_NUMBER],
        ),
      };

      const {
        [FormField.PREVIOUS_DL_STATE]: _1,
        [FormField.PREVIOUS_DL_NUMBER]: _2,
        ...filteredNormalizedValues
      } = normalizedValues;

      updateCandidate(filteredNormalizedValues);
    },
    validate: validateUpdateToDriverLicense,
    validateOnChange: false,
    validateOnBlur: false,
  });
  const { updateCandidate, candidateUpdateStatus } =
    useUpdateCandidate(onUpdateSuccess);

  const renderSubmitButton = () => {
    if (candidateUpdateStatus === 'pending') {
      return (
        <M.LoadingInline
          role='progressbar'
          description={i18n.getStr(
            'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.spinnerDescription',
          )}
        />
      );
    }
    return (
      <M.Button
        disabled={submitButtonIsDisabled}
        aria-disabled={submitButtonIsDisabled}
        form='update-dl-form'
        size='sm'
        kind='primary'
        onClick={formik.handleSubmit}
      >
        {i18n.getStr('buttons.submit')}
      </M.Button>
    );
  };

  const renderError = () => {
    const stateError = formik.errors[FormField.DL_STATE];
    const numberError = formik.errors[FormField.DL_NUMBER];

    if (stateError === 'missingField' && numberError === 'missingField') {
      return renderValidationError('dlStateNumber', 'missingField');
    }
    if (stateError) {
      return renderValidationError(FormField.DL_STATE, stateError);
    }
    if (numberError) {
      return renderValidationError(FormField.DL_NUMBER, numberError);
    }
    if (candidateUpdateStatus === 'error') {
      return renderErrorNotification(
        'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.serverErrorSubtitle',
      );
    }

    return '';
  };

  return (
    <div className='update-dl-form'>
      <Breadcrumb
        crumbKeys={[
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.title',
          'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.title',
        ]}
      />
      <ContentContainer>
        {renderError()}
        <p className='pb-4' role='note'>
          {i18n.getStr(
            'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.note',
          )}
        </p>
        <M.Form id='update-dl-form'>
          <div className='d-flex update-dl-form-inputs'>
            <div className='pr-4'>
              <M.Select
                id='dl-state-selector'
                name={FormField.DL_STATE}
                onChange={formik.handleChange}
                labelText={i18n.getStr(
                  'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.licenseState',
                )}
                placeholder={i18n.getStr(
                  'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.selectAState',
                )}
              >
                {report.account.enabledMvrStates.map(state => (
                  <M.Select.Item key={state} value={state} text={state} />
                ))}
              </M.Select>
            </div>

            <M.Input
              name={FormField.DL_NUMBER}
              onChange={formik.handleChange}
              labelText={i18n.getStr(
                'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.licenseNumber',
              )}
              placeholder={i18n.getStr(
                'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.licenseNumber',
              )}
            />
          </div>
          <div>
            <M.Checkbox
              id='consent-checkbox'
              role='checkbox'
              labelText={i18n.getStr(
                'components.Report.PersonalInfo.Screenings.motorVehicleReport.updateIntentions.driverLicense.updateDLForm.consentLabel',
              )}
              onChange={updateSubmitButtonIsDisabled}
            />
          </div>
        </M.Form>
      </ContentContainer>
      <NavigationFooter back={back} rightButtonContent={renderSubmitButton()} />
    </div>
  );
};

export default UpdateDriverLicenseForm;
