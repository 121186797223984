import { I18n } from 'react-redux-i18n';

interface Field {
  name: string;
  [key: string]: string;
}

export const bestAvailableDisplayText = (
  field: Field,
  displayName: string = '',
): string => {
  const translatedTitle: string = I18n.t(
    `components.Report.Verification.MatrixVerification.${field.name}.${displayName}`,
  );

  if (translatedTitle !== displayName) {
    return translatedTitle;
  }

  return field[displayName] || field.name;
};

export const bestAvailableDisplayTextForMvrConsent = (
  field: Field,
  displayName: string,
): string => {
  return I18n.t(
    `components.Report.Verification.MatrixVerification.mvr_consent_documentation_requested.${displayName}`,
  );
};
