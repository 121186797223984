import * as ActionTypes from '../actions';

const initialState = {
  currentPage: 0,
  currentReportIndex: 0,
};

const navigation = (state = initialState, action) => {
  const { newPage, newReportIndex, type } = action;

  switch (type) {
    case ActionTypes.FETCH_VERIFICATION_SUCCESS:
      return {
        ...state,
        currentPage: 0,
      };
    case ActionTypes.CHANGE_PAGE:
      return {
        ...state,
        currentPage: newPage,
      };
    case ActionTypes.CHANGE_REPORT_INDEX:
      return {
        ...state,
        currentReportIndex: newReportIndex,
      };
    default:
      return state;
  }
};

export default navigation;

export { initialState };
