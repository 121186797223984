import { useLocation } from 'react-router-dom';

export const searchParamsToObject = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.keys()).reduce<Record<string, string>>(
    (result, currentKey) => {
      return {
        ...result,
        [currentKey]: searchParams.get(currentKey) ?? '',
      };
    },
    {},
  );
};

const useLocationQuery = () =>
  searchParamsToObject(new URLSearchParams(useLocation().search));

export default useLocationQuery;
