import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { camelize } from 'humps';
import { M } from '@dashboard-experience/mastodon';
import { address, dateIfDate, isNumeric, text } from './lib/formatting';
import { CHARGE_ATTRS } from '../../../constants';

const IGNORED_ATTRIBUTES = ['charges', 'id', 'bayesianMatchData', 'aliases'];

interface AttributeProps {
  attr: string;
  value: any;
}

const Attribute = ({ attr, value }: AttributeProps) => {
  if (
    !value ||
    typeof value === 'boolean' ||
    typeof value === 'object' ||
    IGNORED_ATTRIBUTES.indexOf(attr) !== -1
  ) {
    return null;
  }

  let attrText = text(attr);

  if (Object.values(CHARGE_ATTRS).includes(attrText)) {
    attrText = i18n.getStr(
      `components.Report.AttributeCharge.${camelize(attrText)}`,
    );
  }
  let valueText;

  if (attr === 'address') {
    valueText = address(value);
  } else if (isNumeric(value)) {
    valueText = parseInt(value, 10);
  } else {
    valueText = dateIfDate(value, attr);
  }

  return (
    <M.Grid>
      <M.GridRow className='mb-1'>
        <M.GridCol md={3} sm={2} className='text-very-muted'>
          {attrText}
        </M.GridCol>
        <M.GridCol md={5} sm={2}>
          {valueText}
        </M.GridCol>
      </M.GridRow>
    </M.Grid>
  );
};

Attribute.propTypes = {
  attr: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ]),
};

Attribute.defaultProps = {
  attr: '',
  value: '',
};

export default Attribute;
