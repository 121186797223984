import PropTypes from 'prop-types';
import React from 'react';
import { stripEmojis } from '../../../../lib/helpers';

import DocumentList from '../../../fields/DocumentList';
import CandidateStoryItemTitle from './CandidateStoryItemTitle';

const CandidateStorySummaryItem = ({ candidateStories, report, type }) => {
  const content = candidateStories.content?.[type] || '';
  const documents = candidateStories[type].documents || [];

  return (
    <div className='cans-item-wrapper'>
      <div className='form-question'>
        <CandidateStoryItemTitle record={report} />
      </div>
      <span>{stripEmojis(content)}</span>
      <div className='cans-doc-wrapper'>
        <DocumentList documents={documents} allowDocumentRemoval={false} />
      </div>
    </div>
  );
};

CandidateStorySummaryItem.propTypes = {
  candidateStories: PropTypes.object.isRequired,
  report: PropTypes.object,
  type: PropTypes.string.isRequired,
};

CandidateStorySummaryItem.defaultProps = {
  report: undefined,
};

export default CandidateStorySummaryItem;
