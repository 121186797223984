import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { formatFileType, formatFileSize } from './lib/uploadFormatters';

const FilePreview: React.FC<{
  url?: string | null;
  filename: string;
  mimetype: string;
  size: number;
  onRemove?: (fileName: string) => void;
}> = ({ url = '', filename, mimetype, size, onRemove = () => {} }) => {
  return (
    <div className='file-uploader--thumb'>
      <div className='file-uploader--thumb__image'>
        {url ? (
          <M.Icon icon='CheckmarkFilled' data-testid='doc-uploaded-icon' />
        ) : null}
      </div>
      <div className='file-uploader--thumb-description'>
        <p className='thumb-description--name'>{filename}</p>
        <p className='thumb-description--type'>
          {formatFileType(mimetype, i18n)} / {formatFileSize(size)}
        </p>
      </div>
      <M.Button
        className='file-uploader--thumb-remove'
        type='button'
        kind='tertiary'
        onClick={() => onRemove(filename)}
      >
        <span>{i18n.getStr('buttons.delete')}</span>
        <M.Icon icon='TrashCan' />
      </M.Button>
    </div>
  );
};

export default FilePreview;
