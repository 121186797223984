import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import clockIcon from 'images/icons/Clock.svg';
import { M } from '@dashboard-experience/mastodon';
import 'scss/RequestConfirmation.scss';

const RequestConfirmation = ({
  setHasSubmitted,
}: {
  setHasSubmitted: (param: boolean) => void;
}) => {
  return (
    <ContentContainer>
      <div className='center'>
        <h1 className='heading js-request-confirmation-heading'>
          {i18n.getStr('workflows.dispute.requestConfirmation.heading')}
        </h1>
        <img src={clockIcon} alt='clock' />
        <h3 className='mt-4 sub-heading'>
          {i18n.getStr('workflows.dispute.requestConfirmation.subHeading')}
        </h3>
        <div className='line' />
        <div className='instructions' role='note'>
          {i18n.renderHTML(
            'workflows.dispute.requestConfirmation.instructionsText',
          )}
        </div>
        <M.Button
          size='sm'
          kind='primary'
          className='mb-4 analytics_post_submission_continue_button'
          onClick={() => setHasSubmitted(false)}
        >
          {i18n.getStr('buttons.continue')}
        </M.Button>
      </div>
    </ContentContainer>
  );
};

export default RequestConfirmation;
