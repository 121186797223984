import { Client } from 'api/checkrMonolithApiClient';
import { AxiosResponse } from 'axios';

type FilePolicyType = { policy: string; signature: string };

const getFilePolicy = (
  filestackKey: string,
): Promise<AxiosResponse<FilePolicyType>> => {
  return Client.get(`/file_policy/${filestackKey}`);
};

export default getFilePolicy;
