import * as ActionTypes from '../actions';

export const initialState = {
  checkedAuth: false,
  processing: false,
  candidateEmailSubmitted: false,
  loginSubmitted: false,
  loggedIn: false,
  loggedOut: false,
  submitError: false,
  candidateEmail: '',
  candidateJwt: '',
  errorMessage: '',
  candidateIds: [],
};

const candidateLogin = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.CHECK_FOR_ACTIVE_AUTH:
      return {
        ...state,
        processing: true,
      };
    case ActionTypes.CHECK_FOR_ACTIVE_AUTH_COMPLETE:
      return {
        ...state,
        checkedAuth: true,
        candidateJwt: payload.candidateJwt,
        loggedIn: payload.loggedIn,
        candidateIds: payload.candidateIds,
        processing: false,
      };
    case ActionTypes.LOGOUT_USER_COMPLETE:
      window.sessionStorage.clear();
      return {
        ...state,
        checkedAuth: true,
        candidateEmail: '',
        candidateJwt: '',
        candidateIds: [],
      };
    case ActionTypes.SUBMIT_CANDIDATE_EMAIL_REQUEST:
      return {
        ...state,
        processing: true,
        candidateEmailSubmitted: true,
        candidateEmail: payload,
      };
    case ActionTypes.SUBMIT_CANDIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        processing: false,
        candidateEmailSubmitted: true,
      };
    case ActionTypes.SUBMIT_CANDIDATE_EMAIL_FAILURE:
      window.sessionStorage.clear();
      return {
        ...state,
        processing: false,
        candidateEmailSubmitted: false,
        submitError: true,
        errorMessage: 'errors.submitError',
      };
    case ActionTypes.SUBMIT_CANDIDATE_LOGIN_REQUEST:
      return {
        ...state,
        processing: true,
        candidateEmail: payload.candidateEmail,
      };
    case ActionTypes.SUBMIT_CANDIDATE_LOGIN_SUCCESS:
      return {
        ...state,
        loginSubmitted: true,
        processing: false,
        submitError: false,
        loggedIn: true,
        loggedOut: false,
        candidateEmail: payload.candidateEmail,
        candidateJwt: payload.candidateJwt,
        candidateIds: payload.candidateIds,
        errorMessage: '',
      };
    case ActionTypes.SUBMIT_CANDIDATE_LOGIN_FAILURE:
      window.sessionStorage.clear();
      return {
        ...state,
        processing: false,
        submitError: true,
        loggedIn: false,
        errorMessage: payload,
      };
    case ActionTypes.FETCH_REPORTS_FAILURE:
      window.sessionStorage.clear();
      return {
        ...state,
        errorMessage: 'errors.notFound',
      };
    case ActionTypes.FETCH_REPORTS_UNKNOWN_FAILURE:
      window.sessionStorage.clear();
      return {
        ...state,
        errorMessage: 'errors.unknownFailure',
      };
    case ActionTypes.CLEAR_FETCH_REPORTS_FAILURE:
      return {
        ...initialState,
      };
    case ActionTypes.LOGOUT_CANDIDATE:
      window.sessionStorage.clear();
      return {
        ...state,
        ...initialState,
      };
    case ActionTypes.CLEAR_LOGGED_OUT:
      return state;
    default:
      return state;
  }
};

export default candidateLogin;
