import React, { FC } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { Translate as T } from 'react-redux-i18n';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../Panels';
import { DrugScreening } from '../../../../types/report';
import { showDrugDispositionIndicator } from '../lib/utils';

interface Props {
  screening: DrugScreening;
}

const DrugV2HealthScreening: FC<Props> = ({
  screening: { analytes, status },
}) => (
  <Panel
    data-testid='health-screening-panel'
    title={i18n.getStr('components.healthScreening.drugScreening')}
    status={status}
    className='card-screening-results'
  >
    <div className='screening-results-category'>
      <div className='mb-4'>
        <strong>
          <T value='components.healthScreening.screeningResults' />
        </strong>
      </div>
      <div className='table-responsive'>
        <table className='table table-sm table-bottom-bordered'>
          <thead>
            <tr>
              <td className='text-very-muted' width='40%'>
                <T value='components.healthScreening.analytes' />
              </td>
              <td className='text-very-muted' width='60%'>
                <T value='components.healthScreening.result' />
              </td>
            </tr>
          </thead>
          <tbody>
            {analytes.map(({ disposition, name, slug, quantity }) => (
              <tr key={`tr-${slug}`}>
                <td>{name}</td>
                <td className='d-flex'>
                  {showDrugDispositionIndicator(disposition, quantity) && (
                    <span className='disposition-indicator text-danger'>
                      <M.Icon icon='CircleFilled' />{' '}
                    </span>
                  )}
                  <span className='disposition'>{disposition || quantity}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </Panel>
);

export default DrugV2HealthScreening;
