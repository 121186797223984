import PropTypes from 'prop-types';
import { i18n } from '@international/mastodon-i18n';

import { getChargeTitle } from '../lib/screenings';

const CandidateStoryItemTitle = ({ record }) => {
  const title = record
    ? getChargeTitle(record)
    : i18n.getStr('components.Report.CandidateStories.generalInformation');

  return title;
};

CandidateStoryItemTitle.propTypes = {
  record: PropTypes.object,
};

CandidateStoryItemTitle.defaultProps = {};

export default i18n.renderTranslation()(CandidateStoryItemTitle);
