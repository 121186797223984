import { getAPIBase } from '../api/verifications/getAPIBase';
import { request } from './helper';
import { BACKEND_FLAG_PATHS } from '../constants';

export const PII_DELETION_REQUEST = 'PII_DELETION_REQUEST';
export const PII_DELETION_SUCCESS = 'PII_DELETION_SUCCESS';
export const PII_DELETION_FAILURE = 'PII_DELETION_FAILURE';

export const piiDeletionRequest = () => ({
  type: PII_DELETION_REQUEST,
});
export const piiDeletionSuccess = () => ({
  type: PII_DELETION_SUCCESS,
});
export const piiDeletionFailure = error => ({
  type: PII_DELETION_FAILURE,
  error,
});

const createOptions = ({
  candidate_ids,
  contact_email,
  contact_name,
  international,
}) => {
  if (!contact_name) contact_name = contact_email;

  return {
    method: 'POST',
    body: JSON.stringify({
      candidate_ids,
      contact_email,
      contact_name,
      international,
    }),
  };
};

export const requestPiiDeletion = userInfo => {
  return dispatch => {
    dispatch(piiDeletionRequest());

    const options = createOptions(userInfo);

    return getAPIBase({ path: BACKEND_FLAG_PATHS.DELETE_PII }).then(baseURL =>
      request(`${baseURL}/privacy/delete-request/candidate`, options)
        .then(json => {
          dispatch(piiDeletionSuccess());
        })
        .catch(error => {
          dispatch(piiDeletionFailure(error));
        }),
    );
  };
};
