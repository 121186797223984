import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';

import { Report } from 'types/report';
import { DISPUTE_FORM_NAME } from '../../../../constants';
import {
  getArticles,
  getArticle,
  getSearches,
  getDisputeSections,
  requiresCharges,
  requiresField,
  isAutoDisputeScreening,
  getFieldOptions,
  requiresTicket,
  isInfoScreen,
  generateDisputeReason,
} from '../lib/screenings';
import { submitDispute as submitDisputeAction } from '../../../../actions';

import ArticlesForm from './ArticlesForm';
import ChargesForm from './ChargesForm';
import FieldsForm from './FieldsForm';
import SectionsForm from './SectionsForm';
import ArticleText from './ArticleText';
import ArticleTicket, { DisputeArticle } from './ArticleTicket';
import DisputeSummary from './DisputeSummary';
import Footer from './Footer';

import '../../../../scss/DisputeModal.scss';
import DescribeForm from './DescribeForm';
import DescribeInaccurateTextForm from './DescribeInaccurateTextForm';

interface Props {
  article: any;
  charge: string;
  disputedItems: any[];
  documents: any[];
  explanation: string;
  fields: any[];
  fieldSelectionComplete: boolean;
  processing: boolean;
  report: Report;
  section: string;
  showSummary: boolean;
  describeInaccurateComplete: boolean;
  describeField: string;
  submitDispute: (payload: any) => void;
  toggleCandidateStoryModal: () => void;
  toggleDisputeModal: () => void;
}

interface DisputeFormState {
  stateSet: boolean;
  useAutomaticDisputeModeForNonMvr: boolean;
}

class DisputeForm extends Component<
  Props & InjectedFormProps,
  DisputeFormState
> {
  constructor(props: Props & InjectedFormProps) {
    super(props);
    this.addItem = this.addItem.bind(this);
    this.addDescribe = this.addDescribe.bind(this);
    this.inaccurateContinue = this.inaccurateContinue.bind(this);
    this.completeFieldSelection = this.completeFieldSelection.bind(this);
    this.disputeAdditionalItem = this.disputeAdditionalItem.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.switchToCansModal = this.switchToCansModal.bind(this);
    this.toggleField = this.toggleField.bind(this);
    this.trackAction = this.trackAction.bind(this);
    this.viewSummary = this.viewSummary.bind(this);
    this.state = { stateSet: false, useAutomaticDisputeModeForNonMvr: false };
  }

  UNSAFE_componentWillMount() {
    const { change, report } = this.props;
    const sections = getDisputeSections(report);
    if (sections.length === 1) {
      change('section', sections[0].name);
    }
  }

  trackAction(action: string) {
    const { array } = this.props;
    array.push('userActions', action);
  }

  addItem() {
    const getDisputeReason = () => {
      const { describeField } = this.props;

      return describeField || generateDisputeReason(article, section);
    };

    const {
      array,
      change,
      section,
      article,
      charge,
      fields,
      explanation,
      documents,
    } = this.props;
    const item = {
      section,
      article,
      charge,
      fields,
      explanation,
      documents,
      reason: getDisputeReason(),
    };
    array.push('disputedItems', item);
    change('showSummary', true);
  }

  removeItem(i: number) {
    const { array, disputedItems } = this.props;

    array.remove('disputedItems', i);
    if (disputedItems.length === 1) {
      this.disputeAdditionalItem();
    }
  }

  disputeAdditionalItem() {
    const { change } = this.props;
    change('section', '');
    change('article', '');
    change('charge', '');
    change('fields', []);
    change('describeInaccurateComplete', false);
    change('describeField', '');
    change('fieldSelectionComplete', false);
    change('explanation', '');
    change('documents', []);
    change('showSummary', false);
  }

  handleBack() {
    const {
      article,
      charge,
      documents,
      explanation,
      fieldSelectionComplete,
      section,
      describeField,
      describeInaccurateComplete,
      change,
    } = this.props;

    this.trackAction('clicked_back');
    if (fieldSelectionComplete) {
      change('fieldSelectionComplete', false);
      change('fields', []);
    } else if (describeField) {
      change('describeField', '');
    } else if (describeInaccurateComplete) {
      change('describeInaccurateComplete', false);
    } else if (article) {
      change('article', '');
    } else if (charge) {
      change('charge', '');
    } else if (section) {
      change('section', '');
    }

    if (explanation || documents.length > 0) {
      change('explanation', '');
      change('documents', []);
    }
  }

  toggleField(field: string) {
    const { array, fields } = this.props;
    const i = fields.indexOf(field);

    if (i === -1) {
      array.push('fields', field);
    } else {
      array.remove('fields', i);
    }
  }

  completeFieldSelection() {
    const { change } = this.props;
    change('fieldSelectionComplete', true);
  }

  viewSummary() {
    const { change } = this.props;

    change('showSummary', true);
  }

  addDescribe(selectedDescribe: string) {
    const { change } = this.props;
    change('describeField', selectedDescribe);
  }

  inaccurateContinue() {
    const { change } = this.props;
    change('describeInaccurateComplete', true);
  }

  switchToCansModal() {
    const { toggleCandidateStoryModal, toggleDisputeModal } = this.props;
    toggleDisputeModal();
    toggleCandidateStoryModal();
  }

  render() {
    const { useAutomaticDisputeModeForNonMvr } = this.state;
    const {
      article,
      charge,
      documents,
      fields,
      describeInaccurateComplete,
      describeField,
      fieldSelectionComplete,
      report,
      section,
      disputedItems,
      showSummary,
      submitDispute,
      array: { remove },
      handleSubmit,
      toggleDisputeModal,
      processing,
      valid,
    } = this.props;

    const showDescribeInaccurate =
      useAutomaticDisputeModeForNonMvr &&
      isAutoDisputeScreening(section) &&
      !describeInaccurateComplete &&
      !describeField &&
      requiresField(section, article);

    const showDescribeFieldSelection =
      useAutomaticDisputeModeForNonMvr &&
      isAutoDisputeScreening(section) &&
      !describeField &&
      requiresTicket(section, article) &&
      !isInfoScreen(section, article);

    const formPages = [
      { name: 'summary', showIf: showSummary },
      { name: 'sectionSelect', showIf: !section },
      { name: 'chargeSelect', showIf: !charge && requiresCharges(section) },
      { name: 'articleSelect', showIf: !article },
      { name: 'describeInaccurate', showIf: showDescribeInaccurate },
      { name: 'describeFieldSelection', showIf: showDescribeFieldSelection },
      {
        name: 'fieldSelect',
        showIf: !fieldSelectionComplete && requiresField(section, article),
      },
      {
        name: 'articleMacro',
        showIf: !showSummary && !requiresTicket(section, article),
      },
      {
        name: 'articleForm',
        showIf: !showSummary && requiresTicket(section, article),
      },
    ];
    const currentPage = formPages.find(page => page.showIf)!.name;
    const fieldOptions = getFieldOptions(section);
    const PagesWithAutoDisputeFlagr = [
      'describeInaccurate',
      'describeFieldSelection',
      'articleSelect',
      'articleForm',
    ];

    if (PagesWithAutoDisputeFlagr.includes(currentPage)) {
      const { stateSet } = this.state;
      if (!stateSet) {
        this.setState(() => ({
          stateSet: true,
          useAutomaticDisputeModeForNonMvr: true,
        }));
      }
    }

    const formActions = {
      addItem: this.addItem,
      completeFieldSelection: this.completeFieldSelection,
      disputeAdditionalItem: this.disputeAdditionalItem,
      inaccurateContinue: this.inaccurateContinue,
      handleBack: this.handleBack,
      toggleDisputeModal,
      viewSummary: this.viewSummary,
    };

    const anyFieldsSelected = fields.length > 0;
    const articleData = getArticle(section, article) as DisputeArticle;
    const canContinueOnFooter = !articleData.hideFormControls;

    return (
      <form onSubmit={handleSubmit(submitDispute)}>
        <div className='p-4'>
          {currentPage === 'sectionSelect' && (
            <SectionsForm
              sections={getDisputeSections(report)}
              trackAction={this.trackAction}
            />
          )}

          {currentPage === 'chargeSelect' && (
            <ChargesForm
              section={section}
              searches={getSearches(report, [section])}
              trackAction={this.trackAction}
            />
          )}

          {currentPage === 'articleSelect' && (
            <ArticlesForm
              section={section}
              articles={getArticles(section)}
              trackAction={this.trackAction}
              switchToCansModal={this.switchToCansModal}
            />
          )}

          {currentPage === 'fieldSelect' && (
            <FieldsForm
              section={section}
              options={fieldOptions}
              fields={fields}
              toggleField={this.toggleField}
              trackAction={this.trackAction}
            />
          )}

          {currentPage === 'describeFieldSelection' && (
            <DescribeForm
              article={articleData}
              selectedField={this.addDescribe}
            />
          )}

          {currentPage === 'describeInaccurate' && (
            <DescribeInaccurateTextForm section={section} />
          )}

          {currentPage === 'articleForm' && (
            <ArticleTicket
              section={section}
              article={articleData}
              remove={remove}
              documents={documents}
              useAutomaticDisputeModeForNonMvr={
                useAutomaticDisputeModeForNonMvr &&
                isAutoDisputeScreening(section)
              }
            />
          )}

          {currentPage === 'articleMacro' && (
            <ArticleText section={section} article={articleData} />
          )}

          {currentPage === 'summary' && (
            <DisputeSummary
              disputedItems={disputedItems}
              removeItem={this.removeItem}
            />
          )}
        </div>

        <div className='tile-option-footer'>
          <Footer
            currentPage={currentPage}
            canContinue={canContinueOnFooter}
            hasItems={disputedItems.length > 0}
            actions={formActions}
            processing={processing}
            valid={valid}
            anyFieldsSelected={anyFieldsSelected}
          />
        </div>
      </form>
    );
  }

  static propTypes = {
    article: PropTypes.string.isRequired,
    charge: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired,
    disputedItems: PropTypes.array,
    documents: PropTypes.array.isRequired,
    explanation: PropTypes.string,
    fields: PropTypes.array.isRequired,
    fieldSelectionComplete: PropTypes.bool,
    report: PropTypes.object.isRequired,
    section: PropTypes.string.isRequired,
    submitDispute: PropTypes.func.isRequired,
    array: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleDisputeModal: PropTypes.func.isRequired,
    processing: PropTypes.bool.isRequired,
    showSummary: PropTypes.bool,
    describeInaccurateComplete: PropTypes.bool,
    describeField: PropTypes.string,
    valid: PropTypes.bool.isRequired,
    toggleCandidateStoryModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disputedItems: [],
    explanation: '',
    fieldSelectionComplete: false,
    showSummary: false,
    describeField: '',
    describeInaccurateComplete: false,
  };
}

const ReduxDisputeForm = reduxForm<any, any>({
  form: DISPUTE_FORM_NAME,
  shouldValidate: () => true,
})(DisputeForm);

const selector = formValueSelector(DISPUTE_FORM_NAME);

const mapStateToProps = (state: any, ownProps: Props) => {
  return {
    initialValues: {
      report: ownProps.report,
      userActions: [],
    },
    section: selector(state, 'section') || '',
    article: selector(state, 'article') || '',
    charge: selector(state, 'charge') || '',
    describeInaccurateComplete:
      selector(state, 'describeInaccurateComplete') || false,
    describeField: selector(state, 'describeField') || '',
    documents: selector(state, 'documents') || [],
    explanation: selector(state, 'explanation') || '',
    fields: selector(state, 'fields') || [],
    fieldSelectionComplete: selector(state, 'fieldSelectionComplete') || false,
    disputedItems: selector(state, 'disputedItems') || [],
    showSummary: selector(state, 'showSummary') || false,
    processing: state.portal.processing,
  };
};

export default connect<any, any, any>(mapStateToProps, {
  submitDispute: submitDisputeAction,
})(ReduxDisputeForm);
