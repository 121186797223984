import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import IconPanel from '../../../IconPanel';
import suspendedIcon from '../../../../images/icons/Suspended.svg';

const CanceledOnHoldNotice = ({ companyName }) => (
  <IconPanel
    translationKey='components.Report.CanceledOnHoldNotice'
    icon={
      <img
        src={suspendedIcon}
        alt={i18n.getStr(
          'components.Report.CanceledOnHoldNotice.reportCanceledOnHold',
        )}
      />
    }
    detailsCustomValue={{ companyName }}
    detailsCentered
  />
);

CanceledOnHoldNotice.propTypes = {
  companyName: PropTypes.string,
};

CanceledOnHoldNotice.defaultProps = {
  companyName: '',
};

export default i18n.renderTranslation()(CanceledOnHoldNotice);
