import React from 'react';

import UploadButton from '../fields/UploadButton';
import DocumentsPanel from './DocumentsPanel';
import Verification, { connectToVerifications } from './VerificationBase';

class PhotoVerification extends Verification {
  renderCustomPanel() {
    const { doc, isSmallOrDown, isValid, needUpload } =
      this.getRenderAttributes();

    return (
      needUpload && (
        <DocumentsPanel {...doc}>
          {isValid && isSmallOrDown && (
            <div className='card-footer'>
              <UploadButton
                name={doc.model}
                buttonClass='btn-block'
                kind={isValid ? 'secondary' : 'primary'}
                context='verifications'
              />
            </div>
          )}
        </DocumentsPanel>
      )
    );
  }
}

export default connectToVerifications(PhotoVerification);
