import React from 'react';
import { appBreakpoints } from 'reducers';
import styled from 'styled-components';

import { colors } from '@dashboard-experience/mastodon';
import headerButtonStyle from './headerButtonStyle';

const StyledDiv = styled.div`
  width: 100%;
  position: relative;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1;
  transition: transform 0.5s;
  transform-origin: 0 0;
  background-color: ${colors.navy03};
  padding-bottom: 9px;
  ul[role='menu'] {
    margin: 0;
    padding: 0;
    li[role='menuitem'] {
      display: block;
    }
  }
  .menu__items {
    width: 100%;
    .menu__item {
      a.header__menu-item {
        color: ${colors.slate03} !important;
      }
      .header__menu-item {
        display: block;
        cursor: pointer;
        width: 100%;
        text-align: left;
        max-width: 100%;
        background-color: ${colors.navy03};
        border: 0;
        border-right: 4px solid ${colors.navy03};
        padding: 13px 13px 13px 38px;
        color: ${colors.slate03};
        font-weight: 400;
        border-radius: 0;
        &:hover,
        &:focus {
          outline: none;
          background-color: rgba(0, 0, 0, 0.4);
        }
        svg {
          color: inherit;
          fill: currentColor;
          width: 16px;
          height: 16px;
          margin: 0;
        }
        svg + span,
        span + svg {
          margin-left: 8px;
        }
      }
    }
  }
  &.header__menu--open {
    transform: scale(100%, 100%);
    .menu__items {
      transition: opacity 0.2s 0.5s;
      opacity: 1;
    }
  }
  &.header__menu--closed {
    transform: scale(100%, 0);
    transition: transform 0.5s 0.2s;
    .menu__items {
      transition: opacity 0.2s;
      opacity: 0;
      visibility: hidden;
    }
  }
  @media (min-width: ${appBreakpoints.small}px) {
    position: static;
    width: auto;
    padding-bottom: 0;
    &.header__menu--open {
      transform: unset;
      transition: unset;
      .menu__items {
        transition: unset;
        opacity: unset;
        visibility: visible;
      }
    }
    &.header__menu--closed {
      transform: unset;
      transition: unset;
      .menu__items {
        transition: unset;
        opacity: unset;
        visibility: visible;
      }
    }
    .menu__items {
      display: flex;
      padding: 1rem 0;
      > .menu__item {
        a.header__menu-item {
          color: ${colors.white} !important;
        }
        .header__menu-item {
          ${headerButtonStyle}
          &:hover,
          &:focus {
            outline: none;
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
        & + .menu__item {
          margin-left: 16px;
        }
      }
      .language-toggle__options {
        padding-bottom: 14px;
      }
    }
  }
`;

interface HeaderMenuContainerProps {
  isOpen: boolean;
}

const HeaderMenuContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<HeaderMenuContainerProps>
>(({ isOpen, children }, ref) => (
  <StyledDiv
    className={`header__menu header__menu${isOpen ? '--open' : '--closed'}`}
    ref={ref}
  >
    {children}
  </StyledDiv>
));

HeaderMenuContainer.displayName = 'HeaderMenuContainer';

export default HeaderMenuContainer;
