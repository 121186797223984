/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// REFACTOR FOR LINT LATER
import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { getRandomKey } from 'lib/helpers';

import ChargesByRecordForm from './ChargesByRecordForm';

const ChargesForm = ({ section, searches, trackAction }) => {
  const renderSearches = () => {
    if (Array.isArray(searches)) {
      const considerSearches = searches.filter(
        search => search.status === 'consider',
      );
      return considerSearches.map((search, i) => (
        <ChargesByRecordForm
          key={getRandomKey()}
          search={search}
          trackAction={trackAction}
        />
      ));
    }
    return (
      searches.status === 'consider' && (
        <ChargesByRecordForm search={searches} trackAction={trackAction} />
      )
    );
  };

  return (
    <div data-testid='dispute-charges-form'>
      <div className='form-group'>
        <label>
          <span className='text-muted'>{section} &#62; </span>
          <span className='form-question'>
            <T value='components.changesForm.selectRecord' />
          </span>
        </label>
        <hr />
      </div>
      <div className='form-group'>{renderSearches()}</div>
    </div>
  );
};

ChargesForm.propTypes = {
  section: PropTypes.string,
  searches: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  trackAction: PropTypes.func,
};

ChargesForm.defaultProps = {
  section: '',
  searches: [],
  trackAction: () => {},
};

export default ChargesForm;
