import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import 'scss/ProvideInformationForm.scss';
import { DisputeReasonSummaryMap } from 'components/Workflow/Disputes/Types';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import { UpdateDraft } from 'components/Workflow/hooks';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import ProvideInformationAccordionSection from 'components/Workflow/Disputes/MotorVehicleReport/ProvideInformationAccordionSection';

const ProvideInformationMultiForm = ({
  continueNext,
  back,
  draftDisputeReasonSummaries,
  updateDraft,
}: {
  continueNext: () => void;
  back: () => void;
  draftDisputeReasonSummaries: DisputeReasonSummaryMap;
  updateDraft: UpdateDraft;
}) => {
  const continueButtonEnabled = () => {
    const { licenseInformation, restrictions, endorsements } =
      draftDisputeReasonSummaries;
    const licenseInfoCheck =
      !licenseInformation ||
      (licenseInformation && licenseInformation.explanation);
    const restrictionsCheck =
      !restrictions || (restrictions && restrictions.explanation);
    const endorsementsCheck =
      !endorsements || (endorsements && endorsements.explanation);

    return licenseInfoCheck && restrictionsCheck && endorsementsCheck;
  };

  const continueButton = () => {
    return (
      <M.Button
        data-testid={DISPUTES_FLOW.disputeFooterContinueButton}
        className='analytics_provide_information_continue_button'
        disabled={!continueButtonEnabled()}
        aria-disabled={!continueButtonEnabled()}
        size='sm'
        kind='primary'
        onClick={continueNext}
      >
        {i18n.getStr('buttons.continue')}
      </M.Button>
    );
  };

  return (
    <div>
      <ContentContainer>
        <M.Form
          aria-label={i18n.getStr(
            'workflows.dispute.provideInformationForm.instructionsText',
          )}
        >
          <div role='note' id='provide-information-instructions-note'>
            {i18n.renderHTML(
              'workflows.dispute.provideInformationForm.instructionsText',
            )}
          </div>
          {draftDisputeReasonSummaries.licenseInformation && (
            <ProvideInformationAccordionSection
              disputeReasonSummary={
                draftDisputeReasonSummaries.licenseInformation
              }
              updateDraft={updateDraft}
            />
          )}
          {draftDisputeReasonSummaries.restrictions && (
            <ProvideInformationAccordionSection
              disputeReasonSummary={draftDisputeReasonSummaries.restrictions}
              updateDraft={updateDraft}
            />
          )}
          {draftDisputeReasonSummaries.endorsements && (
            <ProvideInformationAccordionSection
              disputeReasonSummary={draftDisputeReasonSummaries.endorsements}
              updateDraft={updateDraft}
            />
          )}
        </M.Form>
      </ContentContainer>
      <NavigationFooter
        back={back}
        backButtonClassName='analytics_provide_information_back_button'
        rightButtonContent={continueButton()}
      />
    </div>
  );
};

export default ProvideInformationMultiForm;
