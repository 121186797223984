import { fetchFlag } from '@dashboard-experience/react-flagr';
import { FLAGR_URL, ENV } from '../../constants';
import setFlagrResponse, { UseFlagrResponse } from './lib/setFlagrResponse';

export const fetchFlagr = async (
  flagKey: string,
  context: Record<string, any> = {},
  entity: Record<string, any> = {},
): Promise<UseFlagrResponse> => {
  const type = entity.type || 'account';
  const id = entity.id || 'env';
  const flag = await fetchFlag(
    flagKey,
    {
      type,
      id,
      context: {
        env: ENV,
        ...context,
      },
    },
    { url: FLAGR_URL },
  );
  return setFlagrResponse({ context, entity, data: flag });
};
