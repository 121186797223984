import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';

const CandidateInput = ({ school }) => (
  <div className='table-responsive'>
    <table className='table table-sm table-bottom-bordered'>
      <thead>
        <tr>
          <td className='column-6'>
            <b>
              <T value='components.education.candidateInput' />
            </b>
          </td>
          <td className='column-6' />
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className='text-very-muted column-6'>
            <T value='components.education.degree' />
          </td>
          <td className='column-6'>{school.degree || '-'}</td>
        </tr>
        <tr>
          <td className='text-very-muted column-6'>
            <T value='components.education.major' />
          </td>
          <td className='column-6'>{school.major || '-'}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

CandidateInput.propTypes = {
  school: PropTypes.object.isRequired,
};

export default CandidateInput;
