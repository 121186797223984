import PropTypes from 'prop-types';
import React from 'react';
import DOMPurify from 'isomorphic-dompurify';

const handleLinkClick = event => {
  if (event.target.tagName === 'A' && event.target.href) {
    event.target.setAttribute('target', '_blank');
  }
};

const HTMLText = ({ content }) => (
  <span
    className='custom-text'
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
    onClick={handleLinkClick}
    role='link'
    tabIndex={0}
  />
);

HTMLText.propTypes = {
  content: PropTypes.string,
};

HTMLText.defaultProps = {
  content: '',
};

export default HTMLText;
