import { camelizeKeys } from 'humps';
import { request } from './helper';
import { CHECKR_VERIFICATIONS_API_BASE } from '../constants';

/*
 * Action Types
 */

export const UPDATE_LOCATIONS_REQUEST = 'UPDATE_LOCATIONS_REQUEST';
export const UPDATE_LOCATIONS_SUCCESS = 'UPDATE_LOCATIONS_SUCCESS';

/*
 * Actions
 */
export const updateLocationsRequest = verification => ({
  type: UPDATE_LOCATIONS_REQUEST,
  verification,
});
export const updateLocationsSuccess = verification => ({
  type: UPDATE_LOCATIONS_SUCCESS,
  verification,
});

export const fetchLocations = (verificationId, zipcode) => {
  let path = `${CHECKR_VERIFICATIONS_API_BASE}/schedule_verifications/${verificationId}/locations`;
  if (zipcode) {
    path += `?zipcode=${zipcode}`;
  }
  return request(path);
};

export const updateLocations = (zipcode, verification) => dispatch => {
  dispatch(updateLocationsRequest(verification));

  return fetchLocations(verification.id, zipcode).then(locations => {
    if (locations.data.length > 3) {
      locations.data.length = 3;
    }

    locations = camelizeKeys(locations);

    const newVerification = {
      ...verification,

      locations: locations.data,
    };

    dispatch(updateLocationsSuccess(newVerification));
  });
};
