import React from 'react';

import PropTypes from 'prop-types';

import Panel from '../Panels';
import { StyledHelpfulToKnow, StyledFaq } from './VerificationFooter';

const MatrixVerificationAdditionalInfo = ({ additionalInfoKey }) => {
  return (
    <>
      <Panel>
        <StyledHelpfulToKnow
          translationKey={`${additionalInfoKey}.helpfulToKnow`}
        />
        <hr />
        <StyledFaq translationKey={`${additionalInfoKey}.faq`} />
      </Panel>
    </>
  );
};

MatrixVerificationAdditionalInfo.propTypes = {
  additionalInfoKey: PropTypes.string.isRequired,
};

export default MatrixVerificationAdditionalInfo;
