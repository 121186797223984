import React from 'react';
import PropTypes from 'prop-types';
import { M, colors } from '@dashboard-experience/mastodon';

import '../../scss/DocumentList.scss';

const DocumentList = ({ documents, onRemove, allowDocumentRemoval }) => (
  <div>
    {documents.map((doc, i) => (
      <div
        key={`document-${i + 1}`}
        className='file-uploaded d-flex align-items-center'
      >
        <div className='p-3 pl-4'>
          <M.Icon
            icon='CheckmarkFilled'
            style={{ fill: colors.successGreen100 }}
            data-testid='doc-uploaded-icon'
          />
        </div>
        <div className='p-3 file-name'>
          {doc.filename}
          <br />
          <small className='file-type'>
            {doc.mimetype} {parseInt(doc.size / 1024, 10)}KB{' '}
          </small>
        </div>
        {allowDocumentRemoval && (
          <div
            className='text-muted remove-doc lead ml-auto p-3 pr-4'
            onClick={() => onRemove(i)}
            role='button'
            tabIndex={0}
          >
            <M.Icon icon='Add' />
          </div>
        )}
      </div>
    ))}
  </div>
);

DocumentList.propTypes = {
  documents: PropTypes.array.isRequired,
  onRemove: PropTypes.func,
  allowDocumentRemoval: PropTypes.bool,
};

DocumentList.defaultProps = {
  allowDocumentRemoval: true,
  onRemove: () => {},
};

export default DocumentList;
