import React, { useState } from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import ContentContainer from 'components/Workflow/ContentContainer';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import styled from 'styled-components';
import { Translate as T } from 'react-redux-i18n';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import { slashDatePattern } from 'lib/dateFormat';

export const ContainerStyled = styled.div`
  #contact-dmv {
    background-color: #fefae2;
    border: 2px solid #fad232;
    border-radius: 5px;
    padding: 10px;
  }
  #reporting-disclaimer {
    margin-top: 25px;
  }
  #dmv-radios {
    margin: 15px;
    margin-left: 5px;
  }
`;

export const DatePickerInputStyled = styled.div`
   {
    margin-top: 16px;
  }
`;

export const HrStyled = styled.div`
   {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export interface ProvideInformationVisitedDMVProps {
  continueNext: () => void;
  back: () => void;
  passVisitedDmvDate: (value: string) => void;
  passConfirmVisitedDmv: (value: boolean) => void;
  getVisitedDmvDate: () => string;
}

const ProvideInformationVisitedDMV = ({
  continueNext,
  back,
  passVisitedDmvDate,
  passConfirmVisitedDmv,
  getVisitedDmvDate,
}: ProvideInformationVisitedDMVProps) => {
  const [wentToDmv, setWentToDmv] = useState<boolean | null>(null);

  const handleCheckYes = () => {
    setWentToDmv(true);
    passVisitedDmvDate('');
    passConfirmVisitedDmv(true);
  };

  const handleCheckNo = () => {
    setWentToDmv(false);
    passConfirmVisitedDmv(false);
    passVisitedDmvDate('');
  };

  const datePickerOnChange = (event: { target: HTMLInputElement }) => {
    const date = new Date(event.toString());
    const today = new Date(Date.now());
    if (date <= today) {
      passVisitedDmvDate('');
      return passVisitedDmvDate(date.toLocaleDateString());
    }
    return passVisitedDmvDate('');
  };

  const continueButtonDisabled = () => getVisitedDmvDate() === '' && wentToDmv;

  const continueButton = () => {
    return (
      <M.Button
        data-testid={DISPUTES_FLOW.continue}
        className='analytics_provide_information_continue_button'
        disabled={continueButtonDisabled()}
        aria-disabled={continueButtonDisabled()}
        size='sm'
        kind='primary'
        onClick={continueNext}
      >
        <T value='buttons.continue' />
      </M.Button>
    );
  };

  const toggleSuggestContactDMV = () => {
    return (
      <div id='contact-dmv'>
        <h3 data-testid='contact-dmv'>
          {i18n.getStr('workflows.dispute.confirmVisitedDMV.contactDmv')}
        </h3>
        <p id='reporting-disclaimer'>
          {i18n.getStr(
            'workflows.dispute.confirmVisitedDMV.dmvReportingDisclaimer',
          )}
        </p>
      </div>
    );
  };

  const toggleDatePicker = () => {
    return (
      <>
        <HrStyled>
          <hr />
        </HrStyled>
        <p>
          {i18n.getStr(
            'workflows.dispute.confirmVisitedDMV.dmvProcessDisclaimer',
          )}
        </p>
        <M.DatePicker
          id='date-picker'
          onChange={datePickerOnChange}
          datePickerType='single'
          maxDate={new Date(Date.now())}
          data-testid='datepicker'
        >
          <DatePickerInputStyled>
            <M.DatePickerInput
              id='date-picker-input-id-end'
              placeholder={slashDatePattern()}
              dateFormat={slashDatePattern()}
              value={getVisitedDmvDate()}
            />
          </DatePickerInputStyled>
        </M.DatePicker>
      </>
    );
  };

  return (
    <ContainerStyled>
      <ContentContainer>
        <p data-testid='subject'>
          {i18n.getStr(
            'workflows.dispute.confirmVisitedDMV.provideInformationSubject',
          )}
        </p>
        <div id='dmv-radios'>
          <M.Container.Row>
            <M.RadioButtonGroup>
              <M.Container.Col lg={8} sm={2} md={4}>
                <M.RadioButton
                  labelText='Yes'
                  onClick={handleCheckYes}
                  checked={wentToDmv}
                  data-testid={DISPUTES_FLOW.radioButtonYes}
                  value='visitedDmv'
                />
              </M.Container.Col>
              <M.Container.Col lg={8} sm={2} md={4}>
                <M.RadioButton
                  labelText='No'
                  onClick={handleCheckNo}
                  checked={!wentToDmv && wentToDmv !== null}
                  data-testid={DISPUTES_FLOW.radioButtonNo}
                  value='!visitedDmv'
                />
              </M.Container.Col>
            </M.RadioButtonGroup>
          </M.Container.Row>
        </div>
        {!wentToDmv && wentToDmv !== null && toggleSuggestContactDMV()}
        {wentToDmv && toggleDatePicker()}
      </ContentContainer>
      <NavigationFooter back={back} rightButtonContent={continueButton()} />
    </ContainerStyled>
  );
};

export default ProvideInformationVisitedDMV;
