import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import UpdatePersonalInfoWorkflow from './UpdatePersonalInfoWorkflow';
import { Report } from '../../../../types/report';

const UpdatePersonalInfoModal = ({
  isOpen,
  report,
  toggle,
}: {
  isOpen: boolean;
  report: Report;
  toggle: () => void;
}) => (
  <M.ComposedModal open={isOpen} data-testid='update-personal-info-modal'>
    <M.ModalHeader buttonOnClick={toggle}>
      {i18n.getStr('components.Report.PersonalInfo.Modal.title')}
    </M.ModalHeader>
    <M.ModalBody>
      <UpdatePersonalInfoWorkflow report={report} toggle={toggle} />
    </M.ModalBody>
  </M.ComposedModal>
);

export default UpdatePersonalInfoModal;
