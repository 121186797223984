import React, { useEffect, useRef } from 'react';
import './BannerTitle.scss';

export default function BannerTitle({
  bannerTitle,
  handleDetailsClick,
}: {
  bannerTitle: React.ReactNode;
  handleDetailsClick: () => void;
}) {
  const containerRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    const containerElement = containerRef.current;

    if (!containerElement) return;

    // Function to handle clicks on the span with id 'checkr-pro-text'
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target && target.id === 'checkr-pro-text') {
        handleDetailsClick();
      }
    };

    // Add event listener to the container ref where the dangerous HTML is rendered
    containerElement.addEventListener('click', handleClick);

    // Cleanup listener upon component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      containerElement.removeEventListener('click', handleClick);
    };
  }, [handleDetailsClick]);

  return (
    <h1
      id='personal-checks-banner-header-title'
      style={{ lineHeight: '2.5rem' }}
      ref={containerRef}
    >
      {bannerTitle}
    </h1>
  );
}
