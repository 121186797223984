export const initialState = {
  values: {},
};

const verification = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    default:
      return state;
  }
};

export default verification;
