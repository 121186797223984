import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Panel from '../../../../Panels';
import { computeSearchesStatus } from '../../lib/status';
import Record from '../../Record';

const IntlCriminalSearchesV2 = ({ searches }) => {
  if (!searches || !searches.length) {
    return null;
  }

  return (
    <Panel
      data-testid='intl-criminal-searches-v2'
      title={i18n.getStr('components.internationalCriminalSearch')}
      status={computeSearchesStatus(searches)}
    >
      {searches.map((record, i) => (
        <React.Fragment key={`intl-crim-search-${i + 1}`}>
          <Record
            record={record}
            type='internationalCriminalSearchV2'
            dataTestId={`international-criminal-search-v2-${i + 1}`}
          />
          {i < searches.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </Panel>
  );
};

IntlCriminalSearchesV2.propTypes = {
  searches: PropTypes.array,
};

IntlCriminalSearchesV2.defaultProps = {
  searches: [],
};

export default IntlCriminalSearchesV2;
