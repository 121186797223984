import { datadogRum, RumEvent } from '@datadog/browser-rum';
import { findEnvVar } from '@dashboard-experience/utils';
import { datadogLogs } from '@datadog/browser-logs';

const DD_RUM_CLIENT_TOKEN = findEnvVar('DD_RUM_CLIENT_TOKEN');
const DD_RUM_APPLICATION_ID = findEnvVar('DD_RUM_APPLICATION_ID');
const DD_VERSION = findEnvVar('DD_VERSION');
const ENV = findEnvVar('ENV');

const sanitizeUrlToken = (url: string) =>
  url.replace(/token=[^&]*/g, 'token=REDACTED');
// initialize datadog RUM for production environments
// (Assumes token and app ID are only defined for the appropriate environments)
const initializeDataDog = () => {
  if (DD_RUM_CLIENT_TOKEN && DD_RUM_APPLICATION_ID) {
    datadogRum.init({
      clientToken: DD_RUM_CLIENT_TOKEN,
      applicationId: DD_RUM_APPLICATION_ID,
      service: 'candidate-portal',
      env: ENV,
      sessionSampleRate: 15,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      version: DD_VERSION,
      beforeSend(event: RumEvent) {
        // Sanitize token from dashboard admin in the url
        event.view.url = sanitizeUrlToken(event.view.url);
        if (event.type === 'resource' && event.resource.url)
          event.resource.url = sanitizeUrlToken(event.resource.url);
        return true;
      },
    });
    datadogRum.startSessionReplayRecording();
    // for RUM, try to mark page views which render while inactive / not visible / hidden
    window.addEventListener('load', () => {
      datadogRum.setGlobalContextProperty('view', {
        // old/MS browsers may not support document.hidden.  If it's not supported, then we don't know
        // for sure the tab was or wasn't hidden, so consider it to have been hidden.
        window_hidden: document.hidden === undefined || document.hidden,
      });
    });

    datadogLogs.init({
      clientToken: DD_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'candidate-portal',
      env: ENV,
      version: DD_VERSION,
      forwardErrorsToLogs: false,
    });
  }
};

export default initializeDataDog;
