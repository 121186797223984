import { AxiosError, AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import { QueryKey, useQuery } from '@tanstack/react-query';
import { FetchReportParams, ReportReponse } from 'api/verifications/types';
import { fetchReport } from '../verifications/client';
import setReportStatusfromResult from './lib/setReportStatusFromResult';

export const useReport = ({ id, token }: FetchReportParams) => {
  const key: QueryKey = ['candidate/reports', { id }];

  const request = () =>
    fetchReport({
      id,
      token,
    });

  const { isLoading, isError, data, error } = useQuery<
    AxiosResponse<ReportReponse>,
    AxiosError
  >({
    queryKey: key,
    queryFn: request,
    ...{
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(token),
    },
  });
  const report = data?.data?.report
    ? setReportStatusfromResult(
        camelizeKeys(data.data.report) as Record<string, any>,
      )
    : null;

  return {
    report,
    isLoading,
    isError,
    error,
  };
};
