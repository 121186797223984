import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import Panel from '../../Panels';
import Attribute from './Attribute';

const renderComment = (comment, i) => (
  <div key={i}>
    <Attribute
      attr={i18n.getStr('components.statementDispute.statement')}
      value={comment.comment}
    />
    <Attribute
      attr={i18n.getStr('components.statementDispute.date')}
      value={comment.updatedAt}
    />
    <hr />
  </div>
);

const renderComments = comments => comments.map(renderComment);

const StatementOfDispute = ({ comments }) => {
  if (!comments || comments.length <= 0) {
    return null;
  }

  return (
    <Panel
      data-testid='statement-of-dispute'
      className='card-plain card-border'
      title={i18n.getStr('components.statementDispute.statementOfDispute')}
    >
      {renderComments(comments)}
    </Panel>
  );
};

StatementOfDispute.propTypes = {
  comments: PropTypes.array,
};

StatementOfDispute.defaultProps = {
  comments: [],
};

export default i18n.renderTranslation()(StatementOfDispute);
