import React from 'react';
import { Translate as T } from 'react-redux-i18n';

import { dateIfDate } from '../lib/formatting';
import Document from './Document';

interface CandidateStoryProps {
  story: {
    record?: {
      charge: string;
      location: string;
      offenseDate: Date;
    };
    content: string;
    documents: {
      name: string;
      url: string;
    }[];
  };
}

const CandidateStory = ({ story }: CandidateStoryProps) => (
  <div
    className='candidate-story mb-3'
    data-testid='candidate-stories-general-information'
  >
    <h5 className='d-flex mb-2 candidate-story-title'>
      <strong className='text-uppercase'>
        {story.record ? (
          story.record.charge
        ) : (
          <T value='components.Report.CandidateStories.generalInformation' />
        )}
      </strong>
      {story.record && (
        <small className='ml-auto'>
          {story.record.location}
          {story.record.offenseDate && (
            <span>
              &nbsp;&nbsp;•&nbsp;&nbsp;
              {dateIfDate(story.record.offenseDate)}
            </span>
          )}
        </small>
      )}
    </h5>

    <blockquote>{story.content}</blockquote>

    {!!story.documents.length && (
      <div className='mb-4 mt-4'>
        {story.documents.map(document => (
          <Document
            document={document}
            key={`${document.name} - ${document.url}`}
          />
        ))}
      </div>
    )}
  </div>
);

export default CandidateStory;
