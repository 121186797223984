import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';

export interface ConfirmPreviouslyDisputedReportProps {
  isOpen: boolean;
  continueFlow: () => void;
  toggle: () => void;
  email: string;
  createdAt: string;
  confirmNewInformation: (arg0: boolean) => void;
}

const ConfirmPreviouslyDisputedReport = ({
  continueFlow,
  isOpen,
  toggle,
  email,
  createdAt,
  confirmNewInformation,
}: ConfirmPreviouslyDisputedReportProps) => {
  const handleCancel = () => {
    toggle();
    confirmNewInformation(false);
  };

  const handleContinue = () => {
    toggle();
    continueFlow();
    confirmNewInformation(true);
  };

  return (
    <M.ComposedModal
      open={isOpen}
      data-testid='confirm-previously-disputed-modal'
    >
      <M.ModalHeader buttonOnClick={handleCancel}>
        {i18n.getStr('disputes.previouslyDisputedAt.title')}
      </M.ModalHeader>
      <M.ModalBody>
        <p>
          {i18n.getStr('disputes.previouslyDisputedAt.textBlock1', [
            createdAt,
            email,
          ])}
        </p>
        <p>{i18n.getStr('disputes.previouslyDisputedAt.textBlock2')}</p>
        <p>{i18n.getStr('disputes.previouslyDisputedAt.textBlock3')}</p>
      </M.ModalBody>
      <M.ModalNavigationFooter
        size='lg'
        close={{
          name: i18n.getStr('disputes.previouslyDisputedAt.cancelButton'),
          onClick: handleCancel,
        }}
        next={{
          name: i18n.getStr('disputes.previouslyDisputedAt.continueButton'),
          onClick: handleContinue,
        }}
      />
    </M.ComposedModal>
  );
};

export default ConfirmPreviouslyDisputedReport;
