import moment from 'moment';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import { M } from '@dashboard-experience/mastodon';
import styled from 'styled-components';
import { shortDatePattern } from 'lib/dateFormat';
import UpdatePersonalInfoModal from './UpdatePersonalInfoModal';
import { Report } from '../../../../types/report';

const ButtonContainerStyled = styled(M.GridCol)`
  display: flex !important;
  align-items: center;
  justify-content: end;

  @media (max-width: 767px) {
    justify-content: center;
    padding-top: 10px;
  }
`;

const expirationDate = ({ completedAt }: { completedAt: string }): string => {
  const expiration = moment(completedAt).add(30, 'days');

  return expiration.format(shortDatePattern());
};

interface UpdatePersonalInfoWorkflowCardProps {
  report: Report;
  isDialogOpen: boolean;
  toggleShowModal: () => void;
}

const UpdatePersonalInfoWorkflowCard = ({
  report,
  isDialogOpen,
  toggleShowModal,
}: UpdatePersonalInfoWorkflowCardProps) => {
  return (
    <div>
      <M.Container
        aria-label='update-personal-info-card'
        data-testid='update-personal-info-card'
      >
        <M.Grid fullWidth>
          <M.GridRow>
            <M.GridCol sm={4} md={4} lg={8}>
              <p id='update-personal-info-card-title'>
                <strong>
                  {i18n.getStr('components.Report.PersonalInfo.CTA.title')}
                </strong>
              </p>
              <p>
                {i18n.renderHTML(
                  'components.Report.PersonalInfo.CTA.dlUpdateExpirationText',
                  expirationDate(report),
                )}
              </p>
            </M.GridCol>
            <ButtonContainerStyled sm={4} md={4} lg={8}>
              <M.Button type='button' onClick={toggleShowModal}>
                {i18n.getStr(
                  'components.Report.PersonalInfo.CTA.updatePersonalInfo',
                )}
              </M.Button>
            </ButtonContainerStyled>
          </M.GridRow>
        </M.Grid>
      </M.Container>
      <UpdatePersonalInfoModal
        isOpen={isDialogOpen}
        report={report}
        toggle={toggleShowModal}
      />
    </div>
  );
};

export default UpdatePersonalInfoWorkflowCard;
