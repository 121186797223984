import React, { ReactNode } from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import { TitledContainer } from './TitledContainer';
import { Heading, Alignment as HeadingAlignment } from './Heading';
import { Paragraph } from './Paragraph';
import { BulletedItem } from './BulletedItem';
import { Icon } from './Icon';
import Typography2024Hack from './Typography2024Hack';

interface StartedYourBackgroundCheckProps {
  greeting: ReactNode;
}

export const StartedYourBackgroundCheck: React.FC<
  StartedYourBackgroundCheckProps
> = ({ greeting }) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
        padding: '24px 16px 56px 16px',
        border: 'none',
      }}
    >
      {greeting}
      <StyleableContainer type={Type.Success} styledTopBorder>
        <Icon type={Type.Success} />
        <Heading
          textKey='components.InstantExceptions.StartedYourBackgroundCheck.started'
          align={HeadingAlignment.Center}
        />
        <Paragraph
          textKey='components.InstantExceptions.StartedYourBackgroundCheck.detail'
          align={HeadingAlignment.Center}
        >
          <strong>
            {i18n.getStr(
              'components.InstantExceptions.StartedYourBackgroundCheck.number',
            )}
          </strong>
          {i18n.getStr(
            'components.InstantExceptions.StartedYourBackgroundCheck.days',
          )}
        </Paragraph>
        <M.Divider style={{ width: '100%', margin: '8px 0px' }} />
        <TitledContainer titleKey='components.InstantExceptions.StartedYourBackgroundCheck.whatNext'>
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.StartedYourBackgroundCheck.emailYouStatusUpdates'
          />
          <BulletedItem
            arrowIcon
            text='components.InstantExceptions.StartedYourBackgroundCheck.logIn'
          >
            <a href='https://candidate.checkr.com'>
              {i18n.getStr('components.Header.title')}
            </a>
            <span
              style={{
                ...Typography2024Hack.textIconBlackSecondary78,
                ...Typography2024Hack.p3,
              }}
            >
              {i18n.getStr(
                'components.InstantExceptions.StartedYourBackgroundCheck.toTrack',
              )}
            </span>
          </BulletedItem>
        </TitledContainer>
      </StyleableContainer>
    </M.Container>
  );
};
