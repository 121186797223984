import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, isValid as reduxFormIsValid } from 'redux-form';
import { Upload } from '.';
import { required1Document } from '../../lib/validations';
import {
  CANDIDATE_STORY_FORM_NAME,
  DISPUTE_FORM_NAME,
  MAX_FILE_SIZE,
  VERIFICATION_FORM_NAME,
} from '../../constants';

const documentsOptional = context => {
  return [
    'disputes',
    'comments',
    'candidate-stories',
    'candidate-explanation',
  ].includes(context);
};

const UploadButton = ({
  buttonClass,
  isValid,
  name,
  context,
  hideIcon,
  buttonText,
  fileOptions,
  disabled,
  kind,
}) => {
  const buttonOptions = {};

  if (documentsOptional(context)) {
    buttonOptions.buttonText = 'buttons.addDocument';
    buttonOptions.validate = [];
  } else {
    buttonOptions.buttonText = isValid
      ? 'buttons.uploadMore'
      : 'buttons.uploadNow';
    buttonOptions.validate = [required1Document];
  }

  if (buttonText) {
    buttonOptions.buttonText = buttonText;
  }

  return (
    <Field
      type='upload'
      name={name}
      icon={hideIcon ? null : 'CloudUpload'}
      buttonClass={buttonClass}
      buttonText={buttonOptions.buttonText}
      component={Upload}
      kind={kind}
      options={{ maxSize: MAX_FILE_SIZE, ...fileOptions }}
      validate={buttonOptions.validate}
      disabled={disabled}
    />
  );
};

UploadButton.propTypes = {
  buttonClass: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  context: PropTypes.oneOf([
    'verifications',
    'candidate-stories',
    'disputes',
    'comments',
    'candidate-explanation',
  ]).isRequired,
  hideIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  fileOptions: PropTypes.object,
  disabled: PropTypes.bool,
  kind: PropTypes.string,
};

UploadButton.defaultProps = {
  hideIcon: false,
  buttonText: '',
  fileOptions: {},
  disabled: false,
  kind: 'primary',
};

const mapStateToProps = (state, ownProps) => {
  let formName;
  if (
    ownProps.context === 'verifications' ||
    ownProps.context === 'candidate-explanation'
  ) {
    formName = VERIFICATION_FORM_NAME;
  } else if (ownProps.context === 'candidate-stories') {
    formName = CANDIDATE_STORY_FORM_NAME;
  } else {
    formName = DISPUTE_FORM_NAME;
  }

  return { isValid: reduxFormIsValid(formName)(state) };
};

export default connect(mapStateToProps)(UploadButton);
