import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

import './CardLabel.scss';

export const CardLabel: React.FC<{
  status: string;
  statusClass: string;
}> = ({ status, statusClass }) => {
  const statusLabelKey = status;
  return (
    <>
      {statusLabelKey ? (
        <M.Tag className={`report-status ${statusClass}`}>
          {i18n.getStr(
            `components.CandidateLandingPage.card.label.${statusLabelKey}`,
          )}
        </M.Tag>
      ) : null}
      <M.Icon icon='ChevronRight' />
    </>
  );
};
