import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';

type SimplePanelProps = {
  children: React.ReactNode;
};

const StyledDiv = styled.div`
  padding: 11px;
  font-size: 1rem;
`;

const SimplePanel: React.FC<SimplePanelProps> = ({ children }) => {
  return (
    <M.Container>
      <StyledDiv>{children}</StyledDiv>
    </M.Container>
  );
};

export default SimplePanel;
