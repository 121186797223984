import React from 'react';
import { i18n } from '@international/mastodon-i18n';

import LoginForm from 'components/Reports/LoginForm';
import MainContainer from 'components/MainContainer';
import { fetchCandidateReports, clearLoggedOut } from 'actions';
import ToastNotification from 'components/ToastNotification';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

const PrivacyLogin: React.FC = () => {
  const { loggedOut, processing, error, loggedIn } = useSelector(
    (state: any) => state.portal,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  React.useLayoutEffect(() => {
    if (loggedIn) {
      navigate(state?.prevLocation || '/privacy/access');
    } else if (error) {
      navigate('#login_error', { state });
    }
  }, [loggedIn, navigate, error, state]);
  const handleSubmit = React.useCallback(
    (values: any) => {
      dispatch(fetchCandidateReports(values));
    },
    [dispatch],
  );
  return (
    <MainContainer>
      {loggedOut && (
        <ToastNotification
          title={i18n.getStr('components.successFullyLoggedOut')}
          kind='success'
          onClose={() => {
            clearLoggedOut();
          }}
        />
      )}
      <LoginForm
        onSubmit={handleSubmit}
        processing={processing}
        fetchError={error}
        loginSource='Privacy'
      />
    </MainContainer>
  );
};

export default PrivacyLogin;
