import PropTypes from 'prop-types';
import React from 'react';

import Attribute from '../Attribute';

const CHARGE_ATTRS = Object.freeze(['charge', 'chargeCode']);

const ArrestCharge = ({ charge }) => (
  <div className='arrest-charge'>
    {CHARGE_ATTRS.map((key, i) => (
      <Attribute key={`attribute-${i + 1}`} attr={key} value={charge[key]} />
    ))}
  </div>
);

ArrestCharge.propTypes = {
  charge: PropTypes.object.isRequired,
};

export default ArrestCharge;
