import PropTypes from 'prop-types';
import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';
import Panel from '../Panels';

const Subscribed = ({ onSubmit }) => (
  <Panel
    i18n
    title='components.Verification.Unsubscribe.title'
    className='text-center'
  >
    <p className='lead'>
      <T value='components.Verification.Unsubscribe.intro' />
    </p>
    <p>
      <T value='components.Verification.Unsubscribe.text' />
    </p>
    <M.Button kind='warning' onClick={onSubmit}>
      <T value='buttons.unsubscribeNow' />
    </M.Button>
  </Panel>
);

Subscribed.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Subscribed;
