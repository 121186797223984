import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { Field } from 'redux-form';
import { i18n } from '@international/mastodon-i18n';

import * as V from '../../../../lib/validations/candidateStoryValidations';

import { CANDIDATE_STORY_MAX_CHARACTER_LIMIT } from '../../../../constants';

interface CandidateStoryItemTextFieldProps {
  field: string;
}

const CandidateStoryItemTextField = ({
  field,
}: CandidateStoryItemTextFieldProps) => {
  return (
    <div>
      <p className='text-muted'>
        <T value='components.Report.CandidateStories.Modal.text.note' />
      </p>
      <Field
        name={field}
        component='textarea'
        className='form-control'
        rows='6'
        maxLength={CANDIDATE_STORY_MAX_CHARACTER_LIMIT}
        placeholder={i18n.getStr(
          'components.Report.CandidateStories.Modal.text.placeholder',
        )}
        validate={[V.contentOrDocRequired, V.maxDocuments, V.maxContentLength]}
        data-testid='candidate-stories-text-field'
      />
    </div>
  );
};

export default i18n.renderTranslation()(CandidateStoryItemTextField);
