import React from 'react';
import { M, colors } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Field } from 'redux-form';
import { StyleableContainer } from './StyleableContainer';
import { Type } from './Type';
import {
  Heading,
  Alignment as HeadingAlignment,
  defaultStyle as HeadingStyle,
} from './Heading';
import { Icon } from './Icon';
import { Paragraph } from './Paragraph';
import { SecureDisclosure } from './SecureDisclosure';
import { ReduxFileInput } from './ReduxFileInput';
import FooterButtons from './FooterButtons';
import Typography2024Hack from './Typography2024Hack';
import { Page } from './Page';

interface DocumentUploadProps {
  greeting: React.ReactNode;
  setPage: (pageToSet: Page) => void;
}

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
  greeting,
  setPage,
}) => {
  return (
    <M.Container
      style={{
        background: colors.uiGrey50,
        margin: 0,
        padding: '24px 16px 56px 16px',
        border: 'none',
      }}
    >
      {greeting}
      <StyleableContainer type={Type.Info} styledTopBorder>
        <Icon type={Type.Info} />
        <Heading
          textKey='components.InstantExceptions.DocumentUpload.uploadHeading'
          align={HeadingAlignment.Center}
        />
        <M.Divider style={{ width: '100%', margin: '8px 0px' }} />
        <Paragraph
          textKey='components.InstantExceptions.DocumentUpload.uploadDetail'
          align={HeadingAlignment.Left}
        >
          <strong>
            {i18n.getStr('components.InstantExceptions.DocumentUpload.name')}
          </strong>
          {i18n.getStr('components.InstantExceptions.DocumentUpload.and')}
          <strong>
            {i18n.getStr('components.InstantExceptions.DocumentUpload.birth')}
          </strong>
          {i18n.getStr('components.InstantExceptions.DocumentUpload.readable')}
        </Paragraph>
        <Field name='idDocument' component={ReduxFileInput} type='hidden' />
        <Heading
          textKey='components.InstantExceptions.DocumentUpload.acceptedHeader'
          align={HeadingAlignment.Left}
          style={{ ...HeadingStyle, ...Typography2024Hack.label }}
        />
        <M.UnorderedList>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted1'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted2'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted3'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted4'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted5'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted6'
            />
          </M.ListItem>
          <M.ListItem>
            <Paragraph
              p4
              textKey='components.InstantExceptions.DocumentUpload.accepted7'
            />
          </M.ListItem>
        </M.UnorderedList>
        <M.Divider style={{ width: '100%', margin: 0 }} />
        <SecureDisclosure />
      </StyleableContainer>
      <FooterButtons>
        <M.Button
          onClick={() => setPage(Page.UploadDocumentLater)}
          kind='secondary'
        >
          {i18n.getStr('buttons.addLater')}
        </M.Button>
        <M.Button type='submit'>{i18n.getStr('buttons.submit')}</M.Button>
      </FooterButtons>
    </M.Container>
  );
};
