/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { PATHS } from '../constants';
import { fetchCandidateUsingIds } from '../actions';

export const LOCALSTORAGE_TOKEN_KEY = 'token';

export const EmailAuthComponent = ({
  children,
  portal,
  candidateLogin,
  fetchCandidate,
}) => {
  const { reports, processing, token, count } = portal;
  const { candidateIds, loggedIn, candidateJwt } = candidateLogin;

  React.useEffect(() => {
    if (candidateJwt && candidateIds && count === 0 && loggedIn) {
      fetchCandidate({ candidateIds, candidateJwt });
    }
  }, [candidateJwt, candidateIds, loggedIn, count, fetchCandidate]);

  React.useEffect(() => {
    if (token) {
      window.sessionStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
    }
  }, [token]);

  if (loggedIn && !processing && reports) {
    return <Navigate to={PATHS.REPORTS_OVERVIEW} />;
  }
  return <>{children}</>;
};

EmailAuthComponent.propTypes = {
  children: PropTypes.node,
  portal: PropTypes.object.isRequired,
  candidateLogin: PropTypes.object.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  portal: state.portal,
  candidateLogin: state.candidateLogin,
});

const mapDispatchToProps = dispatch => ({
  fetchCandidate: values => dispatch(fetchCandidateUsingIds(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailAuthComponent);
