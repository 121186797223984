import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { File } from 'components/Workflow/Disputes/Types';
import { FILE_STACK_API_KEY } from '../../constants';
import FilestackCustomRender from './FilestackCustomRender';
import SecureFilestack from '../SecureFilestack/SecureFilestack';
import useFilestackOptions from './hooks/useFIlestackOptions';

import './FilestackUpload.scss';

export interface CustomUploadProps {
  buttonText?:
    | {
        default: string;
        more?: string;
      }
    | string;
  labelText: string;
  options?: any;
  disabled?: boolean;
  errorMessage?: string;
  onChange?: (event: { value: File[] }) => void;
}

const CustomUpload: React.FC<CustomUploadProps> = ({
  buttonText = '',
  labelText,
  options = {},
  onChange = () => {},
  disabled,
  errorMessage = '',
}) => {
  const [uploadedFiles, setUploadedFiles] = React.useState<any[]>([]);
  const [newFiles, setNewFiles] = React.useState(false);
  const [error, setError] = React.useState('');

  const onRemoveHandler = (fileName: string) => {
    setUploadedFiles(prevUploadedFiles =>
      prevUploadedFiles.reduce<any[]>((result, currentFile) => {
        if (currentFile.filename === fileName) return result;
        return [...result, currentFile];
      }, []),
    );
  };
  const fileStackOptions = useFilestackOptions(options);

  const CustomRender = React.useCallback(
    ({ onPick }: any) => (
      <FilestackCustomRender
        onClick={onPick}
        uploadedFiles={uploadedFiles}
        maxFiles={fileStackOptions.maxFiles}
        labelText={labelText}
        buttonText={buttonText}
        onRemove={onRemoveHandler}
        disabled={disabled}
        errorMessage={error}
      />
    ),
    [uploadedFiles, labelText, buttonText, disabled, fileStackOptions, error],
  );

  React.useEffect(() => {
    if (newFiles) {
      setNewFiles(false);
      onChange({ value: uploadedFiles });
    }
  }, [newFiles, onChange, uploadedFiles]);

  React.useEffect(() => {
    if (errorMessage === 'validations.requiredDocuments.one') {
      setError(
        i18n.getStr(
          `components.Verifications.IdDocumentVerification.inputErrorMessage.required`,
          2,
        ),
      );
    }
  }, [errorMessage]);

  return (
    <div
      className={`file-uploader${
        error || errorMessage ? ' file-uploader--error' : ''
      }`}
    >
      <SecureFilestack
        apikey={FILE_STACK_API_KEY}
        actionOptions={fileStackOptions}
        componentDisplayMode={{
          type: 'immediate',
        }}
        customRender={CustomRender}
        onSuccess={(response: any) => {
          setUploadedFiles(prevFiles => {
            const result = [...prevFiles, ...response.filesUploaded];
            if (result.length > fileStackOptions.maxFiles)
              setError(
                i18n.getStr(
                  `components.Verifications.IdDocumentVerification.inputErrorMessage.maxFiles`,
                  2,
                ),
              );
            return result.slice(0, fileStackOptions.maxFiles);
          });
          setNewFiles(true);
        }}
      />
    </div>
  );
};

export default CustomUpload;
