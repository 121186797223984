import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import Typography2024 from './Typography2024Hack';

interface HeadingProps {
  textKey: string;
  align?: Alignment;
  style?: React.CSSProperties;
}

export enum Alignment {
  Center = 1,
  Left,
  Right,
}

export const defaultStyle = (align: Alignment): React.CSSProperties => {
  return {
    textAlign:
      // eslint-disable-next-line no-nested-ternary
      align === Alignment.Center
        ? 'center'
        : align === Alignment.Right
        ? 'right'
        : 'left',
    width: '100%',
    margin: 0,
    gap: '16px',
    ...Typography2024.h4,
  };
};

export const Heading: React.FC<HeadingProps> = ({
  textKey,
  align = Alignment.Left,
  style = null,
}) => {
  return <h4 style={style || defaultStyle(align)}>{i18n.getStr(textKey)}</h4>;
};
