import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import { M } from '@dashboard-experience/mastodon';
import { DISPUTES_FLOW } from 'identifiers/identifiers';

const SubmitButton = ({
  submissionInProgress,
  handleSubmit,
  submitDisabled,
}: {
  submissionInProgress: boolean;
  handleSubmit: () => void;
  submitDisabled: boolean;
}) => {
  if (submissionInProgress) {
    return (
      <M.LoadingInline
        role='progressbar'
        description={i18n.getStr(
          'workflows.dispute.summary.spinnerDescription',
        )}
      />
    );
  }
  return (
    <M.Button
      data-testid={DISPUTES_FLOW.disputeSubmitButton}
      className='analytics_summary_submit_button'
      size='sm'
      kind='primary'
      aria-disabled={submitDisabled}
      disabled={submitDisabled}
      onClick={handleSubmit}
    >
      {i18n.getStr('buttons.submit')}
    </M.Button>
  );
};

export default SubmitButton;
