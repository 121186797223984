import React, { useEffect, useState } from 'react';
import { Report } from 'types/report';
import LicenseInformationSubreasonSelector from 'components/Workflow/Disputes/MotorVehicleReport/LicenseInformationSubreasonSelector';
import MotorVehicleRecordSubreasonSelector from 'components/Workflow/Disputes/MotorVehicleReport/MotorVehicleRecordSubreasonSelector';
import MotorVehicleRecordSubreasonDetailSelector from 'components/Workflow/Disputes/MotorVehicleReport/MotorVehicleRecordSubreasonDetailSelector';
import {
  useDisputeReasonSummaryBuilder,
  useWorkflowScreenStates,
  WorkflowScreenStates,
} from 'components/Workflow/hooks';
import { MotorVehicleRecord } from 'types/motorVehicleReport';
import PaidFinesInformation from 'components/Workflow/Disputes/MotorVehicleReport/PaidFinesInformation';
import CauseConsiderStatusInformation from 'components/Workflow/Disputes/MotorVehicleReport/CauseConsiderStatusInformation';
import ProvideInformationSingleForm from 'components/Workflow/Disputes/MotorVehicleReport/ProvideInformationSingleForm';
import ProvideInformationMultiForm from 'components/Workflow/Disputes/MotorVehicleReport/ProvideInformationMultiForm';
import { isReportEligibleForUpdateDL } from 'components/Reports/Report/lib/mvr';
import MotorVehicleRecordInformationSelector from './MotorVehicleReport/MotorVehicleRecordInformationSelector';
import SituationSelector from './MotorVehicleReport/SituationSelector';
import Breadcrumb from '../Breadcrumb';
import Summary from './MotorVehicleReport/Summary';
import NewDLInformation from './MotorVehicleReport/NewDLInformation';
import ProvideInformationVisitedDMV from './MotorVehicleReport/ProvideInformationVisitedDMV';

const workflowScreenStates: WorkflowScreenStates = {
  situationSelector: {
    transitions: {
      newDLInformation: 'newDLInformation',
      inaccurateLicenseInfo: 'licenseInformationSubreasonSelector',
      inaccurateMotorVehicleRecord: 'motorVehicleRecordSubreasonSelector',
      paidFinesInformation: 'paidFinesInformation',
      causeConsiderStatusInformation: 'causeConsiderStatusInformation',
      summary: 'summary',
    },
    crumbKey: 'workflows.dispute.situationSelector.breadcrumb',
  },
  licenseInformationSubreasonSelector: {
    transitions: {
      previous: 'situationSelector',
      newDLInformation: 'newDLInformation',
      next: 'provideInformationVisitedDMV',
    },
    crumbKey:
      'workflows.dispute.licenseInformationSubreasonSelector.breadcrumb',
  },
  provideInformationVisitedDMV: {
    transitions: {
      next: 'provideInformationMultiForm',
      previous: 'licenseInformationSubreasonSelector',
    },
    crumbKey: 'workflows.dispute.confirmVisitedDMV.breadcrumb',
  },
  motorVehicleRecordSubreasonSelector: {
    transitions: {
      next: 'motorVehicleRecordInformationSelector',
      previous: 'situationSelector',
    },
    crumbKey:
      'workflows.dispute.motorVehicleRecordSubreasonSelector.breadcrumb',
  },
  motorVehicleRecordSubreasonDetailSelector: {
    transitions: {
      previous: 'motorVehicleRecordInformationSelector',
      next: 'provideInformationSingleForm',
    },
    crumbKey:
      'workflows.dispute.motorVehicleRecordSubreasonDetailSelector.breadcrumb',
  },
  motorVehicleRecordInformationSelector: {
    transitions: {
      previous: 'motorVehicleRecordSubreasonSelector',
      next: 'motorVehicleRecordSubreasonDetailSelector',
    },
    crumbKey:
      'workflows.dispute.motorVehicleRecordInformationSelector.breadcrumb',
  },
  newDLInformation: {
    transitions: {
      previous: 'situationSelector',
    },
    crumbKey: 'workflows.dispute.newDLInformation.breadcrumb',
  },
  provideInformationSingleForm: {
    transitions: {
      previous: 'motorVehicleRecordSubreasonDetailSelector',
      next: 'summary',
    },
    crumbKey: 'workflows.dispute.provideInformationForm.breadcrumb',
  },
  provideInformationMultiForm: {
    transitions: {
      previous: 'provideInformationVisitedDMV',
      next: 'summary',
    },
    crumbKey: 'workflows.dispute.provideInformationForm.breadcrumb',
  },
  summary: {
    transitions: {
      previous: 'provideInformationMultiForm',
      situationSelector: 'situationSelector',
    },
    crumbKey: 'workflows.dispute.summary.breadcrumb',
  },
  paidFinesInformation: {
    transitions: {
      previous: 'situationSelector',
    },
    crumbKey: 'workflows.dispute.finesHaveBeenPaidInformation.breadcrumb',
  },
  causeConsiderStatusInformation: {
    transitions: {
      previous: 'situationSelector',
    },
    crumbKey: 'workflows.dispute.causeConsiderStatusInformation.breadcrumb',
  },
};

interface WorkflowProps {
  back?: () => void;
  candidateStoriesEnabled: boolean;
  toggle: () => void;
  toggleCandidateStoryModal: () => void;
  report: Report;
  setHasSubmitted: (param: boolean) => void;
  toggleUpdateDLModal: () => void;
}

const Workflow = ({
  back,
  candidateStoriesEnabled,
  toggle,
  toggleCandidateStoryModal,
  toggleUpdateDLModal,
  report,
  setHasSubmitted,
}: WorkflowProps) => {
  const FIRST_LICENSE_REPORT = report.motorVehicleReport?.licenseReports[0]!;
  const [visitedDmvDate, setVisitedDmvDate] = useState('');
  const [confirmVisitedDmv, setConfirmVisitedDmv] = useState(false);

  const {
    addBreadCrumbKeyPath,
    currentScreen,
    transitionToScreen,
    nextScreen,
    previousScreen,
    breadCrumbKeys,
    breadCrumbKeyPaths,
    removeBreadCrumbKeyPath,
    transitionToScreenWithMostRecentBreadCrumbs,
    transitionBackToScreen,
  } = useWorkflowScreenStates('situationSelector', workflowScreenStates);

  const {
    destroyDraft,
    draftDisputeReasonSummaries,
    initDraft,
    finalizedDisputeReasonSummaries,
    finalizeDrafts,
    updateDraft,
    clearDrafts,
    removeFinalizedDisputeReasonSummary,
  } = useDisputeReasonSummaryBuilder();

  useEffect(() => {
    // Only run if the previous page was Provide Information, and we have
    // more finalized drafts than paths saved.
    if (
      breadCrumbKeys[breadCrumbKeys.length - 2] ===
        'workflows.dispute.provideInformationForm.breadcrumb' &&
      finalizedDisputeReasonSummaries.length > breadCrumbKeyPaths.length
    ) {
      addBreadCrumbKeyPath();
    }
  }, [
    breadCrumbKeys,
    addBreadCrumbKeyPath,
    finalizedDisputeReasonSummaries,
    breadCrumbKeyPaths,
  ]);

  const [currentMotorVehicleRecord, setCurrentMotorVehicleRecord] =
    useState<MotorVehicleRecord | null>(null);

  const situationSelector = () => (
    <SituationSelector
      back={back}
      candidateStoriesEnabled={candidateStoriesEnabled}
      finalizedDisputeReasonSummaries={finalizedDisputeReasonSummaries}
      toggle={toggle}
      toggleCandidateStoryModal={toggleCandidateStoryModal}
      toggleUpdateDLModal={toggleUpdateDLModal}
      transitionToSummary={() =>
        transitionToScreenWithMostRecentBreadCrumbs('summary')
      }
      reasonSelected={reason => {
        transitionToScreen(reason);
      }}
      licenseReport={FIRST_LICENSE_REPORT}
      report={report}
    />
  );

  const clearDraftsAndMoveToPreviousScreen = () => {
    clearDrafts();
    previousScreen();
  };

  const licenseInformationSubreasonSelector = () => (
    <LicenseInformationSubreasonSelector
      back={clearDraftsAndMoveToPreviousScreen}
      continueNext={nextScreen}
      licenseReport={FIRST_LICENSE_REPORT}
      destroyDraft={destroyDraft}
      draftDisputeReasonSummaries={draftDisputeReasonSummaries}
      initDraft={initDraft}
      updateDraft={updateDraft}
      transitionToScreen={transitionToScreen}
      isReportEligibleForUpdateDL={isReportEligibleForUpdateDL(report)}
      toggle={toggle}
      toggleUpdateDLModal={toggleUpdateDLModal}
    />
  );

  const setCurrentMotorVehicleRecordAndMoveToNextScreen = (
    motorVehicleRecord: MotorVehicleRecord,
  ) => {
    setCurrentMotorVehicleRecord(motorVehicleRecord);
    nextScreen();
  };

  const motorVehicleRecordSubreasonSelector = () => (
    <MotorVehicleRecordSubreasonSelector
      back={clearDraftsAndMoveToPreviousScreen}
      continueNext={setCurrentMotorVehicleRecordAndMoveToNextScreen}
      licenseReport={FIRST_LICENSE_REPORT}
      initDraft={initDraft}
    />
  );

  const motorVehicleRecordSubreasonDetailSelector = () => {
    return (
      <MotorVehicleRecordSubreasonDetailSelector
        draftDisputeReasonSummaries={draftDisputeReasonSummaries}
        updateDraft={updateDraft}
        motorVehicleRecord={currentMotorVehicleRecord as MotorVehicleRecord}
        back={previousScreen}
        continueNext={nextScreen}
      />
    );
  };

  const motorVehicleRecordInformationSelector = () => {
    return (
      <MotorVehicleRecordInformationSelector
        back={previousScreen}
        continueNext={nextScreen}
      />
    );
  };

  const finalizeAndMoveToNextScreen = () => {
    finalizeDrafts();
    nextScreen();
  };

  const provideInformationSingleForm = () => {
    return (
      <ProvideInformationSingleForm
        continueNext={finalizeAndMoveToNextScreen}
        back={previousScreen}
        draftDisputeReasonSummaries={draftDisputeReasonSummaries}
        updateDraft={updateDraft}
      />
    );
  };

  const provideInformationMultiForm = () => {
    return (
      <ProvideInformationMultiForm
        continueNext={finalizeAndMoveToNextScreen}
        back={previousScreen}
        draftDisputeReasonSummaries={draftDisputeReasonSummaries}
        updateDraft={updateDraft}
      />
    );
  };

  const provideInformationVisitedDMV = () => (
    <ProvideInformationVisitedDMV
      continueNext={nextScreen}
      back={previousScreen}
      getVisitedDmvDate={() => visitedDmvDate}
      passVisitedDmvDate={setVisitedDmvDate}
      passConfirmVisitedDmv={setConfirmVisitedDmv}
    />
  );

  const deleteCard = (index: number) => {
    removeFinalizedDisputeReasonSummary(index);
    removeBreadCrumbKeyPath(index);
  };

  const summary = () => (
    <Summary
      closed={toggle}
      finalizedDisputeReasonSummaries={finalizedDisputeReasonSummaries}
      report={report}
      setHasSubmitted={setHasSubmitted}
      addAnError={() => {
        transitionBackToScreen('situationSelector');
      }}
      deleteCard={deleteCard}
      visitedDmvDate={visitedDmvDate}
      confirmVisitedDmv={confirmVisitedDmv}
    />
  );

  const renderCurrentScreen = () => {
    switch (currentScreen) {
      case 'situationSelector':
        return situationSelector();
      case 'licenseInformationSubreasonSelector':
        return licenseInformationSubreasonSelector();
      case 'motorVehicleRecordSubreasonSelector':
        return motorVehicleRecordSubreasonSelector();
      case 'motorVehicleRecordSubreasonDetailSelector':
        return motorVehicleRecordSubreasonDetailSelector();
      case 'motorVehicleRecordInformationSelector':
        return motorVehicleRecordInformationSelector();
      case 'provideInformationSingleForm':
        return provideInformationSingleForm();
      case 'provideInformationMultiForm':
        return provideInformationMultiForm();
      case 'provideInformationVisitedDMV':
        return provideInformationVisitedDMV();
      case 'summary':
        return summary();
      case 'paidFinesInformation':
        return <PaidFinesInformation back={previousScreen} closed={toggle} />;
      case 'causeConsiderStatusInformation':
        return (
          <CauseConsiderStatusInformation
            back={previousScreen}
            closed={toggle}
          />
        );
      case 'newDLInformation':
        return <NewDLInformation back={previousScreen} closed={toggle} />;
      default:
        return '';
    }
  };

  return (
    <div role='region' aria-live='polite'>
      <Breadcrumb
        crumbKeys={[
          'workflows.dispute.screenings.motorVehicleReport',
          ...breadCrumbKeys,
        ]}
      />
      {renderCurrentScreen()}
    </div>
  );
};

export default Workflow;
