import React from 'react';
import PropTypes from 'prop-types';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';

const Notice = ({ text }) => (
  <M.Card className='notice' style={{ marginBottom: '16px' }}>
    <p>
      <M.Icon icon='Warning' style={{ margin: 0 }} />
      <strong>&nbsp; {i18n.getStr('components.Report.reportNotice')}</strong>
    </p>
    <p>{text}</p>
  </M.Card>
);

Notice.propTypes = {
  text: PropTypes.string,
};

Notice.defaultProps = {
  text: '',
};

export default Notice;
