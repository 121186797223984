import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate as T } from 'react-redux-i18n';
import { formatSearchTitle } from '../../lib/screenings';
import Status from '../../Status';
import { trackAnalyticsEvent } from '../../../../../actions';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_PROPERTIES,
  AMPLITUDE_NAME_MAP,
} from '../../../../../lib/analytics';

const IntlAdverseMedia = ({ record }) => {
  const dispatch = useDispatch();
  if (!record) {
    return null;
  }

  return (
    <>
      <div className='d-flex mb-2'>
        <div>
          <strong>{formatSearchTitle(record)}</strong>
        </div>
        <div className='ml-auto'>
          <Status status={record.status} />
        </div>
      </div>
      {record.pdfUrl && (
        <div>
          <a
            href={record.pdfUrl}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              dispatch(
                trackAnalyticsEvent(ANALYTICS_EVENTS.SCREENING_DOWNLOADED, {
                  [ANALYTICS_PROPERTIES.SCREENING_NAME]:
                    AMPLITUDE_NAME_MAP.media_search,
                }),
              );
            }}
          >
            <T value='components.viewReport' />
          </a>
        </div>
      )}
    </>
  );
};

IntlAdverseMedia.propTypes = {
  record: PropTypes.object,
};

IntlAdverseMedia.defaultProps = {
  record: null,
};

export default IntlAdverseMedia;
