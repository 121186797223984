import React from 'react';
import { useSelector } from 'react-redux';
import { LOCALSTORAGE_TOKEN_KEY } from 'containers/Auth';
import { isSessionStorageSupported } from 'lib/helpers';
import { useNavigate, useLocation } from 'react-router-dom';
import useLocationQuery from './useLocationQuery';

const getStorageToken = () => {
  if (isSessionStorageSupported()) {
    return window.sessionStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
  }
  return '';
};

const setStorageToken = (token: string) => {
  if (isSessionStorageSupported()) {
    window.sessionStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
  }
};

const useToken = () => {
  const { token: searchParamsToken } = useLocationQuery();
  const storeToken = useSelector((state: any) => state.portal.token);
  const storageToken = getStorageToken();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (searchParamsToken) {
      setStorageToken(searchParamsToken);
      const search = new URLSearchParams(location.search);
      search.delete('token');
      navigate(`${location.pathname}?${search.toString()}`, { replace: true });
    }
    if (!searchParamsToken && !storageToken && storeToken) {
      setStorageToken(storeToken);
    }
  }, [searchParamsToken, location, navigate, storageToken, storeToken]);
  return searchParamsToken || storageToken || storeToken;
};

export default useToken;
