export enum Page {
  Waiting = 'Waiting',
  StartedYourBackgroundCheck = 'Started Your Background Check',
  ReceivedYourInfo = 'Received your info',
  UploadDocumentLater = 'Upload document later',
  SsnReentry = 'SSN Reentry',
  SsnUpload = 'SSN Upload',
  UploadDeadend = "Upload deadend (can't accept uploads)",
  DocumentUpload = 'Document upload',
  ContactCheckrDeadend = 'Contact Checkr Deadend',
}

export default Page;
