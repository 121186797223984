import { decamelizeKeys } from 'humps';
import { getAPIBase } from '../api/verifications/getAPIBase';
import { request } from './helper';
import { BACKEND_FLAG_PATHS } from '../constants';

/*
 * Action Types
 */

export const SUBMIT_COMMENT_REQUEST = 'SUBMIT_COMMENT_REQUEST';
export const submitCommentRequestAction = () => ({
  type: SUBMIT_COMMENT_REQUEST,
});
export const SUBMIT_COMMENT_SUCCESS = 'SUBMIT_COMMENT_SUCCESS';
export const submitCommentSuccessAction = () => ({
  type: SUBMIT_COMMENT_SUCCESS,
});

/*
 * Actions
 */

export const submitComment = values => dispatch => {
  dispatch(submitCommentRequestAction());

  const submissionValues = {
    report: values.report,
    comment: values.comment,
    documents: values.documents || [],
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(decamelizeKeys(submissionValues)),
  };

  return getAPIBase({ path: BACKEND_FLAG_PATHS.COMMENTS }).then(baseURL =>
    request(`${baseURL}/comments`, options).then(() =>
      dispatch(submitCommentSuccessAction()),
    ),
  );
};
