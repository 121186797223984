import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Report } from 'types/report';
import { Translate as T } from 'react-redux-i18n';
import { DISPUTES_FLOW } from 'identifiers/identifiers';
import { M } from '@dashboard-experience/mastodon';
import DisputeForm from './DisputeForm';
import DisputeSuccess from './DisputeSuccess';
import '../../../../scss/DisputeModal.scss';

interface Props {
  disputeSubmitted?: boolean;
  isOpen?: boolean;
  report?: Report;
  toggleDisputeModal?: () => void;
  toggleCandidateStoryModal: () => void;
}

const DisputeModal = ({
  disputeSubmitted = false,
  isOpen = false,
  toggleDisputeModal = () => {},
  toggleCandidateStoryModal,
  report = {} as Report,
}: Props) => {
  useEffect(() => {
    if (!window.adaEmbed) return;
    if (isOpen) {
      window.adaEmbed.stop().catch((e: Error) => {
        if (e.name !== 'AdaEmbedError') {
          throw e;
        }
      });
    } else {
      window.adaEmbed.start(window.adaSettings).catch((e: Error) => {
        if (e.name !== 'AdaEmbedError') {
          throw e;
        }
      });
    }
  }, [isOpen]);

  return (
    <M.ComposedModal open={isOpen} data-testid={DISPUTES_FLOW.disputeModal}>
      <M.ModalHeader buttonOnClick={toggleDisputeModal}>
        <T value='components.Report.Dispute.newTitle' />
      </M.ModalHeader>
      <M.ModalBody>
        {disputeSubmitted ? (
          <DisputeSuccess />
        ) : (
          <DisputeForm
            report={report}
            toggleCandidateStoryModal={toggleCandidateStoryModal}
            toggleDisputeModal={toggleDisputeModal}
          />
        )}
      </M.ModalBody>
    </M.ComposedModal>
  );
};

DisputeModal.propTypes = {
  disputeSubmitted: PropTypes.bool,
  isOpen: PropTypes.bool,
  report: PropTypes.object,
  toggleDisputeModal: PropTypes.func,
  toggleCandidateStoryModal: PropTypes.func.isRequired,
};

DisputeModal.defaultProps = {
  disputeSubmitted: false,
  isOpen: false,
  report: {},
  toggleDisputeModal: () => {},
};

export default DisputeModal;
