import { Report } from 'types/report';
import moment from 'moment';
import { AssessReportStatuses } from '@dashboard-experience/utils';

export type CountyCriminalSearch = {
  status: string;
  county: string;
  state: string;
  createdAt: string;
};

export type CustomIncidentFlagContext = {
  county: string;
  state: string;
};

export type CustomIncidentFlagCheck = {
  context: CustomIncidentFlagContext;
  contentKey: string;
};

const FORTY_EIGTH_HOURS_IN_SECONDS = 48 * 60 * 60;

export const shouldAddCountyCriminalSearchCustomIncidentMessage = (
  countyCriminalSearch: CountyCriminalSearch,
  county: String,
  state: String,
) => {
  const createdAt =
    countyCriminalSearch.createdAt && moment(countyCriminalSearch.createdAt);
  const timeNow = moment();
  const wasCreatedBeforeTimeLimit =
    timeNow.diff(createdAt, 'seconds') > FORTY_EIGTH_HOURS_IN_SECONDS;

  const isCountyLosAngeles =
    countyCriminalSearch.county.toLowerCase() === county.toLowerCase();
  const isStateCalifornia =
    countyCriminalSearch.state.toLowerCase() === state.toLowerCase();
  const isPending =
    countyCriminalSearch.status === AssessReportStatuses.PENDING;

  return (
    isCountyLosAngeles &&
    isStateCalifornia &&
    isPending &&
    wasCreatedBeforeTimeLimit
  );
};

export const shouldAddLACountyCriminalSearchCustomIncidentMessage = (
  countyCriminalSearch: CountyCriminalSearch,
) => {
  return shouldAddCountyCriminalSearchCustomIncidentMessage(
    countyCriminalSearch,
    'Los Angeles',
    'CA',
  );
};

export const shouldAddOrangeCountyCriminalSearchCustomIncidentMesssage = (
  countyCriminalSearch: CountyCriminalSearch,
) => {
  return shouldAddCountyCriminalSearchCustomIncidentMessage(
    countyCriminalSearch,
    'Orange',
    'CA',
  );
};

export const shouldAddSanLuisObispoCountyCriminalSearchCustomIncidentMessage = (
  countyCriminalSearch: CountyCriminalSearch,
) => {
  return shouldAddCountyCriminalSearchCustomIncidentMessage(
    countyCriminalSearch,
    'San Luis Obispo',
    'CA',
  );
};

export const generateCustomIncidentsContentKey = (report: Report) => {
  if (
    report.countyCriminalSearches === undefined ||
    report.countyCriminalSearches.length === 0
  ) {
    return [];
  }

  // const checks: Array<CustomIncidentFlagCheck> = [];
  const checks: Record<string, CustomIncidentFlagContext> = {};

  for (const countyCriminalSearch of report.countyCriminalSearches) {
    if (
      shouldAddLACountyCriminalSearchCustomIncidentMessage(countyCriminalSearch)
    ) {
      checks[
        'components.ApiStatusBanner.customIncidents.LACountyCriminalSearch'
      ] = {
        county: 'los angeles',
        state: 'ca',
      } as CustomIncidentFlagContext;
    }
    if (
      shouldAddOrangeCountyCriminalSearchCustomIncidentMesssage(
        countyCriminalSearch,
      )
    ) {
      checks[
        'components.ApiStatusBanner.customIncidents.OrangeCountyCriminalSearch'
      ] = {
        county: 'orange',
        state: 'ca',
      };
    }
    if (
      shouldAddSanLuisObispoCountyCriminalSearchCustomIncidentMessage(
        countyCriminalSearch,
      )
    ) {
      checks[
        'components.ApiStatusBanner.customIncidents.SanLuisObispoCountyCriminalSearch'
      ] = {
        county: 'san luis obispo',
        state: 'ca',
      };
    }
  }

  const checksArray: Array<CustomIncidentFlagCheck> = [];
  for (const [contentKey, context] of Object.entries(checks)) {
    checksArray.push({ contentKey, context });
  }
  return checksArray;
};

export const daysSinceCreation = (dateCreated: string) => {
  const now = moment();
  const creationDate = moment(dateCreated);
  const differenceInDays = now.diff(creationDate, 'days');

  return differenceInDays;
};
