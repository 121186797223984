import { QueryKey, useQuery } from '@tanstack/react-query';
import portalQueryClient from '../../providers/queryClient';
import { fetchFlagr } from './flagrActions';
import setFlagrResponse, { UseFlagrResponse } from './lib/setFlagrResponse';

export interface UseFlagrParams {
  id: string;
  context?: Record<string, any>;
  entity?: Record<string, any>;
  fallbackVariant?: string;
}

interface Flag {
  variantKey: string;
  variantAttachment?: any;
  variantID?: string;
}

export interface UseFlagrReturn extends UseFlagrResponse {
  flag: Flag;
  isLoading: boolean;
  isError: boolean;
}

const useFlagr = ({
  id,
  context = {},
  entity = {},
  fallbackVariant = '',
}: UseFlagrParams): UseFlagrReturn => {
  const queryKey: QueryKey = ['flagr', id, context, entity];
  const queryFn = () => fetchFlagr(id, context, entity);
  let flag = { variantKey: '' } as Flag;
  const { data, isLoading, isError, ...rest } = useQuery<UseFlagrResponse>({
    queryKey,
    queryFn,
    placeholderData: () =>
      setFlagrResponse({ context, entity, data: { variantKey: '' } }),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: Boolean(id),
  });

  if (!isLoading && data?.flag) {
    flag = data.flag as Flag;
  } else if (isError) {
    portalQueryClient.setQueryData(
      queryKey,
      setFlagrResponse({
        context,
        entity,
        data: { variantKey: fallbackVariant },
      }),
    );

    flag = { variantKey: fallbackVariant } as Flag;
  }
  return {
    flag,
    isLoading,
    isError,
    context,
    ...rest,
  };
};

export default useFlagr;
