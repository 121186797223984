import PropTypes from 'prop-types';
import React from 'react';
import USProfile from './USProfile';
import CanadaProfile from './CanadaProfile';
import InternationalProfile from './InternationalProfile';

const CandidateProfileSelector = ({
  canada,
  international,
  pdf,
  report,
  segmentationEnabled,
}) => {
  const profile = () => {
    if (canada && international) {
      throw new Error('invalid state: Canadian and International candidate.');
    } else if (canada) {
      return <CanadaProfile report={report} pdf={pdf} />;
    } else if (international) {
      return <InternationalProfile report={report} pdf={pdf} />;
    } else {
      return (
        <USProfile
          report={report}
          pdf={pdf}
          segmentationEnabled={segmentationEnabled}
        />
      );
    }
  };

  return profile();
};

CandidateProfileSelector.propTypes = {
  canada: PropTypes.bool.isRequired,
  international: PropTypes.bool.isRequired,
  pdf: PropTypes.bool.isRequired,
  report: PropTypes.object.isRequired,
  segmentationEnabled: PropTypes.bool.isRequired,
};

export default CandidateProfileSelector;
