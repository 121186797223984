import React from 'react';
import { moment, i18n } from '@international/mastodon-i18n';
import { ReportSummary } from 'api/candidates/types/Report';
import { KeyValuePair } from 'types/mastodon';
import { formatDate } from '../../lib/formatDate';
import isActionRequired from '../../lib/isActionRequired';

interface ReportListItems {
  list: KeyValuePair[];
  footerItems: KeyValuePair[];
}

const INITIAL_REPORT_LIST_ITEM: ReportListItems = { list: [], footerItems: [] };

const getI18nText = (
  category: 'description' | 'title',
  text: string,
): string => {
  return `${i18n.getStr(
    `components.CandidateLandingPage.card.textItem.${category}.${text}`,
  )}${category === 'title' && text !== 'inReview' ? ':' : ''}`;
};

const getEta = (reportCardData: ReportSummary) => {
  if (isActionRequired(reportCardData)) {
    return {
      itemKey: getI18nText('title', 'eta'),
      itemValue: getI18nText('description', 'paused'),
    };
  }
  const now = moment().startOf('day');
  const etaDate = moment(reportCardData.eta?.replace(/z$/i, '')).startOf('day');
  if (etaDate.isBefore(now)) return null;
  return {
    itemKey: getI18nText('title', 'eta'),
    itemValue: formatDate(reportCardData.eta),
  };
};

const getDateStatus = (reportCardData: ReportSummary) => {
  switch (reportCardData.status) {
    case 'pending':
      return getEta(reportCardData);
    case 'clear':
    case 'consider':
    case 'complete':
      return {
        itemKey: getI18nText('title', 'completed'),
        itemValue: formatDate(reportCardData.completedDate),
      };
    default:
      return {
        itemKey: getI18nText('title', reportCardData.status),
        itemValue: formatDate(reportCardData.completedDate),
      };
  }
};

const getListItems = (reportCardData: ReportSummary): KeyValuePair[] => {
  const defaultReportTypes = ['mvr', 'drug', 'health', 'general', 'paused'];
  const result: KeyValuePair[] = [
    {
      itemKey: getI18nText('title', 'type'),
      itemValue: defaultReportTypes.includes(reportCardData.type)
        ? getI18nText('description', reportCardData.type)
        : reportCardData.type,
    },
  ];
  const dateStatus = getDateStatus(reportCardData);
  if (dateStatus) result.push(dateStatus);
  return result;
};

const useReportCardListItem = (
  reportCardData: ReportSummary,
): ReportListItems => {
  const [listItems, setListItems] = React.useState<ReportListItems>(
    INITIAL_REPORT_LIST_ITEM,
  );

  React.useEffect(() => {
    const resultItems = {
      list: getListItems(reportCardData),
      footerItems: [
        ...(isActionRequired(reportCardData) && reportCardData.completeByDate
          ? [
              {
                itemKey: getI18nText('title', 'completeByDate'),
                itemValue: formatDate(reportCardData.completeByDate),
              },
            ]
          : []),
        ...(reportCardData.status === 'dispute'
          ? [
              {
                itemKey: getI18nText('title', 'inReview'),
                itemValue: '',
              },
            ]
          : []),
      ],
    };
    setListItems(resultItems);
  }, [reportCardData]);

  return listItems;
};

export default useReportCardListItem;
