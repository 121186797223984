import PropTypes from 'prop-types';
import React from 'react';
import { i18n } from '@international/mastodon-i18n';
import styled from 'styled-components';

import { inputErrorClass } from './helper';
import FeedbackLabel from './FeedbackLabel';
import Label from './Label';

const StyledCharacterCountText = styled.p`
  font-size: 12px !important;
`;

const MultilineText = ({
  autoFocus,
  disabled,
  input,
  label,
  meta: { touched, error, warning },
  placeholder,
  type,
  onPaste,
  dateformat,
  remainingCharacterCount,
  maxLength,
}) => {
  return (
    <div className='form-group'>
      {label && (
        <Label
          label={label}
          htmlFor={input.name}
          error={Boolean(error)}
          touched={touched}
          dateformat={dateformat}
        />
      )}
      <textarea
        {...input}
        id={input.name ? input.name.replace('.', '-') : 'multi-line-text-field'}
        className={`form-control ${inputErrorClass(touched, error)}`}
        placeholder={i18n.getStr(placeholder || label)}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
        onPaste={onPaste}
        rows={6}
        maxLength={maxLength}
      />
      <FeedbackLabel {...{ touched, error, warning }} />
      {remainingCharacterCount >= 0 && (
        <StyledCharacterCountText className='text-muted'>
          {i18n.getStr(
            'components.MultilineText.remainingCharacterCount',
            remainingCharacterCount.toString(),
          )}
        </StyledCharacterCountText>
      )}
    </div>
  );
};

MultilineText.propTypes = {
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  meta: PropTypes.object,
  placeholder: PropTypes.string,
  onPaste: PropTypes.func,
  type: PropTypes.string,
  dateformat: PropTypes.string,
  remainingCharacterCount: PropTypes.number,
  maxLength: PropTypes.number,
};

MultilineText.defaultProps = {
  autoFocus: false,
  disabled: false,
  input: {},
  label: '',
  meta: {},
  placeholder: '',
  onPaste: () => {},
  type: '',
  dateformat: '',
  remainingCharacterCount: -1,
  maxLength: 1000,
};

export default i18n.renderTranslation()(MultilineText);
