import React from 'react';
import { M } from '@dashboard-experience/mastodon';
import DOMPurify from 'isomorphic-dompurify';
import FilePreview from './FilePreview';

const FilestackCustomRender: React.FC<any> = ({
  onClick,
  labelText,
  buttonText,
  maxFiles,
  uploadedFiles,
  onRemove,
  errorMessage,
}) => {
  const [uploadButtonText, setUploadButtonText] = React.useState(
    buttonText.default || buttonText,
  );

  React.useEffect(() => {
    if (uploadedFiles.length === 0 || !buttonText.more) {
      setUploadButtonText(buttonText.default || buttonText);
    } else if (buttonText.more && uploadedFiles.length < maxFiles) {
      setUploadButtonText(buttonText.more);
    } else {
      setUploadButtonText('');
    }
  }, [uploadedFiles.length, maxFiles, buttonText]);
  const onRemoveHandler = (fileName: string) => {
    onRemove(fileName);
  };
  return (
    <>
      <div className='file-uploader__drop-area'>
        {!!labelText && (
          <span
            className='file-uploader__label'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(labelText) }}
          />
        )}
        {uploadedFiles.length > 0 && (
          <div className='file-uploader--thumbs'>
            {uploadedFiles.map((uploadedFile: any) => (
              <FilePreview
                key={uploadedFile.filename}
                url={uploadedFile.url}
                filename={uploadedFile.filename}
                size={uploadedFile.size}
                mimetype={uploadedFile.mimetype}
                onRemove={onRemoveHandler}
              />
            ))}
          </div>
        )}
        {!!uploadButtonText && (
          <M.Button id='upload-btn' kind='secondary' onClick={onClick}>
            {uploadButtonText}
          </M.Button>
        )}
        {!!errorMessage && (
          <span className='file-uploader__error-message'>{errorMessage}</span>
        )}
      </div>
    </>
  );
};

export default FilestackCustomRender;
