import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Translate as T } from 'react-redux-i18n';
import { appBreakpoints } from '../../../reducers';

const CardStyled = styled(M.Container)`
  padding: 39px 23px !important;
`;

const ContainerStyled = styled.div`
  font-size: 14px;
  padding: 1rem 0 0 0;

  @media (min-width: ${appBreakpoints.small}px) {
    padding: 0 18px;
  }

  .cds--link {
    font-size: 14px;
  }

  h5 {
    font-size: 16px;
  }
`;

const FaqStyled = styled.div`
  max-width: 514px;
  padding: 16px 0;

  h3 {
    margin-bottom: 14px !important;
  }
`;

const IconStyled = styled(M.Icon)`
  @media (min-width: ${appBreakpoints.small}px) {
    height: 54px !important;
    width: 54px !important;
  }
`;

const AccordionItemStyled = styled(M.AccordionItem)`
  > button {
    padding: 0 !important;

    .cds--accordion__arrow {
      margin: 12px !important;
    }
  }

  .cds--accordion__content {
    padding-left: 0;
  }

  .cds--accordion__title {
    margin: 0;
    padding: 12px 0;
  }
`;

interface Props {
  companyName: string;
}

const ReportabilityCard = ({ companyName }: Props) => {
  return (
    <div data-testid='reportability-card'>
      <CardStyled>
        <M.Grid>
          <M.Container.Row>
            <M.Container.Col lg={1} sm={12}>
              <IconStyled icon='DocumentUnknown' size='28' />
            </M.Container.Col>
            <M.Container.Col>
              <ContainerStyled>
                <T value='components.Report.Reportability.title' tag='h5' />
                <T
                  value='components.Report.Reportability.subtitle'
                  companyName={companyName}
                />
                <FaqStyled>
                  <T
                    value='components.Report.Reportability.FAQ.title'
                    tag='h3'
                  />
                  <M.Accordion size='sm'>
                    <AccordionItemStyled
                      title={
                        <M.Link>
                          <T value='components.Report.Reportability.FAQ.questions.0.question' />
                        </M.Link>
                      }
                    >
                      <T value='components.Report.Reportability.FAQ.questions.0.answer' />
                    </AccordionItemStyled>
                    <AccordionItemStyled
                      title={
                        <M.Link>
                          <T value='components.Report.Reportability.FAQ.questions.1.question' />
                        </M.Link>
                      }
                    >
                      <T value='components.Report.Reportability.FAQ.questions.1.answer' />
                    </AccordionItemStyled>
                  </M.Accordion>
                </FaqStyled>
                <T value='components.Report.Reportability.readMore' />{' '}
                <M.Link
                  href={i18n.getStr(
                    'components.Report.Reportability.readMoreLinkContentHref',
                  )}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <T value='components.Report.Reportability.readMoreLinkContent' />
                </M.Link>
              </ContainerStyled>
            </M.Container.Col>
          </M.Container.Row>
        </M.Grid>
      </CardStyled>
    </div>
  );
};

export default ReportabilityCard;
