import React from 'react';
import styled from 'styled-components';
import { M } from '@dashboard-experience/mastodon';
import { i18n } from '@international/mastodon-i18n';
import { Link, useNavigate } from 'react-router-dom';
import { clearFetchReportsFailure } from 'actions';
import { useDispatch } from 'react-redux';
import DOMPurify from 'isomorphic-dompurify';
import { PATHS } from '../../constants';

const isNewError = (errorName: string) =>
  ['errors.reportNotFound'].includes(errorName);

const StyledLoginErrorNotification = styled(M.InlineNotification)`
  &.cds--inline-notification.cds--inline-notification--error {
    max-width: 100%;
    border: 0;
    margin: 28px 0;
  }
  .cds--inline-notification__icon {
    margin: 0.15rem 0.65rem 0 0;
    width: 16px;
    height: 16px;
  }
  .cds--inline-notification__details {
    margin: 0 24px 0 15px;
    padding: 18px 0 30px;
    .cds--inline-notification__text-wrapper {
      padding: 0;
    }
    .cds--inline-notification__title {
      padding-bottom: 9px;
    }
    .cds--inline-notification__title,
    .cds--inline-notification__subtitle {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const LoginErrorNotification: React.FC<{
  fetchError: string;
  loginSource?: string;
}> = ({ fetchError, loginSource = 'LoginForm' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      navigate(PATHS.EMAIL_AUTH);
      dispatch(clearFetchReportsFailure());
    },
    [navigate, dispatch],
  );
  if (!fetchError) return null;
  if (isNewError(fetchError) && loginSource !== 'Privacy') {
    const [preText, postText] = i18n
      .getStr(`components.${loginSource}.${fetchError}.text`)
      .split('{{Link}}');
    return (
      <StyledLoginErrorNotification
        hideCloseButton
        kind='error'
        className='login__error-message'
        title={i18n.getStr(`components.${loginSource}.${fetchError}.title`)}
        subtitle={
          <>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(preText),
              }}
            />
            <Link to={PATHS.EMAIL_AUTH} onClick={anchorClickHandler}>
              {i18n.getStr(`components.${loginSource}.${fetchError}.linkText`)}
            </Link>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(postText),
              }}
            />
          </>
        }
      />
    );
  }
  return (
    <div className='alert alert-danger login-error' role='alert'>
      <div>
        <M.Icon icon='WarningAltFilled' />{' '}
        <b>{i18n.getStr(`components.${loginSource}.errorTitle`)}</b>
      </div>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            i18n.getStr(`components.${loginSource}.${fetchError}`),
          ),
        }}
      />
    </div>
  );
};

export default LoginErrorNotification;
