import { camelizeKeys } from 'humps';
import portalQueryClient from '../../providers/queryClient';
import setReportStatusfromResult from './lib/setReportStatusFromResult';

interface ReportQueryData {
  data: { report: Record<string, any> };
}

export const queryReportCache = (id: string) => {
  const queryKey = ['candidate/reports', { id }];
  const queryResponse =
    portalQueryClient?.getQueryData<ReportQueryData>(queryKey);
  if (!queryResponse?.data?.report) return {};
  return setReportStatusfromResult(camelizeKeys(queryResponse.data.report));
};
