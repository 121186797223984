import React from 'react';
import { Translate as T } from 'react-redux-i18n';
import { M } from '@dashboard-experience/mastodon';

interface InfoModalProps {
  handleCloseClick: () => void;
}

const PersonalChecksModalHeader = ({ handleCloseClick }: InfoModalProps) => (
  <header id='personal-checks-modal-header'>
    <p id='personal-checks-modal-header-title'>
      <T value='components.PersonalChecks.modal.title' />
    </p>
    <M.Button
      type='button'
      kind='tertiary'
      onClick={handleCloseClick}
      style={{ color: 'white' }}
    >
      <T value='components.PersonalChecks.modal.close' />
    </M.Button>
  </header>
);

export default PersonalChecksModalHeader;
