import ContentContainer from 'components/Workflow/ContentContainer';
import NavigationFooter from 'components/Workflow/NavigationFooter';
import { Translate as T } from 'react-redux-i18n';
import React from 'react';
import { M } from '@dashboard-experience/mastodon';

interface Props {
  back: () => void;
  closed: () => void;
}

const NewDLInformation = ({ back, closed }: Props) => {
  const closeButton = () => {
    return (
      <M.Button size='sm' kind='secondary' onClick={closed}>
        <T value='buttons.close' />
      </M.Button>
    );
  };
  return (
    <div>
      <ContentContainer>
        <T
          value='workflows.dispute.newDLInformation.explanation'
          tag='div'
          dangerousHTML
        />
      </ContentContainer>
      <NavigationFooter back={back} rightButtonContent={closeButton()} />
    </div>
  );
};

export default NewDLInformation;
